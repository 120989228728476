import React from 'react';
import { HotkeyLabel } from './Hotkeys';
import { Spinner } from './Spinner';

class OneReplyNav extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleSelectReply = this.handleSelectReply.bind(this);
	}
	componentDidMount(){
		if (this.props.onDefaultReply){
			this.props.onDefaultReply();
		}
	}
	handleSelectReply(e) {
		e.preventDefault();
		e.stopPropagation();
		if (this.props.disabled) {
			return;
		}
		this.props.onSelectReply(this.props.me);
	}
	render() {
		const {
				busy
				, me
				, myClass
				, name
				, disabled
				, id
				, 'data-qa-id' : dataQAId
				, tag
				, hotkey
			} = this.props
			;
		let Tag = 'li';
		if (tag === 'a') {
			Tag = 'a';
		}
		return (
			<Tag className="one-reply-nav">
				<label
					onClick={this.handleSelectReply}
					disabled={disabled}
					data-qa-id={dataQAId ? dataQAId : id}
				>
					<div className={myClass}>
						<div className='jq-radio__div'></div>
					</div>
					<span>
						<HotkeyLabel id={me} text={name} hotkey={hotkey} />
					</span>
					<Spinner hidden={!busy} />
				</label>
			</Tag>
		);
	}
};

export default OneReplyNav;
