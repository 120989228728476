import { useCallback, useRef, useState } from "react";
import each from 'lodash/each';
import { I } from "../common/globals";
import {
	INPUT_EMPTY_WARNING,
	doNothing,
	emptyArray
} from "../common/constants";
import { emailValidation, uuidv4 } from "../common/helpers";

export const useCallbackWithValue = (
	value
	, callback
	, addToBackOrArgumentPosition
) => useCallback(
	(...args) => {
		const optionTypeOf = typeof addToBackOrArgumentPosition;
		if (addToBackOrArgumentPosition === false
			|| optionTypeOf === "undefined") {
			return callback(value, ...args);
		} else if (addToBackOrArgumentPosition === true) {
			args.push(value);
		} else if (optionTypeOf !== "number") {
			return callback(...args);
		} else {
			const before = args.slice(0, addToBackOrArgumentPosition)
				, after = args.slice(addToBackOrArgumentPosition)
				;
			before.push(value);
			args = before.concat(after);
		}
		return callback.apply(undefined, args);
	}
	, [value, callback, addToBackOrArgumentPosition]
);

function isEmptyString(value, setWarning) {
	if (value.length === 0) {
		setWarning(INPUT_EMPTY_WARNING);
		return true;
	}
	return false;
}

function checkStringInput(value, setWarning) {
	if (isEmptyString(value, setWarning)) {
		return false;
	}
	setWarning("");
	return true;
}

function isInvalidEmail(value, setWarning) {
	if (!emailValidation(value)) {
		setWarning(I("* Please insert correct contact address"));
		return true;
	}
	return false;
}

function checkEmailAddressInput(value, setWarning) {
	if (isEmptyString(value, setWarning)) {
		return false;
	} else if (isInvalidEmail(value, setWarning)) {
		return false;
	}
	setWarning("");
	return true;
}

const checkValidHandlerByField = {
	emailAddress: checkEmailAddressInput
	, name: checkStringInput
	, wordListname: checkStringInput
};

const useCheckEmailAddressOnBlur = setWarning => useCallback(
	e => {
		const { name, value } = e.target
			, handler = checkValidHandlerByField[name]
			;
		if (typeof handler === "function") {
			handler(value, setWarning);
		}
	}
	, [setWarning]
);

export const useCheckValidValueOnBlur = () => {
	const [ warning, setWarning ] = useState("")
		, handleBlur = useCheckEmailAddressOnBlur(setWarning)
		;
	return [warning, handleBlur];
};

export const useFullCompose = (hook, composer, constants, ...inputs) => {
	if (!constants || !constants.length || constants.length < 0) {
		constants = emptyArray;
	}
	for (let i=0; i<inputs.length; i++) {
		composer = hook(inputs[i], composer, ...constants);
	}
	return composer;
};

// Lower index arg 'inputs' will appear as lower index argument if 'hook' is
// 'useCallbackWithValue'.
// eg: useCompose(useCallbackWithValue, onSave, data1, data2, data3)
//     -> const onSave = (data1, data2, data3) => {...}
export const useCompose = (hook, composer, ...inputs) => useFullCompose(
	hook,
	composer,
	undefined,
	...inputs
)

export const useCallbackMultiValues = (callback, ...values) => useCompose(
  useCallbackWithValue,
  callback,
  ...values
)

const defTimer = 250;
const initDebounce = { timeout: null, args: null, context: null };

export const useDebounce = (fn, wait) => {
	const last = useRef(initDebounce);
	return useCallback((...args) => {
		last.current.args = args;
		last.current.context = this;
		if (!last.current.timeout) {
			last.current.timeout = setTimeout(() => {
				last.current.timeout = null;
				fn.apply(last.current.context, last.current.args);
			}, wait || defTimer);
		}
	}, [fn, wait]);
};

export const useDisableableCallback = (disabled, callback) => useCallback(
	(...args) => {
		if (disabled || typeof callback !== 'function') {
			return
		}
		callback(...args)
	},
	[callback, disabled]
)

export const useToggleBool = callback => useCallback(
	bool => { callback(!bool) },
	[callback]
)

const isWithinSizeLimit = (size, limit) => {
	if (typeof limit === 'undefined' || limit <= 0) {
		return true // don't care about the file limits
	}
	return size <= limit
}

const TXT_UPLOADED_FILE_EXIST_LIMIT = I('The uploaded file ({FILENAME}) has exceeded the max allowed size ({LIMIT})')

const callbackError = (callback, err) => {
	if (typeof callback === 'function') {
		callback(err)
	} else {
		if (process.env.NODE_ENV !== 'production') {
			console.log(err)
		}
	}
}

const oneFormUploadFile = (
	file,
	errorCallback,
	singleFileSizeLimit
) => {
	const { name, size } = file
	if (!isWithinSizeLimit(size, singleFileSizeLimit)) {
		callbackError(errorCallback,
			TXT_UPLOADED_FILE_EXIST_LIMIT
				.replace('{FILENAME}', name)
				.replace('{LIMIT}', singleFileSizeLimit))
		return
	}
	const formData = new FormData()
	formData.append('uploadfile', file)
	formData.append('fileNameOnly', name)
	formData.append('eventType', 'upload')
	formData.append('random', uuidv4())
	return formData
}

export const useFormUploadFile = (
	callback,
	errorCallback,
	multiFiles,
	limit
) => useCallback(
	e => {
		e.preventDefault()
		let { files } = e.target
		if (!files.length) {
			e.target.value = ''
			return
		}
		if (!multiFiles) {
			files = [files[0]]
		}
		const data = []
		let hasError
		each(files, file => {
			const formData = oneFormUploadFile(file, errorCallback, limit)
			if (formData) {
				data.push(formData)
			} else {
				hasError = true
				return false
			}
		})
		e.target.value = ''
		if (hasError || !data.length) {
			return
		}
		if (multiFiles) {
			callback(data, files)
		} else {
			callback(data[0], files[0])
		}
	},
	[callback, errorCallback, multiFiles, limit]
)

export const useIgnorePromiseCatch = callback => useCallback(
	(...args) => { callback(...args).catch(doNothing) },
	[callback]
)
