import React, { Fragment } from 'react'
import { withUnmountWhenHidden } from './hocs'

const withCFlagControl = invert => ({ children, flag }) => {
  if (!cflag.IsActive(flag) != !invert) {
    return children;
  }
  return null
}

const CFlag = withCFlagControl();

export const NoCFlag = withCFlagControl(true);

export default CFlag;

const Hidden = props => <Fragment {...props} />

export const Hideable = withUnmountWhenHidden(Hidden)
