import React, { memo, useCallback, useState } from 'react'
import classNames from 'classnames'
import { withProps } from 'recompose'
import { AUTH_GOOGLE_OAUTH2 } from '../common/constants'
import {
  createServerChangeHandler,
  nameFromOpenIdToken
} from '../common/helpers'
import { useCallbackWithValue } from '../hooks/callback'
import { composeWithDisplayName, withUnmountWhenHidden } from './hocs'
import { useTokenAuthorization } from './OauthToken'
import {
  AuthLogo,
  createServerPortChangeHandler,
  createWithTokenChangeProvider,
  triggerHandler,
  withAuthorized
} from './Microsoft'

// TODO: should pull from backend
const google = 'Google'
const gmail = 'Gmail'

// TODO: can be exported for reuse
const AuthLogoButton = ({
  constants,
  disabled,
  logo,
  onTrigger,
  provider,
  service
}) => {
  const [random, setRandom] = useState('')
  return (
    <AuthLogo
      disabled={disabled}
      logo={logo}
      onClick={useTokenAuthorization(
        constants,
        provider,
        service,
        setRandom
      )}
      onTrigger={useCallbackWithValue(random, onTrigger, 1)}
      provider={provider}
      service={service}
      title={service}
    />
  )
}

const useIMAPGmailTrigger = ({
  constants,
  onChangeName,
  onChangeSecure,
  onChangeServer,
  onChangeToken,
  onChangeUsername,
  onPopAlert
}) => useCallback(
  (data, random) => {
    const { imapGmail } = constants
    const serverChanger = condition => createServerChangeHandler(
      condition,
      imapGmail
    )
    triggerHandler(
      data,
      ({ email, hd, ...jwt }) => {
        onChangeName(nameFromOpenIdToken(jwt) || email)
        onChangeServer(serverChanger(host => !host || !hd))
        onChangeUsername(email)
      },
      err => { onPopAlert(err) },
      (data, success) => {
        if (success) {
          onChangeSecure(true)
          onChangeToken(data)
        }
      },
      google,
      onPopAlert,
      random,
      false
    )
  },
  [
    constants,
    onChangeName,
    onChangeSecure,
    onChangeServer,
    onChangeToken,
    onChangeUsername,
    onPopAlert
  ]
)

const useSMTPGmailTrigger = ({
  constants,
  onChangeServerPort,
  onChangeToken,
  onChangeUsername,
  onPopAlert
}) => useCallback(
  (data, random) => {
    const { smtpGmail, smtpGmailPort } = constants
    const serverPortChanger = condition => createServerPortChangeHandler(
      condition,
      smtpGmail,
      smtpGmailPort
    )
    triggerHandler(
      data,
      ({ email, hd }) => {
        onChangeServerPort(serverPortChanger(host => !host || !hd))
        onChangeUsername(name => {
          if (name) {
            return name
          }
          return email
        })
      },
      err => { onPopAlert(err) },
      (data, success) => {
        if (success) {
          onChangeToken(data)
        }
      },
      google,
      onPopAlert,
      random,
      false
    )
  },
  [
    constants,
    onChangeServerPort,
    onChangeToken,
    onChangeUsername,
    onPopAlert
  ]
)

const createWithTriggerProps = hook => withProps(({
  oldVersion,
  ...props
}) => ({
  logo: classNames({ fa: oldVersion, fab: !oldVersion }, 'fa-google'),
  onTrigger: hook(props),
  provider: google,
  service: gmail
}))

const createWithGmailOAuth2 = triggerHook => composeWithDisplayName(
  'withGmailOAuth2',
  memo,
  withUnmountWhenHidden,
  createWithTokenChangeProvider(AUTH_GOOGLE_OAUTH2),
  createWithTriggerProps(triggerHook)
)

const createGmailOAuth2 = triggerHook => composeWithDisplayName(
  google,
  createWithGmailOAuth2(triggerHook),
  withAuthorized
)(AuthLogoButton)

const SMTPGmailOAuth2 = createGmailOAuth2(useSMTPGmailTrigger)

export default SMTPGmailOAuth2

export const IMAPGmailOAuth2 = createGmailOAuth2(useIMAPGmailTrigger)
