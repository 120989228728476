import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

const Count = ({show, value}) => {
	if (!show) {
		return null;
	}
	let text;
	if (!value) {
		text = "(0)";
	} else {
		text = "(" + value + ")";
	}
	return <span>&nbsp;{text}</span>;
};

export const SimpleLink = ({text, withCount, count, onClick, title, children}) => (
	<a onClick={onClick} title={title}>
		{text}
		{children}
		<Count show={!!withCount} value={count} />
	</a>
);

export const FolderBorder = ({hidden, active, children, ...props}) => {
	if (hidden) {
		return null;
	}
	return (
		<li data-qa-id={props["data-qa-id"]}
			className={classNames({current: active})}
		>
			{children}
		</li>
	);
};
