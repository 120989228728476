import React, { memo } from 'react'
import classNames from 'classnames'
import { I } from '../common/globals'
import { composeWithDisplayName, withUnmountWhenHidden } from './hocs'
import { ButtonIcon } from './HeaderActions'

const ToolbarButtonIconBase = ({ active, className, onClick }) => (
  <ButtonIcon
    className={classNames(className, "btn toggle-toolbar", { active })}
    data-qa-id="btn-toggleToolbar"
    icon="icon-toolbox"
    onClick={onClick}
    noTooltip={true}
    overflow={-30}
    title={I("Toggle toolbar")}
  />
)

const ToolbarButtonIcon = composeWithDisplayName(
  'ToolbarButtonIcon',
  withUnmountWhenHidden,
  memo
)(ToolbarButtonIconBase)

export default ToolbarButtonIcon
