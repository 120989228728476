import React from 'react';
import PopupPage from './PopupPage';
import Button from './Button';

const redBtnStyle = {
	borderColor: '#F4213F',
	backgroundColor: '#F4213F',
	marginRight: '10px',
	color: '#FFF',
	fontSize: '12px',
	padding: '8px 16px',
	borderRadius: '4px'
}
const transparentBtnStyle = {
	borderColor: 'transparent',
	backgroundColor: 'transparent',
	marginRight: '10px',
	color: '#6D6D6D',
	fontSize: '12px',
	padding: '8px 16px'
}

export const DeletePopup = ({ title, msg, icon, show, onDelete, onClose, deleteTxt = I("Delete") }) => {
	return <PopupPage
		data-qa-id="msg-alert-backdrop-popup"
		extraClass="msg-alert-backdrop"
		innerClass="msg-alert"
		show={show}
		onClose={onClose}
	>	<div className="popup-alert">
			<div className="popup-content">
				<div className="popup-icon"><i className={icon}></i></div>
				<div className="popup-text header">
					{title}
				</div>
				<div className="popup-text">
					{msg}
				</div>
			</div>
			<div className="popup-buttons">
				<Button key="btn-cancel" style={transparentBtnStyle} text={I("Cancel")} className="cancel-button" onClick={onClose} />
				<Button key="btn-delete" style={redBtnStyle} text={deleteTxt} className="delete-button" onClick={onDelete} />
			</div>
		</div>
	</PopupPage>
}