import React from 'react';
import PropTypes from 'prop-types';
import ReactMoment from 'react-moment';
import moment from 'moment';
import classNames from 'classnames';
import { PF_DEFAULT } from '../common/v5/constants';
import Anchor from './Anchor';

// TODO: global settings like this may be need be centralized else any other
// component that use 'react-moment' may not aware of this global settings.
ReactMoment.globalMoment = moment.parseZone;
ReactMoment.globalLocale = moment.locale(currentInterface || 'en');

// NOTE: different time format from backend server
// history.created              YYYY-MM-DD HH:mm:ss   "2018-06-26 13:10:36"
// history.lastAction.dateTime  X                     1546596417
//   - history.lastSaved.dateTime
//   - history.actions.ts
//   - chat messages
//   - notifications
//   - internal messages
// history.actions.datetime     YYYY-MM-DD HH:mm:ss Z "2018-09-13 17:53:12 +0800 +08"
// errandList.data.date         YYYY/MM/DD HH:mm      "2018/03/28 14:48"
//   - errandList.data.answeredDate

const FullTimeBorder = ({ children }) => (
	<span className="tooltip-content date text-center">
		<div className="content">{children}</div>
	</span>
);

const StaticFullTime = ({ date }) => <FullTimeBorder>{date}</FullTimeBorder>;

function withFullTimeFactory(MomentComponent, readOnly) {
	return ({ hideFullTime, ...props }) => {
		let fullTime;
		if (!hideFullTime) {
			if (!props.parseFormat || props.parseFormat === PF_DEFAULT) {
				// no need moment component here because date already in the
				// same format the moment component going to produce.
				fullTime = <StaticFullTime date={props.date} />;
			} else {
				const { humanCutOff, ...propsWithoutCutOff } = props;
				fullTime = (
					<MomentComponent {...propsWithoutCutOff}
						element={FullTimeBorder}
						format={PF_DEFAULT}
					/>
				);
			}
		} else {
			fullTime = null;
		}
		return (
			<Anchor
				className={classNames(
					"moment"
					, "tooltip-without-before"
					, props.className
				)}
				readOnly={readOnly}
			>
				<span className="moment-datetime">
					<MomentComponent {...props} />
				</span>
				{fullTime}
			</Anchor>
		);
	};
}

export function withFullTime(MomentComponent) {
	return withFullTimeFactory(MomentComponent, false);
}

export function withReadOnlyFullTime(MomentComponent) {
	return withFullTimeFactory(MomentComponent, true);
}

function getDateAndParseFormat(
	noTZParse
	, date
	, parseFormat
	, timezoneOffset
) {
	const noParseFormatProvided = !parseFormat;
	let parse;
	if (noParseFormatProvided) {
		parse = PF_DEFAULT;
	} else {
		parse = parseFormat;
	}
	if (noParseFormatProvided || !noTZParse) {
		if (timezoneOffset && typeof timezoneOffset === "string") {
			parse += "Z";
			date += timezoneOffset;
		}
	}
	return [date, parse];
}

function withFormatChangeAfterTime(
	MomentComponent
	, duration
	, newFormat
	, hideFullTimeIfNoChange
) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleChange = this.handleChange.bind(this);
			this.state = {
				// purposely default to true because once it is false then
				// format won't change any more and will always be human date
				// time afterward.
				change: true
			};
		}
		checkChange() {
			const now = moment().unix()
				, [ date, parseFormat ] = getDateAndParseFormat(
					this.props.noTZParse
					, this.props.date
					, this.props.parseFormat
					, this.props.timezoneOffset
				)
				;
			const result = this.props.showExactDayAndTime ? (now - moment(date, parseFormat).unix()) : (now - moment(date, parseFormat).unix()) <= duration;
			return result;
		}
		componentDidMount() {
			this.setState({change: this.checkChange()});
		}
		handleChange(...args) {
			this.setState({change: this.checkChange()});
			if (typeof this.props.onChange === "function") {
				this.props.onChange(...args);
			}
		}
		render() {
			let { format, hideFullTime, onChange, ...props } = this.props;
			if (!format && this.state.change) {
				format = newFormat;
				if (hideFullTimeIfNoChange) {
					hideFullTime = true;
				}
				onChange = this.handleChange;
			}
			return (
				<MomentComponent {...props}
					humanCutOff={duration}
					format={format}
					hideFullTime={hideFullTime}
					onChange={onChange}
				/>
			);
		}
	}
}

const DISABLE_UPDATE = 0
	, MIN_UPDATE_INTERVAL = 120000 // miliseconds
	, HUMAN_DATE_TIME_CUT_OFF = 2592000 // seconds, 30 days, 7 days (604800)
	;
export function startPooledTimer() {
	return ReactMoment.startPooledTimer(MIN_UPDATE_INTERVAL);
}

export function clearPooledTimer() {
	return ReactMoment.clearPooledTimer();
}

export function withFormatChange(MomentComponent) {
	return withFormatChangeAfterTime(
		MomentComponent
		, HUMAN_DATE_TIME_CUT_OFF
		, PF_DEFAULT
		, true
	);
}

// NOTE: for testing purposes as it use shorter duration before changing format
// from full-date-time to human-date-time.
// export function withTestFormatChange(MomentComponent) {
// 	return withFormatChangeAfterTime(
// 		MomentComponent
// 		, 150
// 		, PF_DEFAULT
// 		, true
// 	);
// }

const Moment = ({
	noTZParse
	, date: dateValue
	, humanCutOff
	, timezoneOffset
	, noAgo
	, onChange
	, parseFormat
	, format
	, element
}) => {
	const [ date, parse ] = getDateAndParseFormat(
			noTZParse
			, dateValue
			, parseFormat
			, timezoneOffset
		)
		;
	let interval;
	if (!humanCutOff && format) {
		// if just display full-time without display-format change then no need
		// refresh the component.
		interval = DISABLE_UPDATE;
	} else {
		interval = MIN_UPDATE_INTERVAL;
	}
	return (
		<ReactMoment
			element={element}
			interval={interval}
			fromNow
			onChange={onChange}
			parse={parse}
			date={date}
			ago={noAgo}
			format={format}
		/>
	);
};

Moment.propTypes = {
	noTZParse: PropTypes.bool
	, date: PropTypes.oneOfType([
		PropTypes.number
		, PropTypes.string
	]).isRequired
	, timezoneOffset: PropTypes.string // TODO: check agent-agent chat .isRequired
	, noAgo: PropTypes.bool
	, parseFormat: PropTypes.string
	, format: PropTypes.string
	, element: PropTypes.any
};

export default Moment;
