import React, { memo, useCallback, useMemo, useState } from 'react'
import classNames from 'classnames'
import { withProps } from 'recompose'
import update from 'immutability-helper'
import jwtDecode from 'jwt-decode'
import styled from 'styled-components'
import { I } from '../common/globals'
import { AUTH_MICROSOFT_OAUTH2, AUTH_MICROSOFT_OAUTH2_API, TXT_NO_ID_TOKEN } from '../common/constants'
import {
  fontSizeAdminForm,
  inputPadding,
  inputDoubleSpace,
  inputSpace
} from '../styles/_variables'
import { hasPrefix } from '../common/helpers'
import { useCallbackWithValue } from '../hooks/callback'
import { useDetectKeyPressed } from '../hooks/key'
import { composeWithDisplayName, withUnmountWhenHidden } from './hocs'
import { ButtonBlock } from './Button'
import OauthToken, { useTokenAuthorization } from './OauthToken'

const microsoft = 'Microsoft'
const ews = 'EWS'
const ewsAdminConsent = 'EWSadminconsent'
const smtp = 'SMTP'
const smtpAdminconsent = 'SMTPadminconsent'
const graphProvider = "Microsoft";
const msGraph = 'GraphAPI';
const msGraphAdminconsent = 'GraphAPIadminconsent'

const Logo = ({ className }) => <i className={className} />

export const AuthLogo = ({
  disabled,
  logo,
  onClick,
  onTrigger,
  provider,
  service,
  title
}) => (
  <ButtonBlock
    className='btn btn-sm'
    color='primary'
    disabled={disabled}
    onClick={onClick}
    title={`${provider} - ${title}`}
  >
    <OauthToken
      provider={provider}
      onTrigger={onTrigger}
      service={service}
    />
    <Logo className={logo} />
  </ButtonBlock>
)

const AuthButton = ({ oldVersion, ...props }) => (
  <AuthLogo
    logo={classNames({ fa: oldVersion, fab: !oldVersion }, 'fa-windows')}
    provider={microsoft}
    {...props}
  />
)

const AuthButtonGraph = ({ oldVersion, ...props }) => (
  <AuthLogo
    logo={classNames({ fa: oldVersion, fab: !oldVersion }, 'fa-windows')}
    provider={graphProvider}
    {...props}
  />
)

const adminConsentCallback = data => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('dbg: microsoft admin consent:', { data })
  }
}

const handleRandomNotMatch = (expected, result, onPopAlert) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('dbg: id token nonce not match:', { expected, result })
    if (typeof onPopAlert === 'function') {
      onPopAlert(I("Token can't be trusted as the randomized id doesn't match, expected {EXPECTED} is not equal to the result {RESULT}")
        .replace('{EXPECTED}', expected)
        .replace('{RESULT}', result))
    }
  }
}

export const triggerHandler = (
  { data, state },
  parseTokenSuccessHandler,
  parseTokenFailHandler,
  finallyHandler,
  provider,
  onPopAlert,
  random,
  idTokenIsOptional
) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(`dbg: ${provider} token:`, { data })
  }
  let token
  if (data.id_token) {
    token = data.id_token
  } else if (idTokenIsOptional) {
    token = data.access_token
  } else {
    onPopAlert(TXT_NO_ID_TOKEN)
    return
  }
  let jwt
  try {
    jwt = jwtDecode(token)
  } catch (e) {
    console.log(
      `parse ${provider} jwt failed likely non-organization account:`,
      e
    )
    if (typeof parseTokenFailHandler === 'function') {
      parseTokenFailHandler(e)
    }
    if (typeof finallyHandler === 'function') {
      finallyHandler(data, undefined, e)
    }
    return
  }
  if (process.env.NODE_ENV !== 'production') {
    console.log(`dbg: ${provider} jwt:`, { jwt })
  }
  if (random) {
    if (random !== jwt.nonce) {
      handleRandomNotMatch(random, jwt.nonce, onPopAlert)
      return
    } else if (random !== state) {
      handleRandomNotMatch(random, state, onPopAlert)
      return
    }
  }
  if (typeof parseTokenSuccessHandler === 'function') {
    parseTokenSuccessHandler(jwt)
  }
  if (typeof finallyHandler === 'function') {
    finallyHandler(data, jwt)
  }
}

const handleTriggerCallback = (
  data,
  isAdminConsent,
  onChangeAdminConsent,
  onChangeToken,
  parseTokenSuccessHandler,
  parseTokenFailHandler,
  onPopAlert,
  random
) => {
  if (isAdminConsent(data.service)) {
    adminConsentCallback(data.data)
    onChangeAdminConsent(false)
    return
  }
  triggerHandler(
    data,
    parseTokenSuccessHandler,
    parseTokenFailHandler,
    data => { onChangeToken(data) },
    microsoft,
    onPopAlert,
    random,
    true
  )
}

const useEWSTrigger = ({
  constants,
  onChangeAdminConsent,
  onChangeName,
  onChangeServer,
  onChangeToken,
  onChangeUsername
}) => useCallback(
  data => {
    // eslint-disable-next-line camelcase
    const { aud_office_outlook, outlook365_ews_url } = constants
    handleTriggerCallback(
      data,
      service => service === ewsAdminConsent,
      onChangeAdminConsent,
      onChangeToken,
      // eslint-disable-next-line camelcase
      ({ aud, family_name, given_name, upn }) => {
        let name
        // eslint-disable-next-line camelcase
        if (family_name || given_name) {
          // eslint-disable-next-line camelcase
          name = given_name + (given_name && family_name ? ' ' : '') +
            // eslint-disable-next-line camelcase
            family_name
        } else {
          name = upn
        }
        onChangeName(name)
        if (hasPrefix(aud, aud_office_outlook)) {
          onChangeServer(outlook365_ews_url)
        }
        onChangeUsername(upn)
      },
      () => {
        onChangeServer(host => {
          if (!host) {
            // eslint-disable-next-line camelcase
            return outlook365_ews_url
          }
          return host
        })
      }
    )
  },
  [
    constants,
    onChangeAdminConsent,
    onChangeName,
    onChangeServer,
    onChangeToken,
    onChangeUsername
  ]
)


const handleGraphTriggerCallback = (
  data,
  isAdminConsent,
  onChangeAdminConsent,
  onChangeToken,
  parseTokenSuccessHandler,
  parseTokenFailHandler,
  onPopAlert,
  random
) => {
  if (isAdminConsent(data.service)) {
    adminConsentCallback(data.data)
    onChangeAdminConsent(false)
  }
  triggerHandler(
    data,
    parseTokenSuccessHandler,
    parseTokenFailHandler,
    data => { onChangeToken(data) },
    graphProvider,
    onPopAlert,
    random,
    true
  )
}

const useMSGTrigger = ({
  constants,
  onChangeAdminConsent,
  onChangeName,
  onChangeServer,
  onChangeToken,
  onChangeUsername
}) => useCallback(
  data => {
    // eslint-disable-next-line camelcase
    const { aud_office_outlook, MicroSoftGraphURL } = constants
    handleGraphTriggerCallback(
      data,
      service => service === msGraph,
      onChangeAdminConsent,
      onChangeToken,
      ({ name, upn }) => {
         if(!name) {
          name = upn
        }
        onChangeName(name)
        onChangeServer(MicroSoftGraphURL)
        onChangeUsername(upn);
      },
      () => {
        onChangeServer(() => {
            return MicroSoftGraphURL
        })
      }
    )
  },
  [
    constants,
    onChangeAdminConsent,
    onChangeName,
    onChangeServer,
    onChangeToken,
    onChangeUsername
  ]
)

const adminConsentSecretKeys = ['shiftKey', { key: 'A' }, { key: 'C' }]

// Use secret combination SHIFT + A + C then click Microsoft button to start
// admin consent authorization flow.
const MicrosoftBase = ({
  constants,
  disabled,
  isAdminConsent,
  expectedService,
  expectedAdminConsentService,
  oldVersion,
  onTrigger,
  ...props
}) => {
  const [random, setRandom] = useState('')
  const secretKeysPressed = useDetectKeyPressed(adminConsentSecretKeys)
  const service = useMemo(
    () => {
      if (isAdminConsent || secretKeysPressed) {
        return expectedAdminConsentService
      }
      return expectedService
    },
    [
      expectedAdminConsentService,
      expectedService,
      isAdminConsent,
      secretKeysPressed
    ]
  )
  const handleToken = useTokenAuthorization(
    constants,
    microsoft,
    service,
    setRandom
  )
  const { onChangeAdminConsent } = props
  const handleClick = useCallback(
    (...args) => {
      const result = handleToken(...args)
      if (service === expectedAdminConsentService) {
        onChangeAdminConsent(true)
      }
      return result
    },
    [
      expectedAdminConsentService,
      handleToken,
      onChangeAdminConsent,
      service
    ]
  )
  return (
    <AuthButton
      disabled={disabled}
      oldVersion={oldVersion}
      onClick={handleClick}
      onTrigger={useCallbackWithValue(random, onTrigger, 1)}
      service={service}
      title={expectedService}
    />
  )
}

const withEWSTokenTrigger = Component => props => (
  <Component
    expectedService={ews}
    expectedAdminConsentService={ewsAdminConsent}
    onTrigger={useEWSTrigger(props)}
    {...props}
  />
)
const withMSGraphTokenTrigger = Component => props => (
  <Component
    expectedService={msGraph}
    expectedAdminConsentService={msGraphAdminconsent}
    onTrigger={useMSGTrigger(props)}
    {...props}
  />
)
export const createWithTokenChangeProvider = provider => withProps(
  ({ onChangeToken }) => ({
    onChangeToken: useCallback(
      data => { onChangeToken(update(data, { $merge: { provider } })) },
      [onChangeToken]
    )
  })
)


const MicrosoftGraphBase = ({
  constants,
  disabled,
  isAdminConsent,
  expectedService,
  expectedAdminConsentService,
  oldVersion,
  onTrigger,
  ...props
}) => {
  const [random, setRandom] = useState('')
  const secretKeysPressed = useDetectKeyPressed(adminConsentSecretKeys)
  const service = useMemo(
    () => {
      if (isAdminConsent || secretKeysPressed) {
        return expectedAdminConsentService
      }
      return expectedService
    },
    [
      expectedAdminConsentService,
      expectedService,
      isAdminConsent,
      secretKeysPressed
    ]
  )
  const handleToken = useTokenAuthorization(
    constants,
    graphProvider,
    service,
    setRandom
  )
  const { onChangeAdminConsent } = props
  const handleClick = useCallback(
    (...args) => {
      const result = handleToken(...args)
      if (service === expectedAdminConsentService) {
        onChangeAdminConsent(true)
      }
      return result
    },
    [
      expectedAdminConsentService,
      handleToken,
      onChangeAdminConsent,
      service
    ]
  )
  return (
    <AuthButtonGraph
      disabled={disabled}
      oldVersion={oldVersion}
      onClick={handleClick}
      onTrigger={useCallbackWithValue(random, onTrigger, 1)}
      service={service}
      title={expectedService}
    />
  )
}

const createOAuth2TokenHandling = hoc => composeWithDisplayName(
  'Microsoft',
  memo,
  withUnmountWhenHidden,
  createWithTokenChangeProvider(AUTH_MICROSOFT_OAUTH2),
  hoc
)(MicrosoftBase)

const Microsoft = createOAuth2TokenHandling(withEWSTokenTrigger)
const createOAuth2TokenAPIHandling = hoc => composeWithDisplayName(
  'MicrosoftGraph',
  memo,
  withUnmountWhenHidden,
  createWithTokenChangeProvider(AUTH_MICROSOFT_OAUTH2_API),
  hoc
)(MicrosoftGraphBase)

export const MicrosoftGraph = createOAuth2TokenAPIHandling(withMSGraphTokenTrigger)

export default Microsoft

export const useServerPortChange = (
  host,
  port,
  onChangeServer,
  onChangePort
) => useCallback(
  changer => {
    const [_host, _port] = changer(host, port)
    onChangeServer(_host)
    onChangePort(_port)
  },
  [host, onChangePort, onChangeServer, port]
)

export const createServerPortChangeHandler = (
  condition,
  matchedConditionHost,
  matchedConditionPort
) => (host, port) => {
  if (condition(host, port)) {
    host = matchedConditionHost
    port = matchedConditionPort
  }
  return [host, port]
}

const useSMTPTrigger = ({
  constants,
  onChangeAdminConsent,
  onChangeServerPort,
  onChangeToken,
  onChangeUsername,
  onPopAlert
}) => useCallback(
  (data, random) => {
    const { guidMicrosoftAccount, smtpOffice365, smtpOffice365Port } = constants
    const serverPortChanger = condition => createServerPortChangeHandler(
      condition,
      smtpOffice365,
      smtpOffice365Port
    )
    handleTriggerCallback(
      data,
      service => service === smtpAdminconsent,
      onChangeAdminConsent,
      onChangeToken,
      ({ email, iss, upn }) => {
        onChangeServerPort(serverPortChanger(
          host => !host || (iss && iss.indexOf(guidMicrosoftAccount) >= 0)
        ))
        onChangeUsername(name => {
          if (name) {
            return name
          }
          return email || upn
        })
      },
      () => { onChangeServerPort(serverPortChanger(host => !host)) },
      onPopAlert,
      random
    )
  },
  [
    constants,
    onChangeAdminConsent,
    onChangeServerPort,
    onChangeToken,
    onChangeUsername,
    onPopAlert
  ]
)

const withSMTPTokenTrigger = Component => props => (
  <Component
    expectedService={smtp}
    expectedAdminConsentService={smtpAdminconsent}
    onTrigger={useSMTPTrigger(props)}
    {...props}
  />
)

export const OAuth2SMTP = createOAuth2TokenHandling(withSMTPTokenTrigger)

const Span = withUnmountWhenHidden('span')

const StyledSpan = styled(Span)`
  display: flex;
  > * + * {
    margin-left: ${inputSpace};
  }
`
const AdminConsent = ({ hidden, onChangeAdminConsent }) => (
  <StyledSpan className='admin-consent' hidden={hidden}>
    <input type='checkbox' checked onChange={onChangeAdminConsent} />
    <span>Admin consent</span>
  </StyledSpan>
)

const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  .authorized, .admin-consent {
    padding-top: ${inputSpace};
  }
  > * {
    font-size: ${fontSizeAdminForm};
  }
  > button {
    padding: ${inputPadding};
  }
  > * + * {
    margin-left: ${inputDoubleSpace};
  }
`

export const withAuthorized = Component => ({
  children,
  hasAccessToken,
  onClearToken,
  tokenProvider,
  ...props
}) => (
  <StyledDiv>
    <div>{hasAccessToken}</div>
    <Component {...props} />
    <Span className='authorized' hidden={!hasAccessToken}>
      {I('Authorized')}
    </Span>
    {children}
  </StyledDiv>
)

const withAdminConsentBase = Component => props => {
  const [isAdminConsent, changeAdminConsent] = useState(false)
  const cancelAdminConsent = useCallback(
    () => changeAdminConsent(false),
    [changeAdminConsent]
  )
  return (
    <Component
      isAdminConsent={isAdminConsent}
      onChangeAdminConsent={changeAdminConsent}
      {...props}
    >
      <AdminConsent
        hidden={!isAdminConsent}
        onChangeAdminConsent={cancelAdminConsent}
      />
    </Component>
  )
}

export const withAdminConsent = composeWithDisplayName(
  'withAdminConsent',
  withAdminConsentBase,
  withAuthorized
)
