// Wrapped on react-ellipsis-text library to create a auto-size-able and
// ellipsis-able span-text.
import React from 'react';
// import classNames from 'classnames';
import update from 'immutability-helper';
import memoizeOne from 'memoize-one';
import { AutoSizer } from 'react-virtualized';
// import Measure from "react-measure";
import ReactEllipsisText from "react-ellipsis-text";

// NOTE: to use AutoSizer or Measure correctly, the child component need to be
// a block element (div) as only block element can have valid width or height.
// If that is not satisfy, the width or height will return as 0 value.

const pixelPerCharWidth = 6
	, displaySize = width => Math.floor(width/pixelPerCharWidth)
	, memoizedDisplaySize = memoizeOne(displaySize)
	;
const EllipsisTextBase = ({ innerRef, style, text, width, ...props }) => (
	<div {...props} ref={innerRef} style={style}>
		<ReactEllipsisText text={text} length={memoizedDisplaySize(width)} />
	</div>
);

// NOTE: please not remove this as this is kind of sample of how to use
// react-meausre successfully.
// const MeasureEllipsisText = ({ style, text, ...props }) => (
// 	<Measure bounds>
// 	{({ contentRect, measureRef }) => {
// 		const maxWidth = {width: "100%"};
// 		if (!style) {
// 			style = maxWidth;
// 		} else {
// 			style = update(style, {$merge: maxWidth});
// 		}
// 		return (
// 			<EllipsisTextBase {...props}
// 				innerRef={measureRef}
// 				style={style}
// 				text={text}
// 				width={contentRect.bounds.width}
// 			/>
// 		);
// 	}}
// 	</Measure>
// );

const EllipsisText = ({ style, text, ...props }) => {
	if (!text) {
		return null;
	}
	return (
		<AutoSizer disableHeight>
		{({ width }) => {
			const newWidth = {width: width+"px"};
			if (!style) {
				style = newWidth;
			} else {
				style = update(style, {$merge: newWidth});
			}
			return (
				<EllipsisTextBase {...props}
					style={style}
					text={text}
					width={width}
				/>
			);
		}}
		</AutoSizer>
	);
};

export default EllipsisText;
