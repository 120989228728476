import React, { PureComponent } from 'react';
import { SelectBox } from './SelectBox';

class ListSortByDate extends PureComponent {
	constructor(props){
		super(props);
	}
	render(){
		const { options, selectedSort, showSortSelection, onSelectSort, onToggleSort } = this.props;
		return(
			<SelectBox
					top='26px' maxHeight='972px'
					data-qa-id={this.props["data-qa-id"]}
					className="conversations-sort-select"
					textNoItemSelected={I('By Date')}
					sort={true}
					visible={true}
					doNotShowSelectedValue={true}
					selected={selectedSort} onSelect={onSelectSort}
					onToggleShow={onToggleSort}
					show={showSortSelection} options={options} />
			)
	}
}

export default ListSortByDate;