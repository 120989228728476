import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { branch, withProps } from 'recompose';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import {
	Doughnut as ChartDN
	, HorizontalBar as ChartHB
	, Line as ChartLN
	, Bar as ChartVB
	, Pie as ChartPIE
} from 'react-chartjs-2';
import { isChartGrid } from '../common/v5/helpers';
// TODO: look wrong awkward to have redux related code here? 'reactcomponent'
// should never has any redux state as all pass through container. Or
// chartIdString just not a selector.
import { chartIdString } from '../redux/selectors/statistics';
import {
	composeWithDisplayName
	, createWithMountCondition
	, withChartSizer
	, withPointerClickableOnClick
	, withSizeControl
	, withUnmountWhenHidden
} from './hocs';
import OrganizationBarChart from './OrganizationBarChart';
import LeaderboardTable from './LeaderboardTable';
import ContactsToplist from './ContactsToplist';
import FacebookRatingChart from './FacebookRatingChart';
import ReportTable, { ExpandableReportTable } from './ReportTable';
import {
	C_BOTTOM_LEFT
	, C_BOTTOM_RIGHT
	, C_TOP_LEFT
	, C_TOP_RIGHT
	, CT_DOUGHNUT
	, CT_EXPANDABLE_TABLE
	, CT_FB_RATING
	, CT_GENERAL
	, CT_GENERAL_BAR
	, CT_GENERAL_TABLE
	, CT_GENERAL_TOTAL
	, CT_GENERAL_TOTAL_AGENT
	, CT_GENERAL_TOTAL_BIG
	, CT_GENERAL_TOTAL_TIME
	, CT_LEADERBOARD
	, CT_CONTACTSTOPLIST
	, CT_LINE
	, CT_MULTI_TOTAL
	, CT_ORG_BAR
	, CT_PIE
	, CT_VERT_BAR
	, CT_SUMMARY_HORZ_BAR
	, RATING_CLASSNAME
	, SR_ACTIVE_AGENT
	, SR_AVG_HANDLE_TIME
	, SR_AVG_RESP_TIME
	, SR_AVG_UNANS_TIME
	, SR_AVR_CHAT_MSG_RESP_TIME
	, SR_AVR_CHAT_RESP_TIME
	, SR_CAP_UTIL_CHAT
	, SR_CLASSIFY_AREA
	, SR_CLASSIFY_CHANNEL
	, SR_CLOSE_STATUS
	, SR_CLOSED
	, SR_CLOSED_UNANSWERED
	, SR_COLLABORATION
	, SR_DEL_ERRANDS
	, SR_FACEBOOK_RATING
	, SR_HANDLE_TIME
	, SR_INC_CHAT_REQ
	, SR_INCOMING
	, SR_LEADERBOARD
	, SR_ORG_OVERVIEW
	, SR_REPLIES
	, SR_RESP_TIME
	, SR_SATISFY_METER
	, SR_SLR
	, SR_USER_ACTIVITY
	, SR_NUMBER_OF_CONTACTS
	, SR_CHAT_SESSIONS
	, SR_CLOSED_AND_ANSWERED
	, SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP
	, SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION
	, SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP
	, SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION
	, TF_FORMAT_TYPE
	, TS_FORMAT_TYPE
	, CL_OVERVIEW
	, SR_CHAT_RATING
	, SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP
	, SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION
	, SR_INBOUND_AND_OUTBOUND_CALLS
	, SR_SIP_MISSED_CALLS
	, SR_SIP_ABANDONED_CALLS
} from '../common/v5/constants';
import { PC_NEW_CHART_LAYOUT } from '../common/v5/statisticConstants';
import { L, I } from '../common/v5/config';
import { ChartTitle, ChartLink, withLinkClickHandle } from './ChartTitleLink';
import { buildDoughnutData, buildSummaryBarData } from './common';
import { withReactGridLayoutItem } from '../styles/statistic';

const TotalTooltipText = ({ text }) => {
	if (!text) {
		return null;
	}
	return (
		<div className="tooltip-content small text-center">
			<div className="content">{L(text)}</div>
		</div>
	);
};

function withBoxContent(Component) {
	return ({ children, data, onClick, square, tooltip, ...props }) => {
		// using object-spreading here because the property of square can be
		// ignored when pass to Component if it is undefined instead of
		// specifically pass a undefined square field which will give warning
		// from React runtime if Component is native HTML like 'div'.
		const componentProps = {
			className: "widget-body"
			, ["data-tooltip"]: true
			, ["data-qa-id"]: props["data-qa-id"]
		};
		if (square) {
			componentProps.square = true;
		}
		return (
			<Component {...componentProps} onClick={onClick}>
				{data}
				<TotalTooltipText text={tooltip} />
				{children}
			</Component>
		);
	}
}

const Div = withReactGridLayoutItem("div");

const withWidget = BoxComponent => ({
	children
	, className
	, data
	, hideTitleAndLink
	, layout
	, linkTitle
	, onLinkClick
	, rating
	, square
	, title
	, tooltip
}) => (
	<Div
		className={classNames(
			"widget"
			, className
			, typeof rating !== 'undefined' ? RATING_CLASSNAME[rating] : false
		)}
		layout={layout}
	>
		<ChartTitle
			hidden={layout === CL_OVERVIEW ? false : true}
			className="widget-header"
			text={title}
		/>
		<BoxComponent
			data-qa-id={"stat-widget-"+title}
			data={data}
			onClick={onLinkClick}
			square={square}
			tooltip={tooltip}
			unclickable={hideTitleAndLink}
		>
			{children}
		</BoxComponent>
		<ChartLink
			hidden={hideTitleAndLink}
			onClick={onLinkClick}
			title={linkTitle}
		/>
	</Div>
);

function withWidgetExtra(WidgetComponent) {
	return ({ extra, noTooltip, ...props }) => {
		let title, link, linkTitle, tooltip, rating;
		if (extra) {
			title = extra.title;
			link = extra.link;
			if (typeof extra.linkTitle !== "undefined") {
				linkTitle = extra.linkTitle;
			} else {
				linkTitle = title;
			}
			if (!noTooltip) {
				tooltip = extra.tooltip;
			}
			rating = extra.rating;
		} else {
			title = '';
			linkTitle = '';
		}
		return (
			<WidgetComponent {...props}
				title={title}
				link={link}
				linkTitle={linkTitle}
				tooltip={tooltip}
				rating={rating}
			/>
		);
	};
}

const withClickableBox = composeWithDisplayName(
	"withClickableBox"
	, withBoxContent
	, withPointerClickableOnClick
);

const noClick = () => {}

const withUnclickable = withProps(({ unclickable }) => {
	if (unclickable) {
		return { onClick: noClick }
	}
});

const DivBoxContent = withClickableBox("div");

const NoAutoSizerWidget = composeWithDisplayName(
	"NoAutoSizerWidget"
	, withLinkClickHandle
	, withWidgetExtra
	, withWidget
	, withUnclickable
)(DivBoxContent);

const ResizeableDiv = withSizeControl("div");

const ResizeableDivBoxContent = withClickableBox(ResizeableDiv);

const SquareableBoxContent = ({ square, ...props }) => {
	if (!square) {
		return <DivBoxContent {...props} />;
	}
	return <ResizeableDivBoxContent {...props} square />;
};

const AutoSizerWidget = composeWithDisplayName(
	"AutoSizerWidget"
	, withLinkClickHandle
	, withWidgetExtra
	, withWidget
	, withUnclickable
)(SquareableBoxContent);

const withChartSizeControl = Component => withChartSizer(
	Component
	, ResizeableDiv
);

const defChartOpt = {
		responsive: true
		, tooltips: {
			backgroundColor: "rgba(255,255,255,1)",
			bodyFontColor: "black"
		}
		, hover: {
			mode: null
		}
		, maintainAspectRatio: false
		, scales: {
			xAxes: [{
				stacked: true
				, display: true
				, position: "bottom"
				, id: "x-axis-1"
			}]
			, yAxes: [{
				stacked: true
				, ticks: {}
				, maxBarThickness: 25
			}]
		},
		title: {
			display: false
			, text: ""
		}
		, legend: {
			position: 'right'
			, align: 'start'
		}
};

// TODO this mapping did not consider custom report?
// chart options for line chart and vertical bar chart
const MAP_SYSTEM_REPORTS_CHART_OPTIONS = {
	[SR_ACTIVE_AGENT]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agents'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, ticks: {
					callback: function (value) {
						if (value === 1) {
							return 'Online'
						} else if (value === 0) {
							return 'Offline'
						} else {
							return null;
						}
					}
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Active/Inactive Chats'
				}
			}]
		}
	}
	, [SR_AVG_HANDLE_TIME]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agents'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Average Handling Time (seconds)'
				}
			}]
		}
	}
	, [SR_AVG_RESP_TIME]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Area'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Average Response Time (seconds)'
				}
			}]
		}
	}
	, [SR_AVG_UNANS_TIME]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Area'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Average Unanswered Time (seconds)'
				}
			}]
		}
	}
	, [SR_AVR_CHAT_MSG_RESP_TIME]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agents'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Average Chat Message Response Time (seconds)'
				}
			}]
		}
	}
	, [SR_AVR_CHAT_RESP_TIME]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Area'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Average Chat Response Time (seconds)'
				}
			}]
		}
	}
	, [SR_CAP_UTIL_CHAT]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agents'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Average Handling Time / Available For Chat (seconds)'
				}
			}
			, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Closed Errands (amount) / Chat Capacity Utilization (%)'
				}
			}]
		}
	}
	, [SR_CHAT_SESSIONS]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Area'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Chats'
				}
			}]
		}
	}
	, [SR_CLASSIFY_AREA]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Tag'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Errands'
				}
			}]
		}
	}
	, [SR_CLASSIFY_CHANNEL]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Tag'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Errands'
				}
			}]
		}
	}
	, [SR_CLOSE_STATUS]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agents'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Errands'
				}
			}]
		}
	}
	, [SR_CLOSED]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Closed Errands'
				}
			}]
		}
	}
	, [SR_CLOSED_UNANSWERED]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Closed/Unanswered Errands'
				}
			}]
		}
	}
	, [SR_COLLABORATION]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Area'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Average Response Time (seconds)'
				}
			}
			, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Answered'
				}
			}]
		}
	}
	, [SR_DEL_ERRANDS]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Tag'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Deleted Errands'
				}
			}]
		}
	}
	, [SR_FACEBOOK_RATING]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Area'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Facebook ratings'
				}
			}]
		}
	}
	, [SR_HANDLE_TIME]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Completed Errands'
				}
			}]
		}
	}
	, [SR_INC_CHAT_REQ]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Chat Requests'
				}
			}]
		}
	}
	, [SR_INCOMING]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Incoming Errands'
				}
			}]
		}
	}
	, [SR_LEADERBOARD]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agents'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Average Handling Time (seconds)'
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Closed Errands'
				}
			}]
		}
	}
	, [SR_ORG_OVERVIEW]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Organization'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Errands'
				}
			}]
		}
	}
	, [SR_REPLIES]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Replies Errands'
				}
			}]
		}
	}
	, [SR_RESP_TIME]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Completed Errands'
				}
			}]
		}
	}
	, [SR_SATISFY_METER]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Closed/Received Errands'
				}
			}]
		}
	}
	, [SR_SLR]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Responded Errands'
				}
			}]
		}
	}
	, [SR_USER_ACTIVITY]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agents'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Time (seconds)'
				}
			}]
		}
	}
	, [SR_NUMBER_OF_CONTACTS]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Contacts'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number of Errands'
				}
			}]
		}
	}
	, [SR_CLOSED_AND_ANSWERED]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Channel'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Errands'
				}
			}]
		}
	}
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agent Group'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Answered Errands'
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Average Handling Time (seconds)'
				}
			}]
		}
	}
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Organization'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Answered Errands'
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Average Handling Time (seconds)'
				}
			}]
		}
	}
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agent Group'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Closed Voice Errands'
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Average Handling Time (seconds)'
				}
			}]
		}
	}
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Organization'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Closed Voice Errands'
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Average Handling Time (seconds)'
				}
			}]
		}
	}
	, [SR_CHAT_RATING]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Area'
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Chat rating'
				}
			}]
		}
	}
	, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agent Group'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Answered Errands'
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Average Call Time (seconds)'
				}
			}]
		}
	}
	, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Agent Group'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Answered Errands'
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Average Call Time (seconds)'
				}
			}]
		}
	}
	, [SR_INBOUND_AND_OUTBOUND_CALLS]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Inbound/Outbound Calls'
				}
			}, {
				id: 'right-y-axis'
				, position: "right"
				, scaleLabel: {
					display: true
					, labelString: 'Average Call Time (seconds)'
				}
			}]
		}
	}
	, [SR_SIP_MISSED_CALLS]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: ''
				}
			}]
			, yAxes: [{
				scaleLabel: {
					display: true
					, labelString: 'Number Of Missed Calls'
				}
			}]
		}
	}
	, [SR_SIP_ABANDONED_CALLS]: {
		scales: {
			xAxes: [{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: 'Seconds'
				}
			}]
			, yAxes: [{
				id: 'left-y-axis'
				, position: "left"
				, scaleLabel: {
					display: true
					, labelString: 'Number Of Abandoned Calls'
				}
			}]
		}
	}
};

const ExtendFirstLabel = (data) => {
	// mak: temporary disable it (until a better solution) because this code seems not accurately
	// calculate the space needed to avoid cropped long label text (especially in rotated text ccc-3639)
	// e.g. when 1st label is a short text but 2nd label is a long text, it will cause a
	// unnecessary space/gap at the 1st label

	// if (data && data.labels) {
	// 	let longestStr = data.labels.reduce((a, b) => a.length > b.length ? a : b, '')
	// 	if (!data.labels[0] || data.labels[0].length === longestStr.length) {
	// 		return data;
	// 	};
	// 	let appendSpace = '\xa0\xa0\xa0\xa0\xa0';
	// 	for (let i = 0; i < longestStr.length; i++) {
	// 		appendSpace += '\xa0';
	// 	};
	// 	let appendFirstLabel = appendSpace + data.labels[0];
	// 	data.labels[0] = appendFirstLabel;
	// 	let newData = {
	// 		datasets: data.datasets
	// 		, labels: data.labels
	// 	};
	// 	return newData;
	// };
	return data;
};

// TODO: how to handle custom report?
const USE_TIME_SCALES_LABEL = {
	[SR_CLOSED]: true
	, [SR_CLOSED_UNANSWERED]: true
	, [SR_HANDLE_TIME]: true
	, [SR_INC_CHAT_REQ]: true
	, [SR_INCOMING]: true
	, [SR_REPLIES]: true
	, [SR_RESP_TIME]: true
	, [SR_SATISFY_METER]: true
	, [SR_SLR]: true
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: true
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: true
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: true
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: true
	, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP]: true
	, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION]: true
	, [SR_SIP_MISSED_CALLS]: true
	, [SR_SIP_ABANDONED_CALLS]: false
};

const DynamicScalesLabel = (timeStampFormat, timeFormat, reportName) => {
	if (reportName === SR_RESP_TIME || reportName === SR_HANDLE_TIME) {
		return (
			[{
				maxBarThickness: 30
				, scaleLabel: {
					display: true
					, labelString: TF_FORMAT_TYPE[timeFormat]
				}
			}]
		);
	};
	return (
		[{
			maxBarThickness: 30
			, scaleLabel: {
				display: true
				, labelString: TS_FORMAT_TYPE[timeStampFormat]
			}
		}]
	);
};

const HorizontalBar = withChartSizeControl(ChartHB);

function drawHorizontalLabel(chart) {
	var ctx = chart.ctx;
	ctx.font = "0.813em arial";
	ctx.textAlign = 'left';
	ctx.textBaseline = 'middle';

	chart.config.data.datasets.forEach(function (dataset, idx) {
		for (var i = 0; i < dataset.data.length; i++) {
			var meta = chart.getDatasetMeta(idx) // dataset._meta[Object.keys(dataset._meta)[0]]
			, model = meta.data[i]._model
			, left = meta.data[i]._xScale.left
			, text = fitString(ctx, model.label, chart.width - 20)
			;
			ctx.fillText(text, left + 10, model.y);
		}
	});
}

const SummaryHorizontalBarChart = ({
	data
	, height
	, imageGetterRef
	, square
	, width
	, onComplete
	, ...props
}) => {
	const { extra } = props;
	let options = update(defChartOpt, {$merge: {animation: {onComplete}}});
	return (
		<NoAutoSizerWidget {...props} className="chart" noTooltip>
			<HorizontalBar
				data={buildSummaryBarData(extra.labels, data)}
				height={height}
				imageGetterRef={imageGetterRef}
				square={square}
				width={width}
				options={options}
				plugins={[{
					afterDraw: function(chart) {
						var options = chart.config.options;
						// Draw fixed label on horizontal chart
						if (options.fixedLabel && options.fixedLabel.display) {
							drawHorizontalLabel(chart);
						}
					}
				}]}
			/>
		</NoAutoSizerWidget>
	);
};

const HorizontalBarChart = ({
	data
	, height
	, imageGetterRef
	, square
	, width
	, onComplete
	, ...props
}) => {
	let options = update(defChartOpt, {$merge: {animation: {onComplete}}});
	return (
		<NoAutoSizerWidget {...props} className="chart" noTooltip>
			<HorizontalBar
				data={data}
				height={height}
				imageGetterRef={imageGetterRef}
				square={square}
				width={width}
				options={options}
			/>
		</NoAutoSizerWidget>
	);
};

const VerticalBar = withChartSizeControl(ChartVB);

const VerticalBarChart = ({
	data
	, height
	, imageGetterRef
	, onComplete
	, square
	, timeFormat
	, timeStampFormat
	, width
	, ...props
}) => {
	let newOptions = update(defChartOpt, {
		animation: {$set: {onComplete}},
		scales: {$set: {
			xAxes: [{
				maxBarThickness: 30
			}]
		}},
	});
	const newScales = MAP_SYSTEM_REPORTS_CHART_OPTIONS[props.id.id];
	const newData = ExtendFirstLabel(data);
	if (typeof newScales !== "undefined") {
		newOptions = update(newOptions, {$merge: newScales});
	};
	if(USE_TIME_SCALES_LABEL[props.id.id]) {
		const newScalesLabel = DynamicScalesLabel(timeStampFormat, timeFormat, props.id.id);
		newOptions = update(newOptions, {scales: { xAxes: {$merge: newScalesLabel}}});
	};
	return (
		<NoAutoSizerWidget {...props} className="chart" noTooltip>
			<VerticalBar
				data={newData}
				height={height}
				imageGetterRef={imageGetterRef}
				options={newOptions}
				square={square}
				width={width}
			/>
		</NoAutoSizerWidget>
	);
};

const LineChart = withChartSizeControl(ChartLN);

const GeneralLineChart = ({
	data
	, height
	, imageGetterRef
	, timeFormat
	, timeStampFormat
	, square
	, width
	, onComplete
	, ...props
}) => {
	let newOptions = update(defChartOpt, {
		animation: {$set: {onComplete}},
		scales: {$set: {}},
	});
	const newScales = MAP_SYSTEM_REPORTS_CHART_OPTIONS[props.id.id];
	if(typeof newScales !== undefined) {
		newOptions = update(newOptions, {$merge: newScales});
	};
	if(USE_TIME_SCALES_LABEL[props.id.id]) {
		const newScalesLabel = DynamicScalesLabel(timeStampFormat, timeFormat, props.id.id);
		newOptions = update(newOptions, {scales: { xAxes: {$merge: newScalesLabel}}});
	};
	return (
		<NoAutoSizerWidget {...props} className="chart" noTooltip>
			<LineChart
				data={data}
				height={height}
				imageGetterRef={imageGetterRef}
				square={square}
				width={width}
				options={newOptions}
			/>
		</NoAutoSizerWidget>
	);
}

const PieChart = withChartSizeControl(ChartPIE);

const GeneralPieChart = ({
	data
	, height
	, imageGetterRef
	, square
	, width
	, onComplete
	, ...props
}) => {
	const { extra } = props;
	const options = update(defDoughnutChartOpt, {
		animation: {$set: {onComplete}},
		cutoutPercentage: {$set: 0},
	});
	return (
		<NoAutoSizerWidget {...props} className="chart" noTooltip>
			<PieChart
				data={buildDoughnutData(extra.labels, data)}
				height={height}
				imageGetterRef={imageGetterRef}
				square={square}
				width={width}
				options={options}
				plugins={[{
					beforeDraw: function(chart) {
						var options = chart.config.options;
						// Draw fixed label on doughnut chart
						if (options.fixedLabel && options.fixedLabel.display) {
							drawLabel(chart);
						}
					}
				}]}
			/>
		</NoAutoSizerWidget>
	);
}

const labelDataAndPercent = (tooltipItem, data) => {
	const { index } = tooltipItem;
	let splitedLabel = data.labels[index].split(",");

	if(splitedLabel.length > 1) {
		let percent = "Percent of Total: " +
			data.datasets[0].data[index].toFixed(1) + "%";
		return splitedLabel.concat([percent]);
	} else {
		return "  " + data.labels[index] + "  (" +
			data.datasets[0].data[index].toFixed(1) + "%)";
	}
};

const defDoughnutChartOpt = {
	tooltips: {
		backgroundColor: "rgba(255,255,255,1)"
		, bodyFontColor: "black"
		, callbacks: {
			label: labelDataAndPercent
		}
	}
	, maintainAspectRatio: false
	, cutoutPercentage: 70
	, legend: {
		display: false
	}
	, layout: {
		padding: {
			left: 40
			, right: 40
			, top: 14
			, bottom: 14
		}
	}
	, fixedLabel: {
		display: true
	}
};

function fitString(ctx, text, maxWidth) {
    var ellipsis = '…'
	, ellipsisWidth = ctx.measureText(ellipsis).width
	, textWidth = ctx.measureText(text).width
	;
    if (textWidth<=maxWidth || textWidth<=ellipsisWidth) {
        return text;
    }
	var len = text.length;
	while (textWidth>=maxWidth-ellipsisWidth && len-->0) {
		text = text.substring(0, len);
		textWidth = ctx.measureText(text).width;
	}
	return text+ellipsis;
}

// Draw data label specifically for pie/doughnut chart only
function drawLabel(chart) {
	const ctx = chart.ctx
	, width = chart.width
	, height = chart.height
	, cx = width / 2
	, cy = height / 2
	, offsetx = 3 // label offset accordingly to font px
	, offsety = 6
	;
	ctx.restore();
	ctx.font = "0.813em sans-serif"; // ~13px
	ctx.textBaseline = "middle";

	var acc = 0
	, data = chart.config.data.datasets[0].data
	, label = chart.config.data.labels
	, text, textWidth
	, rad, deg, x, y, dx, dy
	;
	for(let i=0; i<data.length; i++) {
		// Calculate the label location (in degree) from data value %
		deg = acc + (data[i] / 2) * 3.6;
		acc += (data[i] * 3.6);

		// Skip the label if the value is too small (~30 degree)
		if (data[i] < 8.3) {
			continue;
		}
		// Label position and its offset
		rad = deg * 0.0174532925;
		x = cx + chart.outerRadius *  Math.sin(rad);
		y = cy - chart.outerRadius *  Math.cos(rad);

		text = label[i] + ' (' + data[i].toFixed(1) + '%)';
		textWidth = ctx.measureText(text).width;

		dy = -Math.cos(rad) * offsety;
		dx = Math.sin(rad) * offsetx;

		// Check if label off the canvas size, then add elipses into the label
		if (deg > 180) {
			if (textWidth > (x+dx)) {
				text = fitString(ctx, text, x+dx);
				textWidth = ctx.measureText(text).width;
				dx = Math.sin(rad) * offsetx;
			}
			dx -= textWidth;
		} else {
			let maxWidth = chart.width - x - dx;
			if (textWidth > maxWidth) {
				text = fitString(ctx, text, maxWidth);
			}
		}
		ctx.fillText(text, x+dx, y+dy);
	}
	ctx.save();
}

function drawTextInChartCenter(chart, text) {
	var ctx = chart.ctx
	, width = chart.width
	, height = chart.height
	;
	ctx.restore();
	var fontSize = (height / 100).toFixed(2);
	ctx.font = fontSize + "em sans-serif";
	ctx.textBaseline = "middle";

	// Reduce the font size if text too long to fit inside
	var maxWidth = (chart.outerRadius - chart.radiusLength) * 2 * 0.8;
	var textWidth = ctx.measureText(text).width;
	while(textWidth>maxWidth && fontSize>0.1){
		fontSize -= 0.1;
		ctx.font = fontSize + "em sans-serif";
		textWidth = ctx.measureText(text).width;
	}

	var textX = Math.round((width - textWidth) / 2)
	, textY = height / 2
	;
	ctx.fillText(text, textX, textY);
	ctx.save();
}

const Doughnut = withChartSizeControl(ChartDN);

const WidgetWithLinkNoAutoSizer = withLinkClickHandle(NoAutoSizerWidget);

const GeneralDoughnutChart = ({
	data
	, height
	, imageGetterRef
	, square
	, width
	, ...props
}) => {
	const { extra } = props;
	let options = Object.assign({}, defDoughnutChartOpt);
	// chart with center total
	if (props.id.id == SR_CLOSED_AND_ANSWERED ||
		props.id.id == SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP ||
		props.id.id == SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION) {
			options = update(options, {$merge: {
				centerText: {
				display: true,
				text: extra.total.key_errand_closure_status_answered_unformatted
			}}});
	}
	if (props.id.id == SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP ||
		props.id.id == SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION) {
			options = update(options, {$merge: {
				centerText: {
				display: true,
				text: extra.total.key_voice_closure_status_answered_unformatted
			}}});
	}
	if (props.id.id == SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP ||
		props.id.id == SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION) {
			options = update(options, {$merge: {
				centerText: {
				display: true,
				text: extra.total.key_closed_voice_errand_unformatted
			}}});
	}
	return <WidgetWithLinkNoAutoSizer {...props} className="chart">
		<Doughnut
			data={buildDoughnutData(extra.labels, data, extra.colorOffset)}
			height={height}
			imageGetterRef={imageGetterRef}
			square={square}
			width={width}
			options={options}
			plugins={[{
				beforeDraw: function(chart) {
					var options = chart.config.options;
					// Draw text in center of doughnut chart
					if (options.centerText && options.centerText.display) {
						drawTextInChartCenter(chart, options.centerText.text);
					}
					// Draw fixed label on doughnut chart
					if (options.fixedLabel && options.fixedLabel.display) {
						drawLabel(chart);
					}
				}
			}]}
		/>
	</WidgetWithLinkNoAutoSizer>
};

const OverviewBoxWidget = ({ className, ...props }) => (
	<AutoSizerWidget
		{...props}
		className={classNames("rectangle", className)}
	/>
);

const MainTotal = ({ data }) => <div className="main-total">{data}</div>;

const cornerStyle = {
		[C_BOTTOM_LEFT]: {bottom: "0px", left: "0px"}
		, [C_BOTTOM_RIGHT]: {bottom: "0px", right: "0px"}
		, [C_TOP_LEFT]: {top: "0px", left: "0px"}
		, [C_TOP_RIGHT]: {top: "0px", right: "0px"}
	}
	;
function getRelativeCorneredStyle(previousStyle, cornerPosition) {
	const style = cornerStyle[cornerPosition];
	if (!previousStyle) {
		return style;
	}
	return update(previousStyle, {$merge: style});
}

const HideableDiv = withUnmountWhenHidden("div");

const CorneredTotal = ({ data, position, style, title }) => (
	<div
		className="cornered-total"
		style={getRelativeCorneredStyle(style, position)}
	>
		<HideableDiv className="title" hidden={!title}>{title}</HideableDiv>
		{data}
	</div>
);

const ChartMultiTotal = ({ data, ...props }) => {
	const { extra } = props
		, { corneredTotals, total } = extra
		;
	let corners;
	if (corneredTotals) {
		corners = [];
		$.each(corneredTotals, (k, v) => {
			let data, title;
			if (typeof v === "object") {
				data = v.total;
				title = v.name;
			} else {
				data = v;
			}
			corners.push(
				<CorneredTotal key={k} position={k} data={data} title={title} />
			);
		});
	} else {
		corners = null;
	}
	return (
		<OverviewBoxWidget className="multi-total" {...props}>
			<MainTotal data={total} />
			{corners}
		</OverviewBoxWidget>
	);
};

const OverviewBoxTotalWidget = props => (
	<OverviewBoxWidget {...props} data={props.extra.total} />
);

const ChartTotalAgent = props => (
	<OverviewBoxTotalWidget className="active-agents" {...props} />
);

const ChartTotalTime = props => (
	<OverviewBoxTotalWidget className="handling-time" {...props} />
);

const ChartTotalNumberBig = props => (
	<OverviewBoxTotalWidget className="closed-errands" {...props} />
);

const ChartTotalNumber = props => (
	<OverviewBoxTotalWidget className="new-errands" {...props} />
);

const FacebookRatingWidget = ({data, extra, className, ...props}) => (
	<AutoSizerWidget {...props}
		extra={extra}
		className="facebook-rating"
	>
		<FacebookRatingChart data={data} extra={extra} />
	</AutoSizerWidget>
);

const withOldMountingEffect = Component => ({ onLoad, onUnload, ...props }) => {
	const { id, layout, position, index, param } = props;
	useEffect(() => {
		if (typeof onLoad === 'function') {
			if (process.env.NODE_ENV !== 'production') {
				console.log("dbg: chart mount:", id);
			}
			onLoad(id, layout, position, null, index, param);
		}
		return () => {
			if (typeof onUnload === 'function') {
				if (process.env.NODE_ENV !== 'production') {
					console.log("dbg: chart unmount:", id);
				}
				onUnload(id, layout, position, null, index);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps because old code.
	}, []);
	return <Component {...props} />
};

const withMountingEffect = Component => ({ onLoad, onUnload, ...props }) => {
	const {
			asyncId
			, notAsyncOwner
			, layout
			, origin
			, position
			, idx
			, index
			, param
		} = props
		, { layout: originLayout, idx: originIdx } = origin
		, idParam = {
			asyncId
			, idx
			, layout
			, notAsyncOwner
			, originIdx
			, originLayout
			, param
		}
		, lastProps = useRef(idParam)
		;
	// this effect is meant for debugging how the state changes during add or
	// remove charts. It is important because there any change will possibly mean
	// an AJAX and impact server performance.
	useEffect(() => {
		if (process.env.NODE_ENV !== 'production') {
			const { current } = lastProps
				, { asyncId: _asyncId, param: _param } = current
				;
			console.log(
				"dbg: component update:"
				, asyncId
				, { new: idParam, old: { ...current } }
				, _asyncId !== asyncId
				, _param !== param
			);
			lastProps.current = idParam;
		}
	}, [asyncId, notAsyncOwner, layout, idx, param]);
	// TODO: probably need to optimize this mounting effect as it is now depends
	// on many props which can stop and start async (onLoad / onUnload) again.
	// This is not wrong code but correct way of writing side effect code. Any of
	// our previous React code that trigger async at mount and stop async at
	// unmount likely is wrong whenever the async depends on some props data. This
	// is because when the props change, the async that was triggered at mount
	// will no longer satisfy the props changes. Currently, param is problematic
	// prop as our effect code change the default empty param AFTER first load and
	// second load carry the non-empty parameters. So, it always load twice.
	useEffect(() => {
		if (typeof onLoad === 'function' && !notAsyncOwner) {
			onLoad(asyncId, layout, position, idx, index, param);
		}
		return () => {
			if (typeof onUnload === 'function' && !notAsyncOwner) {
				onUnload(asyncId, layout, position, idx, index);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps NOTE:1 because
	// 'position' and 'index' is for static chart layout which won't change until
	// unmount. Mounting different report on the same position and index only
	// happen on report page which redux async action will do the state reset.
	}, [asyncId, notAsyncOwner, layout, idx, param]);
	return <Component {...props} />
};

const ChartBase = ({
	chartRenderer: RenderChart
	, data
	, extra
	, height
	, hideTitleAndLink
	, imageRequested
	, onChartImage
	, onLoad
	, onRequestImageDone
	, onUnload
	, param
	, square
	, ...props
}) => {
	const { id, layout, position, idx, index } = props
		, imageGetterRef = useRef(null)
		, handleComplete = useCallback(that => {
			that.chart.options.animation.onComplete = null;
			onChartImage(that.chart.toBase64Image());
		}, [onChartImage])
		;
	useEffect(() => {
		if (imageRequested) {
			const { current } = imageGetterRef;
			if (current
				&& current.chartInstance
				&& typeof current.chartInstance.toBase64Image === "function"
				&& typeof onRequestImageDone === "function") {
				onRequestImageDone(
					{layout, position, idx, index}
					, true
					, current.chartInstance.toBase64Image()
				);
			} else {
				let err;
				if (!current) {
					err = new Error("invalid chart reference as component not support image getter");
				} else {
					err = new Error("chart has no toBase64Image function or invalid onRequestImageDone");
				}
				onRequestImageDone({layout, position, idx, index}, false, err);
			}
		}
		return () => {
			if (imageRequested) {
				if (typeof onRequestImageDone === "function") {
					onRequestImageDone(
						{layout, position, idx, index}
						, false
						, new Error("chart changed at the midst of chart image request")
					);
				}
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps see NOTE:1.
	}, [imageRequested, layout, idx, onRequestImageDone]);
	return (
		<RenderChart
			{...props}
			data={data}
			extra={extra}
			height={height}
			hideTitleAndLink={hideTitleAndLink}
			imageGetterRef={imageGetterRef}
			onComplete={handleComplete}
			square={square}
			timeFormat={param.timeFormat}
			timeStampFormat={param.timeStampFormat}
		/>
	);
};

const chartTypeComponent = {
	[CT_CONTACTSTOPLIST]: ContactsToplist
	, [CT_DOUGHNUT]: GeneralDoughnutChart
	, [CT_EXPANDABLE_TABLE]: ExpandableReportTable
	, [CT_FB_RATING]: FacebookRatingWidget
	, [CT_GENERAL]: HorizontalBarChart
	, [CT_GENERAL_BAR]: HorizontalBarChart
	, [CT_GENERAL_TABLE]: ReportTable
	, [CT_GENERAL_TOTAL]: ChartTotalNumber
	, [CT_GENERAL_TOTAL_AGENT]: ChartTotalAgent
	, [CT_GENERAL_TOTAL_BIG]: ChartTotalNumberBig
	, [CT_GENERAL_TOTAL_TIME]: ChartTotalTime
	, [CT_LEADERBOARD]: LeaderboardTable
	, [CT_LINE]: GeneralLineChart
	, [CT_MULTI_TOTAL]: ChartMultiTotal
	, [CT_ORG_BAR]: OrganizationBarChart
	, [CT_PIE]: GeneralPieChart
	, [CT_SUMMARY_HORZ_BAR]: SummaryHorizontalBarChart
	, [CT_VERT_BAR]: VerticalBarChart
};

const Dummy = () => null;

const useNotAsyncOwnerMemo = ({
	layout
	, position
	, idx
	, index
	, origin: {
		layout: originLayout
		, position: originPosition
		, idx: originIdx
		, index: originIndex
	}
}) => useMemo(
	() => chartIdString(
		layout
		, position
		, idx
		, index
	) !== chartIdString(
		originLayout
		, originPosition
		, originIdx
		, originIndex
	)
	, [
		layout
		, position
		, idx
		, index
		, originLayout
		, originPosition
		, originIdx
		, originIndex
	]
);

const withAsyncIdAndOwner = Component => props => {
	const { id: { id, type, param } } = props;
	return (
		<Component
			asyncId={useMemo(() => ({ id, param, type }), [id, type, param])}
			notAsyncOwner={useNotAsyncOwnerMemo(props)}
			{...props}
		/>
	);
};

export const Chart = composeWithDisplayName(
	"Chart"
	, memo
	, withAsyncIdAndOwner
	, branch(
		({ layout }) => isChartGrid(layout)
		, withMountingEffect
		, withOldMountingEffect
	)
	, createWithMountCondition(({ data }) => !!data)
	, withProps(({ type }) => {
		let chartRenderer = chartTypeComponent[type];
		if (!chartRenderer) {
			chartRenderer = Dummy;
		}
		return {chartRenderer};
	})
)(ChartBase);

Chart.propTypes = {
	className: PropTypes.string
	, data: PropTypes.oneOfType([
		PropTypes.object
		, PropTypes.array
	])
	, extra: PropTypes.object
	, height: PropTypes.number
	, hideTitleAndLink: PropTypes.bool
	, onRequestImageDone: PropTypes.func
	, param: PropTypes.object
	, imageRequested: PropTypes.bool
	, square: PropTypes.bool
	, type: PropTypes.number
};
