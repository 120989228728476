import React from 'react';
import PropTypes from 'prop-types';
import { RINGER_AUDIO_SRC } from '../common/v5/constants';

class CallRinger extends React.Component {
	constructor(props) {
		super(props);
	}
	componentDidUpdate(){
		if(this.props.play) {
			this.play();
		} else {
			this.stop();
			this.audioRef.muted =true;
		}
	}
	play() {
		this.audioRef.muted =false;
		if(this.props.forSIP) {
			setRingerOutput(this.audioRef, this.props.presetRingingDevice);
		} else {
			this.audioRef.play();
		}
	}
	stop() {
		this.audioRef.pause();
	}
	render() {
		return (
			<audio id="CentionCallRinger" src={RINGER_AUDIO_SRC} loop={true} ref={(input) => {this.audioRef = input}} style={{ display: 'none' }} autoPlay={true} preload="auto" muted={true}>
				Your browser does not support the audio tag.
			</audio>
		)
	}
}

CallRinger.propTypes = {
	play: PropTypes.bool
};

async function setRingerOutput(audio, presetRingingDevice) {
	const devices = await navigator.mediaDevices.enumerateDevices();
	let ringingDevice = "";
	devices.forEach(function(device) {
		if(device.kind === "audiooutput" && device.deviceId === presetRingingDevice) {
			console.log("Found preset ringing device - " + device.kind + ": " + device.label +
					" id = " + device.deviceId);
			ringingDevice = device.deviceId;
		}
	});
	if(ringingDevice) {
		const isIE = /*@cc_on!@*/false || !!document.documentMode;
		const isEdge = !isIE && !!window.StyleMedia;
		const isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
		if(isEdge || isChrome) {
			await audio.setSinkId(ringingDevice);
			audio.play();
		} else {
			console.log("Browser does not support HTMLMediaElement.setSinkId()");
			audio.play();
		}
	} else {
		console.log("No preset ringing device was found, using default");
		audio.play();
	}
}

export default CallRinger;
