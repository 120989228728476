import React, { PureComponent } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { I } from '../common/v5/config';
import { LEADERBOARD_STR, CL_OVERVIEW } from '../common/v5/constants';
import { getChevronIcon } from '../common/v5/helpers';
import { composeWithDisplayName, withPointerClickableOnClick } from './hocs';
import { withMeasurableHeight } from '../styles/autoHeight';
import { ChartTitle, ChartLink, withLinkClickHandle } from './ChartTitleLink';

const Img = ({col, row}) => (
	<div>
		<span></span>
		<img className="leaderboard-avatar" src={row.img} />
		<span></span>
	</div>
);

const Title = props => <ChartTitle text={LEADERBOARD_STR} {...props} />;

const FooterLink = props => <ChartLink className="leaderboard-footer" title={LEADERBOARD_STR} {...props} />;

const ClickableDiv = withPointerClickableOnClick("div");

const Table = ({ onClick, unclickable, ...props }) => (
	<ClickableDiv onClick={onClick} unclickable={unclickable}>
		<BootstrapTable {...props} />
	</ClickableDiv>
);

const handlingTimeField = "handlingTime"
	, closedErrandsField = "closedErrands"
	, mapReportKeyToField = {
		"key_average_errand_process_time": handlingTimeField
		, "key_closed": closedErrandsField
	}
	, sortDesc = "desc"
	, sortAsc = "asc"
	, activeClass = "active"
	;
const withLeaderboardTable = Component => class extends PureComponent {
	constructor(props) {
		super(props);
		this.getCaret = this.getCaret.bind(this);
		this.handleTableClick = this.handleTableClick.bind(this);
		this.state = {
			sortName: "",
			sortDir: sortAsc
		};
		this.options = {
			onSortChange: this.onSortChange.bind(this)
		};
		if (this.props.extra.sorted) {
			const { id, desc } = this.props.extra.sorted[0]
				, field = mapReportKeyToField[id]
				;
			if (field) {
				let sortOrder;
				if (desc) {
					sortOrder = sortDesc;
				} else {
					sortOrder = sortAsc;
				}
				this.options.defaultSortOrder = sortOrder;
				this.options.defaultSortName = field;
				this.state.sortName = field;
			}
		}
	};
	imgFormatter(col, row) {
		return <Img col={col} row={row} />
	};
	getCaret(dir) {
		let down;
		if (dir === sortDesc) {
			down = true;
		}
		return <span><i className={getChevronIcon(!down)} /></span>;
	};
	handleTableClick(e) {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: table click:", {e});
		}
		if (this.isOnSortChangeClick) {
			this.isOnSortChangeClick = false;
		} else {
			this.props.onLinkClick(e);
		}
	};
	onSortChange(sortName, sortOrder) {
		if (process.env.NODE_ENV !== 'production') {
			console.log("dbg: table sort click");
		}
		this.isOnSortChangeClick = true;
		this.setState({sortName});
		this.setState({sortDir: sortOrder});
	}
	indexNumberAsc(cell, row, extra, index) {
		return (<div>{index+1}</div>)
	}
	indexNumberDesc(cell, row, extra, index) {
		let indexRowDesc = extra - index
		return (<div>{indexRowDesc}</div>)
	}
	render() {
		const {
				hideTitleAndLink
				, measureRef
				, onLinkClick
				, square
				, style
				, layout
			} = this.props
			;
		let data = []
			,dataLength = 0
			, hideColumn = false
			, hideColumnIndex = false
			, sortByAvg = ""
			, sortByClosed = ""
			;
		if (this.state.sortName === handlingTimeField) {
			sortByAvg = activeClass;
			if(this.state.sortDir === sortDesc) {
				hideColumnIndex = true;
			}
		} else if (this.state.sortName === closedErrandsField) {
			sortByClosed = activeClass;
			if(this.state.sortDir === sortDesc) {
				hideColumnIndex = !true
			} else if (this.state.sortDir === sortAsc) {
				hideColumnIndex = true;
			}
		}
		if(this.props.data) {
			dataLength = this.props.data.length;
			data = this.props.data;
		};
		if (square) {
			hideColumn = true;
			if(dataLength > 3) {
				data = this.props.data.slice(0 ,3);
			}
		};
		return (
			<Component
				className="c3-leaderboard-table"
				ref={measureRef}
				style={style}
			>
				<Title hidden={layout === CL_OVERVIEW ? false : true} />
				<div className="no-footer">
					{/* TODO: convert it react-table because BootstrapTable */}
					{/* can't support default multi-sort. */}
					<Table
						data={data}
						hover
						onClick={this.handleTableClick}
						options={this.options}
						printable
						tableBodyClass='wf-body-class'
						unclickable={hideTitleAndLink}
					>
						<TableHeaderColumn dataField='id' isKey width='10%' hidden />
						<TableHeaderColumn
							className="rank"
							dataField={sortAsc}
							dataFormat={this.indexNumberAsc}
							hidden={hideColumnIndex}
							width='10%'
						>
							{I("Rank ")}<i className="fas fa-medal"></i>{square && I("Avg. Handling Time")}
						</TableHeaderColumn>
						<TableHeaderColumn
							className="rank"
							dataField={sortDesc}
							dataFormat={this.indexNumberDesc}
							formatExtraData={dataLength}
							hidden={!hideColumnIndex}
							width='10%'
						>
							{I("Rank ")}<i className="fas fa-medal"></i>
						</TableHeaderColumn>
						<TableHeaderColumn dataField='img' dataFormat={this.imgFormatter} width='10%' />
						<TableHeaderColumn dataField='name' width='30%' />
						<TableHeaderColumn
							dataField={handlingTimeField}
							dataSort
							caretRender={this.getCaret}
							width='25%'
							hidden={hideColumn}
						>
							<span className={sortByAvg}>
								{I("Avg. Handling Time")}
							</span>
						</TableHeaderColumn>
						<TableHeaderColumn
							dataField={closedErrandsField}
							dataSort
							caretRender={this.getCaret}
							width='25%'
							hidden={hideColumn}
						>
							<span className={sortByClosed}>
								{I("Closed errands")}
							</span>
						</TableHeaderColumn>
					</Table>
					<FooterLink
						hidden={hideTitleAndLink}
						onClick={onLinkClick}
					/>
				</div>
			</Component>
		);
	}
};

const LeaderboardTable = composeWithDisplayName(
	"LeaderboardTable"
	, withLinkClickHandle
	, withMeasurableHeight
	, withLeaderboardTable
)("div");

export default LeaderboardTable;
