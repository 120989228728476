import React from 'react';
import { branch, withProps } from 'recompose';
import { I } from '../common/v5/config';
import { composeWithDisplayName, withUnmountWhenHidden } from './hocs';
import {
	HeaderActionItem
	, HeaderActionItemIcons
	, StandardTooltip
} from './HeaderActions';

const QChatBase = ({ data }) => <span>{data}</span>;

const QChat = ({ data, ...props }) => (
	<HeaderActionItemIcons {...props}>
		<QChatBase data={data} />
	</HeaderActionItemIcons>
);

const queueChatText = I("Total queued chats");

export const QueueChatBase = ({ data, ...props }) => (
	<HeaderActionItem {...props} className="red">
		<QChat
			data={data}
			data-qa-id="header-queue-chat"
			title={queueChatText}
		/>
		<StandardTooltip text={queueChatText} />
	</HeaderActionItem>
);

const QueueChat = composeWithDisplayName(
	"QueueChat"
	, branch(({ data }) => !data, withProps(() => ({hidden: true})))
	, withUnmountWhenHidden
)(QueueChatBase);

export default QueueChat;
