import React from 'react';
import classNames from 'classnames';

const baseClassName = "c3-square-checkbox";

const Div = props => <div {...props} className="jq-checkbox__div" />;

class SquareCheckbox extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		this.props.onClick(!this.props.checked, this.props["data-qa-id"], this.props.name);
		e.stopPropagation();
	}
	render() {
		const p = this.props
			, outerClass = classNames(baseClassName, p.className, {flip: p.flip})
			, myClass = classNames("jq-checkbox", {checked: p.checked}, {disabled: p.disabled})
			;
		let qaId = "QA_ErrandCb";
		if (p["data-qa-id"]) {
			qaId = p["data-qa-id"];
		} else {
			qaId = p.id;
		}
		if (p.checked){
			qaId += " -checked";
		}
		if (p.label) {
			return (
				<label
					className={outerClass}
					data-qa-id={qaId}
					hidden={p.hidden}
					onClick={this.handleClick}
				>
					<div className={myClass}>
						<Div data-qa-id={qaId} />
					</div>
					<span className='label'>{p.label}</span>
				</label>
			);
		}
		if (p.children) {
			return (
				<label
					className={outerClass}
					hidden={p.hidden}
					htmlFor={p.id}
					onClick={this.handleClick}
				>
					<div className={myClass}>
						<Div data-qa-id={qaId}>
							{p.children}
						</Div>
					</div>
				</label>
			);
		}
		return (
			<div
				className={classNames(baseClassName, myClass, p.className, {flip: p.flip})}
				onClick={this.handleClick}
				hidden={p.hidden}
			>
				<Div data-qa-id={qaId} />
			</div>
		);
	}
}

export const CheckboxBase = ({label, onChange, checked, className, name, qaId}) => {
	return (
		<label
			className={classNames("checkbox-container",className)}
			data-qa-id={qaId}
		>
			<input type="checkbox" name={name} checked={checked} onChange={onChange}/>
			<span className="checkmark"></span>
			{label && <span className='label'>{label}</span>}
		</label>
	)
}

export default SquareCheckbox;

export const ConversationCheckbox = ({show, checked, onClick}) => {
	if(!show) {
		return null;
	}
	return <div className="conversation-checkbox">
			<SquareCheckbox checked={checked} onClick={onClick} />
		</div>;
};

export class NamedCheckbox extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(state) {
		this.props.onClick(this.props.me, state);
	}
	render() {
		const {me, onClick, ...props} = this.props;
		return <SquareCheckbox data-qa-id={me} id={me} onClick={this.handleClick} {...props} />;
	}
}
