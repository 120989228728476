import { useCallback, useMemo } from 'react'
import each from 'lodash/each'
import { emptyArray } from '../common/constants'

// useSerialToArrayInt convert csv string or string to array of integers or
// integer.
export const useSerialToArrayInt = (multi, selected, onSelect) => {
  const selectedArray = useMemo(() => {
    const selectedArray = []
    if (multi) {
      let processedSelected
      if (selected) {
        processedSelected = selected.split(',')
      } else {
        processedSelected = emptyArray
      }
      each(processedSelected, v => {
        selectedArray.push(parseInt(v, 10))
      })
    } else {
      selectedIDs.push(parseInt(selected, 10))
    }
    return selectedArray
  }, [multi, selected])
  const handleSelect = useCallback(data => {
    if (multi) {
      onSelect(data.join(','))
    } else {
      onSelect(datas)
    }
  }, [multi, onSelect])
  return [selectedArray, handleSelect]
}
