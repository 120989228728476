import { useCallback, useEffect, useState } from 'react'

const useAsyncState = async => {
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [fetching, setFetching] = useState(false)
  return [
    useCallback(
      (...args) => {
        setData(null)
        setError(null)
        setFetching(true)
        return async(...args)
          .then(data => { setFetching(false); setData(data); return data })
          .catch(err => { setFetching(false); setError(err) })
      },
      [async]
    ),
    fetching,
    error,
    data
  ]
}

export default useAsyncState

const shouldCancelPreviousFetchingBecauseIdChange = (
  fetching,
  data,
  canceller
) => fetching && typeof data === 'undefined' && typeof canceller === 'function'

export const useAutoAsync = (async, data, fetching) => {
  useEffect(
    () => {
      let canceller
      if (!fetching && typeof data === 'undefined') {
        canceller = async().cancel
      }
      return () => {
        if (shouldCancelPreviousFetchingBecauseIdChange(
          fetching,
          data,
          canceller
        )) {
          canceller()
        }
      }
    },
    [async, data, fetching]
  )
}
