import React, { Fragment, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import update from 'immutability-helper';
import { mapProps } from 'recompose';
import { getFile } from '../common/helpers';
import { I } from '../common/v5/config';
import { MM_ADMINISTRATION } from '../common/v5/constants';
import { DISPLAY_NONE } from './common';
import {
	composeWithDisplayName
	, createWithPropsDebug
	, removeProps
	, withDisableAbleClick
	, withElipsisDetection
	, withElipsisInline
	, withRestoreForwardedRef
	, withUnmountEmptyChild
	, withUnmountWhenHidden
} from './hocs';
import Button from './Button';

const baseClassName = "c3-upload-button"
	, baseDownloadClassName = "c3-download-button"
	;
const Italic = withDisableAbleClick("i");

const Label = withDisableAbleClick("label");

const Span = composeWithDisplayName(
	"Span"
	, withUnmountWhenHidden
	, withUnmountEmptyChild
	, withElipsisDetection
	, createWithPropsDebug(["isEllipsised"])
	, withElipsisInline
	, removeProps(["isEllipsised"])
	, withRestoreForwardedRef
)("span");

Span.propTypes = {maxWidth: PropTypes.number.isRequired};

const useUpload = (confirmHandler, errorHandler) => {
	const [ file, setFile ] = useState(null)
		, [ value, setValue ] = useState("")
		, onCancel = useCallback(e => {
			setFile(null);
			setValue("");
		}, [setFile, setValue])
		, onChange = useCallback(e => {
			if (process.env.NODE_ENV !== 'production') {
				console.log(
					"dbg use upload:"
					, {value: e.target.value, files: e.target.files}
				);
			}
			setValue(e.target.value);
			setFile(e.target.files[0]);
		}, [setFile, setValue])
		, onConfirm = useCallback(e => {
			getFile(file)
				.then(file => {
					confirmHandler(file);
					return file;
				})
				.catch(err => {
					errorHandler(err);
					return err;
				})
				;
		}, [confirmHandler, errorHandler, file])
		;
	return [file, value, onCancel, onChange, onConfirm];
};

const ConfirmationBase = ({ disabled, onConfirm, onCancel }) => (
	<Fragment>
		<Italic
			className="fas fa-check-circle"
			disabled={disabled}
			onClick={onConfirm}
		/>
		<Italic
			className="fas fa-times-circle"
			disabled={disabled}
			onClick={onCancel}
		/>
	</Fragment>
);

const ConfirmOrCancel = composeWithDisplayName(
	"ConfirmOrCancel"
	, withUnmountWhenHidden
	, mapProps(({ disabled, ...props }) => {
		if (disabled) {
			props = update(props, {$unset: ["onCancel", "onConfirm"]});
		}
		return { disabled, ...props };
	})
)(ConfirmationBase);

const FileButton = ({
	disabled
	, id
	, onChange
	, filename
	, showFilename
	, text
	, value
	, accept
}) => (
	<Fragment>
		<Span className="filename" hidden={!showFilename} maxWidth={150}>
			{filename}
		</Span>
		<Label className="button" htmlFor={id} disabled={disabled}>
			<Button color="blue" tag="div" text={text} title={filename} />
		</Label>
		<input
			disabled={disabled}
			id={id}
			onChange={onChange}
			style={DISPLAY_NONE}
			type="file"
			value={value}
			accept={accept}
		/>
	</Fragment>
);

const UploadButtonBase = ({
	className
	, disabled
	, id
	, onConfirm: confirmHandler
	, onError
	, text
	, accept
	, ...props
}) => {
	const [
		file
		, value
		, onCancel
		, onChange
		, onConfirm
	] = useUpload(confirmHandler, onError);
	return (
		<span className={classNames(baseClassName, className)}>
			<FileButton
				disabled={disabled}
				filename={file ? file.name : ""}
				id={id + "_uploadbutton"}
				onChange={onChange}
				text={text ? text : I("Browse")}
				value={value}
				accept={accept}
				{...props}
			/>
			<ConfirmOrCancel
				disabled={disabled}
				hidden={!file}
				onCancel={onCancel}
				onConfirm={onConfirm}
			/>
		</span>
	);
};

const UploadButton = UploadButtonBase;

UploadButton.propTypes = {
	className: PropTypes.string
	, disabled: PropTypes.oneOfType([
		PropTypes.string
		, PropTypes.bool
	])
	, id: PropTypes.string.isRequired
	, onConfirm: PropTypes.func
	, onError: PropTypes.func
	, showFilename: PropTypes.bool
	, style: PropTypes.object
	, text: PropTypes.string
	, accept: PropTypes.string
};

export default UploadButton;

export const DownloadButton = ({ className, ...props }) => (
	<Button
		className={classNames(baseDownloadClassName, className)}
		color="blue"
		text={I("Download")}
		{...props}
	/>
);

DownloadButton.propTypes = {
	className: PropTypes.string
	, hidden: PropTypes.bool
	, onClick: PropTypes.func
};
