import React from 'react'
import ClickableTitle from './ClickableTitle'

const MessageGroup = ({
  children,
  eid,
  innerRef,
  left,
  myClass,
  onToggleShow,
  ready,
  show,
  title
}) => (
  <div className={myClass} ref={innerRef}>
    <ClickableTitle
      eid={eid}
      left={left}
      onToggleShow={onToggleShow}
      ready={ready}
      show={show}
      title={title}
    />
    {children}
  </div>
)

export default MessageGroup
