import { useCallback, useState } from 'react'

export const useShowAndHide = () => {
  const [show, setShow] = useState(false)
  return [
    show,
    useCallback(() => setShow(true), []),
    useCallback(() => setShow(false), []),
    useCallback(() => setShow(show => !show), [])
  ]
}

export const useToggle = () => {
  const stateAndHandlers = useShowAndHide()
  return [stateAndHandlers[0], stateAndHandlers[3]]
}
