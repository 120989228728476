import React, { PureComponent } from 'react';
import {defaultPhoto, defaultAvatar} from '../common/v5/helpers';
import classNames from 'classnames';

export class ProfilePhoto extends PureComponent {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		if(this.props.onLoad) {
			this.props.onLoad();
		}
	}
	render() {
		const p = this.props;
		let showAvatar;
		if (p.photo) {
			showAvatar = <img src={p.photo} />;
		} else {
			/*old style (blue icon white outlines)
			keeping here incase of refering back*/
			// showAvatar = defaultPhoto(p.isAgent);

			showAvatar = defaultAvatar(p.isAgent);
		}
		return (
			<figure className={p.className}>
				{showAvatar}
			</figure>
		);
	}
}
/*To-fix: Initial works on hoc that takes ProfilePhoto
and adds a status indicator (dot) over it. Yet to be fully tested and be improved */
const withStatus = (Component) => (props) => {
	return (
		<div className="profile-photo-wrapper">
			<div className="profile-status-container">
				<div className={classNames('profile-status-dot', {'red': !props.status, 'green': props.status})}></div>
			</div>
			<Component {...props} />
		</div>
	)
}

export const ProfileWithStatus = withStatus(ProfilePhoto);

export default ProfilePhoto;
