import React, { useState } from 'react';

// Notes: A component that using input with an "Add" button
// to add the input value to a list
// that showing at the bottom of the input.
export const AddTextItemInput = ({
	onAdd,
	placeholder,
}) => {
	const [text, setText] = useState("");
	const onChange = (e) => {
		setText(e.target.value);
	}
	const onAddItem = () => {
		onAdd(text);
		setText("");
	}

	const handleKeyDown = (e) => {
		if (e.key === 'Enter' && text) {
			onAddItem();
		}
	}

	return (
		<div className="add-item">
			<input type="text" name="items" value={text} onChange={onChange} onKeyDown={handleKeyDown} placeholder={placeholder} />
			<button className="btn-outline" type="button" disabled={text ? false : true} onClick={onAddItem}>{I("Add")}</button>
		</div>
	)
}

export const AddTextItemInputContainer = ({
	id,
	onAdd,
	onRemove,
	placeholder,
	list = [],
}) => {
	const onAddItem = (text) => {
		if (list.indexOf(text) > -1) {
			console.log("Error: text already in list");
			return;
		}
		onAdd(id, text);
	}
	const onRemoveItem = (index) => {
		onRemove(id, index);
	}

	return (
		<div id={"AddTextItemInputContainer_"+id} className="add-item-container">
			<AddTextItemInput onAdd={onAddItem} onRemove={onRemoveItem} placeholder={placeholder} />
			<div className="added-list">
				{list.map((item, index) => {
					return (
						<div key={index} className="added-item">
							{item}
							<i className="icon-times" onClick={() => onRemoveItem(index)}></i>
						</div>
					)
				})}
			</div>
		</div>
	)
}
