import React from 'react';
import {
	DropdownToggle
} from 'reactstrap';
import { BSDropdown  } from './Dropdown';
import { makeStyles } from '@material-ui/core/styles';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import ListSubheader from '@material-ui/core/ListSubheader';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		flexWrap: 'wrap',
		justifyContent: 'space-around',
		overflow: 'hidden',
		backgroundColor: theme.palette.background.paper,
	},
	gridList: {
		width: 400,
		height: 490,
		padding: '0px 15px'
	  },
	  icon: {
		color: 'rgba(255, 255, 255, 0.54)',
	  },
}));

const bgImgPath = process.env.CLOUDFRONT_URL + "/img/background/"

export const backgroundData = [
	{
		img: "vc-background-1.webp",
		title: 'Image',
	},
	{
		img: "vc-background-2.webp",
		title: 'Image',
	},
	{
		img: "vc-background-3.webp",
		title: 'Image',
	},
	{
		img: "vc-background-4.webp",
		title: 'Image',
	},
	{
		img: "vc-background-5.webp",
		title: 'Image',
	},
	{
		img: "vc-background-6.webp",
		title: 'Image',
	},
	{
		img: "vc-background-7.webp",
		title: 'Image',
	}

];

function BackgroundList(props) {
	const classes = useStyles();
	function resetBg() {
		if(props.blurMode) {
			stopBlurBg();
		}
		if(props.bgMode){
			props.onResetBg();
		}
	}
	function blurBg() {
		props.onBlurBg();
	}
	function stopBlurBg() {
		props.onStopBlurBg();
	}
	function selectBg(img) {
		props.onSelectBg(img);
	}
	return (
		<div className={classes.root+" bg-selector-wrapper"}>
			<GridList cellHeight={80} className={classes.gridList}>
				<GridListTile key="Subheader" cols={2} style={{ height: 'auto' }}>
					<ListSubheader className="video-bg-selector-title" component="div">{I("Change background")}</ListSubheader>
				</GridListTile>
				<GridListTile key={"remove-bg"} className="bg-tile" title={I("Reset background")} onClick={resetBg}><div><i className="icon-times"></i></div></GridListTile>
				<GridListTile key={"blur-bg"} className="bg-tile" title={I("Blur background")} onClick={blurBg}><div><i className="icon-blur"></i></div></GridListTile>
				{backgroundData.map((bg) => (
					<GridListTile key={bg.img} className="bg-tile" title={I("Select as background")} onClick={() => selectBg(bg.img)}>
						<img src={bgImgPath+bg.img} alt={bg.title} loading="lazy" />
					</GridListTile>
				))}
			</GridList>
		</div>
	);
}

class ImageSelector extends React.Component {
	constructor(props) {
		super(props);
		this.toggle = this.toggle.bind(this);
	}
	toggle() {
		this.props.onToggle(!this.props.showSelector);
	}
	render() {
		const { id, blurMode, bgMode, onBlurBg, onStopBlurBg, onResetBg, onSelectBg } = this.props;
		return (
		<BSDropdown isOpen={this.props.showSelector} toggle={this.toggle} className="video-bg-selector circle-btns grey">
			<DropdownToggle tag="div">
				<i className="icon-background"></i>
			</DropdownToggle>
			{this.props.showSelector &&
				<BackgroundList
					id={id}
					blurMode={blurMode}
					bgMode={bgMode}
					onBlurBg={onBlurBg}
					onStopBlurBg={onStopBlurBg}
					onResetBg={onResetBg}
					onSelectBg={onSelectBg} />
			}
	  </BSDropdown>)
	}
}

export default ImageSelector;