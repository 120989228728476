import React from 'react';
import PropTypes from 'prop-types';
// import { compose } from 'recompose';
// import update from 'immutability-helper';
import classNames from 'classnames';
import { withUnmountWhenHidden } from './hocs';

const ChartLinkBase = ({ className, onClick, title }) => (
	<div className={classNames(className, "chart-link")}>
		<span className="title-icon" onClick={onClick}>
			<span className="title widget-link">{title}</span>
			<i className="icon-chevron-right" />
		</span>
	</div>
);

export const ChartLink = withUnmountWhenHidden(ChartLinkBase);

ChartLink.propTypes = {
	className: PropTypes.string
	, onClick: PropTypes.func
	, title: PropTypes.string
	, hidden: PropTypes.bool
};

const TitleBase = ({ className, text }) => (
	<div className={classNames(className, "chart-title")}>
		<h3>{text}</h3>
	</div>
);

export const ChartTitle = withUnmountWhenHidden(TitleBase);

ChartTitle.propTypes = {
	className: PropTypes.string
	, text: PropTypes.string
	, hidden: PropTypes.bool
};

export function withLinkClickHandle(Chart) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClick = this.handleClick.bind(this);
		}
		handleClick(e) {
			const { id, origin, onLinkClick } = this.props;
			if (typeof onLinkClick === "function") {
				onLinkClick(
					id
					, origin.layout
					, origin.position
					, origin.index
					, e
				);
			}
		}
		render() {
			const { onLinkClick, ...props } = this.props;
			return <Chart {...props} onLinkClick={this.handleClick} />;
		}
	}
}
