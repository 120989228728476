import React from 'react';

class ClickableTitle extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleToggle = this.handleToggle.bind(this);
	}
	handleToggle(e) {
		e.preventDefault()
		if(this.props.eid){
			this.props.onToggleShow(this.props.eid, !this.props.show);
		}else{
			this.props.onToggleShow(e);
		}
	}
	render() {
		const {ready, title, left, children} = this.props;
		let bttnDOM, href, clickable;
		if(ready) {
			href = '#';
			clickable = this.handleToggle;
			bttnDOM = <button className="dt-msg-collapse" data-qa-id={"clickable_title_btn_"+title}>
					<i className="icon-chevron-down" />
				</button>;
		} else {
			bttnDOM = <i className="fa fa-spinner fa-spin"
				aria-hidden="true" />;
		}
		return (
			<div className="errand-message-group-title">
				<a
					data-qa-id={"clickable_title_"+title}
					href={href}
					className="clickable-title"
					onClick={clickable}
				>
					{left?bttnDOM:title}&nbsp;{left?title:bttnDOM}
					{children}
				</a>
			</div>
		);
	}
}

export default ClickableTitle;
