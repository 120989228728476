import React from 'react';

const OneFBRatingBar = ({stars, count, width}) => (
	<div className="rating-row">
		<div className="label">{stars} {I("stars")}</div>
		<div className="bar-container">
			<div className="bar" style={{width}}></div>
			<div className="count">{count}</div>
		</div>
	</div>
);

const Stars = ({className, style}) => (
	<div className={className} style={style}>
		<span>★</span>
		<span>★</span>
		<span>★</span>
		<span>★</span>
		<span>★</span>
	</div>
);

const FBAverageRating = ({avg}) => {
	const width = Math.round(avg/5*100) + "%";
	return (
		<div className="rating">
			<div className="number">
				{avg}
			</div>
			<div className="stars">
				<div className="star-ratings-css">
					<Stars className="star-ratings-css-top" style={{width}} />
					<Stars className="star-ratings-css-bottom" />
				</div>
			</div>
		</div>
	);
};

const FacebookRatingChart = ({data, extra}) => {
	let fbRatingBar = [];
	$.each(data, (i,v) => {
		fbRatingBar.push(<OneFBRatingBar key={i} stars={v.stars} width={v.width}
			count={v.count} />);
	});
	return (
		<div className="rating-box">
			<FBAverageRating avg={extra.average} />
			<div className="rating-bars">
				{fbRatingBar}
			</div>
		</div>
	);
};

export default FacebookRatingChart
