import React, {
  memo,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import shortid from 'shortid'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { branch, renderComponent } from 'recompose'
import styled from 'styled-components'
import update from 'immutability-helper'
import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import each from 'lodash/each'
import keys from 'lodash/keys'
import { breakpoints, fillupMissingBreakpointKeys } from '../common/theme'
import {
  GRID_MARGIN,
  GRID_ROW_HEIGHT,
  TXT_DONE,
  TXT_EDIT,
  TXT_SAVE
} from '../common/v5/statisticConstants'
import {
  composeWithDisplayName,
  createWithHideCondition,
  withUnmountWhenHidden
} from './hocs'
import { useCallbackWithValue } from '../hooks/callback'
import { useDetectKeyPressed } from '../hooks/key'
import Button from './Button'
import { PointerItalic } from '../styles/statistic'

const classBase = 'c3-grid'
const smallestCols = 2
// NOTE: this list come from react-grid-layout library default props.cols.
const _cols = { lg: 12, md: 10, sm: 6, xs: 4, xxs: smallestCols }
const cols = fillupMissingBreakpointKeys(smallestCols, _cols)
const margin = [GRID_MARGIN, GRID_MARGIN]

const ResponsiveGridLayout = WidthProvider(Responsive)

// NOTE: this won't work because the formula for height isn't as below, check:
// https://github.com/STRML/react-grid-layout/issues/978#issue-479314615
// const wrongGridHeight = height => Math.floor(height / GRID_ROW_HEIGHT) + 1

// follow the formula here: https://github.com/STRML/react-grid-layout/blob/83251e5e682abfa3252ff89d4bacf47fdc1f4270/lib/calculateUtils.js#L136
const gridHeight = height => Math.round((height + GRID_MARGIN) / (GRID_ROW_HEIGHT + GRID_MARGIN))

const updateLayout = (index, height) => layouts => {
  const updater = {}
  each(layouts, (v, k) => {
    updater[k] = { [index]: { h: { $set: height } } }
  })
  return update(layouts, updater)
}

const bottomRightDimension = (currentLayout, shortid) => {
  let _x = 0
  let _y = 0
  let _w = 2
  each(currentLayout, ({ x, y, w, h }) => {
    if (x >= _x) {
      if (x > _x) {
        _x = x
        _w = w
        _y = y + h
      } else if (y + h > _y) {
        _y = y + h
      }
    }
  })
  return { i: shortid, x: _x, y: _y, w: _w, h: _w * 4 }
}

const useLayouts = (
  edit,
  editDimension,
  setEditDimension,
  realLayouts,
  onLayoutChange
) => {
  const handleResize = useCallback((index, { height }) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: resize:', index, height)
    }
    onLayoutChange(updateLayout(index, gridHeight(height)))
  }, [onLayoutChange])
  const layouts = useMemo(
    () => {
      if (!edit) {
        return realLayouts
      }
      const layouts = {}
      each(realLayouts, (v, k) => {
        layouts[k] = update(v, { $push: [editDimension[k]] })
      })
      return layouts
    },
    [edit, editDimension, realLayouts]
  )
  const handleLayout = useCallback((_, layouts) => {
    if (edit) {
      const realLayouts = {}
      const dims = {}
      each(layouts, (v, k) => {
        const lastIndex = v.length - 1
        dims[k] = v[lastIndex]
        realLayouts[k] = update(v, { $splice: [[lastIndex, 1]] })
      })
      setEditDimension(dims)
      layouts = realLayouts
    }
    onLayoutChange(layouts)
  }, [edit, onLayoutChange, setEditDimension])
  return [layouts, handleLayout, handleResize]
}

const withResize = Component => ({ index, onResize, ...props }) => (
  <Component
    index={index}
    onResize={useCallbackWithValue(index, onResize)}
    {...props}
  />
)

const RemoverSpan = styled.span`
  position: absolute;
  right: 4px;
  top: 4px;
  cursor: pointer;
`
const RemoverBase = ({ index, onClick, shortid }) => (
  <RemoverSpan
    onClick={useCallbackWithValue(
      useMemo(() => ({ index, shortid }), [index, shortid]),
      onClick
    )}
  >
    <PointerItalic className='fas fa-trash-alt fa-2x' />
  </RemoverSpan>
)

const Remover = withUnmountWhenHidden(RemoverBase)

const Edit = ({ component: Component, idx, onClick, onSave, ...props }) => {
  const handleSaveBase = useCallbackWithValue(idx, onSave)
  const handleSave = useCallback((...args) => {
    handleSaveBase(...args)
    onClick()
  }, [handleSaveBase, onClick])
  return <Component onCancel={onClick} onSave={handleSave} {...props} />
}

const Plus = ({ onClick }) => (
  <PointerItalic className='fas fa-plus-square fa-3x' onClick={onClick} />
)

const AddOrEditBase = branch(({ edit }) => edit, renderComponent(Edit))(Plus)

const Div = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`
const AddOrEdit = ({
  breakpointLayout,
  component,
  content,
  idx,
  layouts,
  onSave,
  ...props
}) => {
  const [edit, setEdit] = useState(false)
  const handleClick = useCallback(() => setEdit(edit => !edit), [])
  const handleSave = useCallback((idx, data) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: grid save:', data)
    }
    onSave(update(content, { [idx]: { $set: data } }), layouts)
  }, [content, layouts, onSave])
  return (
    <Div>
      <AddOrEditBase
        component={component}
        edit={edit}
        idx={idx}
        breakpointLayout={breakpointLayout}
        onClick={handleClick}
        onSave={handleSave}
        {...props}
      />
    </Div>
  )
}

const FullWidthDiv = styled.div`
  width: 100%;
`
const EditGridBase = ({ edit, onClick, onSave, saveDisabled }) => (
  <FullWidthDiv>
    <Button
      disabled={saveDisabled}
      hidden={!edit && !saveDisabled}
      text={TXT_SAVE}
      onClick={onSave}
    />&nbsp;
    <Button text={edit ? TXT_DONE : TXT_EDIT} onClick={onClick} />
  </FullWidthDiv>
)

const EditGrid = composeWithDisplayName(
  'EditGrid',
  memo,
  withUnmountWhenHidden
)(EditGridBase)

const initEditDimensionShortid = shortid.generate()

const updateEditDimension = (layouts, dimShortid) => {
  const dims = {}
  each(layouts, (v, k) => {
    dims[k] = bottomRightDimension(v, dimShortid)
  })
  return dims
}

const editKey = ['ctrlKey', 'altKey', { key: 'e' }]

const withWrapper = Component => ({
  content,
  gridProps: { onSave, saveDisabled, ...gridProps },
  onAddItem,
  onChangeShowEditor,
  showEditor,
  ...props
}) => {
  const isPressed = useDetectKeyPressed(editKey)
  const { layouts } = gridProps
  const modalRef = useRef(null)
  const handleSave = useCallback(() => {
    onSave(layouts, content)
  }, [content, layouts, onSave])
  const [dimShortid, setDimShortid] = useState(initEditDimensionShortid)
  const [editDimension, setEditDimension] = useState(null)
  const [edit, setEdit] = useState(false)
  const handleEdit = useCallback(() => {
    const nextEdit = !edit
    if (nextEdit) {
      setEditDimension(updateEditDimension(layouts, dimShortid))
    } else {
      onChangeShowEditor(false)
    }
    setEdit(nextEdit)
  }, [dimShortid, edit, layouts, onChangeShowEditor])
  const handleAddItem = useCallback((...args) => {
    onAddItem(...args)
    const newShortid = shortid.generate()
    setDimShortid(newShortid)
    setEditDimension(updateEditDimension(layouts, newShortid))
  }, [layouts, onAddItem])
  return (
    <div>
      <EditGrid
        hidden={!isPressed && !edit && !showEditor}
        edit={edit}
        onClick={handleEdit}
        onSave={handleSave}
        saveDisabled={saveDisabled}
      />
      <Component
        content={content}
        edit={edit}
        editDimension={editDimension}
        gridProps={gridProps}
        modalRef={modalRef}
        onAddItem={handleAddItem}
        onEditDimension={setEditDimension}
        {...props}
      />
      <div ref={modalRef} />
    </div>
  )
}

const StyledIndexDIV = styled.div`
  align-items: center;
  background-color: #006aa7;
  display: flex;
  height: 30px;
  justify-content: center;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 30px;
`
const StyledSpanIndex = styled.span`
  color: #fecc00;
  font-size: 20px;
  font-weight: 800;
`
const Index = withUnmountWhenHidden(({ index }) => (
  <StyledIndexDIV>
    <StyledSpanIndex>{index}</StyledSpanIndex>
  </StyledIndexDIV>
))

const withGridBase = Component => ({
  canNotRemove,
  content,
  edit,
  editDimension,
  editorComponent,
  editorProps,
  gridProps: { layouts, onLayoutChange, onSave, ...gridProps },
  modalRef,
  onAddItem,
  onEditDimension,
  onRemoveItem,
  ...props
}) => {
  const [breakpoint, setBreakpint] = useState('lg')
  const handleBreakpoint = useCallback(
    breakpoint => setBreakpint(breakpoint),
    []
  )
  const [
    _layouts,
    handleLayout,
    handleResize
  ] = useLayouts(edit, editDimension, onEditDimension, layouts, onLayoutChange)
  const layout = _layouts[breakpoint]
  const cs = []
  // NOTE:1 can not use 'data-grid' as the resize did not re-render
  // ResponsiveGridLayout but just the child component. Resize change props
  // layouts, thus it need to present at ResponsiveGridLayout props.
  each(layout, (data, index) => {
    const { i } = data
    if (edit && index === layout.length - 1) {
      cs.push(
        <div key={i}>
          <AddOrEdit
            breakpointLayout={layouts[breakpoint]}
            component={editorComponent}
            content={content}
            idx={i}
            layouts={_layouts}
            onSave={onAddItem}
            {...editorProps}
          />
        </div>
      )
      return
    }
    cs.push(
      <div key={i}>
        <Component
          data={content[i]}
          edit={edit}
          index={index}
          modalRef={modalRef}
          onResize={handleResize}
          shortid={i}
          {...props}
        />
        <Remover
          hidden={!edit || canNotRemove[i]}
          index={index}
          onClick={onRemoveItem}
          shortid={i}
        />
        <Index hidden={!edit} index={index} />
      </div>
    )
  })
  return (
    <ResponsiveGridLayout
      breakpoints={breakpoints}
      className={classBase}
      cols={cols}
      isDraggable={edit}
      isResizable={edit}
      layouts={_layouts}
      margin={margin}
      onBreakpointChange={handleBreakpoint}
      onLayoutChange={handleLayout}
      rowHeight={GRID_ROW_HEIGHT}
      {...gridProps}
    >
      {cs}
    </ResponsiveGridLayout>
  )
}

export const withGrid = composeWithDisplayName(
  'withGrid',
  memo,
  withWrapper,
  createWithHideCondition(({ content, edit }) => !edit && (!content || !keys(content).length)),
  withGridBase,
  withResize
)

const RenderChildComponentProp = ({ childComponent: Child, ...props }) => (
  <Child {...props} />
)

const Grid = composeWithDisplayName('Grid', withGrid)(RenderChildComponentProp)

export default Grid
