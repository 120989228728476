// convert from Footers -> Buttons -> ReplyOneButton. Need to back convert.
import React from 'react';
import PropTypes from 'prop-types';
import {
	branch
	, compose
	, mapProps
	, renderComponent
	, renderNothing
} from 'recompose';
import classNames from 'classnames';
import {
	composeWithDisplayName
	, withIdAttachedOnClick
	, withUnmountWhenHidden
} from './hocs';
import Anchor from './Anchor';
import { ButtonBlock } from './Button';
import { HotkeyLabel } from './Hotkeys';
import { Spinner } from './Spinner';

const baseClassName = "c3-buttons";

const Wrapper = ({ children, className, ...props }) => (
	<div className={classNames(baseClassName, className)} {...props}>
		{children}
	</div>
);

const SpanSpinnerBase = () => <span><Spinner />&nbsp;</span>;

const SpanSpinner = withUnmountWhenHidden(SpanSpinnerBase);

const Text = ({ text }) => <div><span>{text}</span></div>;

const ButtonWithReasonBase = ({
	busy
	, children
	, className
	, color
	, "data-qa-id": dataQAId
	, disabled
	, id
	, onClick
	, reason
	, tag
	, text
}) => {
	let title
		, Tag
		;
	if (tag === 'a') {
		Tag = Anchor;
	} else {
		Tag = ButtonBlock;
	}
	if (reason) {
		title = reason;
	} else {
		title = text;
	}
	return (
		<Tag
			className={className}
			color={color}
			data-qa-id={"QA_btn_"+dataQAId}
			disabled={disabled}
			onClick={onClick}
			title={title}
		>
			<SpanSpinner hidden={!busy} />
			{children}
		 </Tag>
	 );
};

const OneButton = withIdAttachedOnClick(ButtonWithReasonBase);

const withButtonNoReason = Component => ({ disabled, ...props }) => {
	let reason;
	if (disabled) {
		reason = disabled.reason;
	}
	return <Component disabled={!!disabled} reason={reason} {...props} />;
};

const OneButtonWithoutReason = withButtonNoReason(OneButton);

const SimpleButton = ({
	buttonTag: ButtonTag
	, busy
	, disabled
	, className
	, onClick
	, tag
	, textTag: TextTag
	, ...props
}) => (
	<ButtonTag
		busy={busy}
		className={className}
		disabled={disabled}
		onClick={onClick}
		tag={tag}
		{...props}
	>
		<TextTag {...props} />
	</ButtonTag>
);

const withCountdownText = Component => ({ tick, text, ...props }) => (
	<Component text={text + " (" + tick + ")"} {...props} />
);

const withCountdownTypeButton = composeWithDisplayName(
	"withCountdownTypeButton"
	, mapProps(({ countdown, ...props }) => ({tick: countdown.tick, ...props}))
	, withCountdownText
);

const CrazyButton = branch(
	({ countdown }) => typeof countdown !== "undefined"
	, withCountdownTypeButton
)(SimpleButton);

const withButtons = (ButtonComponent, InnerButtonComponent) => ({
	className
	, children
	, condition
	, onClick
	, onEvent
	, tag
}) => {
	const { buttons, order } = condition;
	let bttns = [];
	$.each(order, (i, v) => {
		const bttn = buttons[v];
		if (!bttn || bttn.hidden) {
			return;
		}
		const {
				busy
				, class: bttnClassName
				, disabled
				, hidden
				, id: bttnId // remove any id field from bttn
				, text
				, ...bttnProps
			} = bttn
			;
		bttns.push(
			<CrazyButton
				key={v}
				buttonTag={ButtonComponent}
				busy={busy}
				className={bttnClassName}
				disabled={disabled}
				id={v}
				onClick={onClick}
				onEvent={onEvent}
				text={text}
				textTag={InnerButtonComponent}
				tag={tag}
				{...bttnProps}
			/>
		);
	});
	return <Wrapper className={className}>{bttns}{children}</Wrapper>;
};

const NoButtonBase = ({ className, noButtonText }) => (
	<Wrapper className={className}>{noButtonText}</Wrapper>
);

const withHideNoButtonText = branch(
	({ hideNoButtonText }) => hideNoButtonText
	, renderNothing
);

const NoButton = withHideNoButtonText(NoButtonBase);

const withNoButton = branch(
	({ condition: c }) => !c || !c.order || !c.order.length
	, renderComponent(NoButton)
);

const ButtonsBase = withButtons(OneButtonWithoutReason, Text);

const Buttons = compose(withUnmountWhenHidden, withNoButton)(ButtonsBase);

export default Buttons;

const ButtonsWithHotkeyBase = withButtons(OneButton, HotkeyLabel);

export const ButtonsWithHotkey = withNoButton(ButtonsWithHotkeyBase);

Buttons.propTypes = {
	condition: PropTypes.shape({
		buttons: PropTypes.objectOf(PropTypes.shape({
			busy: PropTypes.bool
			, class: PropTypes.string
			, color: PropTypes.string
			, disabled: PropTypes.oneOfType([
				PropTypes.exact(false)
				, PropTypes.shape({reason: PropTypes.string})
			])
			, hidden: PropTypes.bool
			, text: PropTypes.string
		}))
		, order: PropTypes.arrayOf(PropTypes.number)
	})
	, className: PropTypes.string
	, hidden: PropTypes.bool
	, hideNoButtonText: PropTypes.bool
	, noButtonText: PropTypes.string
	, onClick: PropTypes.func
	, onEvent: PropTypes.func
	, tag: PropTypes.oneOfType([
		PropTypes.string
		, PropTypes.element
	])
};

ButtonsWithHotkey.propTypes = {
	condition: PropTypes.shape({
		buttons: PropTypes.objectOf(PropTypes.shape({
			busy: PropTypes.bool
			, class: PropTypes.string
			, color: PropTypes.string
			, disabled: PropTypes.bool
			, hidden: PropTypes.bool
			, hotkey: PropTypes.string
			, reason: PropTypes.string
			, text: PropTypes.string
		}))
		, order: PropTypes.arrayOf(PropTypes.number)
	})
	, className: PropTypes.string
	, noButtonText: PropTypes.string
	, onClick: PropTypes.func
	, onEvent: PropTypes.func
	, tag: PropTypes.oneOfType([
		PropTypes.string
		, PropTypes.element
	])
};
