import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { HorizontalBar } from 'react-chartjs-2';
import { ORG_STR, CL_OVERVIEW } from '../common/v5/constants';
import { composeWithDisplayName, withPointerClickableOnClick } from './hocs';
import { withMeasurableHeight } from '../styles/autoHeight';
import { AreaDropdown } from './Dropdown';
import memoizeOne from 'memoize-one';
import { ChartTitle, ChartLink, withLinkClickHandle } from './ChartTitleLink';

const Title = props => <ChartTitle text={ORG_STR} {...props} />;

const FooterLink = props => <ChartLink className="footer-row" title={ORG_STR} {...props} />;

const ClickableDiv = withPointerClickableOnClick("div");

const getOrganizationList = data => {
	const orgList = [];
	if (data && data.length > 0) {
		for (let j=0; j<data.length; j++) {
			let org = data[j];
			orgList.push({Id: org.orgId, Name: org.title});
		}
	}
	return orgList;
};

const withStateControl = Component => props => {
	const { data } = props
		, orgs = useMemo(() => getOrganizationList(data), [data])
		, [ showDropdown, setShowDropdown ] = useState(false)
		, [ selectedOrgs, setSelectedOrgs ] = useState([])
		, toggleDropdown = useCallback(
			(...args) => setShowDropdown(show => !show)
			, []
		)
		, handleSelect = useCallback(selected => setSelectedOrgs(selected), [])
		;
	useEffect(() => {
		const defOrgs = [];
		for (let j=0; j<data.length; j++) {
			defOrgs.push(data[j].orgId);
		}
		setSelectedOrgs(defOrgs);
	// TODO: this is anti-pattern and can cause state unsync in certain
	// condtion.
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return (
		<Component
			onSelect={handleSelect}
			onToggle={toggleDropdown}
			orgs={orgs}
			selectedOrgs={selectedOrgs}
			showDropdown={showDropdown}
			{...props}
		/>
	);
};

const withOrganizationBarChart = Component => ({
	data
	, hideTitleAndLink
	, measureRef
	, modalRef
	, onLinkClick
	, onSelect
	, onToggle
	, orgs
	, selectedOrgs
	, showDropdown
	, style
	, layout
}) => (
	<Component className="c3-org-bar" ref={measureRef} style={style}>
		<div className="col-header">
			<Title hidden={layout === CL_OVERVIEW ? false : true} />
			<AreaDropdown
				className={"multi-select"}
				data={orgs}
				id={"orgBarChart_Filter"}
				idFields={{id: "Id", name: "Name"}}
				modalRef={modalRef}
				multiSelect={true}
				onSelect={onSelect}
				onToggle={onToggle}
				overrideTitle={true}
				selectAll={true}
				selectNone={true}
				selected={selectedOrgs}
				show={showDropdown}
				showCaret={true}
				textNoItemSelected={I("None")}
			/>
		</div>
		<ClickableDiv
			className="errands-stats-chart"
			onClick={onLinkClick}
			unclickable={hideTitleAndLink}
		>
			{data.map((v, i) => selectedOrgs.indexOf(v.orgId) !== -1 &&
				<SingleOrganizationBarChart
					key={i}
					data={v.datasets}
					title={v.title}
					total={v.total}
				/>
			)}
			<div className="legend-row">
				<span>{I("Expired")}</span>
				<span>{I("Warnings")}</span>
				<span hidden>{I("Closed")}</span>
				<span>{I("Total errands")}</span>
			</div>
		</ClickableDiv>
		<FooterLink
			hidden={hideTitleAndLink}
			onClick={onLinkClick}
		/>
	</Component>
);

function genData(expired, warn, closed, unanswered, totalOpen) {
	let line;
	if (expired || warn || unanswered) {
		line = 100;
	} else {
		line = 0;
	}
	return {
		datasets: [
			{
				data: [expired],
				backgroundColor: ["#F4213F"],
				stack: "xAxes",
				maxBarThickness: 10,
				label: "expiredErrands"
			},
			{
				data: [warn],
				backgroundColor: ["#FFAF11"],
				stack: "xAxes",
				maxBarThickness: 10,
				label: "warningErrands"
			},
			{
				data: [
					{
						x: expired+warn, y: 1, percent: unanswered
					},
					{
						x: line, y: 1, display: true, label: "allStatusErrand2"
					}
				],
				borderColor: "#0C87F7",
				borderWidth: 2,
				pointBackgroundColor: ["#0C87F7", "#0C87F7"],
				fill: false,
				showLines: true,
				pointRadius: 0,
				pointHoverRadius: 0,
				pointHitRadius: 6667770,
				labels: [1, 1],
				lineTension: 0,
				cubicInterpolationMode: "monotone",
				type: "line",
				yAxisID: "test",
				xAxisID: "test2",
				label: "allStatusErrand"
			}
		],
		labels: [1, 1],
		xLabels: [1, 1],
		yLabels: [1, 1]
	};
}

const defTitleCallback = () => false
	, defLabelCallback = (tooltipItem, data) => {
		const { datasetIndex, index } = tooltipItem;
		if (datasetIndex === 2 && index === 0) {
			return "  " + data.datasets[datasetIndex].data[0].percent + " %";
		} else if (tooltipItem.datasetIndex === 2) {
			return false;
		} else {
			return "   " + data.datasets[datasetIndex].data[index] + " %"
		}
	}
	, defChartOpt = {
		elements: {
			line: {
				tension: 0 // disables bezier curves
			}
		}
		, responsive: true
		, maintainAspectRatio: false
		, tooltips: {
			mode: "dataset"
			, backgroundColor: "rgba(255,255,255,1)"
			, bodyFontColor: "black"
			, position: "average"
			, titleSpacing: 0
			, titleMarginBottom: 0
			, callbacks: {
				title: defTitleCallback
				, label: defLabelCallback
			}
		}
		, legend: {
			display: false
		}
		, layout: {
			padding: {
				left: 0
				, right: 0
				, top: 10
				, bottom: 10
			}
		}
		, scales: {
			display: false,
			xAxes: [
				{
					stacked: true
					, ticks: {
						min: 0
						, max: 100
						, display: false
						, autoSkip: false
					}
					, display: false
				}
				, {
					type: "linear"
					, id: "test2"
					, ticks: {
						min: 0
						, display: false
						, autoSkip: false
					}
					, display: false
				}
			]
			, yAxes: [
				{
					stacked: true
					, ticks: {
						min: 1
						, max: 1
						, display: false
						, autoSkip: false
					}
					, display: false
				}
				, {
					type: "linear"
					, id: "test"
					, ticks: {
						min: 0
						, max: 2
						, display: false
						, autoSkip: false
					}
					, display: false
				}
			]
		}
	}
	;
const OrgBarChartBase = ({ data }) => (
	<HorizontalBar
		data={genData(...data)}
		options={defChartOpt}
	/>
);

const OrgBarChart = memo(OrgBarChartBase);

const SingleOrganizationBarChart = (props) => {
	const { id, title, data, total, options } = props;
	return (
		<div className="chart-row">
			<div className="chart-title">
				<h4>{title}</h4>
			</div>
			<div className="chart" data-qa-id={"one-org-chart-"+title}>
				<div className="chart-holder">
					<OrgBarChart data={data} />
				</div>
				<div className="errands-count">
					{total}
				</div>
			</div>
		</div>
	);
}

const OrganizationBarChart = composeWithDisplayName(
	"OrganizationBarChart"
	, memo
	, withLinkClickHandle
	, withStateControl
	, withMeasurableHeight
	, withOrganizationBarChart
)("div");

export default OrganizationBarChart;
