/*
	This file store function that can be used by functions. It may not need to
	be react component. It can be any function or object.
*/
import React from 'react';
import moment from 'moment';
import update from 'immutability-helper';
import each from 'lodash/each';
import 'moment-duration-format';
import { I, L } from '../common/v5/config';
import { getChevronIcon as getChevronIconHelper } from '../common/v5/helpers';
import {
	CT_LINE
	, CT_PIE
	, SR_ACTIVE_AGENT
	, SR_CAP_UTIL_CHAT
	, SR_LEADERBOARD
	, SR_COLLABORATION
	, SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP
	, SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION
	, SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP
	, SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION
	, SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP
	, SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION
	, SR_INBOUND_AND_OUTBOUND_CALLS
} from '../common/v5/constants';

export function doNothing() {}

export const cursorAuto = {cursor: "auto"}
	, cursorNotAllowed = {cursor: "not-allowed"}
	, cursorPointer = {cursor: "pointer"}
	;
export const getChevronIcon = getChevronIconHelper;

export const DISPLAY_NONE = {display: "none"};

export const POINTER_CURSOR = cursorPointer
	, POINTER_UNSET = {cursor: "unset"}
	;
//To use older report design, change TABLE_BASE_CLASSNAME to 'c3-report-table'
export const TABLE_BASE_CLASSNAME = "c3-report-table-v5"
	, TABLE_EXPAND_TRUE = {expanded: true}
	, TABLE_EXPAND_FALSE = {expanded: false}
	, TABLE_HEADER_STYLE = {
		color: "#6f6f6f"
		, textTransform: "capitalize"
		, fontSize: "11px"
		, border: "none"
		, paddingTop: "30px"
	}
	, TABLE_TITLE_HEADER_STYLE = {
		color: "#6f6f6f"
		, textTransform: "capitalize"
		, fontSize: "17px"
		, fontWeight: "900"
		, border: "none"
		, paddingTop: "25px"
	}
	;

// Static colors for first 20 dataset
export const STANDARD_COLOR = [
	"230, 25, 75", "60, 180, 75", "255, 225, 25", "0, 130, 200",
	"245, 130, 48",	"145, 30, 180", "70, 240, 240", "240, 50, 230",
	"210, 245, 60", "250, 190, 190", "0, 128, 128", "230, 190, 255",
	"170, 110, 40", "255, 250, 200", "128, 0, 0", "170, 255, 195",
	"128, 128, 0", "255, 215, 180", "0, 0, 128", "128, 128, 128",
	// special color for horizontal bar that with label on it for better visibility
	"241, 196, 15"
];
////////////////////////////////////////////////////////////////////////////////
// converting from prior version ReportChart.js
////////////////////////////////////////////////////////////////////////////////
const ST_GROUP = 'GROUP'
	, ST_KEY = 'KEY'
	;
function randomColorFactor() {
	return Math.round(Math.random() * 255);
}

function randomColor(transparency) {
	return randomColorFactor() + ',' + randomColorFactor() + ',' +
		randomColorFactor();
}

function selectColor(colorIndex) {
	let clr;
	if (colorIndex.next >= STANDARD_COLOR.length) {
		clr = randomColor();
	} else {
		clr = STANDARD_COLOR[colorIndex.next];
		colorIndex.next++;
	}
	return clr;
}

// custom dataset attribute for specific report and key
const MAP_SYSTEM_REPORT_KEY_ATTRIBUTE = {
	[SR_ACTIVE_AGENT]: {
		key_active_agent: {
			yAxisID: 'left-y-axis'
		},
		key_active_chat_agents: {
			yAxisID: 'left-y-axis'
		},
		key_active_chats: {
			yAxisID: 'right-y-axis'
		},
		key_inactive_chats: {
			yAxisID: 'right-y-axis'
		},
	}
	, [SR_CAP_UTIL_CHAT]: {
		key_average_errand_process_time: {
			yAxisID: 'left-y-axis'
		},
		key_user_activity_available_chat: {
			yAxisID: 'left-y-axis'
		},
		key_closed: {
			yAxisID: 'right-y-axis'
		},
		key_chat_capacity: {
			yAxisID: 'right-y-axis'
		}
	}
	, [SR_LEADERBOARD]: {
		key_average_errand_process_time: {
			yAxisID: 'left-y-axis'
		},
		key_closed: {
			yAxisID: 'right-y-axis'
		}
	}
	, [SR_COLLABORATION]: {
		key_collaboration_total: {
			yAxisID:'right-y-axis'
		},
		key_collaboration_answered: {
			yAxisID:'right-y-axis'
		},
		key_collaboration_unanswered: {
			yAxisID:'right-y-axis'
		},
		key_average_collaboration_response_time: {
			yAxisID:'left-y-axis'
		},
		key_average_collaboration_waiting_time: {
			yAxisID:'left-y-axis'
		}
	}
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: {
		key_errand_closure_status_answered: {
			yAxisID: 'left-y-axis'
		}
		, key_average_errand_process_time: {
			yAxisID: 'right-y-axis'
		}
	}
	, [SR_ANSWERED_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: {
		key_errand_closure_status_answered: {
			yAxisID: 'left-y-axis'
		}
		, key_average_errand_process_time: {
			yAxisID: 'right-y-axis'
		}
	}
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_AGENT_GROUP]: {
		key_closed_voice_errand: {
			yAxisID: 'left-y-axis'
		}
		, key_average_errand_process_time: {
			yAxisID: 'right-y-axis'
		}
	}
	, [SR_CLOSED_VOICE_ERRAND_AND_AVG_HANDLING_TIME_BY_ORGANISATION]: {
		key_closed_voice_errand: {
			yAxisID: 'left-y-axis'
		}
		, key_average_errand_process_time: {
			yAxisID: 'right-y-axis'
		}
	}
	, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_AGENT_GROUP]: {
		key_voice_closure_status_answered: {
			yAxisID: 'left-y-axis'
		}
		, key_average_call_time: {
			yAxisID: 'right-y-axis'
		}
	}
	, [SR_ANSWERED_AND_AVG_CALLS_TIME_BY_ORGANIZATION]: {
		key_voice_closure_status_answered: {
			yAxisID: 'left-y-axis'
		}
		, key_average_call_time: {
			yAxisID: 'right-y-axis'
		}
	}
	, [SR_INBOUND_AND_OUTBOUND_CALLS]: {
		key_inbound_calls: {
			yAxisID: 'left-y-axis'
		}
		, key_outbound_calls: {
			yAxisID: 'left-y-axis'
		}
		, key_average_inbound_call_time: {
			yAxisID: 'right-y-axis'
		}
		, key_average_outbound_call_time: {
			yAxisID: 'right-y-axis'
		}
	}
};

const loadBarColors = (o, rpt, id, colorIndex) => {
	let clr = selectColor(colorIndex);
	o.backgroundColor = "rgba(" + clr + ",1)";
	o.borderColor = "rgba(" + clr + ",1)";
	o.borderWidth = 1,
	o.hoverBackgroundColor = "rgba(" + clr + ",1)";
	o.hoverBorderColor = "rgba(" + clr + ",1)";

	const customAttribute = MAP_SYSTEM_REPORT_KEY_ATTRIBUTE[rpt];
	if (typeof customAttribute !== "undefined") {
		const attribute = customAttribute[id]
		if (typeof attribute !== "undefined") {
			o = update(o, {$merge: attribute});
		}
	}
	return o;
}

const loadLineColor = (o, rpt, id, colorIndex) => {
	let clr = selectColor(colorIndex);
	o.fill = false;
	o.lineTension = 0.2;
	o.backgroundColor = "rgba(" + clr + ",1)";
	o.borderColor = "rgba(" + clr + ",1)";
	o.borderWidth = 1;
	o.pointBorderColor = "rgba(" + clr + ",1)";
	o.pointBackgroundColor = '#fff';
	o.pointBorderWidth = 1;

	const customAttribute = MAP_SYSTEM_REPORT_KEY_ATTRIBUTE[rpt];
	if (typeof customAttribute !== "undefined") {
		const attribute = customAttribute[id]
		if (typeof attribute !== "undefined") {
			o = update(o, {$merge: attribute});
		}
	}
	return o;
}

const loadPieColor = (o, rpt, id, colorIndex) => {
	o.backgroundColor = [];
	o.borderWidth = 1;
	return o;
}

function dataSetsContains(dataSets, ds) {
	for (let i=0; i<dataSets.length; i++) {
		if (dataSets[i].id == ds) {
			return i;
		}
	}
	return -1;
}

function timeToNumber(data) {
	if (typeof data === 'string') {
		data = data.split(':');
		return (parseInt(/* hour */data[0], 10) * 60 * 60) +
			(parseInt(/* minute */data[1], 10)* 60) +
			parseInt(/* seconds */data[2], 10);
	}
	return 0;
}

export function numberToTime(data) {
	return moment.duration(data, 'seconds').format('hh:mm:ss', {
		useGrouping: false
		, trim: false
	});
	// return Math.floor(data/3600) + ":" + Math.floor((data%3600)/60) + ":" +
	// 	data%60;
}

function countTotal(data, keyTotal, Yi, isTimeType) {
	if (keyTotal !== "") {
		return keyTotal;
	}
	let total = 0;
	for (let i=0; i<=Yi; i++) {
		const d = data[i];
		if (d) {
			total += d
		}
	}
	if (isTimeType) {
		return numberToTime(total);
	}
	return '' + total;
}

function buildDataSet(dataSets, Yi, id, name, key, value, keyTotal, loadChartAttribute, colorIndex, reportName) {
	let isTimeType;
	if (id.indexOf('average') >= 0 || id.indexOf('user_activity') >= 0) {
		isTimeType = true;
	} else {
		isTimeType = false;
	}
	const ds = dataSetsContains(dataSets, id);
	if (ds < 0) {
		let labelValue = value;
		if (isTimeType) {
			labelValue = numberToTime(value)
		}
		let tmp = {id, label: name + "(" + labelValue + ")", data: []};
		tmp = loadChartAttribute(tmp, reportName, key, colorIndex);
		tmp.data[Yi] = value;
		dataSets.push(tmp);
	} else {
		if (!dataSets[ds].data[Yi]) {
			dataSets[ds].data[Yi] = 0;
		}
		dataSets[ds].data[Yi] += value;
		dataSets[ds].label = name + " (" +
			countTotal(dataSets[ds].data, keyTotal, Yi, isTimeType) + ")";
	}
	return dataSets;
}

function getOrder(order, groups) {
	for (let i=0; i<groups.length; i++) {
		if (groups[i].order === order) {
			return groups[i];
		}
	}
}

function chartAccumulator(a, b, orderId) {
	const previous = a.slice(-1)[0];
	if ((!previous && previous !== 0) || previous.id !== b[orderId]) {
		const bGroup0Name = b["group0_name"];
		let valName;
		if (bGroup0Name || bGroup0Name === "") {
			valName = bGroup0Name;
		} else {
			valName = b[orderId];
		}
		a.push({id: b["group0"], name: valName});
	}
	return a;
}

function tableAccumulator(a, b, orderId) {
	const previous = a.slice(-1)[0];
	if ((!previous && previous !== 0) || previous !== b[orderId]) {
		// return different items of same groupX, eg: group0 has Monday,
		// Tuesday, etc, by comparing with previous items. If previous is first
		// item then it is accepted or current not same as previous then will be
		// accepted too because it is a sorted list before reduce is called.
		a.push(b[orderId]);
	}
	return a;
}

// get the distinct group data id. Result object contains sorted array of groupX
// eg: ["Monday", "Tuesday", ...] and id with value groupX, eg: group0.
function sortReduceData(groups, data, order, accumulator) { // get unique groups by id, and order
	const currGroup = getOrder(order, groups)
		, orderId = currGroup.id // groupX eg: group0
		, sortedY = data.slice().sort((a, b) => {
				const sorter = (orderBy, a, b, i) => {
					// orderBy can be group0, group1, etc or order0, order1, etc
					// OR it can be something like "workflow_areas.name", etc.
					if (orderBy.indexOf("order") < 0 && orderBy !== orderId) {
						// Likely only orderBy with value "workflow_areas.name"
						// will fall into here which mean that it will be sorted
						// based on string. Former condition
						const orderIdName = orderId + "_name";
						// if there is object-key groupX_name (even it's empty
						// string) then it'll be used else groupX will be used.
						let orderByName;
						if (a[orderIdName] || a[orderIdName] === "") {
							orderByName = orderIdName;
						} else {
							orderByName = orderId;
						}
						// sort this such a way that empty string name or
						// '(untagged)' name will be sorted last.
						if (a[orderByName] === ''
							|| a[orderByName] == '(untagged)') {
							return 1;
						} else if (b[orderByName] === ''
							|| b[orderByName] == '(untagged)') {
							return -1;
						}
						// sort base on alphabet order.
						const aOrderByName = a[orderByName].toLowerCase()
							, bOrderByName = b[orderByName].toLowerCase()
							;
						if (aOrderByName > bOrderByName) {
							return 1;
						} else if (aOrderByName < bOrderByName) {
							return -1;
						} else if (aOrderByName == bOrderByName) {
							return 0;
						}
					}
					// fall to here mean sort base on orderX object-key which
					// will exist inside data and with value type number OR it
					// also possible sort based on groupX (very unlikely) which
					// may give js error.
					if (a[orderBy] > b[orderBy]) {
						return 1;
					} else if (a[orderBy] < b[orderBy]) {
						return -1;
					} else if (a[orderBy] == b[orderBy]) {
						// TODO: if orderBy === orderId and
						// currGroup.orders.length may be zero value, then may
						// lead to -1 which compare to i (likely 1, 0 + 1 )
						// will always give false result and lead to js error.
						if (currGroup.orders.length-1 === i) {
							return 0;
						}
						const nxti = i+1;
						return sorter(currGroup.orders[nxti], a, b, nxti);
					}
				}
				if (currGroup.orders.length <= 0) {
					// seem like there isn't a chance to fall into here as
					// .orders always has non-zero length array.
					console.log("dbg: group.orders: ", currGroup.orders);
					// TODO: the last argument here should be -1 instead of +1
					// if the intention is to make the above comparison
					// 'currGroup.orders.length-1 === i' always true.
					return sorter(orderId, a, b, currGroup.orders.length+1);
				}
				return sorter(currGroup.orders[0], a, b, 0);
			})
		, Y = sortedY.reduce((a, b) => accumulator(a, b, orderId), [])
		;
	return {sorted: Y, id: orderId};
}

export function getAxesY({ header, data }, order) { // get unique groups by id, and order
	return sortReduceData(header.groups, data, order, chartAccumulator).sorted;
}

function translate(text, stackName, stackType) {
	if (stackType == ST_KEY ||
		(stackType == ST_GROUP && stackName == "Channel")) {
		return L(text);
	}
	return text;
}

export function getKeyValue(data, key) {
	if (data[key]) {
		return data[key]
	}
	return 0;
}

function getGroup(data, ig) {
	return data.header.groups[ig];
}

function buildData(data, stacks, excludeKeys, stackType, loadChartAttribute, reportName) {
	let groups = []
		, datasets = []
		, colorIndex = {next: 0}
		;
	getAxesY(data, 0).map((y, Yi) => {
		groups.push(translate(y.name, getGroup(data, 0).name, ST_GROUP));
		const filtered = data.data.filter(row => y.id === row["group0"]);
		for (let i=0; i<filtered.length; i++) {
			if (stackType === ST_KEY) {
				for (let k=0; k<stacks.length; k++) {
					if (excludeKeys && excludeKeys.length > 0) {
						let skipKey = false;
						for(let j=0; j<excludeKeys.length; j++) {
							if (excludeKeys[j] === stacks[k].id) {
								skipKey = true;
								break;
							}
						}
						if (skipKey) {
							continue;
						}
					}
					datasets = buildDataSet(
						datasets,
						Yi,
						stacks[k].id,
						translate(stacks[k].name, "", stackType),
						stacks[k].id,
						getKeyValue(filtered[i], stacks[k].id + "_unformatted"),
						data.total[stacks[k].id],
						loadChartAttribute,
						colorIndex,
						reportName
					);
				}
			} else {
				datasets = buildDataSet(
					datasets,
					Yi,
					filtered[i].group1 + '',
					translate(filtered[i].group1_name, stacks[0].name, stackType),
					data.header.keys[0].id,
					getKeyValue(filtered[i], data.header.keys[0].id+ "_unformatted"),
					"",
					loadChartAttribute,
					colorIndex,
					reportName
				);
			}
		}
	});
	// fill up the empty dataset
	datasets.forEach(ds => {
		groups.map((g, i) => {
			if (typeof ds.data[i] === "undefined") {
				ds.data[i] = 0;
			}
		});
	});
	return {
		labels: groups
		, datasets
	};
}

export function buildSummaryBarData(labels, data) {
	var datasets = [{
		data: data
		, backgroundColor: "rgba(" + STANDARD_COLOR[STANDARD_COLOR.length-1] + ",1)"
		, borderColor: "rgba(" + STANDARD_COLOR[STANDARD_COLOR.length-1] + ",1)"
	}];
	return {
		labels
		, datasets
	};
}

export function buildDoughnutData(labels, data, colorOffset) {
	let colorIndex = {next: colorOffset ? colorOffset : 0}
	var datasets = [{
		data: data
		, backgroundColor: []
	}]
	labels.map((g, i) => {
		let clr = selectColor(colorIndex);
		datasets[0].backgroundColor.push("rgba(" + clr + ",1)");
	});
	return {
		labels
		, datasets
	};
}

export function genChartData(reportName, data, excludeKeys, chart) {
	const { header } = data
		, { groups, keys } = header
		, groupCount = groups.length
		, keyCount = keys.length
		;
	let loadChartAttribute = loadBarColors;
	if (typeof chart !== "undefined") {
		switch(chart){
			case CT_LINE:
				loadChartAttribute = loadLineColor;
				break;
			case CT_PIE:
				loadChartAttribute = loadPieColor;
		}
	}
	if (keyCount == 1 && groupCount == 2) {
		data = buildData(data, [groups[1]], [], ST_GROUP, loadChartAttribute, reportName);
	} else if (keyCount >= 1 && groupCount == 1) {
		data = buildData(data, keys, excludeKeys, ST_KEY, loadChartAttribute, reportName);
	} else {
		data = buildData(data, keys, excludeKeys, ST_KEY, loadChartAttribute, reportName);
	}
	// Pie chart use array for backgroundColor for each element in a dataset
	if (chart === CT_PIE) {
		let colorIndex = {next: 0}
		data.datasets.forEach(ds => {
			data.labels.map((g, i) => {
				let clr = selectColor(colorIndex);
				ds.backgroundColor.push("rgba(" + clr + ",1)");
			});
		});
	}
	return {data, title: header.title};
}

export function processForTable(groups, data, order) {
	return sortReduceData(groups, data, order, tableAccumulator);
}

////////////////////////////////////////////////////////////////////////////////

// splitAndAdd able to split dynamic i18n text with variables into seperate
// component. This allow more styles on i18n text.
export const splitAndAdd = (
	Component,
	TextComponent,
	props,
	variableList,
	all,
	variableIndex,
	text
) => {
	const variable = variableList[variableIndex++]
	const results = text.split(variable)
	const resultsSize = results.length
	if (resultsSize > 0) {
		each(results, (text, index) => {
			if (text != '') {
				if (variableIndex < variableList.length) {
					splitAndAdd(
						Component,
						TextComponent,
						props,
						variableList,
						all,
						variableIndex,
						text
					)
				} else {
					all.push(<TextComponent key={all.length} text={text} />)
				}
			}
			if (index < resultsSize - 1) {
				all.push(
					<Component
						key={`${variable}-${all.length}`}
						variable={variable}
						{...props}
					/>
				)
			}
		})
	}
}
