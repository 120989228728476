// Table uses react-table v7 which uses react-hook and it is a headless react
// library as UI will be developed by caller. This table is meant to be used
// with constants start with CELL_*. Not all cell-components can be renderred
// automatically. Some need to be provided the data through container of the
// caller.
import React, {
  Fragment,
  memo,
  useCallback,
  useMemo,
  useState
} from 'react'
import { branch, withProps } from 'recompose'
import classNames from 'classnames'
import each from 'lodash/each'
import keys from 'lodash/keys'
import map from 'lodash/map'
import styled from 'styled-components'
import {
  // useBlockLayout,
  useExpanded,
  usePagination,
  // useResizeColumns,
  useSortBy,
  useTable
} from 'react-table'
import update from 'immutability-helper'
import { Tooltip as BSTooltip } from 'reactstrap'
import { I, L } from '../common/v5/config'
import { emptyArray, emptyObject } from '../common/constants'
import {
  PF_TIMESTAMP,
  PF_DEFAULT_FULL,
  TXT_AREA_AVAILABLE,
  TXT_ORG_AVAILABILITY
} from '../common/v5/constants'
import {
  TXT_ALL,
  TXT_CONNECTED_ACCOUNTS,
  TXT_CONNECTED_AREAS,
  TXT_CONNECTED_ORGS,
  TXT_CONNECTED_ORGS_AREAS,
  TXT_NO_SELECTION,
  TXT_NUMBER_SELECTED,
  TXT_SERVICE_ACCOUNTS_AVAILABILITY,
  TXT_SERVICE_NO_ACCOUNT
} from '../common/v5/receiptGreetingConstants'
import { TXT_FALSE, TXT_TRUE } from '../common/v5/smtpConstants'
import { findIdNameFromArray } from '../common/v5/helpers'
import { createCapitalize } from '../styles/flexbox'
import {
  centionBlue,
  centionGreen,
  centionGrey,
  centionRed,
  fontSizeGeneral
} from '../styles/_variables'
import { useCallbackWithValue, useFullCompose } from '../hooks/callback'
import { useDenormalize, useDomState } from '../hooks/state'
import { useToggle } from '../hooks/toggle'
import { numberToTime } from './common'
import {
  composeWithDisplayName,
  createWithMountWhenPropTrue,
  omitProps,
  withUnmountWhenHidden
} from './hocs'
import { Actions as AdminActions } from './Admin'
import Anchor from './Anchor'
import { SingleSelectDD } from './Dropdown'
import Moment from './Moment'
import { CircledIcon, TextField } from './Form'
import { PlainSwitchCheckbox } from './SwitchCheckbox'
import { tintGrey } from '../styles/_variables'

const idName = { id: 'Id', name: 'Name' }
const unknownDate = I('Unknown date')
const TXT_START_END_TOTAL = I('Showing {START} - {END} of {TOTAL}') // eg: Showing 1 - 25 of 2021

const pageInfoText = (start, end, total) => TXT_START_END_TOTAL
  .replace('{START}', start).replace('{END}', end).replace('{TOTAL}', total)

export const arrayLengthAccessors = (...accessors) => row => {
  let total = 0
  each(accessors, accessor => {
    const array = row[accessor]
    if (array && array.length) {
      total += array.length
    }
  })
  return total
}

const ABase = ({ isCurrent, index, onClick, ...props }) => (
  <Anchor onClick={useCallbackWithValue(index, onClick)} {...props} />
)

const StyledA = styled(ABase)`
  padding: 4px 8px;
  ${({ isCurrent }) => isCurrent ? `
    background: ${centionBlue};
    border-radius: 7px;
    color: #ffffff;
    &:focus, &:hover {
      color: #ffffff;
    }
    ` : `
    color: ${centionGrey};
    `}
`
const A = withUnmountWhenHidden(StyledA)

const FirstPage = props => <A className='first-page' {...props}>{'<<'}</A>

const PreviousPage = props => <A className='previous' {...props}>{'<'}</A>

const LastPage = props => <A className='last-page' {...props}>{'>>'}</A>

const NextPage = props => <A className='next' {...props}>{'>'}</A>

const unshiftPreviousNearby = (results, index) => {
  if (index >= 0) {
    results.unshift({ index })
    return true
  }
}

const maxNearbyPerSide = 3

const nearbySize = needMore => maxNearbyPerSide - (needMore ? 1 : 0)

const setupPreviousNearby = (results, index, needMore) => {
  const count = nearbySize(needMore)
  let canMore
  for (let i = 0; i < count; i++) {
    index--
    canMore = unshiftPreviousNearby(results, index)
    if (!canMore) {
      break
    }
  }
  if (needMore && canMore && index > 0) {
    if (index > 1) {
      results.unshift({ index: index >> 1, isMore: true })
    }
    results.unshift({ index: 0 })
  }
}

const pushNextNearby = (results, pageCount, index) => {
  if (index < pageCount) {
    results.push({ index })
    return true
  }
}

const setupNextNearby = (results, pageCount, index, needMore) => {
  const count = nearbySize(needMore)
  let canMore
  for (let i = 0; i < count; i++) {
    index++
    canMore = pushNextNearby(results, pageCount, index)
    if (!canMore) {
      break
    }
  }
  if (needMore && canMore && index < pageCount - 1) {
    if (index < pageCount - 2) {
      results.push({ index: ((pageCount - index) >> 1) + index, isMore: true })
    }
    results.push({ index: pageCount - 1 })
  }
}

const pageAndNearby = (pageCount, pageIndex) => {
  const results = [{ index: pageIndex }]
  const nearBack = pageIndex >= (pageCount >> 1)
  setupPreviousNearby(results, pageIndex, nearBack)
  setupNextNearby(results, pageCount, pageIndex, !nearBack)
  return results
}

const Pages = ({ gotoPage, pageCount, pageIndex }) => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    {pageAndNearby(pageCount, pageIndex).map(({ index, isMore }) => {
      let child
      if (isMore) {
        child = '...'
      } else {
        child = index + 1
      }
      return (
        <A
          key={index}
          index={index}
          isCurrent={index === pageIndex}
          onClick={gotoPage}
        >
          {child}
        </A>
      )
    })}
  </Fragment>
)

const countPageEnd = (total, pageSize, pageIndex) => {
  const end = (pageIndex + 1) * pageSize
  if (end >= total) {
    return total
  }
  return end
}

const PageInfo = ({ pageIndex, total, pageSize }) => (
  <div className='info'>
    {pageInfoText(
      pageSize * pageIndex + 1,
      countPageEnd(total, pageSize, pageIndex),
      total
    )}
  </div>
)

const PaginationBase = ({
  className,
  canNextPage,
  canPreviousPage,
  gotoPage,
  nextPage,
  page,
  pageCount,
  pageIndex,
  pageOptions,
  pageSize,
  previousPage,
  total
}) => (
  <div className={classNames('pagination', className)}>
    <PageInfo
      pageIndex={pageIndex}
      pageSize={pageSize}
      total={total}
    />
    <div className='nav'>
      <FirstPage
        hidden={!canPreviousPage || pageIndex - maxNearbyPerSide <= 0}
        index={0}
        onClick={gotoPage}
      />
      <PreviousPage
        hidden={!canPreviousPage || pageIndex - maxNearbyPerSide <= 1}
        onClick={previousPage}
      />
      <Pages
        gotoPage={gotoPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
      />
      <NextPage
        hidden={!canNextPage || pageIndex + maxNearbyPerSide >= pageCount - 2}
        onClick={nextPage}
      />
      <LastPage
        hidden={!canNextPage || pageIndex + maxNearbyPerSide >= pageCount - 1}
        index={pageCount - 1}
        onClick={gotoPage}
      />
    </div>
  </div>
)

const Pagination = styled(PaginationBase)`
  & {
    display: flex;
    padding: 8px 8px 8px 0px;
    justify-content: space-between;
    font-size: ${fontSizeGeneral};
    width: 100%;
    .info {
      padding: 4px 8px;
      color: ${centionGrey};
    }
  }
`
const tooltipDelayAttr = { show: 0, hide: 0 }

const TooltipBase = props => (
  <BSTooltip
    {...props}
    autohide={false}
    className='cell-preview'
    container='body'
    delay={tooltipDelayAttr}
    hideArrow
    placement='bottom-start'
    trigger='hover'
  />
)

const Tooltip = createWithMountWhenPropTrue('target')(TooltipBase)

const Line = ({ text, isTitle }) => {
  let child
  if (isTitle) {
    child = <b>{L(text)}</b>
  } else {
    child = text
  }
  return <span>{child}<br /></span>
}

const PreviewContent = ({ data }) => (
  // eslint-disable-next-line react/jsx-fragments
  <Fragment>
    {data.map((line, i) => <Line key={i} isTitle={i === 0} text={line} />)}
  </Fragment>
)

const valueToArray = value => {
  if (typeof value === 'string') {
    value = value.split(',')
  }
  return value
}

const createLogMessage = (title, name, id) => `${title} ${id} having inconsistent database as it shouldn't appear here.` +
' Please check workflow_areaagentgroupos table for detail.' +
` Make sure this ${name} belong to the right system group.`

const areaLogMessage = id => createLogMessage('Area', 'area', id)

const orgLogMessage = id => createLogMessage('Organisation', 'organisation', id)

const getSelectedCount = (count, isAll) => {
  if (count > 0 && isAll) {
    return TXT_ALL
  }
  return TXT_NUMBER_SELECTED.replace('{NUMBER}', count)
}

const arrayIdsTracker = arrayOfIds => {
  const idMap = {}
  each(arrayOfIds, id => { idMap[id] = true })
  return {
    remove: id => { delete idMap[id] },
    empty: () => keys(idMap).length === 0
  }
}

const normalizedMapTracker = map => {
  const trackerMap = {}
  each(map, ({ allIds }, serviceType) => {
    if (serviceType !== 'service') {
      trackerMap[serviceType] = arrayIdsTracker(allIds)
    }
  })
  return {
    remove: (serviceType, id) => {
      if (trackerMap[serviceType]) {
        trackerMap[serviceType].remove(id)
      }
    },
    empty: () => {
      let empty = true
      each(trackerMap, tracker => {
        if (!tracker.empty()) {
          empty = false
          return false
        }
      })
      return empty
    }
  }
}

const checkAccountAreaAndConnectedArea = ' Please check account area and user connected area.'

const serviceTypeNoAccountLogMessage = serviceType => `Service type ${serviceType} has no accessible account.` + checkAccountAreaAndConnectedArea

const serviceTypeAccountLogMessage = (serviceType, accountId) => `Service type ${serviceType} has no account ${accountId}.` + checkAccountAreaAndConnectedArea

const getServiceName = (services, serviceType) => {
  if (!services ||
    !services.byId ||
    !services.byId[serviceType] ||
    !services.byId[serviceType].name) {
    return serviceType
  }
  return services.byId[serviceType].name
}

const useAccountsCellPreviewData = (title, { data, value }) => useMemo(
  () => {
    const _data = data || emptyObject
    const tracker = normalizedMapTracker(_data)
    const result = [title]
    each(valueToArray(value), (accounts, serviceType) => {
      each(accounts, id => {
        const normalizedAccounts = _data[serviceType]
        if (!normalizedAccounts) {
          result.push(TXT_SERVICE_NO_ACCOUNT
            .replace('{CHANNEL_TYPE}', serviceType))
          if (process.env.NODE_ENV !== 'production') {
            console.log(serviceTypeNoAccountLogMessage(serviceType))
          }
        } else {
          const { byId } = normalizedAccounts
          const accountData = byId[id]
          const serviceName = getServiceName(_data.service, serviceType)
          if (!accountData) {
            result.push(TXT_SERVICE_ACCOUNTS_AVAILABILITY
              .replace('{CHANNEL_TYPE}', serviceName)
              .replace('{ACCOUNT_ID}', id))
            if (process.env.NODE_ENV !== 'production') {
              console.log(serviceTypeAccountLogMessage(serviceType, id))
            }
          } else {
            tracker.remove(serviceType, id)
            result.push(serviceName + ' - ' + accountData.name)
          }
        }
      })
    })
    return {
      data: result,
      value: getSelectedCount(result.length - 1, tracker.empty())
    }
  },
  [data, title, value]
)

const invalidAreaInfo = areaId => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(areaLogMessage(areaId))
  }
  return TXT_AREA_AVAILABLE.replace('{AREA_ID}', areaId)
}

const areaInfo = (org, area) => org.name + ' - ' + area.name

const addAreaInfoToData = (data, areaId, byArea, byOrg, tracker) => {
  const area = byArea[areaId]
  if (!area) {
    data.push(invalidAreaInfo(areaId))
  } else {
    tracker.remove(areaId)
    data.push(areaInfo(byOrg[area.orgId], area))
  }
}

const useAreasCellPreviewData = (
  title,
  { data: { area: { byId, allIds }, org: { byId: byOrg } }, value }
) => useMemo(
  () => {
    const _byId = byId || emptyObject
    const _byOrg = byOrg || emptyObject
    const _allIds = allIds || emptyArray
    const tracker = arrayIdsTracker(_allIds)
    const data = [title]
    each(valueToArray(value), areaId => {
      addAreaInfoToData(data, areaId, _byId, _byOrg, tracker)
    })
    return { data, value: getSelectedCount(data.length - 1, tracker.empty()) }
  },
  [byId, byOrg, allIds, title, value]
)

const invalidOrgInfo = orgId => {
  if (process.env.NODE_ENV !== 'production') {
    console.log(orgLogMessage(orgId))
  }
  return TXT_ORG_AVAILABILITY.replace('{ORG_ID}', orgId)
}

const useOrgsCellPreviewData = (
  title,
  { data: { org: { byId, allIds } }, value }
) => useMemo(
  () => {
    const _byId = byId || emptyObject
    const _allIds = allIds || emptyArray
    const tracker = arrayIdsTracker(_allIds)
    const data = [title]
    each(valueToArray(value), orgId => {
      const org = _byId[orgId]
      if (!org) {
        data.push(invalidOrgInfo(orgId))
      } else {
        tracker.remove(orgId)
        data.push(org.name)
      }
    })
    return { data, value: getSelectedCount(data.length - 1, tracker.empty()) }
  },
  [allIds, byId, title, value]
)

const useOrgsAreas = (
  title,
  {
    areas,
    data: {
      area: { allIds: areaIds, byId: byArea },
      org: { allIds: orgIds, byId: byOrg }
    },
    orgs
  }
) => useMemo(
  () => {
    const _byArea = byArea || emptyObject
    const _byOrg = byOrg || emptyObject
    const trackAreas = arrayIdsTracker(areaIds || emptyArray)
    const trackOrgs = arrayIdsTracker(orgIds || emptyArray)
    const invalidData = []
    const areaDataByOrgId = {}
    each(valueToArray(areas), areaId => {
      const area = _byArea[areaId]
      if (!area) {
        invalidData.push(invalidAreaInfo(areaId))
      } else {
        const { orgId } = area
        let data = areaDataByOrgId[orgId]
        if (!data) {
          data = []
          areaDataByOrgId[orgId] = data
        }
        trackAreas.remove(areaId)
        data.push(areaInfo(_byOrg[orgId], area))
      }
    })
    const data = [title]
    each(valueToArray(orgs), orgId => {
      const org = _byOrg[orgId]
      if (!org) {
        data.push(invalidOrgInfo(orgId))
      } else {
        trackOrgs.remove(orgId)
        data.push(org.name)
      }
      const areas = areaDataByOrgId[orgId]
      if (areas && areas.length) {
        data.push(...areas)
      }
      delete areaDataByOrgId[orgId]
    })
    each(areaDataByOrgId, (areas, orgId) => {
      if (areas && areas.length) {
        data.push(...areas)
      }
    })
    if (invalidData.length) {
      data.push(...invalidData)
    }
    return {
      data,
      value: getSelectedCount(
        data.length - 1,
        trackAreas.empty() && trackOrgs.empty()
      )
    }
  },
  [areaIds, orgIds, areas, byArea, byOrg, orgs, title]
)

const CellPreview = ({ data, 'data-qa-id': dataQAId, value }) => {
  const [node, callbackRef] = useDomState()
  const [show, onToggle] = useToggle()
  return (
    <span>
      <div ref={callbackRef} data-qa-id={dataQAId}>
        <div>{value}</div>
      </div>
      <Tooltip isOpen={show} target={node} toggle={onToggle}>
        <div className='cell-preview-body'>
          <PreviewContent data={data} />
        </div>
      </Tooltip>
    </span>
  )
}

const CellAccountsPreview = ({ 'data-qa-id': dataQAId, ...props }) => {
  if(typeof props.data === 'undefined') {
    return null
  }
  const { data, value } = useAccountsCellPreviewData(
    TXT_CONNECTED_ACCOUNTS,
    props
  )
  return <CellPreview data={data} data-qa-id={dataQAId} value={value} />
}

const CellAreasPreview = ({ 'data-qa-id': dataQAId, ...props }) => {
  if(typeof props.data === 'undefined') {
    return null
  }
  const { data, value } = useAreasCellPreviewData(
    TXT_CONNECTED_AREAS,
    props
  )
  return <CellPreview data={data} data-qa-id={dataQAId} value={value} />
}

const CellOrgsPreview = ({ 'data-qa-id': dataQAId, ...props }) => {
  if(typeof props.data === 'undefined') {
    return null
  }
  const { data, value } = useOrgsCellPreviewData(
    TXT_CONNECTED_ORGS,
    props
  )
  return <CellPreview data={data} data-qa-id={dataQAId} value={value} />
}

const CellOrgsAreas = ({ 'data-qa-id': dataQAId, ...props }) => {
  if(typeof props.data === 'undefined') {
    return null
  }
  const { data, value } = useOrgsAreas(TXT_CONNECTED_ORGS_AREAS, props)
  return <CellPreview data={data} data-qa-id={dataQAId} value={value} />
}

const Actions = ({
  activeRow,
  cell,
  disableActionsCondition,
  disableActionsWhenRowActive,
  onClick,
  propsGetter,
  row,
  value,
  ...props
}) => {
  const disabledMap = useMemo(() => {
    if (!disableActionsWhenRowActive || !activeRow) {
      return emptyObject
    }
    const disabled = {}
    each(disableActionsWhenRowActive, button => { disabled[button] = true })
    return disabled
  }, [activeRow, disableActionsWhenRowActive])
  const disabledActions = useMemo(() => {
    if (typeof disableActionsCondition !== 'function') {
      return disabledMap
    }
    return button => {
      if (disabledMap[button]) {
        return true
      }
      return disableActionsCondition(button, row, cell)
    }
  }, [cell, disableActionsCondition, disabledMap, row])
  return (
    <AdminActions
      actions={value}
      disabledActions={disabledActions}
      onClick={useFullCompose(
        useCallbackWithValue,
        onClick,
        [1],
        value,
        cell,
        row
      )}
      propsGetter={useMemo(
        () => {
          if (typeof propsGetter === 'function') {
            return action => propsGetter(action, cell)
          }
        },
        [cell, propsGetter]
      )}
      {...props}
    />
  )
}

const SearchIdNameBase = ({ data, nested, nestedParent, value, emptyValue }) => {
  const result = findIdNameFromArray(data, value, idName, nested)
  if (!result) {
    if (value == 0 && typeof emptyValue !== 'undefined') {
      return <span>{emptyValue}</span>
    }
    return <span>{value}</span>
  }
  const [inner, parent] = result
  if (!nestedParent) {
    return <span>{inner.name}</span>
  }
  return <span>{parent.name}</span>
}

const AreaOrOrganization = props => <SearchIdNameBase {...props} nested='Areas' />

const Area = ({ nestedParent, ...props }) => <AreaOrOrganization {...props} />

const Organization = props => <AreaOrOrganization {...props} nestedParent />

const Agent = ({ nested, ...props }) => <SearchIdNameBase {...props} />

const Folder = props => <SearchIdNameBase {...props} />

const MultiClassNamesInput = ({ className, ...props }) => (
  <TextField
    mainClassName={className}
    className='input-block'
    {...props}
  />
)

const StyledTextField = styled(MultiClassNamesInput)`
  float: none;
  .label-input-block {
    label {
      color: #6d6d6d;
      font-size: 12px;
      font-weight: 500;
      margin-right: 10px;
      width: 100px;
    }
  }
  .input-block {
    width: 100%;
    input {
      border: 1px solid ${tintGrey};
      font-size: 12px;
      height: 30px;
      padding: 0 10px;
    }
  }
`

const useCallbackTargetValue = callback => useCallback(
  e => callback(e.target.value),
  [callback]
)

export const Input = ({ onChange, ...props }) => (
  <StyledTextField onChange={useCallbackTargetValue(onChange)} {...props} />
)

const withIndex = Component => ({ index, onChange, ...props }) => (
  <Component
    onChange={useCallbackWithValue(index, onChange)}
    {...props}
  />
)

const InputText = composeWithDisplayName(
  'InputText',
  memo,
  withIndex
)(Input)

const StyledTextarea = styled.textarea`
  width: 100%;
`
const TextareaBase = ({ onChange, ...props }) => (
  <StyledTextarea onChange={useCallbackTargetValue(onChange)} {...props} />
)

const Textarea = composeWithDisplayName(
  'Textarea',
  memo,
  withIndex
)(TextareaBase)

const Duration = ({ value }) => <span>{numberToTime(value)}</span>

const SinceNowDuration = ({ value }) => (
  <Duration value={Math.floor(Date.now() / 1000) - value} />
)

export const CELL_ACCOUNTS = 'accounts'
export const CELL_ACTION = 'action'
export const CELL_AGENT = 'agent'
export const CELL_ANY = 'any'
export const CELL_AREA = 'area'
export const CELL_AREAS = 'areas'
export const CELL_BOOL = 'bool'
export const CELL_CELLS = 'cells'
export const CELL_CUSTOM = 'custom'
export const CELL_DATE_TIME = 'date time'
export const CELL_DURATION = 'duration'
export const CELL_FOLDER = 'folder'
export const CELL_INPUT_TEXT = 'input text'
export const CELL_ORGANIZATION = 'organization'
export const CELL_ORGANIZATIONS = 'organizations'
export const CELL_ORGS_AREAS = 'orgs areas'
export const CELL_SINCE_NOW = 'since now'
export const CELL_SWITCH = 'switch'
export const CELL_TEXTAREA = 'textarea'

const cellNeededPropNames = {
  [CELL_ACCOUNTS]: 'servicesAndAccounts',
  [CELL_ACTION]: 'onClickAction',
  [CELL_AGENT]: 'agents',
  [CELL_ANY]: 'anyProps',
  [CELL_AREA]: 'areas',
  [CELL_AREAS]: 'orgArea',
  [CELL_CUSTOM]: 'customProps',
  [CELL_DATE_TIME]: 'timezoneOffset',
  [CELL_FOLDER]: 'folders',
  [CELL_INPUT_TEXT]: 'onInputChange',
  [CELL_ORGANIZATION]: 'areas',
  [CELL_ORGANIZATIONS]: 'orgArea',
  [CELL_ORGS_AREAS]: 'orgArea',
  [CELL_SWITCH]: 'onSwitchChange',
  [CELL_TEXTAREA]: 'onTextareaChange'
}

const getCellProp = (cellType, props) => props[cellNeededPropNames[cellType]]

const cellAnyRenderer = (
  { typeAccessor, ...column },
  value,
  props,
  row,
  ...args
) => cellRenderer(
  { type: typeAccessor(row), ...column },
  value,
  props,
  row,
  ...args
)

const CellsWrapper = styled.div`
  div& {
    & > * {
      display: inline-block;
    }
    > * + * {
      margin-left: 6px;
    }
  }
`
const renderCells = ({ cells }, values, ...args) => (
  <CellsWrapper>
    {map(cells, (cell, i) => (
      <Fragment key={i}>{cellRenderer(cell, values[i], ...args)}</Fragment>
    ))}
  </CellsWrapper>
)

const cellCustomRenderer = (
  { Renderer, ...column },
  value,
  props,
  row,
  cell
) => (
  <Renderer
    cell={cell}
    column={column}
    row={row}
    value={value}
    {...props}
  />
)

export const createCells = (Header, ...cells) => {
  if (cells.length === 1) {
    if (!Header || cells[0].Header === Header) {
      return cells[0]
    }
    return update(cells[0], { Header: { $set: Header } })
  }
  let result = update(cells[0], { cells: { $set: cells } })
  each(cells.slice(1), cell => { result = update(cell, { $merge: result }) })
  if (Header && result.Header !== Header) {
    result.Header = Header
  }
  result.accessor = data => map(cells, ({ accessor }) => {
    if (typeof accessor === 'string') {
      return data[accessor]
    } else if (typeof accessor === 'function') {
      return accessor(data)
    }
  })
  result.type = CELL_CELLS
  return result
}

export const combineCells = (...cells) => createCells(false, ...cells)

const createValueGetter = field => (column, row, value) => {
  const accessor = column[field]
  if (typeof accessor === 'function') {
    return accessor(row)
  }
  return value
}

const getAccountsValue = createValueGetter('accountsAccessor')

const getAreasValue = createValueGetter('areasAccessor')

const getOrgsValue = createValueGetter('orgsAccessor')

const getCallbackValue = ({ callbackValueGetter }, cell) => {
  if (typeof callbackValueGetter === 'function') {
    return callbackValueGetter(cell)
  }
  return cell.row.index
}

const getDisabled = (disableWhenRowActive, activeRow) => {
  if (disableWhenRowActive) {
    return activeRow
  }
  return false
}

const ActiveSwitch = ({ active, cell, column, disabled, onClick }) => (
  <PlainSwitchCheckbox
    active={active}
    disabled={disabled}
    id='table-switch-checkbox'
    name='table-switch-checkbox'
    onClick={useCallbackWithValue(getCallbackValue(column, cell), onClick)}
  />
)

const BoolIcon = props => <CircledIcon active {...props} />

const IconBoolTrue = styled(BoolIcon)`
  cursor: auto;
  &::before {
    background-color: ${centionGreen}4f;
  }
`
const IconBoolFalse = styled(BoolIcon)`
  cursor: auto;
  &::before {
    background-color: ${centionRed}4f;
  }
`
// NOTE: CELL_ACTION props, disableActionsWhenRowActive take higher precedence
// than disableActionsCondition.
export const cellRenderer = ({ type, ...column }, value, props, row, cell) => {
  switch (type) {
    case CELL_ACCOUNTS:
      return (
        <CellAccountsPreview
          data={getCellProp(type, props)}
          value={getAccountsValue(column, row, value)}
        />
      )
    case CELL_ACTION: {
      const {
        actions,
        disableActionsCondition,
        disableActionsWhenRowActive,
        propsGetter
      } = column
      // provide the click-able action icons. Callback will be passed action-id
      // as first arg and resolved value for the cell as second arg. What action
      // icons needed determined by column.actions.
      return (
        <Actions
          actions={actions}
          activeRow={props.activeRow}
          cell={cell}
          disableActionsCondition={disableActionsCondition}
          disableActionsWhenRowActive={disableActionsWhenRowActive}
          onClick={getCellProp(type, props)}
          propsGetter={propsGetter}
          row={row}
          value={value}
        />
      )
    }
    case CELL_AGENT:
      return (
        <Agent
          data={getCellProp(type, props)}
          emptyValue={I('Unassigned')}
          value={value}
        />
      )
    case CELL_ANY:
      return cellAnyRenderer(column, value, props, row, cell)
    case CELL_AREA:
      return <Area data={getCellProp(type, props)} value={value} />
    case CELL_AREAS:
      return (
        <CellAreasPreview
          data={getCellProp(type, props)}
          value={getAreasValue(column, row, value)}
        />
      )
    case CELL_BOOL:
      if (column.isIcon) {
        if (value) {
          return <IconBoolTrue className='icon-double-tick' title={TXT_TRUE} />
        }
        return <IconBoolFalse className='icon-times' title={TXT_FALSE} />
      }
      return <span>{value ? TXT_TRUE : TXT_FALSE}</span>
    case CELL_CELLS:
      return renderCells(column, value, props, row, cell)
    case CELL_CUSTOM:
      return cellCustomRenderer(column, value, props, row, cell)
    case CELL_DATE_TIME:
      if (!value) {
        return <span>{unknownDate}</span>
      }
      return (
        <Moment
          date={value}
          format={PF_DEFAULT_FULL}
          parseFormat={PF_TIMESTAMP}
          timezoneOffset={getCellProp(type, props)}
        />
      )
    case CELL_DURATION:
      return <Duration value={value} />
    case CELL_FOLDER:
      return <Folder data={getCellProp(type, props)} value={value} />
    case CELL_INPUT_TEXT:
      return (
        <InputText
          index={cell.row.index}
          onChange={getCellProp(type, props)}
          value={value}
        />
      )
    case CELL_ORGANIZATION:
      return <Organization data={getCellProp(type, props)} value={value} />
    case CELL_ORGANIZATIONS:
      return (
        <CellOrgsPreview
          data={getCellProp(type, props)}
          value={getOrgsValue(column, row, value)}
        />
      )
    case CELL_ORGS_AREAS:
      return (
        <CellOrgsAreas
          areas={getAreasValue(column, row, emptyArray)}
          data={getCellProp(type, props)}
          orgs={getOrgsValue(column, row, emptyArray)}
        />
      )
    case CELL_SINCE_NOW:
      return <SinceNowDuration value={value} />
    case CELL_SWITCH:
      return (
        <ActiveSwitch
          active={value}
          cell={cell}
          column={column}
          disabled={getDisabled(column.disableWhenRowActive, props.activeRow)}
          onClick={getCellProp(type, props)}
        />
      )
    case CELL_TEXTAREA:
      return (
        <Textarea
          index={cell.row.index}
          onChange={getCellProp(type, props)}
          value={value}
        />
      )
  }
}

const Styles = styled.div`
  padding: ${({ wrapperPadding }) => wrapperPadding || '1rem'};
  table {
    border: none;
    border-spacing: 0;
    font-size: 12px;
    thead {
      border-bottom: 1px solid ${tintGrey};
      th {
        border: none;
        color: rgb(111, 111, 111);
        font-size: 15px;
        padding-top: 30px;
        text-align: left;
        ${createCapitalize('capitalizedHeader')}
      }
    }
    tbody {
      tr:hover {
        background-color: rgba(0,0,0,.075);
      }
    }
    tr {
      border-bottom: 1px solid ${tintGrey};
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th, td {
      margin: 0;
      padding: 0.75rem;
      :last-child {
        border-right: 0;
      }
    }
  }
`
const THead = withUnmountWhenHidden('thead')
const TrBase = omitProps(['active'])('tr')

const StyledTr = styled(TrBase)`
  ${({ onClick }) => {
    if (typeof onClick === 'function') {
      return 'cursor: pointer'
    }
    return ''
  }}
  ${({ active }) => {
    if (active) {
      return `
        background-color: #7ac0ff4f;
        border-radius: 5px;
        box-shadow: 0 3px 10px 3px #7ac0ff82;
        transition: all .3s;`
    }
    return ''
  }}
`
const withRowClick = Component => ({ cell, onClick, ...props }) => (
  <Component onClick={useCallbackWithValue(cell, onClick)} {...props} />
)

export const Tr = composeWithDisplayName(
  'Tr',
  memo,
  branch(
    ({ onClick }) => typeof onClick === 'function',
    withRowClick,
    omitProps(['cell'])
  )
)(StyledTr)

const Cell = ({ cell, column, row, value, ...props }) => {
  const result = cellRenderer(column, value, props, row, cell)
  if (result) {
    return result
  }
  return cell.render('Cell')
}

const withPagination = Component => ({
  enablePagination,
  wrapperClassName,
  paginationProps,
  ...props
}) => (
  <div className={wrapperClassName}>
    <Component {...props} />
    <Pagination {...paginationProps} />
  </div>
)

const TableWithPaginationOption = branch(
  ({ enablePagination }) => enablePagination,
  withPagination,
  omitProps(['enablePagination', 'wrapperClassName', 'paginationProps'])
)('table')

const useValidTableProps = props => {
  const {
    agents,
    areas,
    folders,
    onClickAction,
    onInputChange,
    onTextareaChange,
    onSwitchChange,
    orgArea,
    servicesAndAccounts,
    timezoneOffset
  } = props
  return useMemo(
    () => ({
      agents,
      areas,
      folders,
      onClickAction,
      onInputChange,
      onTextareaChange,
      onSwitchChange,
      orgArea,
      servicesAndAccounts,
      timezoneOffset
    }),
    [
      agents,
      areas,
      folders,
      onClickAction,
      onInputChange,
      onTextareaChange,
      onSwitchChange,
      orgArea,
      servicesAndAccounts,
      timezoneOffset
    ]
  )
}

const getInitialState = (pageSize, expanded, sortBy, hiddenColumns) => {
  const state = { pageSize }
  if (expanded) {
    state.expanded = expanded
  }
  if (sortBy) {
    state.sortBy = sortBy
  }
  if (hiddenColumns && hiddenColumns.length) {
    state.hiddenColumns = hiddenColumns
  }
  return state
}

const StyledTh = styled.th`
  position: relative;
`
const renderNothing = () => null

// withExpandedRow creates row with SubComponent inside it.
export const withExpandedRow = Component => ({
  RowComponent,
  className,
  colSpan,
  role,
  ...props
}) => (
  <RowComponent className={className} role={role}>
    <td colSpan={colSpan}><Component {...props} /></td>
  </RowComponent>
)

const ExpandedSubComponentBase = ({ children }) => children

const ExpandedSubComponent = withUnmountWhenHidden(ExpandedSubComponentBase)

// const Resizer = styled.div`
//   height: calc(100% - 30px);
//   width: 4px;
//   display: inline-block;
//   touch-action: none;
//   position: absolute;
//   transform: translateX(50%);
//   bottom: 0px;
//   right: 0px;
//   z-index: 1;
//   &:hover {
//     background: ${centionGrey};
//   }
// `
function TableBase ({
  SubComponent = renderNothing,
  subComponentProps = emptyObject,
  ...mainProps
}) {
  const {
    TrComponent,
    activeChecker,
    className,
    columns,
    data,
    expanded,
    hiddenColumns,
    noHeader,
    onRowClick,
    onSortChange,
    pageSize: _pageSize,
    rowProps,
    sortBy = emptyArray,
    wrapperClassName,
    ...props
  } = mainProps
  // Use the state and functions returned from useTable to build your UI
  const validProps = useValidTableProps(props)
  const validRowProps = useMemo(() => rowProps || emptyObject, [rowProps])
  const Row = useMemo(() => TrComponent || Tr, [TrComponent])
  const stateReducer = useCallback((newState, action, prevState) => {
    if (action.type !== 'toggleSortBy' || typeof onSortChange !== 'function') {
      return newState
    }
    onSortChange(newState.sortBy)
    return newState
  }, [onSortChange])
  const {
    canNextPage,
    canPreviousPage,
    getTableProps,
    getTableBodyProps,
    gotoPage,
    headerGroups,
    nextPage,
    page,
    pageCount,
    prepareRow,
    previousPage,
    rows: _rows,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      autoResetPage: false,
      columns,
      data,
      initialState: getInitialState(_pageSize, expanded, sortBy, hiddenColumns),
      stateReducer,
      useControlledState: _state => useMemo(
        () => {
          let state = _state
          if (expanded) {
            state = update(state, { expanded: { $set: expanded } })
          }
          if (state.sortBy !== sortBy) {
            state = update(state, { sortBy: { $set: sortBy } })
          }
          if (state.hiddenColumns !== hiddenColumns &&
            hiddenColumns && hiddenColumns.length) {
            state = update(state, { hiddenColumns: { $set: hiddenColumns } })
          }
          return state
        },
        // react-table ensures useControlledState run on every re-render.
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [expanded, hiddenColumns, _state, sortBy]
      )
    },
    // useBlockLayout, // NOTE: resize need this
    // useResizeColumns, // NOTE: resize need this
    useSortBy,
    useExpanded,
    usePagination
  )
  const total = _rows.length
  const enablePagination = !!pageSize
  const paginationProps = useMemo(
    () => {
      if (!enablePagination) {
        return emptyObject
      }
      return {
        canNextPage,
        canPreviousPage,
        gotoPage,
        nextPage,
        pageCount,
        pageIndex,
        pageSize,
        previousPage,
        total
      }
    },
    [
      canNextPage,
      canPreviousPage,
      enablePagination,
      gotoPage,
      nextPage,
      pageCount,
      pageIndex,
      pageSize,
      previousPage,
      total
    ]
  )
  const rows = enablePagination ? page : _rows
  // Render the UI for your table
  return (
    <TableWithPaginationOption
      className={classNames('table', className)}
      enablePagination={!!pageSize}
      paginationProps={paginationProps}
      wrapperClassName={wrapperClassName}
      {...getTableProps()}
    >
      <THead hidden={!!noHeader}>
        {headerGroups.map(headerGroup => (
          // eslint-disable-next-line react/jsx-key
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              // eslint-disable-next-line react/jsx-key
              <StyledTh {...column.getHeaderProps(column.getSortByToggleProps())}>
                <span>
                  {column.render('Header')}
                  {/* Add a sort direction indicator */}
                  {column.isSorted
                    ? column.isSortedDesc
                      ? ' 🔽'
                      : ' 🔼'
                    : ''}
                </span>
                {/* NOTE: resize need this */}
                {/* <Resizer {...column.getResizerProps()} /> */}
              </StyledTh>
            ))}
          </tr>
        ))}
      </THead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row)
          const active = !!activeChecker(row, i)
          const { key, role } = row.getRowProps()
          return (
            <Fragment key={key}>
              <Row
                active={active}
                onClick={onRowClick}
                role={role}
                row={row}
                {...validRowProps}
              >
                {row.cells.map(({ column: { type }, getCellProps, render }) => (
                  // eslint-disable-next-line react/jsx-key
                  <td {...getCellProps()}>
                    {render(
                      Cell,
                      update(
                        validProps,
                        { $merge: { activeRow: active } }
                      )
                    )}
                  </td>
                ))}
              </Row>
              <ExpandedSubComponent hidden={!row.isExpanded}>
                <SubComponent
                  RowComponent={Row}
                  role={role}
                  row={row}
                  index={i}
                  parentProps={mainProps}
                  {...subComponentProps}
                />
              </ExpandedSubComponent>
            </Fragment>
          )
        })}
      </tbody>
    </TableWithPaginationOption>
  )
}

const withStyles = Component => ({ wrapperPadding, ...props }) => (
  <Styles wrapperPadding={wrapperPadding}><Component {...props} /></Styles>
)

const TableComponent = composeWithDisplayName(
  'TableComponent',
  memo,
  withStyles,
  withProps(({ activeChecker }) => {
    if (typeof activeChecker !== 'function') {
      return { activeChecker: () => false }
    }
  })
)(TableBase)

const Table = styled(TableComponent)``

export default Table

export const NormalizedTable = ({ byId, list, dataGetter, ...props }) => (
  <Table data={useDenormalize(byId, list, dataGetter)} {...props} />
)

// useExternalSort return 'sortBy' state, 'onChange' to be used by external
// sorting component and 'onSortChange' to be used by Table.
export const useExternalSort = initialState => {
  const [sortBy, setSortBy] = useState(initialState)
  const onChange = useCallback(id => {
    if (!id) {
      setSortBy(emptyArray)
      return
    }
    const [first] = sortBy
    if (first && first.id === id) {
      setSortBy([{ id, desc: !first.desc }])
      return
    }
    setSortBy([{ id }])
  }, [sortBy])
  return { sortBy, onChange, onSortChange: setSortBy }
}

const sortId = ({ accessor, id }) => {
  if (id) {
    return id
  }
  return accessor
}

const sortIdFields = {
  id: sortId,
  name: column => {
    const { sortName, Header } = column
    if (sortName) {
      return sortName
    } else if (Header) {
      return Header
    }
    return sortId(column)
  }
}

const StyledI = styled.i`
  color: ${({ active }) => active ? centionBlue : centionGrey};
`
// SortIconText display sort icon and text. Prop 'desc' is tri-state:
//   1) undefined - no sorting
//   2) false - sorting with ascending
//   3) true - sorting with descending
const SortIconText = ({ desc, text }) => {
  let upActive
  let downActive
  if (typeof desc === 'boolean') {
    downActive = desc
    upActive = !desc
  }
  return (
    <span>
      <span>{text}</span>
      <span className='fa-stack'>
        <StyledI active={upActive} className='fas fa-sort-up fa-stack-1x' />
        <StyledI active={downActive} className='fas fa-sort-down fa-stack-1x' />
      </span>
    </span>
  )
}

const getUnselectSort = textNoItemSelected => {
  if (!textNoItemSelected) {
    textNoItemSelected = TXT_NO_SELECTION
  }
  return { selected: '', name: textNoItemSelected }
}

const StyledSingleSelect = styled(SingleSelectDD)`
  display: inline-block;
`
// Sort can take in Table's columns prop and construct the sort dropdown.
export const Sort = ({ columns, onChange, sortBy, textNoItemSelected }) => {
  const { desc, selected, name } = useMemo(() => {
    if (!sortBy || !sortBy.length) {
      return getUnselectSort(textNoItemSelected)
    }
    const [{ id, desc }] = sortBy
    let name
    let found
    each(columns, (column, index) => {
      if (id === sortIdFields.id(column)) {
        name = sortIdFields.name(column)
        found = { id }
        return false
      }
    })
    if (!found) {
      return getUnselectSort(textNoItemSelected)
    }
    return { desc: !!desc, selected: found.id, name }
  }, [columns, sortBy, textNoItemSelected])
  const [show, onToggle] = useToggle()
  return (
    <StyledSingleSelect
      data={columns}
      idFields={sortIdFields}
      onReset={useCallbackWithValue('', onChange)}
      onSelect={onChange}
      onToggle={onToggle}
      selected={selected}
      show={show}
    >
      <SortIconText desc={desc} text={name} />
    </StyledSingleSelect>
  )
}

export const customPageTotal = (from, to, size) => {
  const rowsTotalTxt = I('Showing {START} - {END} of {TOTAL}')
    .replace("{START}", from)
    .replace("{END}", to)
    .replace("{TOTAL}", size);
  return (
    <span className="react-bootstrap-table-pagination-total">
      {size === 0 ? I("No data") : rowsTotalTxt}
    </span>
  )
};


export const TABLE_STANDARD_OPTIONS = {
	paginationSize: 3,
	pageStartIndex: 1,
	hideSizePerPage: true, // Hide the sizePerPage dropdown always
	hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
	firstPageText: '<<',
	prePageText: '<',
	nextPageText: '>',
	lastPageText: '>>',
	showTotal: true,
	paginationTotalRenderer: customPageTotal,
	disablePageTitle: true,
	sizePerPageList: [10]
};