import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
  useRef
} from 'react'
import update from 'immutability-helper'
import each from 'lodash/each'
import { useParams } from 'react-router'
import { emptyArray, identity } from '../common/constants'

// useCheckUnmount can be used to avoid unmount setState error caused by async
// callback that do the setState.
// https://www.robinwieruch.de/react-warning-cant-call-setstate-on-an-unmounted-component
export const useCheckUnmount = () => {
  const r = useRef(false)
  useEffect(() => {
    return () => {
      r.current = true
    }
  }, [])
  return r
}

export const useCustomState = initState => {
  const isUnmounted = useCheckUnmount()
  const [state, setState] = useState(initState)
  const preventableSetState = useCallback(v => {
    if (!isUnmounted.current) {
      setState(v)
    }
  }, [isUnmounted])
  return [state, preventableSetState]
}

export const useCustomReducer = (...args) => {
  const isUnmounted = useCheckUnmount()
  const [state, dispatch] = useReducer(...args)
  const preventableDispatch = useCallback(v => {
    if (!isUnmounted.current) {
      dispatch(v)
    }
  }, [isUnmounted])
  return [state, preventableDispatch]
}

export const createReducer = (init, handlers) => (st = init, act) => {
  if (Object.prototype.hasOwnProperty.call(handlers, act.type)) {
    return handlers[act.type](st, act)
  } else {
    return st
  }
}

export const createStateAccessor = (...args) => state => {
  if (!args || !args.length) {
    return state
  }
  for (let i = 0; i < args.length; i++) {
    state = state[args[i]]
  }
  return state
}

export const createStateUpdaterWithAccessor = (
  accessor,
  ...args
) => (state, updater) => {
  if (!args || !args.length) {
    return update(accessor(state), updater)
  }
  updater = { $set: update(accessor(state), updater) }
  for (let i = args.length - 1; i > -1; i--) {
    updater = { [args[i]]: updater }
  }
  return update(state, updater)
}

export const createStateUpdater = (...args) => (
  state,
  updater
) => createStateUpdaterWithAccessor(createStateAccessor(...args), ...args)

// useNoFalsyArray make sure 'data' at least emptyArray to avoid any js error.
export const useNoFalsyArray = data => useMemo(() => {
  if (!data || !data.length) {
    return emptyArray
  }
  return data
}, [data])

// useDomState return the dom and callback to be used on DOM's ref prop. This
// allows changes to ref to trigger re-render.
export const useDomState = () => {
  const [node, setNode] = useState(null)
  const callbackRef = useCallback(node => { setNode(node) }, [])
  return [node, callbackRef]
}

// useDenormalize convert normalized data (byId, allIds) into array. Function
// 'denormalizer' is optional and if not exist identity function will be used.
export const useDenormalize = (byId, list, denormalizer) => useMemo(() => {
  const result = []
  let _denormalizer
  if (typeof denormalizer === 'function') {
    _denormalizer = denormalizer
  } else {
    _denormalizer = identity
  }
  each(list, (v, i) => { result.push(_denormalizer(byId[v], v, i)) })
  return result
}, [byId, list, denormalizer])

// useFitCount return a counter that is adjusted base on clientWidth and
// scrollWidth, so it creates the condition max(scrollWidth <= clientWidth). For
// this to work, the count return must able to affect clientWidth and
// scrollWidth. combinerWidth is the width of the component that keep those
// unfit count if its width can affect the available width.
export const useFitCount = (
  combinerWidth,
  availableWidth,
  clientWidth,
  scrollWidth,
  itemSize,
  buffer,
  debug
) => {
  const [total, setTotal] = useState(itemSize)
  const previousItemSizeRef = useRef(itemSize)
  const { current: previousItemSize } = previousItemSizeRef
  useEffect(
    () => {
      if (debug) {
        if (process.env.NODE_ENV !== 'production') {
          console.log(
            'dbg: use-fit-count widths:',
            clientWidth,
            scrollWidth,
            availableWidth
          )
        }
      }
      if (scrollWidth > clientWidth ||
        (clientWidth + buffer) < availableWidth) {
        setTotal(total => {
          const dividen = total > 0 ? total : itemSize
          if (dividen) {
            const width = availableWidth > combinerWidth
              ? availableWidth - combinerWidth : availableWidth
            const scrollWidthWithoutCombiner = scrollWidth - (previousItemSize > total && scrollWidth > combinerWidth ? combinerWidth : 0)
            if (scrollWidthWithoutCombiner <= 0) {
              return itemSize
            }
            let newTotal = Math.floor(width / (scrollWidthWithoutCombiner / dividen))
            if (newTotal > itemSize) {
              newTotal = itemSize
            } else if (!newTotal) {
              newTotal = 1
            }
            return newTotal
          } else {
            return total
          }
        })
      }
    },
    [
      availableWidth,
      buffer,
      clientWidth,
      combinerWidth,
      debug,
      itemSize,
      previousItemSize,
      scrollWidth
    ]
  )
  useEffect(
    () => { previousItemSizeRef.current = itemSize },
    [itemSize, previousItemSizeRef]
  )
  if (debug) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('dbg: use-fit-count result:', total)
    }
  }
  return total
}

export const useArrayToArrayObject = (array, { id, name }) => useMemo(() => {
  const newArray = []
  each(array, v => { newArray.push({ [id]: v, [name]: v }) })
  return newArray
}, [array, id, name])

export const useParam = key => {
  const params = useParams()
  if (params) {
    return params[key]
  }
}

export const useRegionParam = () => useParam('region')
