import React, { memo } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import each from 'lodash/each'
import { I } from '../common/globals'
import {
  ACQ_ERD_SELECTED_BOTTOM,
  ACQ_ERD_SELECTED_TOP
} from '../common/v5/constants'
import {
  centionBlue,
  fontSizeActionHistory
} from '../styles/_variables'
import {
  useCallbackMultiValues,
  useCallbackWithValue,
  useDisableableCallback
} from '../hooks/callback'
import { composeWithDisplayName, createWithHideCondition } from './hocs'
import Anchor from './Anchor'
import { ChannelIcon, baseClassNameChannelIcon } from './Channel'

const channelIconStyle = `
  line-height: 1.4;
`
const newDesignDisplayIdStyle = `
  font-style: normal;
`
const newDesignCrossStyle = `
  font-size: ${fontSizeActionHistory};
  font-style: normal;
  margin-left: 2px;
`
const StyledSpan = styled.span`
  span& {
    display: inline-flex;
    > .${baseClassNameChannelIcon} {
      ${({ newDesign }) => newDesign ? channelIconStyle : ''}
    }
    > a:first-of-type {
      white-space: nowrap;
      > i {
        ${({ newDesign }) => newDesign ? newDesignDisplayIdStyle : ''}
      }
    }
    > a:nth-of-type(2) {
      > i {
        ${({ newDesign }) => newDesign ? newDesignCrossStyle : ''}
      }
    }
  }
`
const DisplayID = ({
  className,
  channelId,
  disabled,
  displayID,
  errandID,
  onClick,
  onDelete,
  newDesign,
  source,
  threadID
}) => (
  <StyledSpan className={className} newDesign={newDesign} title={displayID}>
    <ChannelIcon channelId={channelId} hidden={!newDesign || !channelId} />
    <Anchor>
      <i
        onClick={useDisableableCallback(
          disabled,
          useCallbackMultiValues(
            onClick,
            errandID,
            threadID
          )
        )}
      >
        {displayID}
      </i>
    </Anchor>
    <Anchor>
      <i
        className={classNames({
          'icon-circled-times': !newDesign,
          'icon icon-times': newDesign
        })}
        onClick={useDisableableCallback(
          disabled,
          useCallbackMultiValues(
            onDelete,
            source,
            errandID,
            displayID
          )
        )}
      />
    </Anchor>
  </StyledSpan>
)

const useDeleteAssociatedErrand = onDelete => useCallbackWithValue(
  'delete',
  onDelete
)

const displayIDsToComponents = (
  doms,
  texts,
  source,
  selecteds,
  errands,
  onClick,
  onDelete,
  newDesign
) => {
  each(selecteds, v => {
    const { id, data: { displayId, service }, threadId } = errands[v]
    texts.push(displayId)
    doms.push(
      <DisplayID
        key={source + '-' + v}
        channelId={service}
        displayID={displayId}
        errandID={id}
        newDesign={newDesign}
        onClick={onClick}
        onDelete={onDelete}
        source={source}
        threadID={threadId}
      />
    )
  })
  return { doms, texts }
}

const TXT_REMAINING_MORE = I('+ {remainingTagsCount} more')

const StyledDiv = styled.div`
  div&.show-more {
    position: relative;
    display: inline-flex;
    border-bottom: 1px dotted black;
    max-width: 90px;
    &:hover {
      .tooltiptext {
        opacity: 1;
        transition: all 0.5s ease;
      }
    }
    .show-more-label {
      font-size: 12px;
      color: ${centionBlue};
      font-weight: bold;
      width: unset;
      padding: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      display: unset;
    }
    .tooltiptext {
      background-color: #f4f5f5;
      box-shadow: 0 0 8px 0 rgba(0,0,0,.35);
      color: #fff;
      width: 30vw;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      opacity: 0;
      transition: all 0.5s ease;
      padding: 6px;
      right: 0;
      &:after {
        content: "";
        position: absolute;
        top: 100%;
        margin-left: -10px;
        border-width: 10px;
        border-style: solid;
        border-color:#f4f5f5 transparent transparent transparent;
        right: 15px;
      }
    }
  }
`
const MoreDisplayIDsBase = ({ tags }) => (
  <StyledDiv className='show-more'>
    <span className='show-more-label'>
      {TXT_REMAINING_MORE.replace('{remainingTagsCount}', tags.length)}
    </span>
    <div className='tooltiptext'>{tags}</div>
  </StyledDiv>
)

export const MoreDisplayIDs = composeWithDisplayName(
  'MoreDisplayIDs',
  createWithHideCondition(({ tags }) => !tags || !tags.length),
  memo
)(MoreDisplayIDsBase)

export const withAssociatedErrandItems = Component => ({
  associatedErrands,
  newDesign,
  onClick,
  onOpen,
  ...props
}) => {
  const handleDeleteAssociatedErrand = useDeleteAssociatedErrand(onClick)
  const { errands, related, grouped, contacts } = associatedErrands
  const items = []
  const ids = []
  displayIDsToComponents(
    items,
    ids,
    ACQ_ERD_SELECTED_TOP,
    related,
    errands,
    onOpen,
    handleDeleteAssociatedErrand,
    newDesign
  )
  displayIDsToComponents(
    items,
    ids,
    ACQ_ERD_SELECTED_BOTTOM,
    grouped,
    errands,
    onOpen,
    handleDeleteAssociatedErrand,
    newDesign
  )
  displayIDsToComponents(
    items,
    ids,
    ACQ_ERD_SELECTED_BOTTOM,
    contacts,
    errands,
    onOpen,
    handleDeleteAssociatedErrand,
    newDesign
  )
  return <Component ids={ids} items={items} {...props} />
}
