import React, { memo, useContext, useEffect, useRef } from "react";
import styled from 'styled-components';
import {
	Modal,
	ModalBody,
	ModalFooter
} from 'reactstrap';
import {
	POPBOX_ALERT,
	POPBOX_CONFIRM,
	POPBOX_CUSTOM,
	POPBOX_WAIT,
	POPBOX_AUTO_LOGOUT,
	AUTO_LOGOUT_COUNTDOWN_SECONDS
} from '../common/v5/constants';
import {
	composeWithDisplayName,
	withUnmountWhenHidden
} from './hocs';
import AlertConfirmContext from '../contexts/modalRef';
import { Popup } from '../hooks/portal';

class OneButton extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		this.props.onClick(this.props.type, this.props.cancel, e);
	}
	render() {
		const { text, onClick, type, color, ...props } = this.props;
		return (
			<button
				className={"btn-"+color}
				data-qa-id={"QA_alert_btn_"+type}
				onClick={this.handleClick}
				title={text}
			>
				{text}
			</button>
		);
	}
}

const StyledDiv = styled.div`
	margin-top: 8px;
	width: 100%;
`
const DivBase = ({ onMountOrUnMount, ...props }) => {
	const innerRef = useRef(null)
	useEffect(() => {
		onMountOrUnMount(innerRef)
		return () => {
			onMountOrUnMount(null)
		}
	}, [onMountOrUnMount])
	return <StyledDiv ref={innerRef} {...props} />
}

const Div = composeWithDisplayName(
	'Div',
	memo,
	withUnmountWhenHidden
)(DivBase)

export default class AlertConfirm extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleOpened = this.handleOpened.bind(this);
		this.handleCancel = this.handleCancel.bind(this);
		this.handleCopy = this.handleCopy.bind(this);
		this.handleClosed = this.handleClosed.bind(this);
		this.timeoutHandler = null;
		this.state = {
			countDown: AUTO_LOGOUT_COUNTDOWN_SECONDS,
			copying: false
		}
	}
	handleCancel(state) {
		if(typeof state === 'boolean') {
			this.props.onToggleShow(state);
			return;
		}
		const type = this.props.type;
		if(type === POPBOX_WAIT) {
			return;
		}
		if (this.timeoutHandler) {
			clearTimeout(this.timeoutHandler);
			this.timeoutHandler = null;
		}
		this.props.onCancel(type === POPBOX_CONFIRM || type === POPBOX_CUSTOM || type === POPBOX_AUTO_LOGOUT);
	}
	handleEnter() {
	}
	handleExit() {
	}
	handleOpened() {
		if (this.props.type === POPBOX_AUTO_LOGOUT) {
			if (this.timeoutHandler) {
				clearTimeout(this.timeoutHandler);
				this.timeoutHandler = null;
			}
			this.setState({countDown: AUTO_LOGOUT_COUNTDOWN_SECONDS});
			let timerFunc = () => {
				this.setState({countDown: this.state.countDown-1});
				if (this.state.countDown <= 0) {
					this.props.onConfirm();
				} else {
					this.timeoutHandler = setTimeout(timerFunc, 1000);
				}
			}
			this.timeoutHandler = setTimeout(timerFunc, 1000);
		}
	}
	handleCopy() {
		const textToCopy = document.getElementById("c3-textToCopy").innerText;
		if (!textToCopy) {
			return;
		}
		if(navigator.clipboard) {
			navigator.clipboard.writeText(textToCopy).then(() => {
				this.setState({ copying: true });
			})
		} else {
			console.log('Browser Not compatible');
		}
	}
	handleClosed() {
		if(this.props.withCopy) {
			this.setState({ copying: false });
		}
		this.props.onClosed();
	}
	render() {
		const {
			buttons,
			show,
			type,
			mainText,
			onButtonClick,
			onCancel,
			onConfirm,
			onCustomComponentRefChange,
			secondText,
			withCopy,
			copyAbleTxt
		} = this.props;
		let onSecond, onMain, backdrop = true, wait = null, footer = null, listOfMessage = [],
			text = this.props.text, noFade;
		if(type === POPBOX_ALERT || type === POPBOX_CONFIRM || type === POPBOX_AUTO_LOGOUT) {
			onSecond = true;
			if(type === POPBOX_CONFIRM || type === POPBOX_AUTO_LOGOUT) {
				onMain = true;
			}
			footer = <ModalFooter>
					{onMain ?
						<button onClick={onConfirm} className="btn-blue" title={mainText}>{mainText}</button>
					: null}
					{withCopy ?
						<button onClick={this.handleCopy} className="btn-blue" title={I("Copy")}>{I("Copy")}</button>
					: null}
					{onSecond ?
						<button onClick={this.handleCancel} className="btn-grey" title={secondText}>{secondText}</button>
					: null}
				</ModalFooter>;
		} else if(type === POPBOX_WAIT) {
			backdrop = 'static';
			noFade = true;
			if(text) {
				text = ' ' + text;
				wait = <i className="fa fa-spinner fa-spin" aria-hidden="true">
					</i>;
			}
		} else if(type === POPBOX_CUSTOM && buttons && buttons.length) {
			let bttns = [];
			$.each(buttons, (i,v) => {
				const typ = v.type;
				bttns.push(<OneButton key={typ} type={typ} cancel={!!v.cancel}
					color={v.color} onClick={onButtonClick} text={v.text} />);
			});
			footer = <ModalFooter className="custom-alert">{bttns}</ModalFooter>;
		}
		if (type === POPBOX_AUTO_LOGOUT) {
			const expireBaseText = I('If you do nothing, the system will log out automatically in {0} seconds.')
			, expireHeader = I('There has been no activity on your profile for {0} minute(s).')
			;
			let headerText = expireHeader.format(autoLogout.timeout - 1)
			, arg = expireBaseText.indexOf("{0}")
			, leftText = expireBaseText.substr(0, arg)
			, rightText = expireBaseText.substr(arg+3)
			;
			text = <table cellPadding="0" cellSpacing="0" style={{width: '100%'}}>
				<tbody>
					<tr>
						<td style={{padding: '5px', width: '100%'}}>
							<h3 id="countdown_header">{headerText}</h3>
						</td>
					</tr>
					<tr>
						<td style={{verticalAlign: "top", padding: '5px', width: '100%'}}>
							<div id="countdown_text">{leftText}<b>{this.state.countDown}</b>{rightText}</div>
						</td>
					</tr>
				</tbody>
			</table>
		}
		if (Array.isArray(text)) {
			$.each(text, (i, message) => {
				listOfMessage.push(<p key={i}>{message}</p>)
			});
		} else {
			listOfMessage =	text;
		}
		let copyNotice, copiedColor="transparent";
		if(withCopy) {
			if(this.state.copying) {
				copyNotice = I("Copied to clipboard");
				copiedColor = "#F0F0F0";
			}
		}
		return (
			<Modal
				backdrop={backdrop}
				fade={!noFade}
				isOpen={show}
				onClosed={this.handleClosed}
				onEnter={this.handleEnter}
				onExit={this.handleExit}
				onOpened={this.handleOpened}
				toggle={this.handleCancel}
				zIndex={1010}
			>
				<ModalBody className="waiting">
					{wait}
					{listOfMessage}
					{ copyAbleTxt &&
						<div id="c3-textToCopy" style={{background: copiedColor, padding: '10px', margin: '10px'}}>
							{copyAbleTxt}
						</div>
					}
					<Div
						onMountOrUnMount={onCustomComponentRefChange}
						hidden={false}
					/>
					{copyNotice &&
						<div style={{opacity: '0.5', marginLeft: '10px'}}>{copyNotice}</div>
					}
				</ModalBody>
				{footer}
			</Modal>
		);
	}
}

const useModalRef = () => {
	const alertConfirmContext = useContext(AlertConfirmContext)
	if (!alertConfirmContext) {
		return null
	}
	return alertConfirmContext.modalRef
}

const BodyBase = props => <Popup modalRef={useModalRef()} {...props} />

// Use this component to wrap any react component that need to be display on
// async alert / confirmation box (popup). This component should act as
// singleton, meaning at anytime only one instance of 'Body' can exist else
// multiple components will appear on popup body. Use 'hidden' prop to control
// this. Set 'hidden' to false before trigger 'customConfirm', wait for
// 'customConfirm' completion then set 'hidden' to true. It should works for any
// popup that base component uses AlertConfirm, eg: togglePopAlert works too.
export const Body = composeWithDisplayName(
	'Body',
	memo,
	withUnmountWhenHidden
)(BodyBase)
