import React, { useCallback } from "react";
import styled from 'styled-components';
import update from 'immutability-helper';
import {
	branch
	, mapProps
	, withHandlers
	, withProps
	, withState
	, withStateHandlers
} from "recompose";
import classNames from "classnames";
import ReactTable, { ReactTableDefaults } from "react-table-6";
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { I, L } from "../common/v5/config";
import {
	I18N_AGGREGATE
	, INVALID_DATA_STR
	, RDDCDT_AGENT
	, RDDCDT_FOLDER
	, RDDCDT_AREA
	, RDDCDT_BOOL
	, RDDCDT_CLOSE_TYPE
	, RDDCDT_DATE_TIME
	, RDDCDT_DURATION
	, RDDCDT_FALLBACK
	, RDDCDT_NORMALIZED
	, RDDCDT_ORGANIZATION
	, RDDCDT_SINCE_NOW
	, RDDRDT_CHAT_RATING
	, emptyArray
	, emptyObject
} from "../common/v5/constants";
import { useCallbackWithValue, useFullCompose } from "../hooks/callback";
import { TABLE_HEADER_STYLE } from "./common";
import { SingleSelectDD } from "./Dropdown";
import { PopupWithButtons } from "./PopupPage";
import {
	CELL_AGENT,
	CELL_AREA,
	CELL_DATE_TIME,
	CELL_DURATION,
	CELL_FOLDER,
	CELL_ORGANIZATION,
	CELL_SINCE_NOW,
	cellRenderer
} from "./Table";
import {
	composeWithDisplayName
	, createWithPropAttachedOnClick
	, createWithRemounting
	, withShowToggle
} from "./hocs";
import "react-table-6/react-table.css";

const baseTablesClassName = "c3-drilldown-tables"
	, baseClassName = "drilldown-table c3-report-table-v5"
	, defIdSmall = {id: "id", name: "name"}
	, SingleSelect = composeWithDisplayName(
		"SingleSelect"
		, withProps({
			className: "nav-link" // NOTE: need 'reactstrap' to get this class
			, idFields: defIdSmall
			, showChevron: true
			, title: I18N_AGGREGATE
		})
		, withShowToggle
	)(SingleSelectDD)
	;
const removeProps = mapProps(({
	agents
	, areas
	, closeActionMessages
	, folders
	, normData
	, timezoneOffset
	, ...props
}) => props);

const Td = removeProps(ReactTableDefaults.TdComponent);

const drilldownDataTypeToCellType = {
	[RDDCDT_AGENT]: CELL_AGENT,
	[RDDCDT_AREA]: CELL_AREA,
	[RDDCDT_DATE_TIME]: CELL_DATE_TIME,
	[RDDCDT_DURATION]: CELL_DURATION,
	[RDDCDT_FOLDER]: CELL_FOLDER,
	[RDDCDT_ORGANIZATION]: CELL_ORGANIZATION,
	[RDDCDT_SINCE_NOW]: CELL_SINCE_NOW
};

const Normalized = ({ data, value }) => {
	let accessor = data.accessor;
	data = data.data;
	let realValue = data[value];
	if (accessor) {
		if (typeof accessor === "string") {
			accessor = accessor.split(",");
		}
		if (accessor.length) {
			$.each(accessor, (i, v) => {
				realValue = realValue[v];
			});
		}
	}
	if (typeof realValue === "undefined") {
		realValue = value;
	}
	return <span>{realValue}</span>;
};

const CellRenderer = ({ column, value, ...props }) => {
	const { drilldownDataType } = column
		, otherProps = props.tdProps.rest
		, type = drilldownDataTypeToCellType[drilldownDataType]
		;
	if (type) {
		return cellRenderer({ type }, value, otherProps, emptyObject);
	}
	switch (drilldownDataType) {
		case RDDCDT_BOOL:
			if (value) {
				return <span>{I("true")}</span>;
			} else {
				return <span>{I("false")}</span>;
			}
		case RDDCDT_CLOSE_TYPE:
			let text = otherProps.closeActionMessages[value];
			if (typeof text === "undefined") {
				text = value;
			} else {
				text = L(text);
			}
			return <span>{text}</span>;
		case RDDCDT_FALLBACK:
			let fallbackValue;
			if (!props.pivoted) {
				fallbackValue = props.original[column.arguments];
			} else {
				const { subRows } = props
					, { _original } = subRows[0]
					, { originalAccessor } = column
					;
				fallbackValue = value;
				if (typeof originalAccessor !== "undefined"
					&& typeof _original !== "undefined"
					&& _original[originalAccessor]) {
					value = _original[originalAccessor];
				}

			}
			if (!value) {
				value = fallbackValue;
			}
			if (typeof value === "undefined" || value === "") {
				value = "<Unknown>";
			}
			return <span title={`${value} (${fallbackValue})`}>{value}</span>;
		case RDDCDT_NORMALIZED:
			return <Normalized data={otherProps.normData} value={value} />;
		case RDDRDT_CHAT_RATING:
			let ratingText = "";
			if (value == 1) {
				ratingText = I("good")
			} else if (value == 2) {
				ratingText = I("bad")
			}
			return <span>{ratingText}</span>;
		default:
			return <span>{value}</span>;
	}
};

const withOriginalValue = withProps(({
	column
	, subRows
}) => ({value: subRows[0][column.id]}));

const columnDefaults = {
		...ReactTableDefaults.column
		, headerStyle: TABLE_HEADER_STYLE
		, Cell: CellRenderer
		, Pivot: withOriginalValue(CellRenderer)
	};
const NotReadyMessage = ({ text }) => <h3>{text}</h3>;

function withNotReady(Component) {
	return ({ notReady, ...props }) => {
		if (notReady) {
			return <NotReadyMessage text={notReady.reason} />;
		}
		return <Component {...props} />;
	};
}

const AggregateNavItem = ({ className, data, onSelect, selected }) => (
	<NavItem>
		<SingleSelect
			className={className}
			data={data}
			onSelect={onSelect}
			selected={selected}
		/>
	</NavItem>
);

const OneNavItemBase = ({ active, onClick, text }) => (
	<NavItem>
		<NavLink className={classNames({active})} onClick={onClick}>
			{text}
		</NavLink>
	</NavItem>
);

const OneNavItem = composeWithDisplayName(
	"OneNavItem"
	, withHandlers({onClick: ({ onClick }) => tabId => onClick(tabId)})
	, createWithPropAttachedOnClick("tabId")
)(OneNavItemBase);

const Value = ({ value }) => <span>{value}</span>;

function countAggregate(vals) {
	return vals.length;
}

function changeAccessorForPivot(header, pivot) {
	if (typeof pivot === "undefined") {
		return [false, header];
	}
	return [pivot, update(header, {accessor: {$set: pivot}})];
}

function processAggregateTableHeaders(headers, selected, aggregateColumn) {
	let newHeaders = [];
	$.each(headers, (i, v) => {
		const { accessor, drilldownDataType, usePivot } = v;
		if (accessor === aggregateColumn) {
			newHeaders.push(update(v, {
				Aggregated: {$set: Value}
				, Header: {$set: I18N_AGGREGATE}
				// TODO: need to support different type of aggregation but for
				// time being only counting.
				, aggregate: {$set: countAggregate}
			}));
		} else if (accessor === selected) {
			// TODO: can not support organization now because organization id
			// never actually sent from backend data, it is the front-end that
			// do the magic to resolve area into its organization name to be
			// displayed on drilldown table. But when come to aggregation, the
			// 'data' must carry the value as pivot. We probably can re-update
			// any data from backend to insert the organization-id column then
			// we can pivot using organization but for time being just don't
			// allow pivot as organization avoid extra processing on 'data'
			// modification.
			if (drilldownDataType !== RDDCDT_ORGANIZATION) {
				if (typeof usePivot === "undefined" || usePivot === selected) {
					newHeaders.push(v);
				} else {
					newHeaders.push(update(v, {
						accessor: {$set: usePivot}
						, originalAccessor: {$set: selected}
					}));
					selected = usePivot;
				}
			}
		}
	});
	return [newHeaders, selected];
}

const autoCursor = {cursor: "auto"}
	, aggregateTdProps = {
		onClick: (e, handleOriginal) => {}
		, style: autoCursor
	}
	;
const withAggregation = Component => ({
	aggregateColumn
	, selectedPivotBy
	, ...thisProps
}) => {
	let { headers, ...props } = thisProps
		, pivotBy
		;
	if (selectedPivotBy) {
		const [ h, pivot ] = processAggregateTableHeaders(
			headers
			, selectedPivotBy
			, aggregateColumn
		);
		headers = h;
		pivotBy = [pivot];
	}
	return <Component headers={headers} pivotBy={pivotBy} {...props} />;
};

function activeTabFromIndex(activeTabIndex) {
	return (activeTabIndex+1) + "";
}

const remount = "remount"
	, onChangeRemount = "onChangeRemount"
	;
const withRemountReactTable = withState(remount, onChangeRemount, false);

const withTabIndexHandler = withState("activeTabIndex", "onTabClick", 0);

const withPivotHandlers = withStateHandlers(
	({ results }) => {
		const pivotBySelections = [];
		if (results) {
			$.each(results, () => pivotBySelections.push(""));
		}
		return {pivotBySelections};
	}
	, {
		onAggregateSelect: ({ pivotBySelections }, props) => selected => {
			props[onChangeRemount](true);
			return {
				pivotBySelections: update(
					pivotBySelections
					, {$splice: [[props.activeTabIndex, 1, selected]]}
				)
			};
		}
	}
);

const withMultiTables = Component => ({
	activeTabIndex
	, aggregations
	, enableAggregation
	, onAggregateSelect
	, onTabClick
	, pivotBySelections
	, results
	, ...props
}) => {
	if (!results || !results.length) {
		return <NotReadyMessage text={INVALID_DATA_STR} />;
	} else if (results.length === 1 && !enableAggregation) {
		const { data, headers } = results[0];
		return <Component {...props} data={data} headers={headers} />;
	}
	let items;
	if (enableAggregation) {
		items = [
			<AggregateNavItem
				key="aggregation"
				data={aggregations[activeTabIndex].options}
				onSelect={onAggregateSelect}
				selected={pivotBySelections[activeTabIndex]}
			/>
		];
	} else {
		items = [];
	}
	let tabs = [];
	$.each(results, (i, { data, headers, name }) => {
		let aggregateColumn
			, pivotBy
			;
		if (enableAggregation) {
			aggregateColumn = aggregations[i].column;
			pivotBy = pivotBySelections[i];
		}
		items.push(
			<OneNavItem
				key={i}
				active={activeTabIndex === i}
				onClick={onTabClick}
				tabId={i}
				text={L(name)}
			/>
		);
		tabs.push(
			<TabPane key={i} tabId={activeTabFromIndex(i)}>
				<Component
					{...props}
					aggregateColumn={aggregateColumn}
					data={data}
					enableAggregation={enableAggregation}
					headers={headers}
					selectedPivotBy={pivotBy}
				/>
			</TabPane>
		);
	});
	return (
		<div className={baseTablesClassName}>
			<Nav tabs>{items}</Nav>
			<TabContent activeTab={activeTabFromIndex(activeTabIndex)}>
				{tabs}
			</TabContent>
		</div>
	);
}

class OneDrilldownTableBase extends React.PureComponent {
	constructor(props) {
		super(props);
		this.getTdProps = this.getTdProps.bind(this);
	}
	getTdPropsBase(state, rowInfo, { id, drilldownDataType }) {
		if (drilldownDataType === RDDCDT_AGENT) {
			return {agents: this.props.agents};
		} else if (drilldownDataType === RDDCDT_AREA || drilldownDataType === RDDCDT_ORGANIZATION) {
			return {areas: this.props.areas};
		} else if (drilldownDataType === RDDCDT_FOLDER) {
			return {folders: this.props.folders};
		} else if (drilldownDataType === RDDCDT_DATE_TIME) {
			return {timezoneOffset: this.props.timezoneOffset};
		} else if (drilldownDataType === RDDCDT_NORMALIZED) {
			return {normData: this.props.normData[id]};
		} else if (drilldownDataType === RDDCDT_CLOSE_TYPE) {
			return {closeActionMessages: this.props.closeActionMessages};
		}
		return emptyObject;
	}
	getTdProps(...args) {
		const { pivotBy } = this.props;
		let props = this.getTdPropsBase(...args);
		if (pivotBy && pivotBy.length > 0) {
			props = update(props, {$merge: aggregateTdProps});
		}
		return props;
	}
	render() {
		const { className, data, headers, pivotBy } = this.props
			, cn = classNames(
				baseClassName
				, className
			)
			;
		return (
			<ReactTable
				TdComponent={Td}
				className={cn}
				column={columnDefaults}
				columns={headers}
				data={data}
				defaultPageSize={10}
				getTdProps={this.getTdProps}
				pivotBy={pivotBy}
			/>
		);
	}
}

const checkEnableAggregation = ({ enableAggregation }) => enableAggregation;

function buttonsWithExport(enableButtons, exportHandler) {
	if (!enableButtons) {
		return emptyArray;
	}
	return [{id: "export", text: I("Export"), onClick: exportHandler}];
}

const withDrilldownTablePopup = Component => ({
	enableButtons
	, onClose
	, onExport
	, show
	, ...props
}) => (
	<PopupWithButtons
		innerClass="drilldown-table-popup"
		data-qa-id={props["data-qa-id"]}
		buttons={buttonsWithExport(enableButtons, onExport)}
		show={show}
		onClose={onClose}
	>
		<Component {...props} />
	</PopupWithButtons>
);

const DrilldownTable = composeWithDisplayName(
	"DrilldownTable"
	, withTabIndexHandler
	, withRemountReactTable
	, branch(checkEnableAggregation, withPivotHandlers)
	, withProps(({ enableAggregation, activeTabIndex, pivotBySelections }) => {
		if (!enableAggregation
			|| !pivotBySelections
			|| !pivotBySelections[activeTabIndex]) {
			return {enableButtons: true};
		}
		return emptyObject;
	})
	, withDrilldownTablePopup
	, withNotReady
	, withMultiTables
	, branch(checkEnableAggregation, withAggregation)
	, createWithRemounting(remount, onChangeRemount)
)(OneDrilldownTableBase);

export default DrilldownTable;
