import React from "react";
import classNames from "classnames";
import { renameProp } from "recompose";
import { I, L } from "../common/v5/config";
import { NO_DATA_STR } from "../common/v5/constants";
import {
	TABLE_BASE_CLASSNAME
	, TABLE_HEADER_STYLE
	, processForTable
} from "./common";
import ReportReactTable, { ExpandableTable } from "./ReportReactTable";

const baseClassName = TABLE_BASE_CLASSNAME;

const OneHeader = ({id, name}) => (
	<th style={TABLE_HEADER_STYLE} id={id}>{L(name)}</th>
);

const TableHeader = ({ data }) => {
	const { groups, keys } = data;
	let headers = [];
	$.each(groups, (i, props) => {
		headers.push(<OneHeader {...props} key={"reportTblHeaderGS_"+i} />);
	});
	$.each(keys, (i, props) => {
		headers.push(<OneHeader {...props} key={"reportTblHeaderKS_"+i} />);
	});
	return (
		<tr>{headers}</tr>
	);
};

const Column = ({data, ...props}) => <td {...props}>{data}</td>;

const TotalColumn = props => <Column {...props} data={I("Total")} />;

const Total = ({data, colSpan, keys}) => {
	if (typeof data === "undefined") {
		return null;
	}
	let ks = [];
	$.each(keys, (i, v) => {
		ks.push(<Column key={"totalKeyData_"+i} data={data[v.id]} />);
	});
	return (
		<tr style={{verticalAlign: "top"}}>
			<TotalColumn className="group" colSpan={colSpan} />
			{ks}
		</tr>
	);
};

const TotalRow = ({ order, groups, keys, filter, groupTotal }) => {
	let dom = [];
	$.each(keys, (i, {id}) => {
		const g = "group" + order
			, gv = filter[g]
			, gname = filter[g+"_name"]
			, total = groupTotal[id][g]
			;
		let data = total[gv];
		dom.push(<Column key={"reportTblDataNoOrder_"+id} data={data} />);
	});
	return (
		<tr>
			<TotalColumn colSpan={groups.length-1} />
			{dom}
		</tr>
	);
};

const DataColumn = ({ orderId, data, ...props }) => {
	const orderIdName = orderId + "_name";
	if (!data[orderIdName] && data[orderIdName] != "") {
		data = data[orderId];
	} else {
		data = data[orderIdName];
	}
	if (!data && data != 0) {
		data = L("");
	} else {
		data = data.toString().trim();
	}
	return <Column {...props} className='group' data={data} />;
};

const genKeysData = ({keyPrefix, data, keys}) => {
	let cs = [];
	$.each(keys, (i, {id}) => {
		cs.push(<Column key={keyPrefix+i} data={data[id]} />);
	});
	return cs;
};

const RowData = ({data, keys, children}) => {
	return (
		<tr>
			{children}
			{genKeysData({keyPrefix: "ksData_", data, keys})}
		</tr>
	);
};

const genRows = (header, data, groupTotal, order, tdList) => {
	const  { groups, keys } = header
		, { sorted, id: orderId } = processForTable(groups, data, order)
		;
	return sorted.map((v, i) => {
		const filtered = data.filter(row => v === row[orderId])
			, firstFilter = filtered[0]
			, hasRowSpan = order == 0 && filtered.length>1 && groups.length>1
			;
		let rowSpan = filtered.length
			, tmpx
			;
		if (hasRowSpan) {
			rowSpan += 1;
		}
		tdList.push(
			<DataColumn key={"reportTblData_"+(tdList.length+1)}
				rowSpan={rowSpan}
				orderId={orderId}
				data={firstFilter}
			/>
		);
		if (order+1 == groups.length) {
			// last item of same group
			tmpx = [];
			let tmps = [];
			$.each(filtered, (j, w) => {
				tmps.push(
					<RowData key={"reportTblRow_"+j} data={w} keys={keys}>
						{tdList.slice()}
					</RowData>
				);
				// clear columns DOM list after it is 'rendered'.
				tdList.length = 0;
			});
			tmpx.push(tmps);
		} else {
			tmpx = genRows(header, filtered, groupTotal, order+1, tdList);
		}
		if (hasRowSpan) {
			tmpx.push(
				<TotalRow key={"reportTblRowNoOrder_"+i}
					order={order}
					groups={groups}
					keys={keys}
					filter={firstFilter}
					groupTotal={groupTotal}
				/>
			);
		}
		tdList.pop();
		return tmpx;
	});
};

const NoData = ({colSpan, text}) => (
	<tr>
		<td className='group' colSpan={colSpan}>{text}</td>
	</tr>
);

const ContentBorder = ({ header, children }) => (
	<tbody>
		<TableHeader data={header} />
		{children}
	</tbody>
);

const TableContent = ({ header, data, total, groupTotal }) => {
	const { groups, keys } = header
		, colSpan = groups.length
		;
	if (!data || !data.length) {
		return (
			<ContentBorder header={header}>
				<NoData
					colSpan={colSpan+keys.length}
					text={NO_DATA_STR}
				/>
			</ContentBorder>
		);
	}
	// console.log('dbg: report table content should not render wildly');
	return (
		<ContentBorder header={header}>
			{genRows(header, data, groupTotal, 0, [])}
			<Total data={total} colSpan={colSpan} keys={keys} />
		</ContentBorder>
	);
};

class ReportTable extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const { useExpandable, data, customClass, ...props } = this.props;
		if (useExpandable) {
			return <ExpandableReportTable {...props} data={data} />;
		}
		return (
			<table
				data-border="0"
				cellPadding="0"
				cellSpacing="0"
				className={classNames(
					baseClassName
					, "table table-bordered"
					, customClass
				)}
			>
				<TableContent
					header={data.header}
					data={data.data}
					total={data.total}
					groupTotal={data.groupTotal}
				/>
			</table>
		);
	}
}

export default ReportTable;

const ExpandableReportTableBase = ({ tag, className, ...props }) => (
	<ExpandableTable {...props}
		className={classNames(baseClassName, className)}
		tag={ReportReactTable}
	/>
);

export const ExpandableReportTable = renameProp(
	"onTableCellClick"
	, "onClick"
)(ExpandableReportTableBase);
