// Reference:
// https://github.com/zzarcon/react-observable-hook/blob/c15ffd9dd1ba6dd77a381b6e2b412b68a64fdc2d/src/useObservable.tsx#L1-L19
import { useEffect, useState } from 'react'

const useObservable = (onPopAlert, observable, initialValue) => {
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    let subscription
    if (observable) {
      subscription = observable.subscribe({
        error (error) { onPopAlert(error) },
        next (value) { setValue(value) }
      })
    }
    return () => {
      if (subscription) {
        subscription.unsubscribe()
      }
    }
  }, [observable, onPopAlert])
  return value
}

export default useObservable
