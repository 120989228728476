import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import { emptyObject, MP_BASIC_CALL } from '../common/v5/constants';
import {
	SIP_CALL_IDLE
	, SIP_CALL_ON_HOLD
	, SIP_CALL_CONNECTED
	, SIP_REGISTER
} from '../common/v5/callConstants';
import {
	withDisableableOnClick
	, withIdAttachedOnClick
	, withUnmountWhenHidden
} from './hocs';
import { TOGGLE_ATTACHMENT_PLUS_MINUS } from '../redux/constants/constants';

export const BTTN_ONE = 1
	, BTTN_TWO = 2
	, BTTN_THREE = 3
	, BTTN_FOUR = 4
	, BTTN_FIVE = 5
	, BTTN_SIX = 6
	, BTTN_SEVEN = 7
	, BTTN_EIGHT = 8
	, BTTN_NINE = 9
	, BTTN_STAR = 10
	, BTTN_ZERO = 11
	, BTTN_HASH = 12
	;
const baseClassName = "c3-simple-dialpad"
	, noPointerEvents = {pointerEvents: "none"}
	, buttonMap = {
		[BTTN_ONE]: ["1", ""]
		, [BTTN_TWO]: ["2", "abc"]
		, [BTTN_THREE]: ["3", "def"]
		, [BTTN_FOUR]: ["4", "ghi"]
		, [BTTN_FIVE]: ["5", "jkl"]
		, [BTTN_SIX]: ["6", "mno"]
		, [BTTN_SEVEN]: ["7", "pqrs"]
		, [BTTN_EIGHT]: ["8", "tuv"]
		, [BTTN_NINE]: ["9", "wxyz"]
		, [BTTN_STAR]: ["*", ""]
		, [BTTN_ZERO]: ["0", "+"]
		, [BTTN_HASH]: ["#", ""]
	}
	, varButtonList = [
		BTTN_ONE
		, BTTN_TWO
		, BTTN_THREE
		, BTTN_FOUR
		, BTTN_FIVE
		, BTTN_SIX
		, BTTN_SEVEN
		, BTTN_EIGHT
		, BTTN_NINE
		, BTTN_STAR
		, BTTN_ZERO
		, BTTN_HASH
	]
	, mapKeyCharToButton = {
		96: BTTN_ZERO
		, 97: BTTN_ONE
		, 98: BTTN_TWO
		, 99: BTTN_THREE
		, 100: BTTN_FOUR
		, 101: BTTN_FIVE
		, 102: BTTN_SIX
		, 103: BTTN_SEVEN
		, 104: BTTN_EIGHT
		, 105: BTTN_NINE
		, 106: BTTN_STAR
		, 35: BTTN_HASH
	}
	;

const Numbers = ({ text }) => {
	if (!text) {
		return null;
	}
	return <span>{text}</span>;
};

const Strong = ({ data, icon }) => {
	let dom;
	if (icon) {
		dom = <i className={data} />
	} else {
		dom = data;
	}
	return <strong>{dom}</strong>;
};

const withNoPointerEvents = Component => class extends PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.state = {value: ""};
	}
	handleChange(value) {
		this.setState({value});
	}
	render() {
		return (
			<Component
				onChange={this.handleChange}
				value={this.state.value}
				{...this.props}
			/>
		);
	}
};

const OneButtonPadBase = ({
	className
	, disabled
	, isPressed
	, onClick
	, primary
	, primaryIsIcon
	, secondary
	, style
}) => (
	<li
		className={classNames("digits", className, {active: isPressed})}
		onClick={onClick}
		style={disabled ? noPointerEvents : emptyObject}
	>
		<p>
			<Strong icon={primaryIsIcon} data={primary} />
			<Numbers text={secondary} />
		</p>
	</li>
);

const OneButtonPad = withIdAttachedOnClick(OneButtonPadBase);

const ButtonsPadBase = ({ children, style }) => (
	<div className="dials" style={style}><ul>{children}</ul></div>
);

const ButtonsPad = withDisableableOnClick(ButtonsPadBase);

const Buttons = ({ callInProgress, localBtnList, onClick, pressedKey, ...props }) => (
	<ButtonsPad disabled={callInProgress}>
		{localBtnList.map(identifier => {
			const [
					primary
					, secondary
					, isIcon
					, className
				] = buttonMap[identifier]
				;
			return (
				<OneButtonPad
					key={identifier}
					className={className}
					id={identifier}
					isPressed={identifier === pressedKey}
					onClick={onClick}
					primary={primary}
					primaryIsIcon={isIcon}
					secondary={secondary}
				/>
			);
		})}
	</ButtonsPad>
);

const Wrapper = ({ children, compact, onClose, embedded, withClose, className, ...props }) => (
	<div
		className={classNames(
			"dialpad-wrapper"
			, className
			, {embedded}
		)}>
		<div className="dialer-close" style={{padding: "5px 10px", width: "100%", display: "inline-block"}} hidden={embedded || !withClose}>
			<span onClick={onClose}><i style={{fontSize: "12px", float: "right", cursor: "pointer"}} className="icon-times"></i></span>
		</div>
		<section role="main">
			<div
				className={classNames(
					baseClassName
					, "dialpad"
					, {compact}
				)}
			>
				{children}
			</div>
		</section>
	</div>
);

class SimpleDialPadBase extends PureComponent {
	constructor(props) {
		super(props);
		this.cloneBtnList = [...varButtonList];
		this.handleClose = this.handleClose.bind(this);
		this.state = {pressedKey: false};
	}
	isAllowedButtonsChangeValue(pressedKey) {
		const { allowedButtonsChangeValue } = this.props;
		if (!allowedButtonsChangeValue || !allowedButtonsChangeValue.length) {
			return false;
		}
		let found;
		$.each(allowedButtonsChangeValue, (i, v) => {
			if (v === pressedKey) {
				found = true;
				return false;
			}
		});
		return !!found;
	}
	componentDidMount() {
		$(document).on("keydown.dialpad", e => {
			const { which } = e
				, pressedKey = mapKeyCharToButton[which]
				;
			const { callInProgress, sipCallStatus, onButtonKeyDown,
				onChangeDtmf } = this.props;
			if (typeof onChangeDtmf === "function") {
				onChangeDtmf(pressedKey);
				return;
			}
			if ((callInProgress && (typeof sipCallStatus == 'undefined' ||
				sipCallStatus != SIP_CALL_IDLE)) ||
				typeof onButtonKeyDown !== "function") {
				return;
			}
			if (pressedKey !== undefined) {
				this.setState({pressedKey});
				if (this.props.pressedButtonChangeValue
					|| this.isAllowedButtonsChangeValue(pressedKey)) {
					onButtonKeyDown(pressedKey, e);
					e.preventDefault();
				}
			}
		});
		$(document).on("keyup.dialpad", e => {
			this.setState({pressedKey: false});
		});
	}
	componentWillUnmount() {
		$(document).off("keydown.dialpad");
		$(document).off("keyup.dialpad");
	}
	handleClose() {
		this.props.onClose();
	}
	render() {
		const p = this.props;
		let inProgress = this.props.callInProgress
		if(p.type == MP_BASIC_CALL){
			if(p.sipCallStatus !== SIP_CALL_IDLE &&
				p.sipCallStatus !== SIP_REGISTER){
				inProgress = true
			}
		}
		return (
			<Wrapper compact={true} className={p.className} embedded={p.embedded} withClose={p.withClose} onClose={this.handleClose}>
				<Buttons
					callInProgress={this.props.callInProgress}
					onClick={this.props.onClick}
					pressedKey={this.state.pressedKey}
					localBtnList={this.cloneBtnList}
				/>
			</Wrapper>
		);
	}
}

const withCallClick = Component => class extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleButtonKeyDown = this.handleButtonKeyDown.bind(this);
	}
	handleClick(...args) {
		this.directToCallClick(true, ...args);
	}
	handleButtonKeyDown(...args) {
		this.directToCallClick(false, ...args);
	}
	directToCallClick(fromClick, id, ...args) {
		if (fromClick) {
			this.props.onClick(id, ...args);
		} else {
			this.props.onButtonKeyDown(id, ...args);
		}
	}
	render() {
		const { onClick, onButtonKeyDown, ...props } = this.props;
		return (
			<Component
				onClick={this.handleClick}
				onButtonKeyDown={this.handleButtonKeyDown}
				{...props}
			/>
		);
	}
};

const withValueChange = Component => class extends PureComponent {
	constructor(props) {
		super(props);
		this.handleValueChange = this.handleValueChange.bind(this);
	}
	handleValueChange(id) {
		let value = "";
		switch(id) {
			case BTTN_ZERO:
				value += "0";
				break;
			case BTTN_ONE:
				value += "1";
				break;
			case BTTN_TWO:
				value += "2";
				break;
			case BTTN_THREE:
				value += "3";
				break;
			case BTTN_FOUR:
				value += "4";
				break;
			case BTTN_FIVE:
				value += "5";
				break;
			case BTTN_SIX:
				value += "6";
				break;
			case BTTN_SEVEN:
				value += "7";
				break;
			case BTTN_EIGHT:
				value += "8";
				break;
			case BTTN_NINE:
				value += "9";
				break;
			case BTTN_STAR:
				value += "*";
				break;
			case BTTN_HASH:
				value += "#";
				break;
			default: return;
		}
		if (typeof this.props.onChange === "function") {
			this.props.onChange(this.props.value + value);
		}
		if(typeof this.props.onChangeDtmf === "function"){
			this.props.onChangeDtmf(value);
		}
	}
	render() {
		const { onClick, onButtonKeyDown, ...props } = this.props;
		return (
			<Component
				onClick={this.handleValueChange}
				onButtonKeyDown={this.handleValueChange}
				{...props}
			/>
		);
	}
};

const SimpleDialPad = compose(
	withUnmountWhenHidden
	, withValueChange
	, withCallClick
)(SimpleDialPadBase);

SimpleDialPad.propTypes = {
	allowedButtonsChangeValue: PropTypes.arrayOf(PropTypes.number)
	, className: PropTypes.string
	, callInProgress: PropTypes.bool
	, hidden: PropTypes.bool
	, onButtonKeyDown: PropTypes.func
	, onChange: PropTypes.func
	, onClick: PropTypes.func
	, onClose: PropTypes.func
	, onChangeDtmf: PropTypes.func
	, pressedButtonChangeValue: PropTypes.bool
	, callButton: PropTypes.number
	, value: PropTypes.string
};

export default SimpleDialPad;
