// NOTE: this file written with 'eslint' (https://eslint.org/) linter using the
// same 'standardjs' (https://standardjs.com/) rules append react rules with
// 'eslint' config file '.eslintrc' content as below (put it at
// ??/web/.eslintrc):
// {
//     "extends": [
//       "standard",
//       "standard-jsx"
//     ],
//     "plugins": [
//         "react",
//         "react-hooks"
//     ],
//     "parser": "babel-eslint",
//     "rules": {
//         "react-hooks/rules-of-hooks": "error",
//         "react-hooks/exhaustive-deps": "warn"
//     }
// }
// npm packages that are needed for the linter to work:
// - eslint@5.16.0
// - eslint-plugin-react
// - eslint-plugin-react-hooks
// - babel-eslint
// - eslint-config-standard
// - eslint-config-standard-jsx
// PLEASE use the same linter to edit this file.
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import update from 'immutability-helper'
import { withProps } from 'recompose'
import Select, { components as rscs } from 'react-select'
import each from 'lodash/each'
import Creatable from 'react-select/creatable'
import { $, I } from '../common/globals'
import { emptyArray, emptyObject } from '../common/constants'
import { composeWithDisplayName } from './hocs'

const SingleValue = () => null

const Input = ({ value: inputValue, isHidden, ...props }) => {
  const { selectProps: { value: selected, getOptionLabel } } = props
  const label = useMemo(() => {
    if (!selected) {
      return ""
    }
    return getOptionLabel(selected)
  }, [getOptionLabel, selected])
  const value = useMemo(() => {
    if (!inputValue) {
      return label
    }
    return inputValue
  }, [inputValue, label])
  const hidden = useMemo(() => {
    if (value) {
      return false
    }
    return isHidden
  }, [isHidden, value])
  return <rscs.Input isHidden={hidden} value={value} {...props} />
}

const components = {
  Input,
  SingleValue
}

export const EditableSelected = props => (
  <Creatable components={components} {...props} />
)

const useNormOptions = (options, getOptionValue) => useMemo(() => {
  if (!options || !options.length) {
    return emptyObject
  }
  const object = {}
  $.each(options, (i, v) => {
    object[getOptionValue(v)] = v
  })
  return object
}, [options, getOptionValue])

const CreatableStringValueBase = ({
  getOptionValue,
  options,
  value,
  ...props
}) => {
  const normOptions = useNormOptions(options, getOptionValue)
  return (
    <Creatable
      getOptionValue={getOptionValue}
      options={options}
      value={normOptions[value]}
      {...props}
    />
  )
}

export const CreatableStringValue = memo(CreatableStringValueBase)

const arrayNormDataBase = (array, { byId, allIds }, converter, postAction) => {
  $.each(allIds, (i, v) => {
    const converted = converter(byId[v], v, i)
    array.push(converted)
    postAction(converted, v, i)
  })
  return array
}

const arrayNormData = (array, normData, converter, postAction) => {
  if (typeof converter !== 'function') {
    converter = v => v
  }
  if (typeof postAction !== 'function') {
    postAction = () => {}
  }
  return arrayNormDataBase(array, normData, converter, postAction)
}

const arrayOrgArea = (normOrg, { allIds, byId }) => {
  const array = []
  const map = {
    org: { allIds: normOrg.allIds, byId: {} },
    area: { allIds, byId: {} }
  }
  arrayNormData(
    array,
    normOrg,
    v => update(v, { isOrg: { $set: true } }),
    ({ areas, id }) => {
      map.org.byId[id] = array.length - 1
      arrayNormData(array, { allIds: areas, byId }, undefined, ({ id }) => {
        map.area.byId[id] = array.length - 1
      })
    }
  )
  return [array, map]
}

const useArrayData = normData =>
  useMemo(() => {
    const { org, area } = normData
    return arrayOrgArea(org, area)
  }, [normData])

const optionLabelGetter = ({ name }) => name

const optionValueGetter = ({ id, isOrg }) => (isOrg ? id + ',org' : id)

const selectAll = '__selectall__'
const selectNone = '__selectnone__'
const selectDone = '__selectdone__'
const textSystemOptions = {
  [selectAll]: 'textSelectAll',
  [selectNone]: 'textSelectNone',
  [selectDone]: 'textDone'
}
const systemOptions = {
  [selectAll]: {
    id: selectAll,
    name: I('Select all'),
    isSystem: true
  },
  [selectNone]: {
    id: selectNone,
    name: I('Select none'),
    isSystem: true
  },
  [selectDone]: {
    id: selectDone,
    name: I('Done'),
    isSystem: true
  }
}

const useGetSystemOption = texts =>
  useCallback(
    which => {
      const overridenText = texts ? texts[textSystemOptions[which]] : false
      const option = systemOptions[which]
      if (overridenText) {
        return update(option, { name: { $set: overridenText } })
      }
      return option
    },
    [texts]
  )

const pointerCSS = (provided, state) => {
  let cursor = provided.cursor
  if (!state.isDisabled) {
    cursor = 'pointer'
  }
  return {
    ...provided,
    cursor
  }
}

const customStyles = {
  option: (provided, state) => {
    let backgroundColor
    let cursor = provided.cursor
    if (state.data.isOrg) {
      if (state.isFocused) {
        backgroundColor = '#eedd88'
      } else if (state.isSelected) {
        backgroundColor = '#ddcc00'
      } else {
        backgroundColor = '#ffcc00'
      }
    } else {
      if (state.isFocused) {
        backgroundColor = '#deebff'
      } else {
        backgroundColor = provided.backgroundColor
      }
    }
    if (!state.isDisabled) {
      cursor = 'pointer'
    }
    return {
      ...provided,
      color: 'black',
      cursor,
      backgroundColor,
      paddingLeft: !state.data.isOrg ? '20px' : provided.paddingLeft
    }
  },
  multiValue: (provided, state) => {
    let backgroundColor
    if (state.data.isOrg) {
      backgroundColor = '#eedd88'
    } else {
      backgroundColor = '#deebff'
    }
    return {
      ...provided,
      backgroundColor
    }
  },
  clearIndicator: pointerCSS,
  dropdownIndicator: pointerCSS,
  multiValueRemove: pointerCSS
}

const createWithCustomStyles = styleName => Component => props => {
  return <Component {...props} style={props.getStyles(styleName, props)} />
}

// emulate Option.
const SystemOption = props => {
  const { data, onClick, getStyles, selectProps } = props
  const { getOptionLabel } = selectProps
  const [focus, setFocus] = useState(false)
  const handleMouseOver = useCallback(e => {
    setFocus(true)
  }, [])
  const handleMouseOut = useCallback(e => {
    setFocus(false)
  }, [])
  const propsForStyle = useMemo(
    () => update(props, { isFocused: { $set: focus } }),
    [props, focus]
  )
  return (
    <div
      onClick={onClick}
      onMouseOver={handleMouseOver}
      onMouseOut={handleMouseOut}
      style={getStyles('option', propsForStyle)}
    >
      {getOptionLabel(data) + ''}
    </div>
  )
}

const withCustomOption = Component => ({ children, onClick, ...props }) => {
  const { isSelected, setValue, innerProps, data, selectProps } = props
  const { value } = selectProps
  const handleChange = useCallback(() => {
    let index = -1
    $.each(value, (i, v) => {
      if (v.isOrg === data.isOrg && v.id === data.id) {
        index = i
        return false
      }
    })
    if (index < 0) {
      const newValue = value.slice()
      newValue.push(data)
      setValue(newValue)
    } else {
      setValue(update(value, { $splice: [[index, 1]] }))
    }
  }, [data, setValue, value])
  // console.log("sany", props);
  return (
    <Component {...innerProps} {...props}>
      <input type='checkbox' checked={!!isSelected} onChange={handleChange} />
      &nbsp;
      {children}
    </Component>
  )
}

const Option = composeWithDisplayName(
  'Option',
  memo,
  withCustomOption,
  createWithCustomStyles('option')
)(rscs.Option)

const borderStyles = {
  borderStyle: 'none',
  borderWidth: '1px',
  boxShadow: '0 2px 4px 0 rgba(0,0,0,0.4)'
}

const withCustomMenu = Component => ({ children, ...props }) => {
  const { setValue, selectProps } = props
  const { onMenuClose, options } = selectProps
  const systemOptionGetter = useGetSystemOption(selectProps.texts)
  const handleSelectAll = useCallback(() => {
    setValue(options)
  }, [options, setValue])
  const handleSelectNone = useCallback(() => {
    setValue([])
  }, [setValue])
  const handleSelectDone = useCallback(() => {
    onMenuClose()
  }, [onMenuClose])
  return (
    <Component {...props}>
      <div style={borderStyles}>
        <SystemOption
          {...props}
          data={systemOptionGetter(selectAll)}
          onClick={handleSelectAll}
        />
        <SystemOption
          {...props}
          data={systemOptionGetter(selectNone)}
          onClick={handleSelectNone}
        />
      </div>
      {children}
      <SystemOption
        {...props}
        data={systemOptionGetter(selectDone)}
        onClick={handleSelectDone}
      />
    </Component>
  )
}

const Menu = composeWithDisplayName(
  'Menu',
  memo,
  withCustomMenu,
  createWithCustomStyles('menu')
)(rscs.Menu)

const customComponents = { Menu, Option }

const useMultiArrayMerge = (options, normOptions) => useCallback(
  (orgs, areas) => {
    const { org, area } = normOptions
    const orgAreaMap = {}
    const orgAreas = []
    const array = []
    $.each(areas, (i, v) => {
      const a = options[area.byId[v]]
      const { orgId } = a
      // console.log("a", a);
      let as = orgAreaMap[orgId]
      if (!as) {
        as = { index: orgAreas.length, array: [] }
        orgAreas.push(orgId)
        orgAreaMap[orgId] = as
      }
      as.array.push(a)
    })
    // console.log("after areas");
    $.each(orgs, (i, v) => {
      const o = options[org.byId[v]]
      let as = orgAreaMap[v]
      // console.log("org", o);
      if (as) {
        as = as.array
        delete orgAreaMap[v]
      } else {
        as = []
      }
      array.push(o, ...as)
    })
    $.each(orgAreas.reverse(), (i, v) => {
      const m = orgAreaMap[v]
      if (m) {
        array.unshift(...m.array)
      }
    })
    return array
  },
  [options, normOptions]
)

const convertValueToMultiArray = value => {
  const areas = []
  const orgs = []
  $.each(value, (i, { id, isOrg }) => {
    if (isOrg) {
      orgs.push(id)
    } else {
      areas.push(id)
    }
  })
  return [orgs, areas]
}

// NOTE: do not refactor this code if you did not gone through what it actually
// does. Just create a new hook for another use case.
const useValueChange = (options, normOptions, changer) =>
  useCallback(
    (vs, { action, option }) => {
      // console.log("value and meta:", vs, action, option);
      if (action === 'select-option') {
        const { isOrg, id, areas } = option
        if (isOrg) {
          const areaMap = {}
          $.each(areas, (i, v) => {
            areaMap[v] = true
          })
          $.each(vs, (i, { id: areaId, isOrg, orgId }) => {
            if (!isOrg && orgId === id) {
              delete areaMap[areaId]
            }
          })
          const extraAreas = []
          $.each(areaMap, k =>
            extraAreas.push(options[normOptions.area.byId[k]])
          )
          vs = vs.concat(extraAreas)
        } else {
          vs = [option]
        }
      } else if (action === 'deselect-option' && option.isOrg) {
        vs = vs.filter(({ isOrg, orgId }) => isOrg || orgId !== option.id)
      }
      changer(vs)
    },
    [options, normOptions, changer]
  )

const createWithSelect = (
  closeMenuOnSelect,
  isMulti,
  components
) => Component => ({
  className,
  getOptionLabel,
  getOptionValue,
  normOptions,
  onChange,
  options,
  texts,
  value
}) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const handleMenuOpen = useCallback(() => setMenuOpen(true), [])
  const handleMenuClose = useCallback(() => setMenuOpen(false), [])
  return (
    <Component
      className={className}
      texts={texts}
      components={components}
      closeMenuOnSelect={closeMenuOnSelect}
      getOptionLabel={getOptionLabel}
      getOptionValue={getOptionValue}
      hideSelectedOptions={false}
      isMulti={isMulti}
      // isOptionSelected={(obj, obj2) => console.log("sany", obj2)}
      menuIsOpen={menuOpen}
      onChange={onChange}
      onMenuOpen={handleMenuOpen}
      onMenuClose={handleMenuClose}
      options={options}
      styles={customStyles}
      value={value}
    />
  )
}

const withOrgAreaOnChange = Component => ({
  normOptions,
  onChange,
  options,
  ...props
}) => (
  <Component
    getOptionLabel={optionLabelGetter}
    getOptionValue={optionValueGetter}
    onChange={useValueChange(options, normOptions, onChange)}
    options={options}
    {...props}
  />
)

const MultiSelectBase = composeWithDisplayName(
  'MultiSelectBase',
  withOrgAreaOnChange,
  createWithSelect(false, true, customComponents)
)(Select)

const MultiSelect = memo(MultiSelectBase)

const createWithDefaultCallback = (
  name,
  defaultCallback
) => withProps(({ [name]: callback }) => {
  if (typeof callback === 'function') {
    return
  }
  return { [name]: defaultCallback }
})

const singleOptionLabelGetter = ({ name }) => name

const SingleSelectBase = composeWithDisplayName(
  'SingleSelectBase',
  memo,
  createWithSelect(true),
  createWithDefaultCallback('getOptionLabel', singleOptionLabelGetter)
)(Select)

const useMemoNormalizedValue = (byId, selected) => useMemo(() => {
  const _value = byId[selected]
  if (_value) {
    return _value
  }
  return null
}, [byId, selected])

const useCallbackNormalizedKeyValue = (keyGetter, callback) => useCallback(
  v => callback(keyGetter(v), v),
  [keyGetter, callback]
)

export const NormalizedSelect = ({
  byId,
  data,
  getOptionValue,
  onSelect,
  selected,
  ...props
}) => {
  const options = useMemo(() => {
    if (!data.length) {
      return emptyArray
    }
    const result = []
    each(data, v => {
      result.push(byId[v])
    })
    return result
  }, [byId, data])
  return (
    <SingleSelectBase
      getOptionValue={getOptionValue}
      onChange={useCallbackNormalizedKeyValue(getOptionValue, onSelect)}
      options={options}
      value={useMemoNormalizedValue(byId, selected)}
      {...props}
    />
  )
}

const ReactSingleSelectBase = ({
  className,
  data,
  getOptionLabel,
  getOptionValue,
  onSelect,
  selected
}) => (
  <SingleSelectBase
    className={className}
    getOptionLabel={getOptionLabel}
    getOptionValue={getOptionValue}
    onChange={useCallbackNormalizedKeyValue(getOptionValue, onSelect)}
    options={data}
    value={useMemoNormalizedValue(useNormOptions(data, getOptionValue), selected)}
  />
)

const singleOptionValueGetter = ({ id }) => id

export const ReactSingleSelect = composeWithDisplayName(
  'ReactSingleSelect',
  memo,
  createWithDefaultCallback('getOptionValue', singleOptionValueGetter)
)(ReactSingleSelectBase)

// useAutoSelect will auto pre-select ReactSingleSelect if the selected value
// not valid or no selection.
export const useAutoSelect = (
  onAutoSelect,
  data,
  selected,
  checkSelectedAuto,
  getOptionValue = singleOptionValueGetter
) => useEffect(() => {
  if (!data) {
    if (selected) {
      onAutoSelect('')
    }
    return
  }
  let found
  let candidate
  each(data, v => {
    const value = getOptionValue(v)
    if (value === selected) {
      found = true
      return false
    } else if (!candidate && checkSelectedAuto(v)) {
      candidate = value
    }
  })
  if (found) {
    return
  } else if (candidate) {
    onAutoSelect(candidate)
  }
}, [checkSelectedAuto, data, getOptionValue, onAutoSelect, selected])

// TODO: refactor this independent of organizations and areas.
const MultiSelectMultiArrayBase = ({
  areas,
  normalizedOptions,
  onChange,
  organizations,
  texts
}) => {
  const [options, normOptions] = useArrayData(normalizedOptions)
  const multiArrayToSingle = useMultiArrayMerge(options, normOptions)
  const handleChange = useCallback(vs => {
    const [orgs, areas] = convertValueToMultiArray(vs)
    onChange('organizations', orgs)
    onChange('areas', areas)
  }, [onChange])
  return (
    <MultiSelect
      normOptions={normOptions}
      onChange={handleChange}
      options={options}
      texts={texts}
      value={multiArrayToSingle(organizations, areas)}
    />
  )
}

const MultiSelectMultiArray = memo(MultiSelectMultiArrayBase)

export default MultiSelectMultiArray
