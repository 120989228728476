import React from "react";
import classNames from "classnames";
import update from "immutability-helper";
import { AutoSizer } from 'react-virtualized';
import ReactTable, { ReactTableDefaults } from "react-table-6";
import {
	emptyObject,
	SR_ORG_OVERVIEW,
	SR_LEADERBOARD
} from "../common/v5/constants";
import { I, L } from "../common/v5/config";
import { getChevronIcon } from "../common/v5/helpers";
import { withUnmountWhenHidden } from "./hocs";
import {
	POINTER_CURSOR
	, POINTER_UNSET
	, TABLE_EXPAND_TRUE
	, TABLE_EXPAND_FALSE
	, TABLE_HEADER_STYLE
	, TABLE_TITLE_HEADER_STYLE
} from "./common";
import "react-table-6/react-table.css";

const NAME = "name";

function returnTotalData(last) {
	if (!last || !last.isTotal) {
		return;
	}
	return last;
}

function getTotalData(data) {
	if (!data || !data.length) {
		return;
	}
	return returnTotalData(data[data.length-1]);
}

function getFooterProps(state, _rowInfo, column, instance) {
	const { data } = instance.props
		, totalData = getTotalData(data)
		;
	let rowIndex = 0
		;
	if (data && data.length) {
		rowIndex = data.length-1;
	}
	return handleTdProps(
		state
		, getTotalData(data)
		, rowIndex
		, column
		, instance
	);
}

function getFooter(totalData, id, isGroup) {
	if (!totalData) {
		return;
	} else if (isGroup) {
		return totalData[id][NAME];
	}
	return totalData[id];
}

function getOriginalLastRow(originalLastIndex, data) {
	let found;
	$.each(data, (i, {_original, _index}) => {
		if (_index === originalLastIndex) {
			found = _original;
			return false;
		}
	});
	return found;
}

const Footer = ({ column, data }) => {
	const { expander, id } = column;
	let footerValue;
	if (data && data.length) {
		footerValue = getFooter(
			returnTotalData(getOriginalLastRow(data.length-1, data))
			, id
			, expander
		);
	}
	if (typeof footerValue === "undefined") {
		footerValue = "<Invalid>";
	}
	return <span>{footerValue}</span>;
};

const columnDefaults = {
		...ReactTableDefaults.column
		, getFooterProps
		, Footer
	}
	, expanderDefaults = {
		...ReactTableDefaults.expanderDefaults
		, minWidth: 100
		, width: undefined
		, resizable: true
		, sortable: true
	}
	;
export class ColData extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		if (typeof this.props.onClick === "function") {
			const { data, row, extra, index } = this.props;
			this.props.onClick(data, row, extra, index, e);
		}
	}
	render() {
		const { className, data, clickable, children } = this.props;
		return (
			<span onClick={this.handleClick}
				className={classNames(
					"col-data"
					, className
					, {"clickable": !!clickable}
				)}
			>
				{data}{children}
			</span>
		);
	}
}

const ExpandableCol = ({ className, data, expanded }) => (
	<ColData className={className} data={data}>
		&nbsp;<span className={getChevronIcon(expanded)} />
	</ColData>
);

export const KeyCol = ({ expandable, ...props }) => {
	if (!expandable) {
		return <ColData {...props} className="no-expand" />;
	}
	return <ExpandableCol {...props} expanded={expandable.expanded} />;
};

function Expander({ column, isExpanded, original }) {
	const { expand } = original;
	let expandable, value = original;
	$.each(column.accessor, (i, v) => {
		value = value[v];
	});
	if (typeof expand !== "undefined") {
		if (isExpanded) {
			expandable = TABLE_EXPAND_TRUE;
		} else {
			expandable = TABLE_EXPAND_FALSE;
		}
	}
	return <KeyCol data={value} expandable={expandable} />;
}

function arrayOfGroup(columns) {
	let groups = [];
	$.each(columns, (i, { id, isGroup }) => {
		if (!isGroup) {
			return false;
		}
		groups.push(id);
	});
	return groups;
}

function canClickStatus(expand, hasSubGroup, cellValue) {
	const isExpandableColumn = hasSubGroup && !!expand;
	let cursor, cellClick;

}

function handleTdProps(state, original, rowIndex, { hasSub, expander, colIndex, id }, instance) {
	if (!original) {
		return emptyObject;
	}
	const value = original[id]
		, isNonGroupCell = !hasSub && !expander
		, props = {
			onClick: (event, handleOriginal) => {
				if (hasSub && handleOriginal) {
					handleOriginal();
				}
				if (isNonGroupCell) {
					instance.props.onCellClick([
						event
						, hasSub
						, rowIndex
						, colIndex
						, id
						, value
					]);
				}
			}
		}
		;
	if (isNonGroupCell && value) {
		props.style = POINTER_CURSOR;
	} else if (!original.expand) {
		props.style = POINTER_UNSET;
	}
	return props;
}

function tdProps(state, rowInfo, column, instance) {
	const { original, index } = rowInfo;
	return handleTdProps(
		state
		, original
		, index
		, column
		, instance
	);
}

function withWidthMeasurement(Component) {
	return ({ onResize, style, ...props }) => (
		<AutoSizer disableHeight onResize={onResize}>
		{({ width }) => {
			const widthStyle = {width: width + "px"};
			if (!style) {
				style = widthStyle;
			} else {
				style = update(style, {$merge: widthStyle});
			}
			return <Component {...props} style={style} />;
		}}
		</AutoSizer>
	);
}

const Table = withWidthMeasurement(ReactTableDefaults.TableComponent);

const RowGroup = withUnmountWhenHidden(ReactTableDefaults.TrGroupComponent);

const FooterRow = withUnmountWhenHidden(ReactTableDefaults.TfootComponent);

function reportSortAttribute(extra) {
	if (!extra) {
		return;
	}
	return extra.sorted;
}

const CELL_HEADER_ORGANIZATION = I("Organization");
const CELL_HEADER_WARNINGS = I("Warnings");
const CELL_HEADER_EXPIRED = I("Expired");
const CELL_HEADER_TOTALERRANDS = I("Total errands");
const CELL_STYLE_COLOR_WARNINGS = "#ffaf11";
const CELL_STYLE_COLOR_EXPIRED = "#f4213f";

// To extract the number from a string
const extractNumber = (str) => {
	const number = str.match(/\d+/);
	return number ? parseInt(number[0]) : 0;
};

// To extract the unit from a string
const extractUnit = (str) => {
	const unit = str.match(/[a-zA-Z]+/);
	return unit ? unit[0] : '';
};

const convertUnitToNumber = (unit) => {
	switch (unit.toLowerCase()) {
		case 'year':
		case 'years':
			return 4;
		case 'month':
		case 'months':
			return 3;
		case 'day':
		case 'days':
			return 2;
		case 'hour':
		case 'hours':
			return 1.5;
		case 'minute':
		case 'minutes':
			return 1;
		default:
			return 0;
	}
};

const compareNumberAndUnit = (aData, bData) => {
	if (aData.unit !== bData.unit) {
		return aData.unit > bData.unit ? 1 : -1;
	} else {
		return aData.number > bData.number ? 1 : (aData.number < bData.number ? -1 : 0);
	}
};

// Check if the string is a date in the format YYYY/MM/DD HH:MM:SS
const isDateString = (str) => {
	return /\d{4}\/\d{2}\/\d{2} \d{2}:\d{2}:\d{2}/.test(str);
};

const parseDateString = (str) => {
	return new Date(str.replace(/\//g, '-'));
};

class ReportReactTable extends React.PureComponent {
	constructor(props) {
		super(props);
		this.subComponentRenderer = this.subComponentRenderer.bind(this);
		this.handleCellClick = this.handleCellClick.bind(this);
		this.handleExpandedChange = this.handleExpandedChange.bind(this);
		this.handbleSubTableCellClick = this.handbleSubTableCellClick.bind(this);
		this.getTableProps = this.getTableProps.bind(this);
		this.getTrGroupProps = this.getTrGroupProps.bind(this);
		this.headerCellProps = this.headerCellProps.bind(this);
		this.footerProps = this.footerProps.bind(this);
		this.onResizeProp = {onResize: this.handleTableResize.bind(this)};
		this.defaultSorted = [];
		if (this.props.expandLevel === 0) {
			const sorted = reportSortAttribute(this.props.extra);
			if (sorted) {
				this.defaultSorted = sorted;
			}
		}
		this.state = {
			expanded: {}
			, width: 0
		};
	}
	handleCellClick(data) {
		const { parentRowIndex, onClick } = this.props;
		if (typeof onClick === "function") {
			let parentRowIndices;
			if (typeof parentRowIndex === "undefined") {
				parentRowIndices = [];
			} else {
				parentRowIndices = [parentRowIndex];
			}
			onClick(parentRowIndices, data);
		}
	}
	handleExpandedChange(expanded) {
		this.setState({expanded});
	}
	handbleSubTableCellClick(parentRowIndices, data) {
		const { parentRowIndex, onClick } = this.props;
		if (typeof onClick === "function") {
			if (typeof parentRowIndex !== "undefined") {
				parentRowIndices.unshift(parentRowIndex);
			}
			onClick(parentRowIndices, data);
		}
	}
	subComponentRenderer({ index, original }) {
		const { expand } = original;
		if (typeof expand === "undefined") {
			return null;
		}
		const {
				className
				, data
				, expandLevel
				, ...props
			} = this.props
			;
		return (
			<ReportReactTable {...props}
				className={classNames(className, {expand: expandLevel <= 0})}
				data={expand}
				expandLevel={expandLevel+1}
				onClick={this.handbleSubTableCellClick}
				parentRowIndex={index}
			/>
		);
	}
	handleTableResize({ width }) {
		this.setState({width});
	}
	getTableProps() {
		return this.onResizeProp;
	}
	getTrGroupProps(state, rowInfo, columnInfo, instance) {
		const { original, index } = rowInfo
			, { isGroupTotal, isTotal } = original
			;
		return {hidden: isTotal || (isGroupTotal && !this.state.expanded[isGroupTotal.index])};
	}
	headerCellProps(state, rowInfo, column) {
		if(this.props.id.id == SR_ORG_OVERVIEW){
			if(column.Header == CELL_HEADER_ORGANIZATION) {
				return { style: TABLE_TITLE_HEADER_STYLE }
			}else{
				return { style: TABLE_HEADER_STYLE }
			}
		} else if(this.props.id.id == SR_LEADERBOARD) {
			return { className: "arrow-style", style: TABLE_HEADER_STYLE };
		}
		return { style: TABLE_HEADER_STYLE };
	}
	footerProps() {
		return {hidden: this.props.expandLevel > 0};
	}
	customSortMethod(a, b, desc) {
		// Handling empty value
		if ((!a || a === '') && b) {
			return -1;
		} else if ((!b || b === '') && a) {
			return 1;
		} else if ((!a || a === '') && (!b || b === '')) {
			return 0;
		}

		// Handle date strings
		if (isDateString(a) && isDateString(b)) {
			const dateA = parseDateString(a);
			const dateB = parseDateString(b);

			if (dateA > dateB) return 1;
			if (dateA < dateB) return -1;
			return 0;
		}

		// When both strings contain "ago"
		if ((typeof a === 'string' && a.includes('ago')) && (typeof b === 'string' && b.includes('ago'))) {
			const aNumber = extractNumber(a);
			const bNumber = extractNumber(b);
			const aUnit = convertUnitToNumber(extractUnit(a));
			const bUnit = convertUnitToNumber(extractUnit(b));
			const aData = { number: aNumber, unit: aUnit };
			const bData = { number: bNumber, unit: bUnit };

			const result = compareNumberAndUnit(aData, bData);
			return result;
		}
		// Default sorting for other cases
		const result = a > b ? 1 : (a < b ? -1 : 0);
		return desc ? result * -1 : result;
	}
	render() {
		const { data, header } = this.props.data
			, cn = classNames(
				this.props.className
			)
			, [
				columnParts
				, hasSubgroup
			] = getColumnPartSizeAndCheckForSubgroup(header)
			, convertedHeader = convertHeaderFormatBoostrapToReactTable(
				header
				, this.state.width
				, this.props.extra
				, columnParts
			)
			;
		let subComponentRenderer;
		if (hasSubgroup) {
			subComponentRenderer = this.subComponentRenderer;
		}
		return (
			<ReactTable
				className={cn}
				column={columnDefaults}
				columns={convertedHeader}
				data={data}
				defaultSorted={this.defaultSorted}
				expanded={this.state.expanded}
				expanderDefaults={expanderDefaults}
				getTableProps={this.getTableProps}
				getTrGroupProps={this.getTrGroupProps}
				getTdProps={tdProps}
				getTheadThProps={this.headerCellProps}
				getTfootProps={this.footerProps}
				onCellClick={this.handleCellClick}
				onExpandedChange={this.handleExpandedChange}
				pageSize={data.length}
				showPagination={false}
				SubComponent={subComponentRenderer}
				TableComponent={Table}
				TrGroupComponent={RowGroup}
				TfootComponent={FooterRow}
				defaultSortMethod={this.customSortMethod}
			/>
		);
	}
}

export default ReportReactTable;

function checkColumnFontColor (col, rowInfo) {
	if(rowInfo){
		let rows = rowInfo.original;
		if(rows.group0){
			if(col && col.Header == CELL_HEADER_WARNINGS){
				return CELL_STYLE_COLOR_WARNINGS;
			}else if (col && col.Header == CELL_HEADER_EXPIRED){
				return CELL_STYLE_COLOR_EXPIRED;
			}
		}
	}else{
		if(col && col.Header == CELL_HEADER_WARNINGS){
			return CELL_STYLE_COLOR_WARNINGS;
		}else if (col && col.Header == CELL_HEADER_EXPIRED){
			return CELL_STYLE_COLOR_EXPIRED;
		}
	}
	return null;
}

function checkColumnFontWeight (col, rowInfo, isGroup) {
	if(rowInfo){
		let rows = rowInfo.original;
		if(rows.group0){
			if((col && col.Header == CELL_HEADER_WARNINGS)
				|| (col && col.Header == CELL_HEADER_EXPIRED)
				|| (col && col.Header == CELL_HEADER_TOTALERRANDS)
			){
				return "bold";
			}else if(isGroup && (col.Header == CELL_HEADER_ORGANIZATION)){
				return "700";
			}
		}
	}else{
		if((col && col.Header == CELL_HEADER_WARNINGS)
			|| (col && col.Header == CELL_HEADER_EXPIRED)
			|| (col && col.Header == CELL_HEADER_TOTALERRANDS)
		){
			return "bold";
		}else if(isGroup && (col.Header == CELL_HEADER_ORGANIZATION)){
			return "700";
		}
	}
	return null;
}

function checkTextAlign (col, isGroup) {
	if(isGroup){
		return "left";
	}
	return null;
}

function checkFontSize (col, rowInfo) {
	if(!rowInfo){
		if (col && col.Header == CELL_HEADER_TOTALERRANDS){
			return "20px";
		}
	}
	return null;
}

function convertHeaderFormatBoostrapToReactTable(cols, tableWidth, { totalColumn }, columnParts) {
	let groups = []
		, keys = []
		, totalRemain = 0
		, totalWidth = 0
		;
	$.each(cols, (i, { id, isGroup, groupHeaders, name }) => {
		const o = {
				Header: name
				, colIndex: i
				, id
				, getProps: (state, rowInfo, column) => {
					//Specifying custom table cell style
					return {
						style: {
							fontWeight: checkColumnFontWeight(column, rowInfo, isGroup),
							color: checkColumnFontColor(column, rowInfo),
							textAlign: checkTextAlign(column, isGroup),
							fontSize: checkFontSize(column, rowInfo)
						},
					};
				}
			}
			;
		if (tableWidth) {
			if (isLastColumn(i, cols.length)) {
				o.width = tableWidth - totalWidth;
			} else {
				let [ width, remain ] = calcHeaderWidth(
						tableWidth
						, columnParts[i]
						, totalColumn
					)
					;
				totalRemain += remain;
				if (totalRemain >= totalColumn) {
					width++;
					totalRemain -= totalColumn;
				}
				totalWidth += width;
				o.width = width;
			}
		}
		if (isGroup) {
			o.expander = true;
			o.accessor = [id, NAME];
			o.Expander = Expander;
			if (isGroup.hasSub) {
				o.hasSub = true;
			}
			groups.push(o);
		} else {
			o.accessor = id;
			keys.push(o);
		}
	});
	return createReactTableColumns(groups, keys);
}

function isLastColumn(index, columnSize) {
	return index === columnSize - 1;
}

function calcHeaderWidth(tableWidth, numberOfParts, totalColumn) {
	if (!tableWidth) {
		return;
	}
	const partsTimesWidth = numberOfParts*tableWidth;
	return [
		Math.floor(partsTimesWidth/totalColumn)
		, partsTimesWidth%totalColumn
	];
}

function createGroupHeaders(groups, keys) {
	return [
		{
			Header: "Groups"
			, columns: groups
		}
		, {
			Header: "Keys"
			, columns: keys
		}
	];
}

function createNoGroupHeader(groups, keys) {
	return groups.concat(keys);
}

const createReactTableColumns = createNoGroupHeader

function getColumnPartSizeAndCheckForSubgroup(headers) {
	let columnParts = [], hasSubgroup;
	$.each(headers, (i, { isGroup, groupHeaders }) => {
		if (!isGroup || !groupHeaders || !groupHeaders.length) {
			columnParts[i] = 1;
		} else {
			columnParts[i] = getNumberOfSmallestPart(groupHeaders);
			if (!hasSubgroup) {
				hasSubgroup = true;
			}
		}
	});
	return [columnParts, hasSubgroup];
}

function getNumberOfSmallestPart(headers) {
	if (!headers || !headers.length) {
		return 1;
	}
	let total = 0;
	$.each(headers, (i, { groupHeaders }) => {
		total += getNumberOfSmallestPart(groupHeaders);
	});
	return total;
}

function withTableCellClickHandler(Component) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClick = this.handleClick.bind(this);
		}
		handleClick(parentTables, cellData) {
			const [
					event
					, isGroup
					, rowIndex
					, columnIndex
					, key
					, value
				] = cellData
				;
			if (isGroup) {
				return;
			}
			let { data, header } = this.props.data
				, ids = []
				, groups = []
				;
			$.each(parentTables, (i, v) => {
				const dataRow = data[v]
					, {
						data: expandData
						, header: expandHeader
					} = dataRow.expand
					;
				insertGroupIds(ids, groups, header, dataRow);
				data = expandData;
				header = expandHeader;
			});
			insertGroupIds(ids, groups, header, data[rowIndex]);
			ids.push(key);
			this.props.onClick(
				{
					groups: convertArrayGroupToMap(groups)
					, key
				}
				, parentTables
				, cellData
				, ids
				, value
			);
		}
		render() {
			const { onClick, ...props } = this.props;
			return <Component {...props} onClick={this.handleClick} />;
		}
	}
}

function convertArrayGroupToMap(groups) {
	if (!groups.length) {
		return null;
	}
	let map = {};
	$.each(groups, (i, { id, value }) => {
		map[id] = value + ""; // force it to be string as backend expected that
	});
	return map;
}

function insertGroupIds(ids, groups, header, dataRow) {
	$.each(header, (i, { id, isGroup }) => {
		if (!isGroup) {
			return false;
		}
		const row = dataRow[id];
		ids.push(row);
		if (!row.isTotal) {
			groups.push({id, value: row.id});
		}
	});
	return [ids, groups];
}

const ExpandableTableEntry = ({ tag: Tag, ...props }) => (
	<Tag {...props} expandLevel={0} />
);

export const ExpandableTable = withTableCellClickHandler(ExpandableTableEntry);
