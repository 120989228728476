import React, { memo, useState } from 'react'
import PropTypes from 'prop-types'
import { withProps } from 'recompose'
import styled from 'styled-components'
import {
  AUTH_MICROSOFT_OAUTH2,
  TXT_AUTHORIZED_OPTION,
  TXT_NO_ACCESS_TOKEN,
  authorizationOption
} from '../common/constants'
import { inputDoubleSpace, inputSpace, intentSpace } from '../styles/_variables'
import { composeWithDisplayName, withUnmountWhenHidden } from './hocs'
import { SimpleNormSingleSelect } from './Dropdown'
import { RemovableSelectedItem } from './Input'

const columnMarginTop = `
  > * + * {
    margin-top: ${inputSpace};
  }
`
const StyledDiv = styled.div`
  display: flex;
  flex-direction: ${({ row }) => row ? 'row' : 'column'};
  ${({ row }) => row ? '' : columnMarginTop}
  > *:not(:first-of-type) {
    padding-left: ${({ row }) => row ? inputDoubleSpace : intentSpace};
  }
`
const AuthorizationBase = ({ component: Component, title, ...props }) => (
  <Component title={title} {...props} />
)

const Authorization = composeWithDisplayName(
  'Authorization',
  memo,
  withProps(({ auth, components }) => ({
    component: components[auth],
    title: authorizationOption[auth]
  }))
)(AuthorizationBase)

const SingleSelectAuth = styled(SimpleNormSingleSelect)`
  &.c3-dropdown {
    a:hover, a:focus {
      text-decoration: none;
    }
  }
`
export const ControlAuthorizationSelect = ({
  selected,
  components,
  constants,
  disabled,
  oldVersion,
  onChangeName,
  onChangeSecure,
  onChangeServer,
  onChangeServerPort,
  onChangeToken,
  onChangeUsername,
  onPopAlert,
  onSelect,
  option,
  provider,
  row
}) => (
  <StyledDiv row={row}>
    <SingleSelectAuth
      data={option}
      disabled={option.allIds.length <= 1 || disabled}
      oldVersion={oldVersion}
      onSelect={onSelect}
      selected={selected}
    />
    <Authorization
      auth={selected}
      components={components}
      constants={constants}
      disabled={disabled}
      hasAccessToken={provider === selected}
      oldVersion={oldVersion}
      onChangeName={onChangeName}
      onChangeSecure={onChangeSecure}
      onChangeServer={onChangeServer}
      onChangeServerPort={onChangeServerPort}
      onChangeToken={onChangeToken}
      onChangeUsername={onChangeUsername}
      onPopAlert={onPopAlert}
    />
  </StyledDiv>
)

const AuthorizationSelect = ({ option, selected, onSelect, ...props }) => {
  // const [auth, setAuth] = useState(option.allIds[0])
  return (
    <ControlAuthorizationSelect
      selected={selected}
      onSelect={onSelect}
      option={option}
      {...props }
    />
  )
}

export default AuthorizationSelect

AuthorizationSelect.propTypes = {
  components: PropTypes.objectOf(PropTypes.elementType),
  option: PropTypes.shape({
    byId: PropTypes.objectOf(PropTypes.string),
    allIds: PropTypes.arrayOf(PropTypes.number)
  }),
  provider: PropTypes.number, // undefined mean no live authorized token.
  row: PropTypes.bool // select and authorization in same row
}

const AuthorizedTokenBase = ({
  disabled,
  hasAccessToken,
  onClear,
  provider
}) => (
  <RemovableSelectedItem
    disabled={disabled}
    name={hasAccessToken ? TXT_AUTHORIZED_OPTION.replace('{AUTHORIZATION_OPTION}', authorizationOption[provider]) : ''}
    onClick={onClear}
    textNoItem={TXT_NO_ACCESS_TOKEN}
  />
)

export const AuthorizedToken = composeWithDisplayName(
  'AuthorizedToken',
  memo,
  withUnmountWhenHidden
)(AuthorizedTokenBase)

export const justAuthorizedTokenProvider = (accessToken, provider) => {
  if (accessToken) {
    return provider
  }
}
