import React, { useMemo } from 'react'
import each from 'lodash/each'
import keys from 'lodash/keys'
import { IDTYPE_DOMAIN, IDTYPE_EMAIL, emptyArray } from '../common/constants'
import {
  TXT_DKIM_TOKENS,
  TXT_DISABLED,
  TXT_IDENTITY,
  TXT_IDENTITY_TYPE,
  TXT_STATUS,
  TXT_VERIFIED
} from '../common/texts'
import {
  TXT_BEHAVIOUR_ON_MX_FAIL,
  TXT_MAIL_FROM_DOMAIN,
  TXT_MAIL_FROM_DOMAIN_MX_RECORD,
  TXT_MAIL_FROM_DOMAIN_STATUS,
  TXT_MAIL_FROM_DOMAIN_TXT_RECORD,
  getMailFromMX,
  getMailFromTXT,
  tokenToCNAME
} from '../common/v5/smtpConstants'
import { useRegionParam } from '../hooks/state'
import Table from './Table'

const identityDetailColumns = [
  { accessor: 'key' },
  { accessor: 'value' }
]

const identityDetailMapper = {
  dkimStatus: TXT_STATUS
}

const identityEmailDetailMapper = {
  id: TXT_IDENTITY,
  type: TXT_IDENTITY_TYPE,
  verified: TXT_VERIFIED
}

const addToResult = (map, _key, object, result) => {
  const key = map[_key]
  if (typeof key !== 'undefined') {
    result.push({ key, value: `${object[_key]}` })
    return true
  }
  return false
}

const addMailFrom = (key, object, result, region) => {
  if (key === 'behaviorOnMxFailure' || key === 'mailFromDomainStatus') {
    return true
  } else if (key !== 'mailFromDomain') {
    return false
  } else if (!object.mailFromDomain) {
    result.push({ key: TXT_MAIL_FROM_DOMAIN, value: TXT_DISABLED })
    return true
  }
  const {
    behaviorOnMxFailure,
    [key]: mailFromDomain,
    mailFromDomainStatus
  } = object
  result.push({ key: TXT_MAIL_FROM_DOMAIN, value: mailFromDomain })
  result.push({
    key: TXT_MAIL_FROM_DOMAIN_MX_RECORD,
    value: getMailFromMX(region, mailFromDomain)
  })
  result.push({
    key: TXT_MAIL_FROM_DOMAIN_TXT_RECORD,
    value: getMailFromTXT(mailFromDomain)
  })
  result.push({
    key: TXT_MAIL_FROM_DOMAIN_STATUS,
    value: mailFromDomainStatus
  })
  result.push({
    key: TXT_BEHAVIOUR_ON_MX_FAIL,
    value: behaviorOnMxFailure
  })
  return true
}

const domainObjectToData = (awssesDKIMDomain, keys, object, result, region) => {
  each(keys, k => {
    if (addMailFrom(k, object, result, region)) {
      return
    } else if (addToResult(identityEmailDetailMapper, k, object, result)) {
      return
    }
    if (k === 'dkimTokens') {
      const value = object[k]
      if (value.length) {
        each(value, (v, index) => {
          result.push({
            key: `${TXT_DKIM_TOKENS} (${index + 1})`,
            value: tokenToCNAME(object.id, awssesDKIMDomain, v)
          })
        })
      }
    } else {
      addToResult(identityDetailMapper, k, object, result)
    }
  })
}

const emailObjectToData = (keys, object, result, region) => {
  each(keys, k => {
    if (addMailFrom(k, object, result, region)) {
      return
    }
    addToResult(identityEmailDetailMapper, k, object, result)
  })
}

const useObjectAsData = (object, awssesDKIMDomain, region) => useMemo(() => {
  if (!object) {
    return emptyArray
  }
  const result = []
  const ks = keys(object).sort()
  if (object.type === IDTYPE_DOMAIN) {
    domainObjectToData(awssesDKIMDomain, ks, object, result, region)
  } else if (object.type === IDTYPE_EMAIL) {
    emailObjectToData(ks, object, result, region)
  }
  if (!result.length) {
    return emptyArray
  }
  return result
}, [awssesDKIMDomain, object, region])

const AWSSESIdentityDetailTable = ({ awssesDKIMDomain, data, ...props }) => (
  <Table
    columns={identityDetailColumns}
    data={useObjectAsData(data, awssesDKIMDomain, useRegionParam())}
    noHeader
    {...props}
  />
)

export default AWSSESIdentityDetailTable
