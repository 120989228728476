// Component for showing audio input list.
import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { I } from '../common/globals'
import { doNothing } from '../common/constants'
import { useShowAndHide } from '../hooks/toggle'
import { Body } from './AlertConfirm'
import { ReactSingleSelect, useAutoSelect } from './Select'

const TXT_SELECT_AUDIO_INPUT = I('Please select an audio input device.')

const checkSelectedAuto = ({ id }) => id !== 'default'

const AudioInputs = ({ data, onSelect, onTrigger, selected }) => {
  const lastPopupRef = useRef(null)
  const [newSelected, setNewSelected] = useState(selected)
  const [saveSelection, setSaveSelection] = useState(false)
  const handleSelect = useCallback(selected => { setNewSelected(selected) }, [])
  const [show, onShow, onHide] = useShowAndHide()
  const [dataChanged, setDataChanged] = useState(false)
  const handleAutoSelect = useCallback(selected => {
    setNewSelected(selected)
    onSelect(selected)
  }, [onSelect])
  const validData = data && data.length
  useEffect(() => {
    if (saveSelection) {
      setSaveSelection(false)
      onSelect(newSelected)
    }
  }, [saveSelection, newSelected, onSelect])
  useEffect(() => {
    if (data && data.length) {
      setDataChanged(true)
    } else {
      setDataChanged(false)
      if (lastPopupRef && lastPopupRef.current) {
        lastPopupRef.current()
      }
    }
  }, [data, lastPopupRef])
  useEffect(() => {
    if (validData && dataChanged && !show) {
      setDataChanged(false)
      onShow()
      const p = onTrigger(TXT_SELECT_AUDIO_INPUT)
      if (p && typeof p.close === 'function') {
        lastPopupRef.current = p.close
      }
      p.then(() => { setSaveSelection(true) })
        .catch(doNothing)
        .then(() => {
          lastPopupRef.current = null
          onHide()
        })
    }
  }, [dataChanged, lastPopupRef, onHide, onShow, onTrigger, show, validData])
  useAutoSelect(handleAutoSelect, data, selected, checkSelectedAuto)
  return (
    <Body hidden={!show}>
      <ReactSingleSelect
        data={data}
        onSelect={handleSelect}
        selected={newSelected}
      />
    </Body>
  )
}

AudioInputs.propTypes = {
  onTrigger: PropTypes.func // must return promise, eg: optionalConfirm
}

export default AudioInputs
