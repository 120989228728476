import React from 'react'
import { I } from '../common/globals'

// TODO: this component seem useless because any chat service will not use
// errand view and thus this component will not be renderred.
const ClientSatisfactionMeter = ({ csmData: csm }) => (
  <div className='chat-satisfaction'>
    <span className='feedback-container'>
      <span className='feedback-rating'>
        <label className='rating'>{I('Rating: ')}</label>
        <span className='satisfaction-icon'>
          {csm.rating === 1 ? <i className='fa fa-thumbs-up' /> : null}
          {csm.rating === 0 ? <i className='fa fa-thumbs-down' /> : null}
        </span>
      </span>
      {csm.message !== '' && csm.message.length > 0 &&
        <span className='feedback-comment'>
          <label className='comment'>{I('Comment: ')}</label>
          <span className='text'>{csm.message}</span>
        </span>}
    </span>
  </div>
)

export default ClientSatisfactionMeter
