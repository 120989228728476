import React, { PureComponent, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import classNames from 'classnames';
import styled from 'styled-components';
import each from 'lodash/each';
import {
	branch
	, compose
	, mapProps
	, renderComponent
	, renderNothing
} from 'recompose';
import { I } from '../common/globals';
import { emptyArray, emptyObject } from '../common/v5/constants';
import {
	AGS_ASSIGNING
	, AGS_INACTIVE
	, CALL_NOT_READY
	, RS_FAILED_SERVER
	, RS_NO_SAFARI
	, RS_STPD_BRWSR
	, SCS_CALL_WIP
	, SCS_INCOMING_CALL
	, SCS_NOT_READY_OR_ERROR
	, TM_AGENT_ID
	, TM_PHONE_NUMBER
	, TXT_AVAILABLE
	, TXT_AWAY
	, TXT_BUSY
	, TXT_CONNECT_COLON
	, TXT_ID_COLON
	, TXT_NAME_COLON
	, TXT_NO_CALL
	, TXT_STATUS_COLON
	, TXT_STATUS_ID_COLON
	, TXT_UNKNOWN
} from '../common/v5/callConstants';
import useObservable from '../hooks/observable';
import {
	withOverwriteProps
	, withRandomProp
	, withTestValue
} from './hocsForTests';
import {
	createWithMountWhenPropTrue
	, createWithToggleStateOnClick
	, withDisableableOnClick
	, withIdAttachedOnClick
	, withUnmountWhenHidden
} from './hocs';
import Anchor from './Anchor';
import AudioInputs from './AudioInputs';
import ButtonGroup from './ButtonGroup';
import { DropDownSelectBox, createWithMultiNormalized } from './SelectBox';
import ProfilePhoto from './ProfilePhoto';
import {
	centionGreen
	, centionRed
 } from '../styles/_variables'

const baseClassName = "c3-callpad"
	, headerClassName = "callpad-header"
	, footerClassName = "callpad-footer"
	, emptyAgentDetail = {id: 0, name: "", connect: "", status: "", statusId: 0}
	, forwardeeSelectedGetter = (_, index) => index
	, multi = [
		{
			onSelect: "onSelect"
			, options: "options"
			, order: "order"
			, selected: "selected"
		}
		, {
			onSelect: "onSelectForwardee"
			, options: "forwardeeOptions"
			, order: "forwardeeOrder"
			, selected: "selectedForwardee"
			, selectedGetter: "forwardeeSelectedGetter"
		}
	]
	, indexToTransferMethod = [
		TM_AGENT_ID
		, TM_PHONE_NUMBER
	]
	;
const Div = ({ children, className, ...props }) => (
	<div className={className} {...props}>{children}</div>
);

const Wrapper = ({ children, className, ...props }) => (
	<Div className={classNames(baseClassName, className)} {...props}>
		{children}
	</Div>
);

const withBlock = Component => ({ className, ...props }) => (
	<Div className={className}><Component {...props} /></Div>
);

const TextBase = ({ children }) => <span className="text">{children}</span>;

const withHideableText = compose(
	mapProps(({ hidden, ...props }) => ({
		hidden: !props.children || hidden
		, ...props
	}))
	, withUnmountWhenHidden
);

const withHideableBlockText = compose(withHideableText, withBlock);

const withIcon = TextComponent => ({
	children
	, className
	, hideText
	, icon
	, ...props
}) => (
	<Div className={className}>
		<i
			className={icon}
			title={typeof children === "string" ? children : ""}
		/>
		<TextComponent hidden={hideText}>{children}</TextComponent>
	</Div>
);

const withIconOrText = branch(
	({ icon }) => !!icon
	, compose(withIcon, withHideableText)
	, withHideableBlockText
);

const IconStackDangerBase = ({ icon, text }) => (
	<span className="fa-stack fa-2x" aria-hidden="true" title={text}>
		<i className={icon} />
		<i className="fa fa-ban fa-stack-2x text-danger" />
	</span>
);

const IconStackDanger = ({ icon, ...props }) => (
	<IconStackDangerBase
		{...props}
		icon={classNames("fas", icon, "fa-stack-1x")}
	/>
);

const FailedServer = ({ text }) => (
	<IconStackDanger icon="fa-server" text={text} />
);

const NoIE = ({ text }) => (
	<IconStackDanger icon="fa-internet-explorer" text={text} />
);

const NoSafari = ({ text }) => (
	<IconStackDanger icon="fa-safari" text={text} />
);

const mapTypeSpecialRender = {
	[CALL_NOT_READY]: {
		[RS_FAILED_SERVER]: FailedServer
		, [RS_NO_SAFARI]: NoSafari
		, [RS_STPD_BRWSR]: NoIE
	}
};

const withSpecialIcon = TextComponent => ({
	className
	, hideText
	, ...props
}) => {
	const { children, icon } = props
		, { type, special } = icon
		, Renderer = mapTypeSpecialRender[type][special]
		;
	return (
		<Div className={className}>
			<Renderer text={children} {...props} />
			<TextComponent hidden={hideText}>{children}</TextComponent>
		</Div>
	);
};

const Header = 	branch(
	({ icon }) => icon && typeof icon === "object"
	, compose(withSpecialIcon, withHideableText)
	, withIconOrText
)(TextBase);

const Footer = withIconOrText(TextBase);

const ProfileBase = ({ data, ...props }) => {
	const { avatar, name, number } = data;
	let info, tooltip = number;
	if (name) {
		info = name;
		if (!tooltip) {
			tooltip = name;
		}
	} else if (number) {
		info = number;
	} else {
		info = TXT_UNKNOWN;
	}
	return (
		<div className="profile">
			<ProfilePhoto isAgent={false} photo={avatar} {...props} />
			<div className="profile-info" title={tooltip}>{info}</div>
		</div>
	);
};

const Profile = branch(
	({ data }) => !data
	, renderNothing
)(ProfileBase);

const Span = ({ title, value }) => <span>{title + " " + value}</span>;

const OtherAgentStatusDetail = ({ data }) => (
	<div>
		<Span title={TXT_ID_COLON} value={data.id} /><br />
		<Span title={TXT_NAME_COLON} value={data.name} /><br />
		<Span title={TXT_CONNECT_COLON} value={data.connect} /><br />
		<Span title={TXT_STATUS_COLON} value={data.status} /><br />
		<Span title={TXT_STATUS_ID_COLON} value={data.statusId} /><br />
	</div>
);

const SimpleAgentStatus = ({ name, status }) => (
	<div>{name + " is " + status}</div>
);

const DbgAvailableAgentsBase = ({ data, selected }) => {
	const all = [];
	let current = emptyAgentDetail;
	each(data, v => {
		const { id, name, status } = v;
		if (selected == id) {
			current = v;
		}
		all.push(<SimpleAgentStatus key={id+""} name={name} status={status} />);
	});
	return (
		<div>
			<OtherAgentStatusDetail data={current} />
			{all}
		</div>
	);
};

const DbgAvailableAgents = withUnmountWhenHidden(DbgAvailableAgentsBase);

const DivSelection = ({ children }) => <div className="selection">{children}</div>;

const NoSelectionData = ({ textNoItem }) => (
	<DivSelection><span>{textNoItem}</span></DivSelection>
);

const SelectedInfoBase = ({ text }) => <span>&nbsp;&nbsp;{text}</span>;

const SelectedInfo = createWithMountWhenPropTrue("text")(SelectedInfoBase);

const SelectionBase = ({
	forwardeeOptions
	, forwardeeOrder
	, forwardeeSelectedGetter
	, multi
	, onSelect
	, onSelectForwardee
	, options
	, order
	, selected
	, selectedForwardee
	, selectedGetter
	, textNoItemSelected
	, transferMethod
}) => {
	// const handleSelect = useCallback(
	// 	(id, data, index) => {
	// 		const handlerMap = [
	// 			onSelect
	// 			, onSelectForwardee
	// 		];
	// 		handlerMap[index](id, data, indexToTransferMethod[index])
	// 	}
	// 	, [onSelect, onSelectForwardee]
	// );
	let info = "";
	if (transferMethod === TM_AGENT_ID) {
		const value = options[selected];
		if (typeof value !== "undefined") {
			if (value.statusId == AGS_ASSIGNING) {
				info = TXT_BUSY;
			} else if (value.statusId == AGS_INACTIVE) {
				info = TXT_AWAY;
			} else {
				info = TXT_AVAILABLE;
			}
		}
	}
	return (
		<div>
			<DropDownSelectBox
				forwardeeOptions={forwardeeOptions}
				forwardeeOrder={forwardeeOrder}
				forwardeeSelectedGetter={forwardeeSelectedGetter}
				multi={multi}
				onSelect={onSelect}
				// onSelect={handleSelect}
				onSelectForwardee={onSelectForwardee}
				options={options}
				order={order}
				selected={selected}
				selectedForwardee={selectedForwardee}
				selectedGetter={selectedGetter}
				textNoItemSelected={textNoItemSelected}
			/>
			<SelectedInfo text={info} />
		</div>
	);
};

const withWrapperAndAgentsDetail = SelectionComponent => ({
	forwardeeOptions
	, forwardeeSelectedGetter
	, hideSelection
	, multi
	, onSelect
	, onSelectForwardee
	, options
	, selected
	, selectedForwardee
	, textNoItem
	, textNoItemSelected
	, transferMethod
}) => (
	<DivSelection>
		<SelectionComponent
			forwardeeOptions={forwardeeOptions}
			forwardeeSelectedGetter={forwardeeSelectedGetter}
			hideSelection={hideSelection}
			multi={multi}
			onSelect={onSelect}
			onSelectForwardee={onSelectForwardee}
			options={options}
			selected={selected}
			selectedForwardee={selectedForwardee}
			textNoItem={textNoItem}
			textNoItemSelected={textNoItemSelected}
			transferMethod={transferMethod}
		/>
		<DbgAvailableAgents
			hidden={true}
			others={options}
			selected={selected}
		/>
	</DivSelection>
);

const Selection = compose(
	withWrapperAndAgentsDetail
	, branch(
		({ hideSelection }) => hideSelection
		, renderNothing
		, compose(
			branch(
				({
					options
					, forwardeeOptions
				}) => (!options || !options.length)
					&& (!forwardeeOptions || !forwardeeOptions.length)
				, renderComponent(NoSelectionData)
			)
			, createWithMultiNormalized("options")
		)
	)
)(SelectionBase);

const withWebsocketOnClickAndOnEvent = Component => {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClick = this.handleClick.bind(this);
			this.handleEvent = this.handleEvent.bind(this);
		}
		handleClick(buttonType, ...args) {
			const { onClick, websocket } = this.props;
			if (typeof onClick === 'function') {
				onClick(buttonType, websocket, ...args);
			}
		}
		handleEvent(...args) {
			const { onEvent, websocket } = this.props;
			if (typeof onEvent === 'function') {
				onEvent(websocket, ...args);
			}
		}
		render() {
			const props = update(
				this.props
				, {$unset: ["onClick", "onEvent", "websocket"]}
			);
			return (
				<Component
					onClick={this.handleClick}
					onEvent={this.handleEvent}
					{...props}
				/>
			);
		}
	};
};

const Buttons = withWebsocketOnClickAndOnEvent(ButtonGroup);

const CallPadBase = ({
	buttonsCondition
	, headerIcon
	, headerText
	, hideButtons
	, hideNoButtonText
	, hideSelection
	, footerIcon
	, footerText
	, forwardeeOptions
	, onClick
	, onEvent
	, onSelect
	, onSelectForwardee
	, profile
	, selected
	, selectedForwardee
	, selectionOptions
	, textNoItem
	, textNoItemSelected
	, transferMethod
	, websocket
	, ...props
}) => (
	<Wrapper>
		<Header className={headerClassName} icon={headerIcon}>
			{headerText}
		</Header>
		<Profile data={profile} />
		<Buttons
			condition={buttonsCondition}
			hidden={hideButtons}
			hideNoButtonText={hideNoButtonText}
			noButtonText={TXT_NO_CALL}
			onClick={onClick}
			onEvent={onEvent}
			websocket={websocket}
		/>
		<Selection
			forwardeeOptions={forwardeeOptions}
			forwardeeSelectedGetter={forwardeeSelectedGetter}
			hideSelection={hideSelection}
			multi={multi}
			onSelect={onSelect}
			onSelectForwardee={onSelectForwardee}
			options={selectionOptions}
			selected={selected}
			selectedForwardee={selectedForwardee}
			textNoItem={textNoItem}
			textNoItemSelected={textNoItemSelected}
			transferMethod={transferMethod}
		/>
		<Footer className={footerClassName} icon={footerIcon}>
			{footerText}
		</Footer>
	</Wrapper>
);

const CallPad = withUnmountWhenHidden(CallPadBase);

CallPad.propTypes = {
	buttonsCondition: PropTypes.object
	, className: PropTypes.string
	, callInProgress: PropTypes.bool
	, headerIcon: PropTypes.oneOfType([
		PropTypes.string
		, PropTypes.shape({
			type: PropTypes.number
			, special: PropTypes.number
		})
	])
	, headerText: PropTypes.string
	, hidden: PropTypes.bool
	, onClick: PropTypes.func
};

export default CallPad;

function hasCallStatus(status) {
	if (status === SCS_CALL_WIP || status === SCS_INCOMING_CALL) {
		return true;
	}
	return false;
}

const StyledCallIconWithStatus = styled.span`
	&:before {
		display: ${props => props.collapsed ? "inline-block" : "none"};
		width: 11px;
		height: 11px;
		border-radius: 30px;
		background-color: #ccc;
		content: '';
		left: 20px;
		position: relative;
		top: 6px;
		transition: background-color .2s ease;
		background: ${props => props.active ? centionGreen : centionRed};
	}
`

const CallIconBase = ({ onClick, callPadPopupShown, status, forSidebar, collapsed, ...props }) => {
	let phoneIconStyle = "fas fa-2x"
	, phoneIconClass = "fa-mobile-alt" // "fa-phone"
	, phoneIconCallClass = "icon-mobile-alt-call"
	, statusText = ""
	, active = false
	;
	const hasCall = hasCallStatus(status);
	if(forSidebar) {
		phoneIconClass = "icon-phone";
		phoneIconStyle = "";
		phoneIconCallClass = "";
		if(forSidebar && !collapsed) {
			//TODO: Review this
			if(SCS_NOT_READY_OR_ERROR) {
				statusText = I("Phone disconnected");
			} else {
				statusText = I("Phone connected");
				active = true;
			}
		}
	}
	return (
		<Anchor onClick={onClick} {...props}>
			<i
				className={classNames(
					phoneIconStyle
					, {
						[phoneIconClass]: !hasCall
						, [phoneIconCallClass]: hasCall
					}
					, {
						"has-call": hasCall
						, "has-error": status === SCS_NOT_READY_OR_ERROR
						, "call-shake": status === SCS_INCOMING_CALL
					}
				)}
				aria-hidden="true"
			/>
			<span hidden={collapsed} className={classNames({active: active})}>{statusText}</span>
		</Anchor>
	);
};

const TXT_NO_VALID_AUDIO_INPUT = I('No valid audio input')

const useSelectedAudioInput = (data, selected) => useMemo(() => {
	let found
	each(data, ({ id, name }) => {
		if (selected === id) {
			found = name
			return false
		}
	})
	if (found) {
		return found
	}
	return TXT_NO_VALID_AUDIO_INPUT
}, [data, selected])

// CallIconAndAudioInputs expecting an observable audio input list.
const CallIconAndAudioInputs = ({
	audioInputListS
	, onPopAlert
	, onPopupAudioInputSelection
	, onSelectAudioInput
	, selectedAudioInput
	, ...props
}) => {
	const data = useObservable(onPopAlert, audioInputListS, emptyArray)
	let active = false;
	if(!SCS_NOT_READY_OR_ERROR) {
		active = true;
	}
	return (
		<StyledCallIconWithStatus className='c3-call-icon' active={active} collapsed={props.collapsed}>
			<CallIconBase
					title={useSelectedAudioInput(data, selectedAudioInput)}
					{...props}
				/>
				<AudioInputs
					data={data}
					onSelect={onSelectAudioInput}
					onTrigger={onPopupAudioInputSelection}
					selected={selectedAudioInput}
				/>
		</StyledCallIconWithStatus>
	)
}

export const CallIcon = compose(
	withUnmountWhenHidden
	, createWithToggleStateOnClick("callPadPopupShown")
)(CallIconAndAudioInputs);

CallIcon.propTypes = {
	callPadPopupShown: PropTypes.bool
	, hidden: PropTypes.bool
	, onClick: PropTypes.func
};
