import React from 'react';
import update from 'immutability-helper';
import AutoSuggest from 'react-autosuggest';

const getSuggestions = (value, data, fields) => {
	if(typeof value === 'undefined' || value == null){
			return [];
	}

	const inputValue = value.trim().toLowerCase();
	const inputLength = inputValue.length;
	let suggestion = inputLength === 0 ? [] : data.filter(info =>
		info[fields.value].toLowerCase().slice(0, inputLength) === inputValue
	);
	if(suggestion.length == 0){
		return [];
	}
	return suggestion;
};

class SearchFilter extends React.Component {
	constructor(props) {
		super(props);
		this.displayName = "SearchFilter";
		// Autosuggest is a controlled component.
		// This means that you need to provide an input value
		// and an onChange handler that updates this value (see below).
		// Suggestions also need to be provided to the Autosuggest,
		// and they are initially empty because the Autosuggest is closed.
		this.state = {
			value: '',
			suggestions: []
		};
	}
	renderSuggestion = suggestion => (
		<div>
			<span>{suggestion[this.props.fields.value]}</span>
		</div>
	);
	onChange = (event, { newValue, method }) => {
		if(typeof newValue !== 'undefined'){
		this.setState({
			value: newValue
		});
		} else {
		this.setState({
			value: ""
		});
		}
	}
	onSuggestionsFetchRequested = ({ value }) => {
		const p = this.props;
		var filters = [];
		$.each(p.data, function(k,v) {
			if(p.nestedSearch){
				if(v[p.nestedGroup]) {
					$.each(v[p.nestedGroup], function(i,n){
						filters = update(filters,{$push: [{[p.fields.id]: n[this.props.fields.id], [p.fields.value]: n[this.props.fields.value], parent: v[this.props.fields.id], count: v[this.props.fields.count]}]});
					}.bind(this));
				}
			}else{
				filters = update(filters,{$push: [{[p.fields.id]: v[this.props.fields.id], [p.fields.value]: v[this.props.fields.value], count: v[this.props.fields.count]}]});
			}
		}.bind(this));
		let suggestedFilters = getSuggestions(value, filters, p.fields);
		this.setState({
			suggestions: suggestedFilters
		});
		this.props.onSelectFilter(suggestedFilters, value);
	}
	onSuggestionsClearRequested = () => {
		this.setState({
			suggestions: []
		});
	}
	onSuggestionSelected = (event, { suggestion }) => {
	};
	getSuggestionValue = (suggestion) => {
		if(typeof suggestion === 'undefined' || suggestion.length == 0) {
			return this.state.value;
		}
		return suggestion[this.props.fields.value];
	}
	render() {
		const {
			altClass
		} = this.props;
		//Automatically will load only filters that specified in this input
		const { value, suggestions } = this.state;
		const inputProps = {
			placeholder: this.props.label,
			value,
			onChange: this.onChange
		};
		const renderInputComponent = inputProps => (
			<div>
				<input data-qa-id={"QA_searchFilter_"+this.props.name} {...inputProps} />
			</div>
		);
		let customClass = "search_field";
		if(typeof altClass !== 'undefined' && altClass != ""){
			customClass = altClass
		}
		return (
				<div className={customClass} hidden={this.props.hide}>
					<AutoSuggest
						suggestions={suggestions}
						onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
						onSuggestionsClearRequested={this.onSuggestionsClearRequested}
						getSuggestionValue={this.getSuggestionValue}
						renderSuggestion={this.renderSuggestion}
						inputProps={inputProps}
						alwaysRenderSuggestions={true}
						onSuggestionSelected={this.onSuggestionSelected}
						renderInputComponent={renderInputComponent} />
					<input type="hidden" name={this.props.name} id={this.props.name} value={value} placeholder={this.props.label} />
				</div>
			);
	}
}

export default SearchFilter;
