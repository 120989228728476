import React from 'react';

export class TabNav extends React.Component {
	constructor(props) {
		super(props);
	}
	render(){
		const {
			hide
			, selected
			, setSelected
			, children
		} = this.props
		;
		return(
			<div className="tabNav" hidden={hide}>
			<ul className="nav nav-tabs">
			{
				this.props.tabs.map(tab => { const active = (tab === selected ? 'active ' : '' );
					return (
					<li className="nav-item" key={ tab }>
						<a className={"nav-link " + active} onClick={ () => setSelected(tab) }>
						{ tab }
						</a>
					</li>
					);
				})
			}
			</ul>
			{ children }
			</div>
		);
	}
}

export class Tab extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		const {
			isSelected
			, children
		} = this.props
		;
	  if (isSelected) {
		return (
		  <div className="tab-section">
			{ children }
		  </div>
		);
	  }
	  return null;
	}
}