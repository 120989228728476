import React, { Children } from 'react';
import PropTypes from 'prop-types';
import { branch, compose, withProps, withPropsOnChange } from 'recompose';
import styled from 'styled-components';
import update from 'immutability-helper';
import classNames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import { UNSELECT, emptyArray, emptyObject } from '../common/v5/constants';
import { doNothing } from './common';
import {
	composeWithDisplayName
	, createHandlerAttachData
	, createWithAutoResetInvalidSelection
	, createWithCallbackSelectedData
	, createWithCheckValidHandler
	, createWithClickOutsideToggleFalse
	, createWithEffectCondition
	, createWithMountWhenPropTrue
	, createWithPropAttachedActionThenRemoveProp
	, createWithPropAttachedOnClick
	, createWithQADataId
	, createWithToggleStateCallback
	, makeAutoResetInvalidSelectionCondition
	, withIdAttachedOnClick
	, withUnmountWhenHidden
} from './hocs';

const selectCallback = "onSelect"
	, optionsProp = "options"
	;
const validMulti = multi => multi && multi.length > 0;

const isMulti = ({ multi }) => validMulti(multi);

function qaIdOrId(props, defValue) {
	const dataQAId = props["data-qa-id"];
	if (dataQAId) {
		return dataQAId;
	} else if (props.id) {
		return props.id
	} else if (defValue) {
		return defValue;
	}
	return "";
}

const SortIcon = ({ icon }) => <i className={icon} />;

const zeroPaddingFullWidth = {padding: "0px", width: "100%"};

const HrLine = () => (
	<li style={zeroPaddingFullWidth}><hr className="hr-borderline" /></li>
);

const ArrowBase = ({ className, style, ...props }) => (
	<div className={className} style={style}>
		<div
			className="jq-selectbox__trigger-arrow"
			data-qa-id={qaIdOrId(props)+"-arrow"}
		/>
	</div>
);

const Arrow = ({ className, 'data-qa-id': dataQAId, props }) => (
	<ArrowBase
		className="jq-selectbox__trigger"
		data-qa-id={dataQAId}
		{...props}
	/>
);

const wrapperClassName = "c3-dropdownselectbox"
	, baseClassName = "c3-selectbox"
	, zeroPadding = {padding: "0px"}
	, unsetLabelBlock = {width: "unset", float: "unset", position: "unset"}
	;
const createWithWrapper = (className, style) => Component => props => (
	<div className={classNames(wrapperClassName, className)} style={style}>
		<Component {...props} />
	</div>
);

const withAutoToggleAndHideOnSelect = composeWithDisplayName(
	"withAutoToggleAndHideOnSelect"
	, Component => class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				show: false
			};
			this.handleSelect = this.handleSelect.bind(this);
			this.toggle = this.toggle.bind(this);
		}
		handleSelect(...args) {
			this.props.onSelect(...args);
			this.setState({show: false});
		}
		toggle(v) {
			this.setState({show: v});
		}
		render() {
			const { onSelect, onToggleShow, show, ...props } = this.props;
			return (
				<Component
					onToggleShow={this.toggle}
					onSelect={this.handleSelect}
					show={this.state.show}
					{...props}
				/>
			);
		}
	}
);

function withSelectOneBase(IconComponent) {
	return ({ className, icon, name, onClick, tooltip }) => {
		const noIcon = !icon;
		let item;
		if (!noIcon) {
			item = name + '  ';
		} else {
			item = name;
		}
		return (
			<li
				className={className}
				data-qa-id={"select-list-"+name}
				onClick={onClick}
				title={tooltip}
			>
				{item}<IconComponent hidden={noIcon} icon={icon} />
			</li>
		);
	};
}

function withSelectOneBaseTwo(IconComponent) {
	return ({ className, icon, name, onClick, tooltip }) => {
		const noIcon = !icon;
		let item;
		if (!noIcon) {
			item = name + '  ';
		} else {
			item = name;
		}
		return (
			<li
				className={className}
				data-qa-id={"select-list-"+name}
				onClick={onClick}
				title={tooltip}
			>
				<i className="icon-folder"></i>
				{item}<IconComponent hidden={noIcon} icon={icon} />
			</li>
		);
	};
}

const withSelectOne = composeWithDisplayName(
	"withSelectOne"
	, withSelectOneBase
);

const withSelectOne2 = composeWithDisplayName(
	"withSelectOne2"
	, withSelectOneBaseTwo
);

const Icon = withUnmountWhenHidden(SortIcon);

const withSelectedItem = composeWithDisplayName(
	"withSelectedItem"
	, IconComponent => ({ children, icon, tooltip }) => {
		const noIcon = !icon;
		if (!noIcon) {
			children += ' ';
		}
		if(children === "as new errand"){
			children = I("as new errand")
		}else if(children === "in My errands"){
			children = I("in My errands")
		}else if(children === "as closed errand"){
			children = I("as closed errand")
		}
		return (
			<div className="jq-selectbox__select-text" title={tooltip}>
				{children}<IconComponent hidden={noIcon} icon={icon} />
			</div>
		);
	}
);

const SelectedItem = withSelectedItem(Icon);

const chevronStyle = {display: "inline-block", marginLeft: "10px"};

const ChevronBase = ({ style, ...props }) => (
	<ArrowBase style={chevronStyle} {...props} />
);

const Chevron = withUnmountWhenHidden(ChevronBase);

const singleOrMulti = multi => multi ? multi : [false];

const defaultName = (obj, field) => {
	if (obj && typeof obj[field] === "string") {
		return obj[field];
	}
	return field;
};

const nonEmptyName = (multi, field) => {
	if (multi) {
		const multiField = multi[field];
		if (multiField) {
			return multiField;
		}
	}
	return field;
};

const essentialPropsName = (multi, defNames) => ({
	onSelect: nonEmptyName(multi, defaultName(defNames, selectCallback))
	, options: nonEmptyName(multi, defaultName(defNames, optionsProp))
	, order: nonEmptyName(multi, defaultName(defNames, "order"))
	, selected: nonEmptyName(multi, defaultName(defNames, "selected"))
	, selectedGetter: nonEmptyName(multi, defaultName(defNames, "selectedGetter"))
});

const essentialProps = (multi, props, defNames) => {
	const names = essentialPropsName(multi, defNames);
	let selectedGetter = props[names.selectedGetter];
	if (typeof selectedGetter === "undefined") {
		selectedGetter = idSelectedGetter;
	}
	return {
		onSelect: props[names.onSelect]
		, options: props[names.options]
		, order: props[names.order]
		, selected: props[names.selected]
		, selectedGetter
	};
};

const createWithPropIfNotExist = (defaultValue, propName) => withProps(
	({ [propName]: prop }) => {
		if (typeof prop === "undefined") {
			return {[propName]: defaultValue};
		}
		return emptyObject;
	}
);

const idSelectedGetter = v => 'id' in v ? v.id: v.Id;

const withSelectedGetter = createWithPropIfNotExist(
	idSelectedGetter
	, "selectedGetter"
);

const getSelector = selectedGetter => (v, index) => {
	if (typeof selectedGetter === "function") {
		return selectedGetter(v, index);
	}
	return v[selectedGetter+""];
};

const normalizedOptions = (options, getter) => {
	if (!options || !options.length) {
		return [emptyObject, emptyArray];
	}
	const norm = {}
		, order = []
		;
	options.forEach((v, index) => {
		const itemId = getSelector(getter)(v, index);
		norm[itemId] = v;
		order.push(itemId);
	});
	return [norm, order];
};

const createWithNormalizedOptionsBase = propName => withPropsOnChange(
	[propName]
	, ({ [propName]: prop, selectedGetter }) => {
		const [ norm, order ] = normalizedOptions(prop, selectedGetter);
		return {[propName]: norm, order};
	}
);

export const createWithNormalizedOption = propName => composeWithDisplayName(
	"withNormalizedOption"
	, withSelectedGetter
	, createWithNormalizedOptionsBase(propName)
);

const fieldNotExist = (
	newProps
	, props
	, field
) => typeof newProps[field] === "undefined"
	&& typeof props[field] === "undefined";

function getOrderName(orderName, newProps, props) {
	if (orderName) {
		return orderName;
	} else if (fieldNotExist(newProps, props, "order")) {
		return "order";
	}
	for (let i=1; true; i++) {
		orderName = "order"+i;
		if (fieldNotExist(newProps, props, orderName)) {
			return orderName;
		}
	}
}

const createPropsChangeChecker = (arrayOfProp, defName) => (
	{ multi, ...props }
	, { nextMulti, ...nextProps }
) => {
	if (nextMulti !== multi) {
		return true;
	}
	const list = singleOrMulti(multi);
	for (let i=0; i<list.length; i++) {
		const propsName = essentialPropsName(list[i], defName);
		let change;
		for (let j=0; j<arrayOfProp.length; j++) {
			const propName = propsName[arrayOfProp[j]];
			if (props[propName] !== nextProps[propName]) {
				change = true;
				break;
			}
		}
		if (change) {
			return true;
		}
	}
	return false;
};

const createWithDataNormalizationMultiAware = (
	arrayOfProp
	, modifiedOptionsName
) => composeWithDisplayName(
	"withMultiNormalized"
	, withSelectedGetter
	, withPropsOnChange(
		createPropsChangeChecker(arrayOfProp, modifiedOptionsName)
		, ({ multi, ...props }) => {
			const newProps = {}
				, list = singleOrMulti(multi);
				;
			for (let i=0; i<list.length; i++) {
				const {
					options
					, ...rest
				} = essentialPropsName(list[i], modifiedOptionsName);
				if (!options) {
					continue;
				}
				const { order, selectedGetter } = rest
					, [ norm, _order ] = normalizedOptions(
						props[options]
						, props[selectedGetter]
					)
					;
				newProps[options] = norm;
				newProps[getOrderName(order, newProps, props)] = _order;
			}
			return newProps;
		}
	)
);

const withDataNormalizationMultiAware = createWithDataNormalizationMultiAware([optionsProp]);

export const createWithMultiNormalized = propName => createWithDataNormalizationMultiAware([propName], {options: propName});

const withSelectedClass = createWithPropIfNotExist(
	"sel selected"
	, "selectedClass"
);

const withSelected = IconComponent => ({
	children
	, hideIcon
	, icon
	, onClick
	, tooltip
	, ...props
}) => (
	<div
		className="jq-selectbox__select"
		onClick={onClick}
		data-qa-id={qaIdOrId(props, "select-box")}
	>
		<SelectedItem icon={icon} tooltip={tooltip}>
			{children}
		</SelectedItem>
		<IconComponent
			hidden={hideIcon}
			data-qa-id={props["data-qa-id"]}
			id={props.id}
		/>
	</div>
);

const ListWrapperBase = ({ className, children, maxHeight, top, width }) => {
	const style = {};
	if (maxHeight) {
		style.maxHeight = maxHeight;
		style.display = 'block';
	}
	return (
		<div
			className="jq-selectbox__dropdown"
			style={{width, height: 'auto', bottom: 'auto', top}}
		>
			<ul className={classNames(className)} style={style}>
				{children}
			</ul>
		</div>
	);
};

const ListWrapper = withUnmountWhenHidden(ListWrapperBase);

const renderOneList = (
	list
	, parentIndex
	, selectedItem
	, hideList
	, {
		textNoItemSelected
		, doNotShowSelectedValue
		, selectedClass
		, tooltipProp
		, selectNone
		, ...props
	}
	, multi
	, Component
) => {
	const {
			onSelect
			, options
			, order
			, selected
			, selectedGetter
		} = essentialProps(multi, props)
		;
	let loadOnce = true;
	$.each(order, (index, w) => {
		const v = options[w]
			, selector = getSelector(selectedGetter)(v, index)
			, name = 'name' in v ? v.name : v.Name
			, icon = 'icon' in v ? v.icon : v.Icon
			, isSelected = selector === selected
			;
		if (!selectedItem.found && isSelected) {
			selectedItem.found = true;
			if (!doNotShowSelectedValue) {
				selectedItem.item = name;
			}
			if (icon) {
				selectedItem.icon = icon;
			}
			if (tooltipProp) {
				selectedItem.tooltip = v[tooltipProp];
			}
		}
		if (hideList || selector === UNSELECT) {
			return;
		}
		let tooltip;
		if (tooltipProp) {
			tooltip = v[tooltipProp];
		}
		if (loadOnce && selected !== UNSELECT && !multi && selectNone) {
			loadOnce = false;
			let item = I('Select none');
			list.push(
				<Component
					key={'l'+parentIndex+'_'+-1}
					className={classNames({[selectedClass]: false})}
					icon={icon}
					id={UNSELECT}
					index={parentIndex}
					multi={!!multi}
					name={item}
					onClick={onSelect}
					tooltip={tooltip}
				/>
			);
		}
		list.push(
			<Component
				key={'l'+parentIndex+'_'+index}
				className={classNames({[selectedClass]: isSelected})}
				icon={icon}
				id={selector}
				index={parentIndex}
				multi={!!multi}
				name={name}
				onClick={onSelect}
				tooltip={tooltip}
			/>
		);
	});
};

const renderSelectedAndLists = (
	list
	, hideList
	, { multi, ...props }
	, Component
) => {
	const lists = multi ? multi : []
		, selected = {}
		;
	// NOTE: purposely not to initialize textNoItemSelected to able to catch
	// any code error when item showing {invalid}.
	let item
		;
	if (validMulti(multi)) {
		multi.forEach((v, index) => {
			const l = [];
			renderOneList(l, index, selected, hideList, props, v, Component);
			if (l.length > 0) {
				if (list.length > 0) {
					list.push(<HrLine key={"s"+index} />);
				}
				list.push(...l);
			}
		});
	} else {
		renderOneList(list, 0, selected, hideList, props, false, Component);
	}
	if (!selected.found || props.doNotShowSelectedValue) {
		if (props.textNoItemSelected) {
			item = props.textNoItemSelected;
		} else {
			item = I('Select an item');
		}
	} else {
		item = selected.item;
	}
	if (typeof item === "undefined") {
		item = '{invalid}';
	}
	return [item, selected.icon, selected.tooltip];
};

const onlyOneValidOptionBase = (options, order, selected) => {
	if (selected === UNSELECT) {
		return false;
	}
	const actualSelectionSize = options[UNSELECT] ? 2 : 1;
	return order.length <= actualSelectionSize;
};

const onlyOneValidOption = ({ doNotShowSelectedValue, multi, ...props }) => {
	if (doNotShowSelectedValue) {
		return false;
	} else if (validMulti(multi)) {
		let totalOthers = 0
			, hasOnlyOne
			;
		for (let i=0; i<multi.length; i++) {
			const {
					options
					, order
					, selected
				} = essentialProps(multi[i], props)
				;
			if (!hasOnlyOne
				&& onlyOneValidOptionBase(options, order, selected)) {
				hasOnlyOne = true;
			} else {
				const size = order.length;
				totalOthers += options[UNSELECT] ? size - 1 : size;
			}
		}
		if (hasOnlyOne && totalOthers <= 0) {
			return true;
		}
		return false;
	}
	const { options, order, selected } = props;
	return onlyOneValidOptionBase(options, order, selected);
};

const StyledManualHeightDiv = styled.div`
	${({ manualHeight }) => {
		if (manualHeight) {
			return `height: ${manualHeight};`
		} else {
			return ''
		}
	}}
`
const createWithSelections = SelectedComponent => OneComponent => ({
	className,
	listClass,
	id,
	manualHeight,
	maxHeight,
	onToggleShow,
	show,
	sort,
	top,
	width,
	...props
}) => {
	const oneOrLess = onlyOneValidOption(props)
		, onClick = oneOrLess ? doNothing : onToggleShow
		, hideList = !show || oneOrLess
		, list = []
		, [
			item
			, selectedIcon
			, selectedTooltip
		] = renderSelectedAndLists(
			list
			, hideList
			, props
			, OneComponent
		)
		;

	return (
		<StyledManualHeightDiv
			className={classNames(
				baseClassName
				, "jq-selectbox jqselect"
				, className
				, {"dropdown opened": !hideList}
			)}
			manualHeight={manualHeight}
			style={zeroPadding}
		>
			<SelectedComponent
				data-qa-id={props["data-qa-id"]}
				hideIcon={oneOrLess}
				icon={selectedIcon}
				id={id}
				onClick={onClick}
				tooltip={selectedTooltip}
			>
				{item}
			</SelectedComponent>
			<ListWrapper
				hidden={hideList}
				maxHeight={maxHeight}
				top={top}
				width={width}
			>
				{list}
			</ListWrapper>
		</StyledManualHeightDiv>
	);
};

const createWithConstantsProps = (propMaxHeight, propTop) => Component => ({
	className
	, maxHeight
	, top
	, visible
	, ...props
}) => (
	<Component
		className={classNames("sidebar-nav-select", className)}
		maxHeight={propMaxHeight}
		top={propTop}
		visible={true}
		{...props}
	/>
);

const withConstantProps = composeWithDisplayName(
	"withConstantProps"
	, createWithConstantsProps("176px", "-72px")
);

const withIndexAttachedCallbackIfMulti = branch(
	({ multi }) => multi
	, createWithPropAttachedOnClick("index", 1)
);

const withSelectOneList = composeWithDisplayName(
	"withSelectOneList"
	, withIndexAttachedCallbackIfMulti
	, withIdAttachedOnClick
	, withSelectOne
);

const withSelectOneList2 = composeWithDisplayName(
	"withSelectOneList2"
	, withIndexAttachedCallbackIfMulti
	, withIdAttachedOnClick
	, withSelectOne2
);

const SelectOne = withSelectOneList(Icon);

const SelectOne2 = withSelectOneList2(Icon);

export const SelectList = SelectOne;
export const SelectList2 = SelectOne2;


const toggleCallback = "onToggleShow"
	, nameProp = "name"
	;
const withVisibility = composeWithDisplayName(
	"withVisibility"
	, createWithMountWhenPropTrue("visible")
	, createWithCheckValidHandler(toggleCallback)
	, onClickOutside
	, createWithClickOutsideToggleFalse(toggleCallback)
	, createWithCheckValidHandler(selectCallback)
	, createWithToggleStateCallback("show", toggleCallback)
);

const SelectedWithArrow = composeWithDisplayName(
	"SelectedWithArrow"
	, withSelected
	, withUnmountWhenHidden
)(Arrow);

const withItemWithArrow = createWithSelections(SelectedWithArrow);

const stringOrNumberPropTypes = PropTypes.oneOfType([
	PropTypes.string
	, PropTypes.number
]);

const stringOrFuncPropTypes = PropTypes.oneOfType([
	PropTypes.string
	, PropTypes.func
]);

const multiPropTypes = PropTypes.arrayOf(PropTypes.shape({
	onSelect: PropTypes.string
	, options: PropTypes.string.isRequired
	, order: PropTypes.string
	, selected: PropTypes.string
	, selectedGetter: PropTypes.string
}));

const dataPropTypes = PropTypes.arrayOf(PropTypes.shape({
	id: stringOrNumberPropTypes
	, Id: stringOrNumberPropTypes
	, name: PropTypes.string
	, Name: PropTypes.string
	, value : stringOrNumberPropTypes
}));

export const withSelectBox = hoc => {
	const pre = [
			"SelectBox"
			, withVisibility
			, withDataNormalizationMultiAware
		]
		, post = [
			withSelectedClass
			, withItemWithArrow
		]
		;
	if (hoc) {
		pre.push(hoc);
	}
	const total = pre.concat(post);
	return composeWithDisplayName(...total)(SelectOne);
};

const optionsPropNameGetter = (
	{ multi }
	, _
	, index
) => essentialPropsName(validMulti(multi) ? multi[index] : false).options;

const withCallbackDataMultiAware = createWithCallbackSelectedData(
	optionsPropNameGetter
	, ({ multi }) => {
		const names = [];
		singleOrMulti(multi).forEach(
			v => names.push(essentialPropsName(v).onSelect)
		);
		return names;
	}
);

export const SelectBoxCallbackData = withSelectBox(withCallbackDataMultiAware);

export const SelectBox = withSelectBox();

SelectBox.propTypes = {
	multi: multiPropTypes,
	manualHeight: PropTypes.string,
	options: dataPropTypes,
	onSelect: PropTypes.func,
	onToggleShow: PropTypes.func,
	selected: stringOrNumberPropTypes, // Note: Use "UNSELECT" or -1 for default value
	selectedGetter: stringOrFuncPropTypes,
	show: PropTypes.bool,
	textNoItemSelected: PropTypes.string,
	selectNone: PropTypes.bool
};

SelectBoxCallbackData.propTypes = SelectBox.propTypes;

const withNameAttachedOnSelect = composeWithDisplayName(
	"withNameAttachedOnSelect"
	, createWithPropAttachedActionThenRemoveProp(nameProp, selectCallback, 1)
);

export const SelectionBox = composeWithDisplayName(
	"SelectionBox"
	, createWithConstantsProps("972px", "26px")
	, createWithQADataId("selection-box-", nameProp)
	, withAutoToggleAndHideOnSelect
	, withNameAttachedOnSelect
)(SelectBox);

const withAutoResetInvalidMultiSelection = createWithEffectCondition((
	{ multi, ...props }
	, prevProps
) => {
	singleOrMulti(multi).forEach((v, index) => {
		const { onSelect, options, selected } = essentialPropsName(v)
			, extraArgs = multi ? [undefined, index] : emptyArray
			;
		makeAutoResetInvalidSelectionCondition(
			options
			, selected
			, onSelect
			, ...extraArgs
		)(props, prevProps);
	});
});

const withDropdownSelectBox = composeWithDisplayName(
	"withDropdownSelectBox"
	, withVisibility
	, withAutoResetInvalidMultiSelection
	, withCallbackDataMultiAware
);

// changes from original component SelectBox:
// 1) outer wrapper always wrap with class 'label-block' and style
//    unsetLabelBlock. 'label-block' is needed to generate the dropdown border.
// 2) show state is not a controlled prop but internal state.
// 3) createWithSelections uses Chevron component instead of Arrow where prop
//    style determine the Chevron and not the jq class.
// 4) this can auto reset the selection when selection 'options' changed and
//    selected value no longer valid.
// 5) Use normalized data. This is default behaviour. SelectBox use
//    createWithNormalizedOption to get the normalized data for this default
//    behaviour.
export const DropDownSelectBox = composeWithDisplayName(
	"DropDownSelectBox"
	, createWithWrapper("label-block", unsetLabelBlock)
	, withConstantProps
	, withAutoToggleAndHideOnSelect
	, withSelectedClass
	, withDropdownSelectBox
	, createWithSelections(withSelected(Chevron))
)(SelectOne);

DropDownSelectBox.propTypes = {
	multi: multiPropTypes
	, options: PropTypes.object
	, order: PropTypes.arrayOf(stringOrNumberPropTypes)
	, onSelect: PropTypes.func
	, selected: stringOrNumberPropTypes
	, selectedGetter: stringOrFuncPropTypes
	, textNoItemSelected: PropTypes.string
};

const fullWidth = {width: "100%"};

const createWithLabel = isFullWidth => Component =>
	class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClick = this.handleClick.bind(this);
		}
		handleClick(v) {
			this.props.onToggleShow(!this.props.show);
		}
		render() {
			const {
					id
					, selectClassName
					, style
					, title
					, wrapClassName
					, ...props
				} = this.props
				, ignoreClick = "ignore-" + id + "-label"
				;
			let updatedStyle
				;
			if (isFullWidth) {
				if (style) {
					updatedStyle = update(style, {$merge: fullWidth});
				} else {
					updatedStyle = fullWidth;
				}
			} else {
				updatedStyle = style;
			}
			return (
				<div className={wrapClassName} style={updatedStyle}>
					<label className={ignoreClick} onClick={this.handleClick}>
						{title}
					</label>
					<Component
						className={selectClassName}
						outsideClickIgnoreClass={ignoreClick}
						visible={true}
						{...props}
					/>
				</div>
			);
		}
	};

// LabeledSelection different from LabeledSelectBox:
//   1) options data is normalized.
//   2) data callback as id and whole normalized data.
//   3) the item wrapper is taking full width of its container.
export const LabeledSelection = composeWithDisplayName(
	"LabeledSelection"
	, withAutoToggleAndHideOnSelect
	, createWithLabel(true)
	, withDropdownSelectBox
	, withItemWithArrow
)(SelectOne);

export const LabeledSelectBox = composeWithDisplayName(
	"LabeledSelectBox"
	, withAutoToggleAndHideOnSelect
	, createWithLabel(false)
)(SelectBox);

class DDList extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick (evt) {
		this.props.onSelect(this.props.me, this.props.channel);
	}
	render() {
		const p = this.props;
		let content;
		if(p.icon) {
			content = <label title={p.name}><i className={p.icon}></i>{p.name}</label>;
		} else {
			content = <label title={p.name}>{p.name}</label>;
		}
		return (
			<li data-qa-id={"dd_list_"+p.me} onClick={this.handleClick}>
				{content}
			</li>
		);
	}
};

const displayBlk = {display: 'block'}, displayNone = {display: 'none'};

class StaticDD extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleToggleShow = this.handleToggleShow.bind(this);
	}
	handleClickOutside(evt) {
		this.props.onToggleShow(false);
	}
	handleToggleShow(evt) {
		this.props.onToggleShow(!this.props.show);
	}
	render() {
		const p = this.props
			, opts = p.options
			, listClass = classNames(
				p.listClass
				, {"dropdown-menu": !p.listClass}
			)
			;
		let style
			, myClass
			, list = []
			, title
			, count = 0
			, txt = ''
			, arrow = 'icon-chevron-'
			;
		$.each(opts, (k, v) => {
			if (!p.isSocialMedia || v.active) {
				count++;
				list.push(
					<DDList
						key={'l'+k}
						me={k}
						channel={p.channelAlias}
						onSelect={p.onSelect}
						name={v.name}
						icon={v.icon}
					/>
				);
			}
		});
		if (!p.show) {
			style = displayNone;
			myClass = p.myClass;
			arrow += 'down';
		} else {
			let openClass;
			if (p.openClass) {
				openClass = ' ' + p.openClass;
			} else {
				openClass = ' dropdown-open';
			}
			style = displayBlk;
			myClass = p.myClass + openClass;
			arrow += 'up';
		}
		if (p.selected) {
			txt = '{invalid}';
			if (opts && opts[p.selected] && opts[p.selected].name) {
				txt = opts[p.selected].name;
			}
		}
		const moreThanOne = !p.selected || count > 1;
		let titleLabel = p.title;
		if (typeof p.title === "string") {
			titleLabel = (p.title).toUpperCase(); //todo: Text should not be uppercase anymore? To stay consistent.
		}
		title = <span className="row-wrap">
				<span className="label">{titleLabel}</span>
				<span className="title">{txt}</span>
			</span>
		return (
			<div
				className={myClass + " ignore-react-onclickoutside"}
				onClick={moreThanOne ? this.handleToggleShow : doNothing}
				hidden={p.hidden}
				data-dropdown-menu
			>
				{title}
				{moreThanOne && <i className={arrow} />}
				{moreThanOne &&
					<ul className={listClass} style={style}>
						{p.ddTitle && <h6 className='dropdown-title'>{p.ddTitle}</h6>}
						{list}
					</ul>
				}
			</div>
		);
	}
}

const DropDownSelect = onClickOutside(StaticDD);

export { DropDownSelect };
