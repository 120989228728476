import React, { Fragment, useCallback } from 'react'
import styled from 'styled-components'
import each from 'lodash/each'
import { withProps } from 'recompose'
import classNames from 'classnames'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import { I, L } from '../common/v5/config'
import { boldBorderStyle, centionBlue } from '../styles/_variables'
import { useCallbackWithValue } from '../hooks/callback'
import { composeWithDisplayName } from './hocs'

export const OneNavItem = ({ active, onClick, tabId, text, disabled, hidden, title }) => (
  <NavItem hidden={hidden} title={title}>
    <NavLink
      className={classNames({ active })}
      onClick={useCallbackWithValue(tabId, onClick)}
      disabled={disabled}
    >
      {text}
    </NavLink>
  </NavItem>
)

const StyledNav = styled(Nav)`
  &.nav.nav-tabs {
    border-bottom: unset;
    .nav-link {
      cursor: pointer;
      &.active {
        // border: unset;
        // border-bottom: ${boldBorderStyle};
      }
    }
  }
`
const NavTabs = ({
  activeTab,
  className,
  fullContent,
  onClick,
  tabs,
  ...props
}) => {
  const navs = []
  const contents = []
  each(tabs, ({ id, name, Renderer }) => {
    navs.push(
      <OneNavItem
        key={id}
        active={activeTab === id}
        onClick={onClick}
        tabId={id}
        text={L(name)}
      />
    )
    if (fullContent || activeTab === id) {
      contents.push(
        <TabPane key={id} tabId={id}>
          <Renderer tabId={id} {...props} />
        </TabPane>
      )
    }
  })
  return (
    <Fragment>
      <StyledNav className={className} tabs>{navs}</StyledNav>
      <TabContent activeTab={activeTab}>{contents}</TabContent>
    </Fragment>
  )
}

export default NavTabs
