import React, { memo } from 'react'
import classNames from 'classnames'
import styled from 'styled-components'
import { branch, renderComponent } from 'recompose'
import { I } from '../common/globals'
import {
  //DEFAULT_ERRAND_TYPE_ICONS,
  ICON_V5_EMAIL
} from '../common/v5/constants'
import { inputSpace } from '../styles/_variables'
import { composeWithDisplayName, withUnmountWhenHidden } from './hocs'
import { DropDownSelect } from './SelectBox'

const Selection = ({
  className,
  onSelect,
  options,
  onToggle,
  selected,
  title,
  ddTitle,
  show
}) => (
  <DropDownSelect
    myClass={className}
    title={title || I('Channel: ')} //defaults to "Channel" as it was originally
    onToggleShow={onToggle}
    options={options}
    show={show}
    selected={selected}
    onSelect={onSelect}
    isSocialMedia={false}
    ddTitle={ddTitle}
  />
)

const ReadOnlyChannel = ({ selected }) => (
  <div className='channel-select channel-select-readonly'>
    {I('CHANNEL:')} {selected.toUpperCase()}
  </div>
)

const SelectionOrReadOnly = branch(
  ({ readOnly }) => readOnly,
  renderComponent(ReadOnlyChannel),
)(Selection)

const ChannelBase = ({
  myClass,
  onSelect,
  options,
  onToggle,
  readOnly,
  selected,
  show,
  title,
  ddTitle
}) => (
  <span className='channel-select-opt'>
    <SelectionOrReadOnly
      className={myClass}
      onSelect={onSelect}
      onToggle={onToggle}
      options={options}
      readOnly={readOnly}
      selected={selected}
      show={show}
      title={title}
      ddTitle={ddTitle}
    />
  </span>
)

const Channel = composeWithDisplayName(
  'Channel',
  withUnmountWhenHidden,
  memo
)(ChannelBase)

export default Channel

export const baseClassNameChannelIcon = 'channel-icon'

const StyledI = styled.i`
	margin-right: ${inputSpace};
`
const ChannelIconBase = ({ channelId, channelIcon }) => (
  <StyledI
    className={classNames(
      baseClassNameChannelIcon,
      channelIcon === "" ? (typeof channelId !== 'undefined' ? (DEFAULT_ERRAND_TYPE_ICONS[channelId] || ICON_V5_EMAIL) : "icon-v5-chat" ): channelIcon
    )}
  />
)

export const ChannelIcon = withUnmountWhenHidden(ChannelIconBase)
