import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import classNames from 'classnames'
import { branch, renderComponent } from 'recompose'
import styled from 'styled-components'
import {
  centionBlack,
  centionBlue,
  fontSizeAdminForm,
  inputBorderRadius,
  inputBorderStyle,
  inputMinHeight,
  inputPadding,
  inputSpace,
  inputSpaceBorderWidth
} from '../styles/_variables'
import {
  TXT_NO_UPLOADED_FILE,
  TXT_UNKNOWN_FILE_SIZE
} from '../common/v5/receiptGreetingConstants'
import { humanFileSize } from '../common/helpers'
import {
  composeWithDisplayName,
  createWithMountCondition,
  withUnmountWhenHidden
} from './hocs'
import Anchor from './Anchor'
import { PlainButton, focusedBorderStyled } from './Button'

const StyledInput = styled.input`
  min-height: ${inputMinHeight};
  padding: ${inputPadding};
  font-size: ${fontSizeAdminForm};
  line-height: 1.5;
  border-radius: ${inputBorderRadius};
  width: 100%;
  // background-color: ${({ disabled }) => disabled ? '#00000012' : '#fff'};
  background-image: none;
  border: ${inputBorderStyle};
  ${({ disabled }) => disabled ? 'border-color: hsl(0,0%,90%);' : ''}
  -webkit-box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  box-shadow: inset 0 1px 1px rgb(0 0 0 / 8%);
  ${({ disabled }) => disabled ? 'box-shadow: unset;' : ''}
  -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
  -o-transition: border-color ease-in-out .15s,box-shadow ease-in-out .15s;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  &:focus {${focusedBorderStyled}}
`
export const InputText = forwardRef((props, ref) => (
  <StyledInput ref={ref} type='text' {...props} />
))

const InputBase = ({ onChange, ...props }) => (
  <InputText
    onChange={useCallback(e => { onChange(e.target.value, e) }, [onChange])}
    {...props}
  />
)

const Input = withUnmountWhenHidden(InputBase)

export default Input

const getFileSize = size => {
  if (size) {
    return humanFileSize(size)
  }
  return TXT_UNKNOWN_FILE_SIZE
}

const fileSizeWithBraces = size => {
  size = getFileSize(size)
  return `(${size})`
}

const FileSizeBase = ({ size }) => (
  <span className='size' title={size}>{fileSizeWithBraces(size)}</span>
)

const FileSize = createWithMountCondition(({ size }) => typeof size === 'number')(FileSizeBase)

const FilenameAndSizeBase = ({ name, size }) => (
  <div className='filename-size'>
    <span className='name' title={name}>{name}</span>
    <FileSize size={size} />
  </div>
)

const FilenameAndSize = createWithMountCondition(({ name }) => !!name)(FilenameAndSizeBase)

const crossDeleteSize = '10px'

const StyledFileDiv = styled.div`
  &.selected {
    background-color: ${({ disabled }) => disabled ? 'hsl(0,0%,95%)' : '#fff'};
    border: ${inputBorderStyle};
    border-radius: ${inputBorderRadius};
    color: ${centionBlack};
    ${({ disabled }) => disabled ? 'border-color: hsl(0,0%,90%);' : ''}
    display: flex;
    font-size: ${fontSizeAdminForm};
    padding: ${inputPadding};
    > * + * {
      margin-left: ${inputSpace};
    }
    .cross {
      color: ${centionBlack};
    }
    .filename-size {
      display: flex;
      max-width: calc(100% - ${crossDeleteSize});
      .name {
        color: ${centionBlue};
        overflow: hidden;
      }
      > * + * {
        margin-left: ${inputSpace};
      }
    }
  }
`
const SelectedFile = ({ className, disabled, name, onClick, size }) => (
  <StyledFileDiv
    className={classNames(className, 'selected')}
    disabled={disabled}
  >
    <Anchor className='cross' disabled={disabled} onClick={onClick}>X</Anchor>
    <FilenameAndSize name={name} size={size} />
  </StyledFileDiv>
)

const NoFileDiv = styled.div`
  font-size: ${fontSizeAdminForm};
  padding: ${inputSpaceBorderWidth} ${inputSpace};
`
const NoFile = ({ className, textNoItem = TXT_NO_UPLOADED_FILE }) => (
  <NoFileDiv className={className}>{textNoItem}</NoFileDiv>
)

const SelectedOrNoFile = composeWithDisplayName(
  'SelectedOrNoFile',
  memo,
  branch(
    ({ name }) => !name,
    renderComponent(NoFile)
  )
)(SelectedFile)

export const RemovableSelectedItem = SelectedOrNoFile

const StyledDiv = styled.div`
  &.file {
    > * + * {
      margin-top: ${inputSpace};
    }
  }
`
// File react like file input.
// Caveat: input file always uncontrolled because browser don't allow setting
//         current selected file programmatically.
// props: 1) name: button text
//        2) filename: the file name that is displayed
//        3) size: file size that is displayed
//        4) onChange: file => {...} callback with file object.
export const File = ({
  className,
  disabled,
  filename,
  name,
  onChange,
  onClear,
  size,
  ...props
}) => {
  const inputEl = useRef(null)
  return (
    <StyledDiv className={classNames(className, 'file')}>
      <PlainButton
        disabled={disabled}
        text={name}
        onClick={useCallback(
          (_, e) => {
            inputEl.current.click()
            e.preventDefault()
          },
          []
        )}
      />
      <input
        ref={inputEl}
        className='input'
        disabled={disabled}
        hidden
        onChange={onChange}
        type='file'
        {...props}
      />
      <SelectedOrNoFile name={filename} onClick={onClear} size={size} />
    </StyledDiv>
  )
}
