import React from 'react';
import { SketchPicker } from 'react-color';

//TODO: Support transparency selection
class ColorPicker extends React.Component {
	state = {
		displayColorPicker: false,
		color: {
			r: '241',
			g: '112',
			b: '19',
			a: '1',
		},
		selected: ""
	};

	handleClick = () => {
		this.setState({ displayColorPicker: !this.state.displayColorPicker })
	};

	handleClose = () => {
		this.setState({ displayColorPicker: false })
	};

	handleChange = (color) => {
		this.setState({ color: color.rgb })
	};

	handleChangeComplete = (color) => {
		this.props.onChangeColor(color.hex);
	};

	hexToRgb = (hex) => {
		var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})?$/i.exec(hex);
		return result ? {
			r: parseInt(result[1], 16),
			g: parseInt(result[2], 16),
			b: parseInt(result[3], 16),
			a: result[4] ? parseInt(result[4], 16) / 255 : 1 // Alpha channel conversion
		} : null;
	}

	renderColorOnce = () => {
		if (this.props.selected) {
			let selected = this.state.selected;
			let color = this.state.color;
			if (selected !== this.props.selected) {
				const parsedColor = this.hexToRgb(this.props.selected);
				if(parsedColor !== null) {
					color.r = parsedColor.r;
					color.g = parsedColor.g;
					color.b = parsedColor.b;
					color.a = parsedColor.a;
				}
				selected = this.props.selected;
			}
			return color;
		}
	}

	render() {
		const colorSwatchStyle = {
			background: `${this.props.selected}`,
			width: '16px',
			height: '16px',
			borderRadius: '2px'
		}
		const swatchStyle = {
			background: 'transparent',
			display: 'inline-block',
			cursor: 'pointer',
			borderRadius: '2px',
			boxShadow: '0 0 3px 2px rgb(0 0 0 / 20%)'
		}
		let drawingStyle = {};
		//Todo: extend this option
		if(this.props.popoverPos === "top") {
			drawingStyle = {
				top: '-300px',
				right: '50px'
			}
		}
		let color = this.renderColorOnce()

		return (
			<div className="color-picker" name={this.props.name}>
				<div className="swatch-container" style={swatchStyle} onClick={this.handleClick}>
					<div className="swatch-color" style={colorSwatchStyle} />
				</div>
				{this.state.displayColorPicker ?
					<div className="picker-modal" style={drawingStyle}>
						<div className="picker-cover" onClick={this.handleClose}/>
						<SketchPicker color={color} onChange={this.handleChange} onChangeComplete={this.handleChangeComplete} />
					</div>
					:
					null
				}
				<span className="picker-value" style={{ marginLeft: '5px', marginTop: '5px' }} hidden={this.props.hideSelected} >{this.props.selected}</span>
			</div>
		)
	}
}

export default ColorPicker