import React from 'react';
import { flatMap, removeCharOnce } from '../common/v5/helpers';
import { L } from '../common/v5/config';

class Hotkeys extends React.Component {
	static defaultProps = { mod: '', activated: false };
	constructor(props){
		super(props);
		this.handleKeyDown = this.handleKeyDown.bind(this);
		this.handleShortcut = this.handleShortcut.bind(this);
		this.handleEnter = this.handleEnter.bind(this);
		this.helperAction = this.helperAction.bind(this);
	}
	componentDidMount(){
		document.addEventListener("keydown", this.handleKeyDown, false);
	}
	componentWillUnmount(){
		document.removeEventListener("keydown", this.handleKeyDown, false);
	}
	handleKeyDown(e){
		let metaKeyPressed = e.metaKey, ctrlKeyPressed = e.ctrlKey, altKeyPressed = e.altKey, shiftKeyPressed = e.shiftKey;
		let key, keyList = [], listControlKey = [];
		let allKeys = this.props.keys;
		switch (e.which) {
			case 27:
				key = "Escape";
				break;
			case 39:
				key = "Right";
				break;
			case 37:
				key = "Left";
				break;
			case 13:
				key = "Enter";
				break;
			default:
				key = String.fromCharCode(e.which);
				break;
		}
		allKeys.map(function(hotkey,i){
				keyList.push(hotkey.key);
		}.bind(this));
		if(this.props.mod != null) {
			listControlKey = (this.props.mod.toLowerCase()).split("+");
			listControlKey = listControlKey.slice(0,-1);
		}
		var keyExist = $.inArray(key, keyList);
		if(!this.props.hotkeyDialog){
			if(keyExist != -1){
				var updatedCKey = [{key: "meta", valid: false},{key: "ctrl", valid: false}, {key: "alt", valid: false}, {key: "shift", valid: false}];
				updatedCKey.map(function(ckey, i){
						var ck = ckey.key+"KeyPressed";
						if(eval(ck)){
							ckey.valid = true;
						}
				});

				function checkValid (key){
					return key.valid == true;
				}
				function checkInvalid (key){
					return key.valid == false;
				}

				var validKey = [];
				var invalidKey = [];

				updatedCKey.map(function(cK,i){
					var exist = $.inArray(cK.key, listControlKey);
					if(exist != -1){
						validKey.push(cK);
					}else{
						invalidKey.push(cK);
					}
				},this);

				var valid = validKey.every(checkValid);
				var invalid = invalidKey.every(checkInvalid);

				if(valid && invalid){
					if(this.safetyCheck()){
						this.handleShortcut(key, allKeys, keyList, true);
					}
					this.handleShortcut(key, allKeys, keyList, true);
				}
			}
		}else{
			if(keyExist != -1 || key == "Escape" || key == "Enter"){
				if(this.safetyCheck()){
					if(key == "H") {
						this.handleShortcut(key, allKeys, keyList, true);
					}else{
						this.handleShortcut(key, allKeys, keyList, false);
					}
				}
			}
		}
	}
	handleShortcut(key, keyObj, keyList, helper){
		const p = this.props;
		let params = [];
		keyObj.map(function(keyO, i){
			if(keyO.key == key){
				params.currentKey = keyO.key;
				params.actionTxt = keyO.name;
				params.action = keyO.action;
				params.type = keyO.type;
				params.advice = I("(Press Enter to Confirm, Escape to Cancel)");
				this.props.setHotKeyList(keyList);
			}
		},this);
		this.props.onShowHelper(true);
		if(this.props.show){
			this.props.setActiveHotkeyDialog(true);
				if(key == "Enter"){
					if(p.params.action){
							this.handleEnter();
					}
					this.props.onShowHelper(false);
					this.props.setActiveHotkeyDialog(false);
				}
				if(key == "Escape" || key == "Esc"){
					this.props.onShowHelper(false);
					this.props.setActiveHotkeyDialog(false);
				}
				keyObj.map(function(keyO, i){
					if(keyO.key.toLowerCase() == key){
						params.currentKey = keyO.key;
						params.actionTxt = keyO.name;
						params.action = keyO.action;
						params.type = keyO.type;
						params.advice = I("(Press Enter to Confirm, Escape to Cancel)");
					}
				},this);
		}else{
			this.props.setActiveHotkeyDialog(false);
		}
		this.props.onChangeKeys(params);
	}
	handleEnter(){
		const p = this.props, params = p.params;
		if(params.type == "link"){
			location.href = params.action;
		}else{
			p.onEnter(params.action);
			p.onShowHelper(false);
		}
		p.setHotkeyActive(false);
	}
	helperAction(){
		this.props.onShowHelper(false);
	}
	safetyCheck(){
		/* This will check if cursor focused on any textfield to avoid conflict
			for example, SHIFT + Character
		*/
		var whichFocused;
		try {
			whichFocused = document.activeElement.tagName;
		}catch(error) {
			whichFocused = "BODY";
		}
		if(whichFocused == "INPUT"){
			if(document.activeElement.type == "text" || document.activeElement.contentEditable == "true")
				return false;
		}else {
			return true;
		}
	}
	render(){
		const p = this.props, params = p.params;
		var shortcut = "";
		var key = params.currentKey;
		if(key == "H"){
			shortcut = "☛";
		}
		else{
			shortcut = p.mod+''+key;
		}
		return(
			<div hidden={p.show ? false : true} className="workflow-hotkey-dialog" id={p.id}>
				<div>
					<span className="Hotkeys_Shortcut" style={{color:'#FFF', fontSize:'24px'}}>{shortcut}</span> <br /><br />
					<b><span className="Hotkeys_Action" style={{color:'#FFF', fontSize:'15px'}}>{params.actionTxt}</span></b><br /><br />
						<span className="Hotkeys_Advice" style={{color:'rgb(255, 255, 255)', fontSize:'10px'}}>{params.advice}</span> <br /><br />
					<span className="Hotkeys_Available" style={{color:'#888', fontSize:'12px', display: 'block'}} >{p.show ? "[ " +p.hotkeys.join(" ")+ " ]" : " "}</span> <br />
				</div>
			</div>
		);
	}
};

// TODO: this break reactcomponents as features should get from redux server.
//A little component to render text with it's hotkey shortcut character
//For example, to render "Send" with S character underline,
//the usage is <HotkeyLabel id="sendErrand" text={I("Send")} hotkey="S" />
//If the character not in the text, the hotkey char will be displayed
//next to the text wrapped with []
export const HotkeyLabel = ({id, text, hotkey}) => {
	let hotkeyFeature = features["hotkeys"] && features["hotkeys-markers"];
	let labelText = text, actionLabel = [], charKey = hotkey;
	if(hotkeyFeature && hotkey){
		if(text.toUpperCase().indexOf(hotkey) > -1){
			let index = text.toUpperCase().indexOf(hotkey);
			let foundChar = text[index];
			if(foundChar === foundChar.toLowerCase()){
				charKey = hotkey.toLowerCase();
			}else{
				charKey = hotkey.toUpperCase();
			}
		}

		if(labelText.indexOf(charKey) > -1){
			actionLabel = flatMap(removeCharOnce(labelText, charKey), function (part) {
				return [part, <span key={"hotkeyChar-"+id} className="hotkey-label">{charKey}</span>];
			});
			actionLabel.pop();
		}else{
			actionLabel.push(<span key={"hotkeyChar-"+id}> {L(text)} [<span title={I("Shortcut")} className='hotkey-label'>{hotkey}</span>] </span>);
		}
		return <span key={"hotkeyLabel"+id}>{actionLabel}</span>;
	}else{
		return <span>{L(text)}</span>;
	}
};

export default Hotkeys;
