// higher order components for testing usage.
import React, { PureComponent } from "react";
import { compose, mapProps } from 'recompose';
// import update from 'immutability-helper';
// import classNames from "classnames";
// import { doNothing, cursorNotAllowed } from "./common";

export const withTestValue = Component => class extends PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.state = {value: ""};
	}
	handleChange(value) {
		this.setState({value});
	}
	render() {
		return (
			<Component
				onChange={this.handleChange}
				value={this.state.value}
				{...this.props}
			/>
		);
	}
};

function getRandomInt(max) {
	return Math.floor(Math.random() * Math.floor(max));
}

// Create random prop value for prop 'propName' with frequency change of
// 'frequencyChange' in ms base on the array 'randomList' where each element is
// possible value the prop. The chances of appearance will be equal among the
// element.
export const withRandomProp = (
	Component
	, propName
	, frequencyChange
	, randomList
) => class extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {[propName]: undefined};
	}
	componentDidMount() {
		this.timer = window.setInterval(() => {
			const randomInt = getRandomInt(randomList.length);
			this.setState({[propName]: randomList[randomInt]});
		}, frequencyChange);
	}
	componentWillUnmount() {
		if (this.timer) {
			window.clearInterval(this.timer);
			this.timer = null;
		}
	}
	render() {
		const { ...props } = this.props;
		props[propName] = this.state[propName];
		return <Component {...props} />;
	}
};

// Like withRandomProp but support multiple props via 'propsMapList'.
// 'propsMapList' is in the format:
//   [
//     {prop1Name: prop1Value1, prop2Name: prop2Value1}
//     , {prop1Name: prop1Value2, prop2Name: prop2Value2}
//     , {prop1Name: prop1Value3, prop2Name: prop2Value3}
//   ]
// 'initialIndex' can be used to change the first render props choice.
export const withRandomMultiProps = (
	Component
	, frequencyChange
	, initialIndex
	, propsMapList
) => class extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {randomIndex: initialIndex};
	}
	componentDidMount() {
		this.timer = window.setInterval(() => {
			const randomInt = getRandomInt(propsMapList.length);
			this.setState({randomIndex: randomInt});
		}, frequencyChange);
	}
	componentWillUnmount() {
		if (this.timer) {
			window.clearInterval(this.timer);
			this.timer = null;
		}
	}
	render() {
		const { ...props } = this.props;
		$.each(propsMapList[this.state.randomIndex], (k, v) => {
			props[k] = v;
		});
		return <Component {...props} />;
	}
};

// Do nothing on Component, return the same Component.
export const withIdentity = Component => Component;

export const withOverwriteProps = newProps => Component => props => (
	<Component {...props} {...newProps} />
);
