import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { I } from '../common/v5/config';
import Anchor from './Anchor';

const baseClassName = "c3-reload";

const ReloadBase = ({
	enableLabel
	, loading
	, loadingIconClassName
	, ...props
}) => {
	let label = null;
	if (loading) {
		if (enableLabel) {
			label = <span className="label">{I("Loading")}</span>;
		}
		return (
			<span className={baseClassName}>
				<i className={loadingIconClassName} aria-hidden="true" />
				{label}
			</span>
		);
	}
	const {
			className
			, reloadIconClassName
			, reloadIconWrapper: Tag
			, title
			, ...otherProps
		} = props;
	if (enableLabel) {
		label = <span className="label">{title}</span>;
	}
	return (
		<Tag {...otherProps} className={classNames(baseClassName, className)}>
			<i
				className={reloadIconClassName}
				title={title}
				aria-hidden="true"
			/>
			{label}
		</Tag>
	);
};

class Reload extends PureComponent {
	constructor(props) {
		super(props);
	}
	render() {
		const {
				loadingIconClassName
				, reloadIconClassName
				, reloadIconWrapper
				, icon
				, ...props
			} = this.props;
		return (
			<ReloadBase {...props}
				// className="loading" // TODO: seem useless
				loadingIconClassName="fa fa-spinner fa-spin"
				reloadIconClassName={reloadIconClassName ? reloadIconClassName : "icon-refresh"}
				reloadIconWrapper={Anchor}
				title={I('Reload')}
			/>
		);
	}
}

export default Reload;
