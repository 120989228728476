import React, { PureComponent, Fragment, memo, useState, useCallback, useEffect } from 'react';
import { createPortal } from 'react-dom';
import classNames from 'classnames';
import styled from 'styled-components';
import {
	Button
	, Modal
	, ModalHeader
	, ModalBody
	, ModalFooter
} from 'reactstrap';
import { TableIconicButton } from './Form';
import SimpleTable from './SimpleTable';
import { I } from '../common/v5/config';
import { PT_COLLABORATION, PT_CHAT } from '../common/v5/constants';
import {
	composeWithDisplayName
	, withUnmountWhenHidden
} from '../reactcomponents/hocs';

const StandardContent = ({body, content, head, children, withAnswer}) => {
	if (!head) head = null;
	return (
		<div>
			<table className="table table-bordered">
				{head}
				<tbody>
					{children}
					<tr>
						<td colSpan="2">
							{I('Subject')}: {content.subject}
						</td>
					</tr>
				</tbody>
			</table>
			<div>
				{withAnswer && <span>{I("Question: ")}</span>}
				<div dangerouslySetInnerHTML={{__html: content.message}} /><br />
			</div>
			{
				withAnswer &&
					<div><span>{I("Answer: ")}</span>
						<div dangerouslySetInnerHTML={{__html: content.answer}} /><br />
					</div>
			}
		</div>
	);
};

const IdAndDate = ({displayId, createdDate}) => (
	<tr>
		<th># {displayId}</th>
		<th>{I('Date')}: {createdDate}</th>
	</tr>
);

const FromAndTo = ({from, to}) => (
	<tr>
		<td>{I('From')}: {from}</td>
		<td>{I('To')}: {to}</td>
	</tr>
);
const CC = ({hidden, cc}) => (
	<tr hidden={hidden}>
		<td>{I('CC')}: {cc}</td>
		<td></td>
	</tr>
);

const StandardContentWithAnswer = withAnswer(StandardContent);

function withAnswer(Component) {
	return props => <Component {...props} withAnswer={true} />;
}

const ErrandContent = ({body, content}) => {
	const head = (
		<thead>
			<IdAndDate displayId={content.displayId} createdDate={content.created} />
		</thead>
	);
	return (
		<StandardContentWithAnswer body={body} content={content} head={head}>
			<FromAndTo from={content.from} to={content.to}/>
		</StandardContentWithAnswer>
	);
};

const ChatContent = ({body, content}) => {
	const head = (
		<thead>
			<IdAndDate displayId={content.displayId} createdDate={content.created} />
		</thead>
	);
	return (
		<StandardContent body={body} content={content} head={head}>
			<FromAndTo from={content.from} to={content.to}/>
		</StandardContent>
	);
};

const Collaboration = ({body, content}) => {
	let type
		, froms = []
		, tos = []
		, ccs = []
		, hidecc = false
		;
	if (content.from) {
		froms.push(content.from.name);
	}
	if (content.to) {
		tos.push(content.to);
	}
	if (content.cc) {
		ccs.push(content.cc);
	} else {
		hidecc = true;
	}
	if (content.answer) {
		type = I('Answer');
		if (content.fromInternal) {
			froms.push(content.fromInternal.name);
		}
	} else {
		type = I('Question');
		if (content.toInternals) {
			$.each(content.toInternals, (k, v) => {
				tos.push(v);
			});
		}
	}
	const head = (
		<thead>
			<tr>
				<th colSpan="2">{I('Type')}:&nbsp;{type}</th>
			</tr>
		</thead>
	);
	return <StandardContent body={body} content={content} head={head}>
				<IdAndDate displayId={content.id} createdDate={content.created} />
				<FromAndTo from={froms.join(", ")} to={tos.join(", ")}/>
				<CC hidden={hidecc} cc={ccs.join(", ")}/>
     	   </StandardContent>;
};

class BodyChild extends PureComponent {
	constructor (props) {
		super(props);
		this.displayName = "BodyChild";
	}
	componentDidMount () {
		this.props.onContentReady();
	}
	render () {
		const { id, contentRef, children } = this.props;
		return (
			<div id={id} className="cention-print-content" ref={contentRef}>
				{children}
			</div>
		);
	}
}

const Body = ({body, type, contentID, contentRef, show, printInfo, onContentReady, isChat}) => {
	if (!show) {
		return <ModalBody />;
	}
	let bodyContent = [], ContentTag;
	if (type === PT_COLLABORATION) {
		ContentTag = Collaboration;
	} else if (type === PT_CHAT) {
		ContentTag = ChatContent;
	} else {
		ContentTag = ErrandContent;
	}
	if (body !== null && body.content && body.content.length > 0) {
		$.each(body.content, (i,v) => {
			bodyContent.push(<ContentTag key={"printPreview_"+v.eid} body={body}
				content={v} isChat={isChat} />);
		});
	}
	return (
		<ModalBody>
			<BodyChild id={contentID} contentRef={contentRef}
				onContentReady={onContentReady}>
				<div className="print-info">{printInfo}</div>
				{bodyContent}
			</BodyChild>
		</ModalBody>
	);
}

class PrintModal extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClosed = this.handleClosed.bind(this);
		this.handleContentReady = this.handleContentReady.bind(this);
		this.handlePrintAction = this.handlePrintAction.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
		this.getContentRef = element => {
			this.contentRef = element;
		};
	}
	handleClosed () {
		if (typeof this.props.onClosed === 'function') {
			this.props.onClosed();
		}
	}
	handleContentReady () {
		if (typeof this.props.onContentReady === 'function') {
			this.props.onContentReady(this.props.id, this.contentRef.innerHTML);
		}
	}
	handlePrintAction () {
		this.props.onPrintAction(this.props.id, this.contentRef.innerHTML);
	}
	handleToggle () {
		const { id, show } = this.props;
		this.props.onToggle(id, show);
	}
	render () {
		const {
			id,
			contentID,
			show,
			headerText,
			isFooter,
			body,
			isChat,
			printInfo,
			...props
		} = this.props;
		let { type } = props;
		if (isChat) {
			type = PT_CHAT;
		}
		return (
			<Modal isOpen={show} size="lg" toggle={this.handleToggle}
				onClosed={this.handleClosed} className="c3-modal cention-modal">
				<ModalHeader>
					<span>{headerText}</span>
					<span onClick={this.handleToggle}
						className="modal-header-link">
						<i className="icon-times" />
					</span>
				</ModalHeader>
				<Body contentID={contentID} body={body} type={type} show={show} printInfo={printInfo} isChat={isChat}
					onContentReady={this.handleContentReady}
					contentRef={this.getContentRef} />
				{isFooter && <ModalFooter>
					<Button className="btn-grey" onClick={this.handleToggle}>
						{I("Cancel")}
					</Button>{' '}
					<Button className="btn-blue"
						onClick={this.handlePrintAction}>
						{I("Print")}
					</Button>
				</ModalFooter>}
			</Modal>
		);
	}
}

export class Print extends PureComponent {
	constructor(props) {
		super(props);
		this.handleToggle = this.handleToggle.bind(this);
	}
	handleToggle (id, show) {
		if (show && typeof this.props.onClose === "function") {
			this.props.onClose(show);
		}
	}
	render () {
		const {
			onToggle
			, onClose
			, ...props
		} = this.props;
		return <PrintModal {...props} onToggle={this.handleToggle} />;
	}
}

const ActionModalPrint = props => <PrintModal {...props} contentID="printableArea" />;

export default ActionModalPrint;

const Preformat = ({ className, content }) => (
	<pre className={classNames(className)}>{content}</pre>
)

const StyledPre = styled(Preformat)`
	white-space: pre-wrap;
	overflow-x: auto;
	word-break: break-word;
`
function DynamicInput(props) {
	const [values, setValues] = useState({ val: props.datas});

	function createInputs() {
		if(values.val === null){
			return null
		}
		if (values.val !== null){
			return values.val.map((el, i) =>
				<div key={i} className="form-group">
					<label htmlFor={i}>{el.title}</label>
					<input
						id={i}
						type="text"
						className="form-control"
						value={el.value || ''}
						onChange={handleChange.bind(i)}
					/>
				</div>
			);
		}
	}
	function handleChange(e) {
		let vals = [...values.val];
		vals[this].value = e.target.value;
		setValues({ val: vals });
		props.onHandle(values.val);
	}
	return (<div className="form-group">
			<label>{I("Template")}:</label>
			<div><StyledPre content={props.templ}></StyledPre></div>
			{values.val !== null && values.val.length > 0 && <span>{I("Template variable replacement")}:</span>}
			{createInputs()}
	</div>
	);
}
export class ModalPopup extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			answers: []
		}
	}
	handleToggle = () =>{
		this.props.onToggle(false);
	}
	handleSendPM = (e) =>{
		const { viewAs, templId, dataSrc } = this.props;
		if (viewAs === "template"){
			let varAnswer = this.state.answers;
			let content = {"templateId":templId};
			let finalTemplate =  dataSrc;
			varAnswer.forEach((v, i)=>{
				content[`"${v.title}"`] = v.value;
				finalTemplate = finalTemplate.replace(v.title, `<b>${v.value}</b>`);
			});
			this.props.onSend(content, finalTemplate);
			//cleanup state
			this.setState({answers: []});
		}else{
			this.props.onSend(this.props.id);
		}
		this.props.onToggle(false);
	}
	handleOnChange = (e) =>{
		this.props.onChange(this.props.id, e.target.value);
	}
	handleText = ( val ) => {
		this.setState({answers: val});
	}
	render = () =>{
		const {id, show, headerText, dataSrc, viewAs, isFooter} = this.props;
		let buttonTxt = I("Send");
		let columns = [
			{header: I("Created"), key: "created"},
			{header: I("Message"), key: "message"},
			{header: I("Ratings"), key: "rating", type:"emoticon"}
		];
		let ReactionColumns = [
			{header: I("Created"), key: "created"},
			{header: I("Sender name"), key: "senderName"},
			{header: I("Sender ID"), key: "senderId"},
			{header: I("Reaction"), key: "icon", type:"emoticon"}
		];
		let dataBody = "", modalSize = "", modalCustomClass = "", buttonClass = "";
		if(viewAs == 'list')
				dataBody = <SimpleTable columns={columns} dataSrc={dataSrc}/>
		if(viewAs === "emotlist")
				dataBody = <SimpleTable columns={ReactionColumns} dataSrc={dataSrc}/>
		if(viewAs === 'form'){
			dataBody = <div className="form-group">
				<label htmlFor="textarea">{I("Message")}</label>
				<textarea onChange={this.handleOnChange} defaultValue={dataBody} className="form-control" id="textarea" rows="3"></textarea>
			</div>
		}
		if(viewAs === 'file-preview'){
			dataBody = dataSrc;
			modalSize = "lg";
			modalCustomClass = " preview-modal";
		}
		if(viewAs === "template"){
			if (dataSrc !== ""){
				buttonTxt = I("Insert");
				buttonClass = " btn-blue";
				const regex = /\{{(\d+)}}/g;
				let cm = dataSrc.match(regex);
				let contents = [];
				if(cm !== null){
					cm.forEach((v, i) =>{
						contents.push({title: v, value: ""});	
					});
				}
				dataBody = <DynamicInput onHandle={this.handleText} datas={contents} templ={dataSrc}/>
			}
		}
		return (
			<Modal size={modalSize} id={id} isOpen={show} toggle={this.handleToggle} className={"c3-table-modal cention-modal "+modalCustomClass}>
				<ModalHeader toggle={this.handleToggle}>{headerText}</ModalHeader>
				<ModalBody>
					{dataBody}
				</ModalBody>
				{isFooter && <ModalFooter>
					<Button className={"btn btn-info" + buttonClass} onClick={this.handleSendPM}>{buttonTxt}</Button>
				</ModalFooter>}
			</Modal>
		);
	}
}



//Toast
const TOAST_PRIMARY = "primary",
	TOAST_SECONDARY = "secondary",
	TOAST_SUCCESS = "success",
	TOAST_DANGER = "danger",
	TOAST_WARNING = "warning",
	TOAST_INFORMATION = "information"

export const TOAST_POSITION = {
	topLeft: "top-left",
	topRight: "top-right",
	top: "top",
	bottom: "bottom",
	bottomLeft: "bottom-left",
	bottomRight: "bottom-right",
}

export const TOAST_TYPE = {
		[TOAST_PRIMARY]: {class: TOAST_PRIMARY, icon: "icon-bell"},
		[TOAST_SECONDARY]: {class: TOAST_SECONDARY, icon: "icon-bell"},
		[TOAST_SUCCESS]: {class: TOAST_SUCCESS, icon: "icon-single-tick"},
		[TOAST_DANGER]: {class: TOAST_DANGER, icon: "icon-caution"},
		[TOAST_WARNING]: {class: TOAST_WARNING, icon: "icon-alert"},
		[TOAST_INFORMATION]: {class: TOAST_INFORMATION, icon: "icon-alert"}
	}

const ToastItem = ({id, title, type, onRemoveToast}) => {
	const [visible, setVisible] = useState(false);
	const animationDuration = 1000

	const handleClose = () => {
		setVisible(false);
		setTimeout(()=> onRemoveToast(id),animationDuration)
	  }

	useEffect(() => {
		const showToast = setTimeout(() => setVisible(true), animationDuration);
		const removeToast = setInterval(()=> handleClose(), 4000)
		return () => {
			clearTimeout(showToast);
			clearInterval(removeToast);
		};
	}, [id, onRemoveToast]);

	return (
		<div className={`toast__item ${type.class} ${visible ? "enter" : "exit"}`}>
			<div className='toast__item-icon'>
				<i className={type.icon}></i>
			</div>
			<div className='toast__item-content'>
				{title}
			</div>
			<TableIconicButton
				className={"toast__item-button btn-transparent"}
				iconClass={"icon-times"}
				onClick={handleClose}
				title={I("Close")}
			/>
		</div>
	)
}

export const ToastAlertBase = ({ list, onUpdateToastList }) => {

	const removeToast = useCallback( id => {
		const toastListItem = list.filter( l => l.id !== id);
		onUpdateToastList(toastListItem);
	},[list]);

	const groupedItems = list.reduce((groups, item) => {
		const position = item.position;
		if (!groups[position]) {
			groups[position] = [];
		}
		groups[position].push(item);
		return groups;
	}, {});

	return (
		<div className='toast__container' hidden={list.length <= 0}>
			{Object.keys(groupedItems).map(position => (
				<div className={"grid " + position} key={position}>
					{groupedItems[position].map(item => (
						<ToastItem
							key={item.id}
							id={item.id}
							title={item.text}
							type={item.type}
							position={item.position}
							onRemoveToast={removeToast}
						/>
					))}
				</div>
			))}
		</div>
	)
}
export const ToastAlert = composeWithDisplayName(
	'ToastAlert',
	memo,
	withUnmountWhenHidden
)(ToastAlertBase)

export const ModalBox = ({
    show
    , className
    , onClose
    , children
    , headerContent
	, withOutsideClick = false //default value
}) => {

	const handleOutsideClick = (e) => {
		// ensure the outside click is specifically on the targeted element (modal's background)
		// and not on any of its child
        if (withOutsideClick && e.target === e.currentTarget) {
            onClose();
        }
    };

    return (
        <div className={`${className} v5-modal popup ` + (show ? "open" : "")}
			onClick={handleOutsideClick}
		>
            <div className='popup-inner'>
                <div className='popup-header'>
                    {headerContent}
                    <TableIconicButton
                        className={"popup-close btn-transparent"}
                        iconClass={"icon-times"}
                        onClick={onClose}
                        title={I("Close")}
                    />
                </div>
                <div className='popup-content'>
                    {children}
                </div>
            </div>
        </div>
    )
}

//modal box that mounts at 'body' dom
const withPortal = (WrappedComponent) => {
	return function WithPortal(props) {
		if (!props.show) {
			return null;
		}

		return createPortal(
			<WrappedComponent {...props} />,
			document.body
		);
	};
}

export const PortalModalBox = withPortal(ModalBox);