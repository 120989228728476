import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';

class OneSnoopAction extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(e) {
		const p = this.props;
			console.info(p);
		this.props.clickAction(p.actionType, p.id);
	}
	render() {
		const actionType = this.props.actionType,
			id = this.props.id, useId = this.props.useId;
		return(<li style={{borderBottom: '0px'}}>
			<div onClick={this.handleClick} data-qa-id={"QA_SnoopAction_"+actionType+"_"+id + useId} key={useId} className="errand-message-actions-button">
				<span>{actionType}</span>
			</div>
		</li>
		);
	}
}

class StaticSnoopActions extends React.PureComponent {
	constructor(props){
		super(props);
		this.state = {
			hideActions: true
		}
		this.handleToggleActions = this.handleToggleActions.bind(this);
		this.handleClickOutside = this.handleClickOutside.bind(this);
		this.handleSnoopAction = this.handleSnoopAction.bind(this);
	}
	handleToggleActions(){
		this.setState({hideActions: !this.state.hideActions})
	}
	handleClickOutside() {
		if(!this.state.hideActions)
			this.setState({hideActions: true})
	}
	handleSnoopAction(actionType, id){
		if(!this.state.hideActions)
			this.setState({hideActions: true})
		if(typeof this.props.handleClick !== "undefined"){
			this.props.handleClick(actionType, id)
		}
	}
	render(){
		let snoopActions = [];
		let dSrc = this.props.src;
		snoopActions.push(<OneSnoopAction actionType="listen" key="listen" id={dSrc.sip} clickAction={this.handleSnoopAction} useId={"id"+Math.random()}/>);
		snoopActions.push(<OneSnoopAction actionType="whisper" key="whisper" id={dSrc.sip} clickAction={this.handleSnoopAction} useId={"id"+Math.random()}/>);
		snoopActions.push(<OneSnoopAction actionType="barge" key="barge" id={dSrc.sip} clickAction={this.handleSnoopAction} useId={"id"+Math.random()}/>);
		return(
		<div>
			<i className={"icon-spread fa-rotate-90"} onClick={this.handleToggleActions}>
			</i>
			<div className={"errand-message-actions-links"} hidden={this.state.hideActions}>
				<ul>
					{snoopActions}
				</ul>
			</div>
		</div>
		);
	}
}

const SnoopActions = onClickOutside(StaticSnoopActions);

export { SnoopActions, StaticSnoopActions };
