import React, { memo, useCallback, useEffect, useState } from 'react';
import { SelectBoxCallbackData } from './SelectBox';
import { L } from '../common/v5/config'

const attributeOptions = [
	{name: I('Errand'), id: 3, attr: 'id'}
	, {name: I('Subject'), id: 4, attr: 'subject'}
	, {name: I('Contact'), id: 5, attr: 'from'}
	, {name: I('Date'), id: 6, attr: 'date'}
	, {name: I('Channel'), id: 7, attr: 'channel'}
	, {name: I('Attachment'), id: 8, attr: 'attachment'}
	, {name: I('Organization'), id: 9, attr: 'organisationName'}
	, {name: I('Area'), id: 10, attr: 'areaName'}
	, {name: I('Address To'), id: 11, attr: 'addressTo'}
	, {name: I('Priority'), id: 12, attr: 'priority'}
	, {name: I('Due Date'), id: 13, attr: 'donedate'}
	, {name: I('Postpone Date'), id: 14, attr: 'postponeDate'}
];

const directionOptions = [
	{name: I('Ascending'), id: 1, attr: 'sort', dir: 'asc'}
	, {name: I('Descending'), id: 2, attr: 'sort', dir: 'desc'}
];

const multi = [
	{
		options: "options"
		, order: "order"
		, selected: "listCurrentSorting"
		, selectedGetter: "selectedGetter"
	}
	, {
		options: "directionOptions"
		, order: "directionOrder"
		, selected: "listSortDirection"
		, selectedGetter: "directionSelectedGetter"
	}
];

const ListSortByFiltersBase = ({
	listCurrentSortName
	, listCurrentSorting
	, listSortDirection
	, onSelectSort
	, onToggleSort
	, selectedSort
	, showSortSelection
	, ...props
}) => {
	const [attributeOptionsNew, setAttributeOptionsNew] = useState(attributeOptions);
	const [directionOptionsNew, setDirectionOptionsNew] = useState(directionOptions);
	useEffect(()=>{
		let aol = attributeOptions;
		let dol = directionOptions;
		if(typeof TranslationDictionary !== 'undefined'){
			aol = attributeOptions.map(v => ({ name: L(v.name), id: v.id, attr: v.attr }));
			setAttributeOptionsNew(aol);
			dol = directionOptions.map(v => ({ name: L(v.name), id: v.id, attr: v.attr, dir: v.dir }));
			setDirectionOptionsNew(dol);
		}
	}, [])

	return <SelectBoxCallbackData
		className="conversations-sort-select"
		data-qa-id={props["data-qa-id"]}
		directionOptions={directionOptionsNew}
		directionSelectedGetter="dir"
		doNotShowSelectedValue={true}
		listCurrentSorting={listCurrentSorting}
		listSortDirection={listSortDirection}
		maxHeight='972px'
		multi={multi}
		onSelect={useCallback(
			(_, { attr, dir, id, name }) => {
				onSelectSort(attr, id, dir, name);
			}
			, [onSelectSort]
		)}
		onToggleShow={onToggleSort}
		options={attributeOptionsNew}
		selected={selectedSort}
		selectedClass="sel selected filter"
		selectedGetter="attr"
		show={showSortSelection}
		sort={true}
		textNoItemSelected={I('By {currentSortName}').replace(
			'{currentSortName}'
			, L(listCurrentSortName)
		)}
		top='26px'
		visible={true}
	/>
};

const ListSortByFilters = memo(ListSortByFiltersBase);

export default ListSortByFilters;
