// generic component for plus button and text.
import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import isPropValid from '@emotion/is-prop-valid'
import styled from 'styled-components'
import { I } from '../common/globals'
import Anchor from './Anchor'

export const TXT_ADD_MORE = I('add more')

const addButtonStyle = `
  content: "+";
  background-color: #bcdffd;
  font-weight: 700;
  padding: 1px 4px;
  border-radius: 4px;
  margin: 0 4px;
  text-align: center;
`
const StyledA = styled(Anchor, { shouldForwardProp: isPropValid })`
  ${({ addButtonOnRight }) => {
    if (addButtonOnRight) {
      return `&::after {${addButtonStyle}}`
    }
    return `&::before {${addButtonStyle}}`
  }}
`
const AddMore = ({
  className,
  innerClassName,
  innerRef,
  style,
  tag: Component = 'span',
  ...props
}) => (
  <Component ref={innerRef} className={className} style={style}>
    <StyledA className={classNames('anchor', innerClassName)} {...props} />
  </Component>
)

AddMore.propTypes = {
  addButtonOnRight: PropTypes.bool,
  className: PropTypes.string,
  innerClassName: PropTypes.string,
  tag: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

export default AddMore
