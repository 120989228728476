// higher order components
import React, {
	PureComponent
	, forwardRef
	, useEffect
	, useMemo
	, useRef
	, useState
} from "react";
import omit from 'lodash/fp/omit';
import {
	branch
	, compose
	, defaultProps
	, lifecycle
	, mapProps
	, renderNothing
	, setDisplayName
	, wrapDisplayName
	, withHandlers
	, withProps
	, withState
} from 'recompose';
import update from 'immutability-helper';
import classNames from "classnames";
import Measure from 'react-measure';
import onClickOutside from 'react-onclickoutside';
import { AutoSizer } from 'react-virtualized';
import {
	UNSELECT,
	emptyObject,
	CT_LINE,
	CT_VERT_BAR,
	CT_PIE,
} from "../common/v5/constants";
import {
	POINTER_CURSOR
	, cursorNotAllowed
	, cursorPointer
	, doNothing
} from "./common";

export const omitProps = compose(mapProps, omit);

const createWithDisplayName = name => Component => {
	if (process.env.NODE_ENV !== 'production') {
		return setDisplayName(wrapDisplayName(Component, name))(Component);
	}
	return Component;
};

export const composeWithDisplayName = (name, ...hoc) => compose(
	createWithDisplayName(name)
	, ...hoc
);

export const removeProps = arrayOfPropsToBeRemoved => mapProps(
	props => update(props, {$unset: arrayOfPropsToBeRemoved})
);

export const mergeStyleIfExist = (exist, want) => {
	if (exist) {
		return update(exist, {$merge: want});
	}
	return want;
};

const cursorStyle = disabled => disabled ? cursorNotAllowed : cursorPointer
	, stylePositionAbsolute = {position: "absolute"}
	, elipsisInlineStyleCreator = maxWidth => ({
		display: "inline-block"
		, overflow: "hidden"
		, textOverflow: "ellipsis"
		, verticalAlign: "middle"
		, whiteSpace: "nowrap"
		, maxWidth: maxWidth + "px"
	})
	;
export const withDisableAbleClick = withProps(({ disabled, style }) => ({
	style: mergeStyleIfExist(style, cursorStyle(disabled))
}));

export const usePropsDebug = (propsName, props) => {
	const arrayOfProp = [];
	propsName.forEach(prop => arrayOfProp.push(props[prop]));
	useEffect(() => {
		propsName.forEach(prop => {
			if (process.env.NODE_ENV !== 'production') {
				console.log("debug props:", {[propsName]: props[prop]});
			}
		});
	}, arrayOfProp);
};

// util HOC creator to print props value when it is updated.
export const createWithPropsDebug = propsName => Component => props => {
	usePropsDebug(propsName, props);
	return <Component {...props} />;
};

// HOC that create the style which allows browser to do ellipsis.
export const withElipsisInline = mapProps(({
	children
	, maxWidth
	, style
	, title
	, ...props
}) => ({
	children
	, style: mergeStyleIfExist(style, elipsisInlineStyleCreator(maxWidth))
	, title: typeof children === "string" ? children : title
	, ...props
}));

// HOC that detect if the wrapped component ellipsis-ed by browser and pass a
// isEllipsised prop for underneath component to use. It base on answer from
// https://stackoverflow.com/a/7739113/1778714 which should work across all
// browsers (even < IE9) but slower though.
export const withElipsisDetection = Component => props => {
	const innerRef = useRef(null)
		, [ isEllipsised, setIsEllipsised ] = useState(false)
		;
	useEffect(() => {
		if (innerRef) {
			const el = $(innerRef.current)
				, cloned = el
					.clone()
					.css({
						display: 'inline-block'
						, width: 'auto'
						, visibility: 'hidden'
					})
					.appendTo('body')
				;
			setIsEllipsised(cloned.width() > el.width());
			cloned.remove();
		}
	}, [innerRef, setIsEllipsised, props.children]);
	return (
		<Component
			forwardedRef={innerRef}
			isEllipsised={isEllipsised}
			{...props}
		/>
	);
};

export const withRestoreForwardedRef = Component => ({
	forwardedRef
	, ...props
}) => <Component ref={forwardedRef} {...props} />;

export const withUnmountEmptyChild = branch(
	({ children }) => !children
	, renderNothing
);

function withTypeOnClickBase(Component) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClick = this.handleClick.bind(this);
		}
		handleClick(e) {
			this.props.onClick(this.props.type, e);
		}
		render() {
			const { onClick, type, ...props } = this.props;
			let clickHandler;
			if (typeof onClick === "function") {
				clickHandler = this.handleClick;
			}
			return <Component {...props} onClick={clickHandler} />;
		}
	}
}

export const withTypeOnClick = composeWithDisplayName(
	"withTypeOnClick"
	, withTypeOnClickBase
);

export const createUnclickable = (
	handlerName
	, propName
	, mergeStyle
) => withProps(({ style, ...props }) => {
	if (props[propName]) {
		const result = {[handlerName]: doNothing};
		if (mergeStyle) {
			result.style = mergeStyleIfExist(style, mergeStyle);
		}
		return result;
	}
	return emptyObject;
});

export const withDisableableOnClick = composeWithDisplayName(
	"withDisableableOnClick"
	, Component => createUnclickable(
		"onClick"
		, "disabled"
		, cursorNotAllowed
	)(Component)
);

export const withDisableableTypeOnClick = composeWithDisplayName(
	"withDisableableTypeOnClick"
	, withTypeOnClick
	, withDisableableOnClick
);

export const withDisabledClassNameWhenDisabled = composeWithDisplayName(
	"withDisabledClassNameWhenDisabled"
	, Component => ({ className, ...props }) => {
		if (props.disabled) {
			className = classNames(className, "disabled");
		}
		return <Component {...props} className={className} />;
	}
);

const dropEffect = "none";

function withDisableDragAndDropOptionBase(Component) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleDragEnter = this.handleDragEnter.bind(this);
			this.handleDragOver = this.handleDragOver.bind(this);
			this.handleDrop = this.handleDrop.bind(this);
		}
		handleDragOver(e) {
			if (typeof this.props.onDragOver === "function") {
				this.props.onDragOver(e);
			}
			e.preventDefault();
			e.dataTransfer.dropEffect = dropEffect;
		}
		handleDragEnter(e) {
			if (typeof this.props.onDragEnter === "function") {
				this.props.onDragEnter(e);
			}
			e.dataTransfer.dropEffect = dropEffect;
		}
		handleDrop(e) {
			if (typeof this.props.onDrop === "function") {
				this.props.onDrop(e);
			}
			e.preventDefault();
		}
		render() {
			const {
					noDefaultDragAndDrop
					, onDragEnter
					, onDragOver
					, onDrop
					, ...props
				} = this.props
				;
			let handleDragEnter
				, handleDragOver
				, handleDrop
				;
			if (noDefaultDragAndDrop) {
				handleDragEnter = this.handleDragEnter;
				handleDragOver = this.handleDragOver;
				handleDrop = this.handleDrop;
			} else {
				handleDragEnter = onDragEnter;
				handleDragOver = onDragOver;
				handleDrop = onDrop
			}
			return (
				<Component {...props}
					onDragEnter={handleDragEnter}
					onDragOver={handleDragOver}
					onDrop={handleDrop}
				/>
			);
		}
	}
}

export const withDisableDragAndDropOption = composeWithDisplayName(
	"withDisableDragAndDropOption"
	, withDisableDragAndDropOptionBase
);

function createHideableUsingUnmount(propName) {
	return Component => props => {
		if (props[propName]) {
			return null;
		}
		props = update(props, {$unset: [propName]});
		return <Component {...props} />;
	};
}

function withUnmountWhenHiddenBase(Component) {
	return createHideableUsingUnmount("hidden")(Component);
}

export const withUnmountWhenHidden = composeWithDisplayName(
	"withUnmountWhenHidden"
	, withUnmountWhenHiddenBase
);

export const createWithHideCondition = condition => composeWithDisplayName(
	"withHideCondition"
	, withProps(props => {
		if (condition(props)) {
			return {hidden: true}
		}
	})
	, withUnmountWhenHidden
);

export const createWithMountCondition = condition => createWithHideCondition(props => !condition(props));

export const createWithMountWhenPropTrue = propName => compose(
	mapProps(({ hidden, ...props }) => {
		const value = props[propName];
		return {hidden: !value, ...props}
	})
	, withUnmountWhenHidden
);

export const withMountWhenShow = composeWithDisplayName(
	"withMountWhenShow"
	, createWithMountWhenPropTrue("show")
);

function withSizeControlBase(Component) {
	return ({ height, square, ...props }) => (
		<AutoSizer disableHeight>
		{({ width }) => {
			const widthStr = width + "px"
				, style = {position: "relative", width: widthStr}
				, heightType = typeof height
				;
			if (heightType !== "undefined") {
				if (heightType === "number") {
					style.height = height+"px";
				} else {
					style.height = height;
				}
			} else if (square) {
				style.height = widthStr;
			}
			return <Component {...props} style={style} />;
		}}
		</AutoSizer>
	);
}

export const withSizeControl = composeWithDisplayName(
	"withSizeControl"
	, withSizeControlBase
);

function withChartSizerBase(Component, SizerComponent) {
	return ({ height, imageGetterRef, square, ...props }) => (
		<SizerComponent height={height} square={square}>
			<Component ref={imageGetterRef} {...props} />
		</SizerComponent>
	);
}

export const withChartSizer = composeWithDisplayName(
	"withChartSizer"
	, withChartSizerBase
);

function calculateBarHeight(calculate, data, chartType) {
	// fixed height for specific chart type
	if(chartType === CT_LINE || chartType == CT_VERT_BAR) {
		return 400;
	} else if (chartType === CT_PIE) {
		return 250;
	}
	if (!calculate || !data || !data.labels || !data.labels.length) {
		return;
	}
	return data.labels.length*25 + 139;
}

function withHeightCalculationBase(Component) {
	return ({ calculateHeight, ...props }) => {
		return (
			<Component
				{...props}
				height={calculateBarHeight(calculateHeight, props.data, props.type)}
			/>
		);
	};
}

export const withHeightCalculation = composeWithDisplayName(
	"withHeightCalculation"
	, withHeightCalculationBase
);

function createWithToggleable(Component, propName, propCallbackName) {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.state = {
				show: false
			};
			this.handleToggle = this.handleToggle.bind(this);
		}
		handleToggle(e) {
			this.setState({show: !this.state.show});
		}
		render() {
			const { ...props } = this.props;
			props[propCallbackName] = this.handleToggle;
			props[propName] = this.state.show;
			return <Component {...props} />;
		}
	};
}

const onToggle = "onToggle";

export function withShowToggle(Component) {
	return createWithToggleable(Component, "show", onToggle);
}

function withToggleBase(Component) {
	return createWithToggleable(Component, "toggle", onToggle);
}

export const withToggle = composeWithDisplayName(
	"withToggle"
	, withToggleBase
);

export const createWithToggleStateCallback = (propName, propCallbackName) =>
	withHandlers({[propCallbackName]: props => e => props[propCallbackName](!props[propName])});

export const createWithToggleStateOnClick = propName =>
	createWithToggleStateCallback(propName, "onClick");

export const createWithPropAttachedAction = (
	idField
	, actionField
	, idFieldArgumentPosition
	, removeProp
) => Component => class extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleAction = this.handleAction.bind(this);
	}
	handleAction(...args) {
		const callback = this.props[actionField];
		if (typeof callback === "function") {
			const value = this.props[idField];
			if (typeof idFieldArgumentPosition !== "number") {
				callback(value, ...args);
				return;
			}
			const before = args.slice(0, idFieldArgumentPosition)
				, after = args.slice(idFieldArgumentPosition)
				;
			before.push(value);
			callback.apply(undefined, before.concat(after));
		}
	}
	render() {
		const { ...props } = this.props;
		if (removeProp) {
			// avoid Component carry unnecessary field
			delete props[idField];
		}
		props[actionField] = this.handleAction;
		return <Component {...props} />;
	}
};

export const createWithPropAttachedCallback = (
	idField
	, actionField
	, removeProp
) => createWithPropAttachedAction(idField, actionField, undefined, removeProp);

// WRONG: DO NOT USE THIS
// For ID field, always put the parent on most left side of argument position,
// so that this HOC can be chained without the need to set
// idFieldArgumentPosition.
export const createWithIdAttachedAction = (
	Component
	, idField
	, actionField
	, idFieldArgumentPosition
) => createWithPropAttachedAction(
	idField
	, actionField
	, idFieldArgumentPosition
	, true
)(Component);

// For ID field, always put the parent on most left side of argument position,
// so that this HOC can be chained without the need to set
// idFieldArgumentPosition.
export const createWithPropAttachedActionThenRemoveProp = (
	propName
	, actionName
	, propFieldArgumentPosition
) => createWithPropAttachedAction(
	propName
	, actionName
	, propFieldArgumentPosition
	, true
);

export const createWithPropAttachedOnClick = (
	propName
	, propFieldArgumentPosition
) => createWithPropAttachedActionThenRemoveProp(
	propName
	, "onClick"
	, propFieldArgumentPosition
);

export const withIdAttachedOnClick = composeWithDisplayName(
	"withIdAttachedOnClick"
	, createWithPropAttachedOnClick("id")
);

export function createWithClickOutsideToggleFalse(toggleHandlerName) {
	return Component => class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleClickOutside = this.handleClickOutside.bind(this);
		}
		handleClickOutside() {
			this.props[toggleHandlerName](false);
		}
		render() {
			return <Component {...this.props} />;
		}
	};
}

export function createWithCheckValidHandler(handlerName) {
	return Component => class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handle = this.handle.bind(this);
		}
		handle(...args) {
			const callback = this.props[handlerName];
			if (typeof callback === "function") {
				callback(...args);
			}
		}
		render() {
			const { ...props } = this.props;
			props[handlerName] = this.handle;
			return <Component {...props} />;
		}
	};
}

function checkIsArray(array) {
	if (typeof array === "object" && typeof array.length === "number") {
		return true;
	}
	return false;
}

function isArray(array) {
	if (Array.isArray) {
		return Array.isArray(array);
	}
	return checkIsArray(array);
}

function stringOrFunction(sOrf, ...args) {
	if (typeof sOrf === "function") {
		return sOrf(...args);
	}
	return sOrf + "";
}

export const createHandlerAttachData = (
	handlerName
	, optionsPropNameGetter
) => props => (id, ...args) => {
	const optionsPropName = stringOrFunction(
		optionsPropNameGetter
		, props
		, id
		, ...args
	);
	return props[handlerName](id, props[optionsPropName][id], ...args);
};

// Attached normalized data to callback. Expecting callback with id and the data
// is always attach to second argument of callback. 'optionsPropName' is string
// or function which return string.
export const createWithCallbackSelectedData = (
	optionsPropNameGetter
	, handlerPropNameGetter
) => withHandlers(initialProps => {
	const handlerCreator = handlerName => createHandlerAttachData(
			handlerName
			, optionsPropNameGetter
		)
		, handlerNames = stringOrFunction(handlerPropNameGetter, initialProps)
		, type = typeof handlerNames
		, result = {}
		;
	let loop
		;
	if (type === "string") {
		loop = fn => fn(handlerNames);
	} else if (isArray(handlerNames)) {
		loop = fn => handlerNames.forEach(fn);
	} else if (type === "object") {
		loop = fn => $.each(handlerNames, fn);
	} else {
		loop = fn => doNothing;
	}
	loop(name => { result[name] = handlerCreator(name); });
	return result;
});

// export function createWithCallbackSelectedData(optionsPropName, handlerName) {
// 	return Component => class extends React.PureComponent {
// 		constructor(props) {
// 			super(props);
// 			this.handle = this.handle.bind(this);
// 		}
// 		handle(id, ...args) {
// 			const { [handlerName]: callback, ...props } = this.props
// 				, optionsProp = stringOrFunction(
// 					optionsPropName
// 					, props
// 					, id
// 					, ...args
// 				)
// 				;
// 			return callback(id, props[optionsProp][id], ...args);
// 		}
// 		render() {
// 			const { ...props } = this.props;
// 			props[handlerName] = this.handle;
// 			return <Component {...props} />;
// 		}
// 	};
// }

// Helper to create automatic callback base on 'condition' function that take
// two arguments, current props and previous props. If it is componentDidMount
// then previous props is an empty key object.
function createAutoCallback(condition) {
	return lifecycle({
		componentDidMount: function() {
			condition(this.props, emptyObject);
		}
		, componentDidUpdate: function(prevProps) {
			condition(this.props, prevProps);
		}
	});
}

export const createWithEffectCondition = createAutoCallback;

function propStringOrFunction(sOrf, props, ...args) {
	if (typeof sOrf === "function") {
		return sOrf(props, ...args);
	}
	return props[sOrf + ""];
}

export const makeAutoResetInvalidSelectionCondition = (
	optionsPropNameGetter
	, selectedPropNameGetter
	, handlerGetter
	, ...args
) => (props, prevProps) => {
	const optionsPropName = stringOrFunction(
			optionsPropNameGetter
			, props
			, prevProps
		)
		, options = props[optionsPropName]
		, selected = props[stringOrFunction(
			selectedPropNameGetter
			, props
			, prevProps
		)]
		;
	if (options !== prevProps[optionsPropName] && selected !== UNSELECT) {
		if (typeof options[selected] === "undefined") {
			propStringOrFunction(handlerGetter, props, prevProps)(
				UNSELECT
				, ...args
			);
		}
	}
};

export const createWithAutoResetInvalidSelection = (
	optionsPropName
	, selectedPropName
	, handlerName
) => createAutoCallback(makeAutoResetInvalidSelectionCondition(
	optionsPropName
	, selectedPropName
	, handlerName
));

export function createWithQADataId(prefix, identifierProp) {
	return Component => props => (
		<Component {...props} data-qa-id={prefix+props[identifierProp]} />
	);
}

function createWithSingleSelectionDetectionBase(isSelected, single, handlerName) {
	return createAutoCallback((props, prevProps) => {
		if (isSelected(props)) {
			return;
		}
		const item = single(props, prevProps);
		if (typeof item !== "undefined") {
			const handler = props[handlerName];
			if (typeof handler === "function") {
				handler(item);
			}
		}
	});
}

function createNestedArrayDataDetector(nestedName, optionsPropName) {
	return (props, prevProps) => {
		const data = props[optionsPropName];
		if (data !== prevProps[optionsPropName]) {
			let item, total = 0;
			$.each(data, (i, v) => {
				const nestedData = v[nestedName];
				$.each(v[nestedName], (j, w) => {
					total++;
					if (total > 1) {
						return false;
					}
					item = w;
				});
				if (total > 1) {
					return false;
				}
			});
			if (total === 1) {
				return item;
			}
		}
	};
}

function createIsSelectedTrueWhenMoreThanZero(selectedPropName) {
	return props => {
		let selected = props[selectedPropName];
		if (typeof selected === "string") {
			selected = parseInt(selected, 10);
		}
		if (selected > 0) {
			return true;
		}
		return false;
	};
}

const isSelected = createIsSelectedTrueWhenMoreThanZero("selected");

function createWithSingleSelectionDetection(single, handlerName) {
	return createWithSingleSelectionDetectionBase(
		isSelected
		, single
		, handlerName
	);
}

// create HOC that trigger onSingleOption when singleGetter return non-undefined
// value. Parent component must has 'selected' prop as currently selected option
// and considered has valid selection when 'selected' > 0.
export function createWithSingleOptionHandler(singleGetter) {
	return createWithSingleSelectionDetection(singleGetter, "onSingleOption");
}

// work like createWithSingleOptionHandler but specifically for areas dropdown
// which has nested data format and selection option that is passed through
// 'data' prop.
export const withSingleAreaOptionDetect = createWithSingleOptionHandler(createNestedArrayDataDetector("Areas", "data"));

export const createWithPointerWhenClickable = (
	eventName
	, inactive
) => Component => ({
	[inactive]: notActive
	, [eventName]: callback
	, ...props
}) => {
	let onEvent;
	if (!notActive && typeof callback === "function") {
		let updater;
		if (props.style) {
			updater = {$merge: POINTER_CURSOR};
		} else {
			updater = {$set: POINTER_CURSOR};
		}
		props = update(props, {
			style: updater
			, [eventName]: {$set: callback}
			, className: {$set: classNames(props.className, "pointer")}
		});
	}
	return <Component {...props} />;
};

export const withPointerClickableOnClick = composeWithDisplayName(
	"withPointerClickableOnClick"
	, createWithPointerWhenClickable("onClick", "unclickable")
);

const createWithAutoResetRemountLifeCycles = (
	propName
	, handlerName
) => createAutoCallback((props, prevProps) => {
	const currentValue = props[propName];
	if (currentValue && currentValue !== prevProps[propName]) {
		props[handlerName](false);
	}
});

const createWithAutoResetRemount = (
	propName
	, handlerName
) => composeWithDisplayName(
	"withAutoResetRemount"
	, createWithAutoResetRemountLifeCycles(propName, handlerName)
	, renderNothing
);

export const createWithRemounting = (
	propName
	, handlerName
) => composeWithDisplayName(
	"withRemounting"
	, branch(
		props => props[propName]
		, createWithAutoResetRemount(propName, handlerName)
	)
);

const createWithOnClickOutsideBase = handlerName => Component => {
	return class extends PureComponent {
		constructor(props) {
			super(props);
			this.handleClickOutside = this.handleClickOutside.bind(this);
		}
		handleClickOutside(evt) {
			this.props[handlerName](evt);
	  }
		render() {
			const { [handlerName]: handler, mainProps } = this.props;
			return <Component {...mainProps} />;
		}
	};
};

// helper to provide on click outside at the same time allow it to disable the
// on click outside by providing a falsy value to handler.
export const createWithOnClickOutside = handlerName => composeWithDisplayName(
	"withOnClickableOutside"
	, branch(
		({ [handlerName]: handler }) => typeof handler === "function"
		, composeWithDisplayName(
			"withOnClickOutside"
			, mapProps(({ [handlerName]: handler, ...props }) => ({
				[handlerName]: handler
				, mainProps: props
			}))
			, onClickOutside
			, createWithOnClickOutsideBase(handlerName)
		)
	)
	, omitProps([handlerName, "disableOnClickOutside"])
);

// NOTE: please NOT remove as it may be helpful in future.
// const withCountdown = Component => class extends React.PureComponent {
// 	constructor(props) {
// 		super(props);
// 		this.handleCancel = this.handleCancel.bind(this);
// 		this.state = {
// 			tick: this.props.defaultTick
// 		};
// 	}
// 	handleCancel() {
// 		if (this.timer) {
// 			clearInterval(this.timer);
// 			this.timer = null;
// 		}
// 	}
// 	componentDidMount() {
// 		this.timer = setInterval(
// 			() => {
// 				const nextTick = this.state.tick - 1;
// 				this.setState({tick: nextTick});
// 				if (!nextTick) {
// 					this.handleCancel();
// 					if (typeof this.props.onTrigger === "function") {
// 						this.props.onTrigger();
// 					}
// 				}
// 			}
// 			, this.props.defaultTimer
// 		);
// 	}
// 	componentWillUnmount() {
// 		this.handleCancel();
// 	}
// 	render() {
// 		const { onCancel, tick, ...props } = this.props;
// 		return (
// 			<Component
// 				onCancel={this.handleCancel}
// 				tick={this.state.tick}
// 				{...props}
// 			/>
// 		);
// 	}
// };
//
// const onToggleShowCallback = "toggleMountState";
//
// const createWithToggleableMountState = (
// 	stateName
// 	, actionName
// ) => composeWithDisplayName(
// 	"createWithToggleableMountState"
// 	, withState(stateName, onToggleShowCallback, true)
// 	, branch(({ [stateName]: show }) => !show, renderNothing)
// 	, withHandlers({
// 		[actionName]: props => (...args) => {
// 			props[onToggleShowCallback](show => !show);
// 			if (typeof props[actionName] === "function") {
// 				props[actionName](...args);
// 			}
// 		}
// 	})
// 	, mapProps(({ [onToggleShowCallback]: _, show, ...props }) => props)
// );
//
// // sample usage:
// // const withEventTriggerRedirect = composeWithDisplayName(
// // 	"withEventTriggerRedirect"
// // 	, createWithGeneraliseEvent("onEvent", "trigger", "onTrigger")
// // 	, createWithPropAttachedCallback("id", "onTrigger")
// // );
// //
// // const someHOC = composeWithDisplayName(
// // 	"someHOCName"
// // 	, withEventTriggerRedirect
// // 	, mapProps(({ countdown, ...props }) => {
// // 		if (typeof countdown !== "number") {
// // 			return props;
// // 		}
// // 		return {defaultTick: countdown, ...props};
// // 	})
// // 	, createWithCountdown()
// // 	, withCountdownText
// // 	, withHandlers({
// // 		onClick: ({ onCancel, onClick }) => (...args) => {
// // 			onCancel();
// // 			onClick(...args);
// // 		}
// // 	})
// // );
// export const createWithCountdown = (
// 	tick = 5
// 	, optionalTimer = 1000
// ) => composeWithDisplayName(
// 	"createWithCountdown"
// 	, createWithToggleableMountState("show", "onTrigger")
// 	, defaultProps({defaultTick: tick, defaultTimer: optionalTimer})
// 	, withCountdown
// );
//
// // Example: eventType = 'click' and callbackName = 'onClick'
// export const createWithCallbackRedirect = (
// 	generalisedCallback
// 	, eventType
// 	, callback
// ) => Component => class extends React.PureComponent {
// 	constructor(props) {
// 		super(props);
// 		this.handleCallback = this.handleCallback.bind(this);
// 	}
// 	handleCallback(...args) {
// 		this.props[generalisedCallback](eventType, ...args);
// 	}
// 	render() {
// 		const { ...props } = this.props;
// 		props[callback] = this.handleCallback;
// 		return <Component {...props} />;
// 	}
// };
//
// export const createWithGeneraliseEvent = (
// 	generalisedCallback
// 	, eventType
// 	, callback
// ) => composeWithDisplayName(
// 	"createWithGeneraliseEvent"
// 	, createWithCallbackRedirect(generalisedCallback, eventType, callback)
// 	, mapProps(props => update(props, {$unset: [generalisedCallback]}))
// );
