import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';
import { emptyObject } from '../common/v5/constants';
import {
	withOverwriteProps
	, withRandomProp
	, withTestValue
} from './hocsForTests';
import {
	withDisableableOnClick
	, withIdAttachedOnClick
	, withUnmountWhenHidden
} from './hocs';

export const BTTN_ONE = 1
	, BTTN_TWO = 2
	, BTTN_THREE = 3
	, BTTN_FOUR = 4
	, BTTN_FIVE = 5
	, BTTN_SIX = 6
	, BTTN_SEVEN = 7
	, BTTN_EIGHT = 8
	, BTTN_NINE = 9
	, BTTN_STAR = 10
	, BTTN_ZERO = 11
	, BTTN_HASH = 12
	, BTTN_CLEAR = 13
	, BTTN_DELETE = 14
	, BTTN_CALL = 15
	, BTTN_NIL = 0
	;
export const baseClassName = "c3-dialpad"
	, noPointerEvents = {pointerEvents: "none"}
	, buttonMap = {
		[BTTN_ONE]: ["1", ""]
		, [BTTN_TWO]: ["2", "abc"]
		, [BTTN_THREE]: ["3", "def"]
		, [BTTN_FOUR]: ["4", "ghi"]
		, [BTTN_FIVE]: ["5", "jkl"]
		, [BTTN_SIX]: ["6", "mno"]
		, [BTTN_SEVEN]: ["7", "pqrs"]
		, [BTTN_EIGHT]: ["8", "tuv"]
		, [BTTN_NINE]: ["9", "wxyz"]
		, [BTTN_STAR]: ["*", ""]
		, [BTTN_ZERO]: ["0", "+"]
		, [BTTN_NIL]: ["0", "+"]
		, [BTTN_HASH]: ["#", ""]
		, [BTTN_CLEAR]: ["fas fa-redo fa-xs", I("Clear"), true]
		, [BTTN_DELETE]: ["fas fa-backspace fa-xs", I("Delete"), true]
		, [BTTN_CALL]: ["fas fa-phone fa-xs", I("Call"), true, "call-action"]
	}
	, buttonList = [
		BTTN_ONE
		, BTTN_TWO
		, BTTN_THREE
		, BTTN_FOUR
		, BTTN_FIVE
		, BTTN_SIX
		, BTTN_SEVEN
		, BTTN_EIGHT
		, BTTN_NINE
		, BTTN_STAR
		, BTTN_ZERO
		, BTTN_HASH
		, BTTN_CLEAR
		, BTTN_DELETE
		, BTTN_CALL
	]
	, buttonListIVR = [
		BTTN_ONE
		, BTTN_TWO
		, BTTN_THREE
		, BTTN_FOUR
		, BTTN_FIVE
		, BTTN_SIX
		, BTTN_SEVEN
		, BTTN_EIGHT
		, BTTN_NINE
		, BTTN_NIL
	]
	, mapKeyCharToButton = {
		96: BTTN_ZERO
		, 97: BTTN_ONE
		, 98: BTTN_TWO
		, 99: BTTN_THREE
		, 100: BTTN_FOUR
		, 101: BTTN_FIVE
		, 102: BTTN_SIX
		, 103: BTTN_SEVEN
		, 104: BTTN_EIGHT
		, 105: BTTN_NINE
		, 8: BTTN_DELETE
		, 27: BTTN_CLEAR
		, 106: BTTN_STAR
		, 35: BTTN_HASH
		// , 13: BTTN_CALL
	}
	;
const Sup = ({ text }) => {
	if (!text) {
		return null;
	}
	return <sup>{text}</sup>;
};

const Strong = ({ data, icon }) => {
	let dom;
	if (icon) {
		dom = <i className={data} />
	} else {
		dom = data;
	}
	return <strong>{dom}</strong>;
};

const withNoPointerEvents = Component => class extends PureComponent {
	constructor(props) {
		super(props);
		this.handleChange = this.handleChange.bind(this);
		this.state = {value: ""};
	}
	handleChange(value) {
		this.setState({value});
	}
	render() {
		return (
			<Component
				onChange={this.handleChange}
				value={this.state.value}
				{...this.props}
			/>
		);
	}
};

const OneButtonPadBase = ({
	className
	, disabled
	, isPressed
	, onClick
	, primary
	, primaryIsIcon
	, secondary
	, style
}) => (
	<li
		className={classNames("digits", className, {active: isPressed})}
		onClick={onClick}
		style={disabled ? noPointerEvents : emptyObject}
	>
		<p>
			<Strong icon={primaryIsIcon} data={primary} />
			<Sup text={secondary} />
		</p>
	</li>
);

export const OneButtonPad = withIdAttachedOnClick(OneButtonPadBase);

const ButtonsPadBase = ({ children, style }) => (
	<div className="dials" style={style}><ul>{children}</ul></div>
);

export const ButtonsPad = withDisableableOnClick(ButtonsPadBase);

const Buttons = ({ callInProgress, onClick, pressedKey, ...props }) => (
	<ButtonsPad disabled={callInProgress}>
		{buttonList.map(identifier => {
			const [
					primary
					, secondary
					, isIcon
					, className
				] = buttonMap[identifier]
				;
			return (
				<OneButtonPad
					key={identifier}
					className={className}
					disabled={callInProgress}
					id={identifier}
					isPressed={identifier === pressedKey}
					onClick={onClick}
					primary={primary}
					primaryIsIcon={isIcon}
					secondary={secondary}
				/>
			);
		})}
	</ButtonsPad>
);

const Wrapper = ({ children, compact, ...props }) => (
	<div>
		<section role="main">
			<div
				className={classNames(
					baseClassName
					, "dialpad"
					, {compact}
				)}
			>
				{children}
			</div>
		</section>
	</div>
);

class DialPadBase extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {pressedKey: false};
	}
	isAllowedButtonsChangeValue(pressedKey) {
		const { allowedButtonsChangeValue } = this.props;
		if (!allowedButtonsChangeValue || !allowedButtonsChangeValue.length) {
			return false;
		}
		let found;
		$.each(allowedButtonsChangeValue, (i, v) => {
			if (v === pressedKey) {
				found = true;
				return false;
			}
		});
		return !!found;
	}
	componentDidMount() {
		$(document).on("keydown.dialpad", e => {
			const { callInProgress, onButtonKeyDown } = this.props;
			if (callInProgress || typeof onButtonKeyDown !== "function") {
				return;
			}
			const { which } = e
				, pressedKey = mapKeyCharToButton[which]
				;
			if (pressedKey !== undefined) {
				this.setState({pressedKey});
				if (this.props.pressedButtonChangeValue
					|| this.isAllowedButtonsChangeValue(pressedKey)) {
					onButtonKeyDown(pressedKey, e);
					e.preventDefault();
				}
			} else if (which === this.props.callButton) {
				// purposely bypass pressedButtonChangeValue to allow this
				// button to have some special privilege if need to do so.
				this.setState({pressedKey: BTTN_CALL});
				onButtonKeyDown(BTTN_CALL, e);
				e.preventDefault();
			}
		});
		$(document).on("keyup.dialpad", e => {
			this.setState({pressedKey: false});
		});
	}
	componentWillUnmount() {
		$(document).off("keydown.dialpad");
		$(document).off("keyup.dialpad");
	}
	render() {
		return (
			<Wrapper compact={true}>
				<Buttons
					callInProgress={this.props.callInProgress}
					onClick={this.props.onClick}
					pressedKey={this.state.pressedKey}
				/>
			</Wrapper>
		);
	}
}

const withCallClick = Component => class extends PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
		this.handleButtonKeyDown = this.handleButtonKeyDown.bind(this);
	}
	handleClick(...args) {
		this.directToCallClick(true, ...args);
	}
	handleButtonKeyDown(...args) {
		this.directToCallClick(false, ...args);
	}
	directToCallClick(fromClick, id, ...args) {
		if (id === BTTN_CALL) {
			this.props.onCallClick();
			return;
		}
		if (fromClick) {
			this.props.onClick(id, ...args);
		} else {
			this.props.onButtonKeyDown(id, ...args);
		}
	}
	render() {
		const { onClick, onButtonKeyDown, ...props } = this.props;
		return (
			<Component
				onClick={this.handleClick}
				onButtonKeyDown={this.handleButtonKeyDown}
				{...props}
			/>
		);
	}
};

const withValueChange = Component => class extends PureComponent {
	constructor(props) {
		super(props);
		this.handleValueChange = this.handleValueChange.bind(this);
	}
	handleValueChange(id) {
		let { value } = this.props;
		switch(id) {
			case BTTN_ZERO:
				value += "0";
				break;
			case BTTN_ONE:
				value += "1";
				break;
			case BTTN_TWO:
				value += "2";
				break;
			case BTTN_THREE:
				value += "3";
				break;
			case BTTN_FOUR:
				value += "4";
				break;
			case BTTN_FIVE:
				value += "5";
				break;
			case BTTN_SIX:
				value += "6";
				break;
			case BTTN_SEVEN:
				value += "7";
				break;
			case BTTN_EIGHT:
				value += "8";
				break;
			case BTTN_NINE:
				value += "9";
				break;
			case BTTN_DELETE:
				value = value.slice(0, -1);
				break;
			case BTTN_CLEAR:
				value = "";
				break;
			case BTTN_STAR:
				value += "*";
				break;
			case BTTN_HASH:
				value += "#";
				break;
			default: return;
		}
		if (typeof this.props.onChange === "function") {
			this.props.onChange(value);
		}
	}
	render() {
		const { onClick, onButtonKeyDown, ...props } = this.props;
		return (
			<Component
				onClick={this.handleValueChange}
				onButtonKeyDown={this.handleValueChange}
				{...props}
			/>
		);
	}
};

const withRandomCallInProgress = Component =>
	withRandomProp(Component, "callInProgress", 1500, [false, true]);

// NOTE: sample code conversion from stateless to stateful using 'withTestValue'
const DialPad = compose(
	withUnmountWhenHidden
	// , withOverwriteProps({
	// 	pressedButtonChangeValue: true
	// 	, callButton: 13
	// }) // TODO: remove this as for testing only
	// , withTestValue // TODO: remove this, for testing only
	// , withRandomCallInProgress // TODO: remove this, for testing only
	, withValueChange
	, withCallClick
)(DialPadBase);

DialPad.propTypes = {
	allowedButtonsChangeValue: PropTypes.arrayOf(PropTypes.number)
	, className: PropTypes.string
	, callInProgress: PropTypes.bool
	, hidden: PropTypes.bool
	, onCallClick: PropTypes.func
	, onButtonKeyDown: PropTypes.func
	, onChange: PropTypes.func
	, onClick: PropTypes.func
	, pressedButtonChangeValue: PropTypes.bool
	, callButton: PropTypes.number
	, value: PropTypes.string
};

export default DialPad;
