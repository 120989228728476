import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { breakpoints as _breakpoints } from '../common/theme'

export const useBinaryMediaQueryString = (comparator, breakpoint) => {
  const { breakpoints } = useTheme()
  let compare = breakpoints[comparator]
  if (typeof compare !== 'function') {
    compare = breakpoints.down
  }
  if (typeof _breakpoints[breakpoint] === 'undefined') {
    breakpoint = 'md'
  }
  return compare(breakpoint)
}

// Turn breakpoint range into binary state. 'comparator' can be 'down', 'up',
// etc (check material-ui). 'breakpoint' is the standard name for breakpoints.
// Only work on application with 'ThemeProvider'. Page v5 app carry theme.
export const useBinaryBreakpoint = (
  comparator,
  breakpoint
) => useMediaQuery(useBinaryMediaQueryString(comparator, breakpoint))

export const useIsXL = () => useBinaryBreakpoint('up', 'xl')
