import React, { memo } from "react";
import {
	branch
	, compose
	, mapProps
	, renameProp
	, renderNothing
	, withProps
	, withStateHandlers
} from 'recompose';
import update from 'immutability-helper';
import classNames from 'classnames';
import { I } from '../common/v5/config';
import {
	AT_ACTIVE
	, AT_INACTIVE
	, AT_PARTIAL
	, CTX_MY
	, emptyObject
} from '../common/v5/constants';
import { AnchorBase } from './Anchor';
import {
	composeWithDisplayName
	, createWithPropAttachedOnClick
	, withIdAttachedOnClick
	, withUnmountWhenHidden
} from './hocs';
import SearchFilter from './SearchFilter';

// utility function for worklow to check counter for each filter given it's set
const checkCounter = (id, data, showAll) => {
	let val;
	for (var key in data) {
		if (key == id) {
			if (data.hasOwnProperty(key)) {
				val = data[key];
				return val ? val : 0;
			}
		}
	}
	if (showAll) {
		return 0;
	}
};

const braceCount = count => "(" + count + ")";

const spaceBraceCount = count => " " + braceCount(count);

const spaceBraceCountIfShow = (show, count) => show ? spaceBraceCount(count) : "";

const CountBase = ({ count }) => <span>{" "+braceCount(count)}</span>;

const Count = branch(({ count, noCounter }) => (!count || noCounter), renderNothing)(CountBase);

const withDataRemove = mapProps(({ remove, ...props }) => ({
	"data-remove": "true" // remove ? "true" : "false"
	, ...props
}));

const LinkBase = withDataRemove(AnchorBase);

// TODO: remove this as it is useless.
const withActiveStateControl = withStateHandlers(
	({ active: false })
	, {onToggleActive: ({ active }) => () => ({active: !active})}
);

const FilterItemBase = ({ count, noCounter, itemClass, name, onClick }) => (
	<LinkBase
		className={classNames(itemClass, {active: !itemClass})}
		onClick={onClick}
	>
		{name}<Count count={count} noCounter={noCounter}/>
	</LinkBase>
);

const withFilterClick = Component => class extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick() {
		const { active, id, onFilterClick, onToggleActive, type } = this.props;
		if (type == "link") {
			const toggleActive = !active;
			if (typeof onToggleActive === "function") {
				onToggleActive(toggleActive);
			}
			onFilterClick(id, toggleActive);
		}
	}
	render() {
		const {
				active
				, id
				, onToggleActive
				, onFilterClick
				, type
				, ...props
			} = this.props
			;
		return <Component onClick={this.handleClick} {...props} />;
	}
}

const FilterItem = composeWithDisplayName(
	"FilterItem"
	, withProps({type: "link"})
	// , withActiveStateControl
	, withFilterClick
)(FilterItemBase);

const OneFilterItem = ({
	count
	, noCounter
	, id
	, itemClass
	, name
	, onClick
	, selected
	, ...props
}) => (
	<li
		className={classNames({current: selected})}
		data-qa-id={props["data-qa-id"]}
	>
		<FilterItem
			active={selected}
			id={id}
			itemClass={itemClass}
			name={name}
			count={count}
			noCounter={noCounter}
			onFilterClick={onClick}
		/>
	</li>
);

const CircleRemoveBase = ({ active, ...props }) => (
	<LinkBase {...props}>
		<i
			className={classNames({
				"icon-circled-times": active
				, "far fa-plus-square": !active // NOTE: can not find free circle plus
			})}
		/>
	</LinkBase>
);

const NameLink = AnchorBase;

const withNameCircleRemove = Tag => ({
	active
	, children
	, className
	, count
	, nameQAId
	, onSelect
	, onToggle
	, removeQAId
}) => {
	const visibleClass = classNames({active, inactive: !active});
	return (
		<Tag className={className}>
			<NameLink
				className={visibleClass}
				data-qa-id={nameQAId}
				onClick={onSelect}
			>
				{children}
			</NameLink>
			<CircleRemoveBase
				active={active}
				className={visibleClass}
				data-qa-id={removeQAId}
				onClick={onToggle}
			/>
		</Tag>
	);
};

function getAreasIdsAndObjectFromOrg(normOrgs, org) {
	if (!normOrgs || !org || !normOrgs[org]) {
		return;
	}
	const array = [], object = {};
	$.each(normOrgs[org].Areas, (i, { Id }) => {
		object[Id] = true;
		array.push(Id);
	});
	return {array, object};
}

// Check if 'thisId' is the only key exist inside 'selected'. Return false if
// 'thisId' not inside 'selected'.
function onlyThisIdSelected(selected, thisId) {
	if (!selected[thisId]) {
		return false;
	}
	let found;
	$.each(selected, (k, v) => {
		if (k != thisId && v) {
			found = true;
			return false;
		}
	});
	return !found;
}

const ST_AREA = 1
	, ST_ORG = 2
	;
const withSelectOrgArea = Component => class extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleSelect = this.handleSelect.bind(this);
		this.handleToggle = this.handleToggle.bind(this);
	}
	handleSelect(type, id, active) {
		const {
				orgAreas
				, onSelect
				, selectedAreas
				, selectedOrgs
				, ... props
			} = this.props
			, { byOrg, byArea } = orgAreas
			;
		if (type === ST_AREA) {
			const area = byArea[id];
			if (area) {
				if (!onlyThisIdSelected(selectedAreas, id)) {
					onSelect(update(selectedAreas, {$set: {[id]: true}}));
				} else {
					// TODO: do we want to trigger different behaviour when
					// currently selected areas already single select one
					// area from an org? Now trying to backward compatible
					// that is it will be unselect the area.
					onSelect(update(selectedAreas, {$set: emptyObject}));
				}
			}
		} else if (type === ST_ORG) {
			const areas = getAreasIdsAndObjectFromOrg(byOrg, id);
			if (areas) {
				if (onlyThisIdSelected(selectedOrgs, id)
					&& active === AT_ACTIVE) {
					// TODO: do we want to trigger different behaviour when
					// currently selected orgs already single select one org
					// areas only? Now trying backward compatible that is
					// unselect the areas of the org.
					onSelect(update(selectedAreas, {$unset: areas.array}));
				} else {
					if (active === AT_PARTIAL) {
						onSelect(update(selectedAreas, {$merge: areas.object}));
					} else {
						onSelect(update(selectedAreas, {$set: areas.object}));
					}
				}
			}
		}
	}
	handleToggle(type, id, active) {
		const { orgAreas, onSelect, selectedAreas, ... props } = this.props
			, { byOrg } = orgAreas
			;
		if (type === ST_AREA) {
			if (active) {
				onSelect(update(selectedAreas, {$unset: [id]}));
			} else {
				onSelect(update(selectedAreas, {[id]: {$set: true}}));
			}
		} else if (type === ST_ORG) {
			const areas = getAreasIdsAndObjectFromOrg(byOrg, id);
			if (typeof areas !== "undefined") {
				if (active === AT_INACTIVE) {
					onSelect(update(selectedAreas, {$merge: areas.object}));
				} else {
					onSelect(update(selectedAreas, {$unset: areas.array}));
				}
			}
		}
	}
	render() {
		const { onSelect, onToggle, ... props } = this.props;
		return (
			<Component
				onSelect={this.handleSelect}
				onToggle={this.handleToggle}
				{...props}
			/>
		);
	}
};

const withOnClickGroupFalse = Component => class extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleClick = this.handleClick.bind(this);
	}
	handleClick(id, delOrAdd) {
		this.props.onClick(id, delOrAdd, false);
	}
	render() {
		const { onClick, ... props } = this.props;
		return <Component onClick={this.handleClick} {...props} />;
	}
}

const createWithTypeIdActiveAttached = activeProp => Component => {
	return class extends React.PureComponent {
		constructor(props) {
			super(props);
			this.handleSelect = this.handleSelect.bind(this);
			this.handleToggle = this.handleToggle.bind(this);
		}
		handleCallback(callbackName) {
			this.props[callbackName](
				this.props.type
				, this.props.id
				, this.props[activeProp]
			);
		}
		handleSelect(e) {
			this.handleCallback("onSelect");
		}
		handleToggle(e) {
			this.handleCallback("onToggle");
		}
		render() {
			const { onSelect, onToggle, ...props } = this.props;
			return (
				<Component
					onSelect={this.handleSelect}
					onToggle={this.handleToggle}
					{...props}
				/>
			);
		}
	};
};

const AreaBase = composeWithDisplayName(
	"AreaBase"
	, withProps({type: ST_AREA})
	, createWithTypeIdActiveAttached("active")
	, withNameCircleRemove
)("li");

const AreaSubFilterItem = ({
	id
	, count
	, name
	, noCounter
	, onSubClick
	, ...props
}) => (
	<AreaBase
		id={id}
		nameQAId={"QA_areaFilter_"+name+"_"+id}
		onClick={onSubClick}
		removeQAId={"QA_areaRemove_"+name+"_"+id}
		{...props}
	>
		{name + spaceBraceCountIfShow(!noCounter, count)}
	</AreaBase>
);

function isSelectedOrg(normalizedData, id) {
	const data = normalizedData[id];
	if (typeof data === "undefined") {
		return AT_INACTIVE;
	}
	return data;
}

const withActiveAndActiveType = withProps(({ id, selected }) => {
	const activeType = isSelectedOrg(selected, id);
	return {active: activeType !== AT_INACTIVE, activeType};
});

const OrgBase = composeWithDisplayName(
	"OrgBase"
	, withActiveAndActiveType
	, withProps({type: ST_ORG})
	, createWithTypeIdActiveAttached("activeType")
	, withNameCircleRemove
)("div");

const HideableCount = composeWithDisplayName(
	"HideableCount"
	, memo
	, withUnmountWhenHidden
)(CountBase);

const Org = ({ id, count, noCounter, name, ...props }) => (
	<OrgBase
		id={id}
		nameQAId={"QA_orgFilter_"+name+"_"+id}
		removeQAId={"QA_orgRemove_"+name+"_"+id}
		{...props}
	>
		{name}<HideableCount hidden={noCounter} count={count} />
	</OrgBase>
);

function isSelectedArea(normalizedData, id) {
	const data = normalizedData[id];
	if (typeof data === "undefined") {
		return false;
	}
	return data;
}

const ShowLessTextBase = ({ onClick, show }) => (
	<div className="show-all-area">
		<a onClick={onClick}>{show ? I("show less") : I("show all")}</a>
	</div>
);

const ShowLessText = composeWithDisplayName(
	"ShowLessText"
	, memo
	, withUnmountWhenHidden
)(ShowLessTextBase);

const AreaGroupFilterBase = ({
	counter
	, data
	, grpClass
	, mainClass
	, name
	, noCounter
	, onSelect
	, onToggle
	, selectedAreas
	, selectedOrgs
	, subClass
	, onShowAll
	, showAll
}) => {
	// How this should work
	// Parent(org) child(area) filtering
	// Exactly how area filter should be working
	// Select Organisation will toggle all the area action
	// Select Area, will toggle single specific area action
	// Accepts json data with grpData(org) and subData(area) props
	let grpData = [];
	$.each(data, (k, v) => {
		let subData = [], countPerOrg = [];
		$.each(v["Areas"], (sk, sv) => {
			let countPerArea;
			if (noCounter) {
				countPerArea = 0;
			} else {
				countPerArea = checkCounter(sv.Id, counter, showAll);
			}
			if (typeof countPerArea !== "undefined"
				&& (noCounter || showAll || countPerArea !== 0)
				&& typeof countPerArea === "number") {
				countPerOrg.push(countPerArea);
				subData.push(
					<AreaSubFilterItem
						key={"subFilter_"+sv.Id+"_"+k}
						active={isSelectedArea(selectedAreas, sv.Id)}
						id={sv.Id}
						name={sv.Name}
						count={countPerArea}
						child={true}
						noCounter={noCounter}
						onSelect={onSelect}
						onToggle={onToggle}
						parentId={v.Id}
					/>
				);
			}
		});
		if (subData.length > 0) {
			grpData.push(
				<li
					key={"grpList_"+name+"_"+k}
					data-qa-id={"grpList_"+name+"_"+k}
				>
					<Org
						className={mainClass}
						count={noCounter ? 0 : countPerOrg.reduce((a, b) => a + b, 0)}
						id={v.Id}
						name={v.Name}
						noCounter={noCounter}
						onSelect={onSelect}
						onToggle={onToggle}
						selected={selectedOrgs}
					/>
					<ul className={subClass}>
						{subData}
					</ul>
				</li>
			);
		}
	});
	return (
		<ul className={grpClass}>
			{grpData}
			<ShowLessText
				hidden={noCounter}
				onClick={onShowAll}
				show={showAll}
			/>
		</ul>
	);
};

const AreaGroupFilter = composeWithDisplayName(
	"AreaGroupFilter"
	, renameProp("onChange", "onClick")
	, createWithPropAttachedOnClick("context")
	, renameProp("onClick", "onSelect")
	, withSelectOrgArea
)(AreaGroupFilterBase);

const withDroppedClassWhenShow = Component => ({ show, ...props }) => (
	<Component
		className={classNames({dropped: show})}
		data-dropdown-action
		{...props}
	/>
);

const DropdownFilter = composeWithDisplayName(
	"DropdownFilter"
	, withDroppedClassWhenShow
)("a");

const idValue = {id: "Id", value: "Name"};

const IdNameSearchFilter = props => <SearchFilter fields={idValue} {...props} />;

export class AreaFilter extends React.PureComponent {
	constructor(props){
		super(props);
		this.handleLoadFilteredAreas = this.handleLoadFilteredAreas.bind(this);
	}
	handleLoadFilteredAreas(areas){
		this.props.onSetFilteredAreas(areas);
	}
	render(){
		const {
			context,
			top,
			show,
			data,
			counter,
			onToggleAreasListFilter,
			getCurrentAreas,
			noCounter,
			orgAreas,
			onChange,
			selectedAreas,
			selectedOrgs,
			onShowAll,
			showAll
		} = this.props;
		return (
			<li>
				<DropdownFilter
					data-qa-id="QA_toggleAreaFilter"
					onClick={onToggleAreasListFilter}
					show={show}
				>
					{I("Areas")}
				</DropdownFilter>
				<IdNameSearchFilter
					name="search_area"
					label={I("Search area")}
					data={data}
					onSelectFilter={this.handleLoadFilteredAreas}
					nestedSearch={true}
					nestedGroup="Areas"
				/>
				<AreaGroupFilter
					context={context}
					name="AreaFilter"
					mainClass="area"
					data={getCurrentAreas}
					grpData="Id"
					grpClass="areas-menu"
					noCounter={noCounter}
					orgAreas={orgAreas}
					selectedAreas={selectedAreas}
					selectedOrgs={selectedOrgs}
					subData="Areas"
					subClass="areas-submenu"
					onChange={onChange}
					counter={counter}
					onShowAll={onShowAll}
					showAll={showAll}
					showListBullet={top ? true : false}
				/>
			</li>
		);
	}
}

export class PriorityFilter extends React.PureComponent {
	constructor(props) {
		super(props);
	}
	handleSortBy = (id, status) => {
		this.props.onSortBy(id, status ? "yes" : "no");
	}
	render() {
		// TODO: Get how many High priority, Warnings, Collaboration, Expired.
		const { selected, ...p } = this.props;
		let pList = [];
		$.each(p.paramsSet, (k, { className, id, name }) => {
			pList.push(
				<OneFilterItem
					key={id+"_"+k}
					itemClass={className}
					data-qa-id={"priorityList_"+name+"_sort_"+id}
					id={id}
					name={name}
					onClick={this.handleSortBy}
					selected={selected[id] === "yes"}
				/>
			);
		});
		return (
			<ul
				className={classNames(
					"sidebar-menu with-priority"
					, {"top-filter": p.top}
				)}
			>
				{pList}
			</ul>
		);
	}
}

export class AgentFilter extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			filteredAgents: []
		};
	}
	handleFilterAgent = (id, status) => {
		let params = {};
		params.user = id;
		params.source = CTX_MY;
		this.props.onClickAgent(params);
	}
	handleLoadFilteredAgents = agents => {
		this.setState({filteredAgents: agents});
	}
	render() {
		const { counter, noCounter, selectedAgent, ...props } = this.props;
		let agents = [], agentList, data, fromFilter;
		if (this.state.filteredAgents.length > 0) {
			fromFilter = true;
			agentList = this.state.filteredAgents;
			data = agentList;
		} else {
			agentList = [];
			data = props.data;
		}
		$.each(data, (k, v) => {
			const count = checkCounter(v.Id, counter, false);
			if (fromFilter || typeof count !== "undefined") {
				if (!fromFilter) {
					agentList.push(v);
				}
				agents.push(
					<OneFilterItem
						key={"agentList_"+v.Id}
						count={count}
						noCounter={noCounter}
						data-qa-id={"agentFilter_"+v.Name+"_"+v.Id}
						id={v.Id}
						name={v.Name}
						onClick={this.handleFilterAgent}
						selected={selectedAgent == v.Id}
					/>
				);
			}
		});
		return (
			<ul className="sidebar-menu agents">
				<IdNameSearchFilter
					name={"search_agent"}
					label={I("Search agents")}
					data={agentList}
					onSelectFilter={this.handleLoadFilteredAgents}
				/>
				{agents}
			</ul>
		);
	}
}

const idValueCount = {id: "id", value: "name", count: "count"};

export class TagFilter extends React.PureComponent {
	constructor(props) {
		super(props);
		this.handleFilterTag = this.handleFilterTag.bind(this);
		this.handleLoadFilteredTags = this.handleLoadFilteredTags.bind(this);
	}
	handleFilterTag(id, add) {
		this.props.onSetSelectedTags(id, add);
		this.props.onClickTag();
	}
	handleLoadFilteredTags(tags) {
		this.props.onSetFilteredTags(tags);
	}
	render() {
		const {
				currentFiltered
				, currentSelected
				, currentTags
				, onClickPanel
				, showTagsFilter
				, noCounter
			} = this.props
			;
		let data, tags = [];
		if (currentFiltered.length > 0) {
			data = currentFiltered;
		} else {
			data = currentTags;
		}
		$.each(data, (k, { id, name, count }) => {
			tags.push(
				<OneFilterItem
					key={"tagList_"+k+"_"+id}
					count={count}
					noCounter={noCounter}
					data-qa-id={"tagList_"+name+"_"+id}
					id={id}
					name={name}
					onClick={this.handleFilterTag}
					selected={currentSelected.indexOf(id) > -1}
				/>
			);
		});
		return (
			<li>
				<DropdownFilter
					data-qa-id="QA_toggleTagFilter"
					onClick={onClickPanel}
					show={showTagsFilter}
				>
					{I("Tags")}
				</DropdownFilter>
				<SearchFilter
					name={"search_tag"}
					label={I("Search tags")}
					data={currentTags}
					fields={idValueCount}
					onSelectFilter={this.handleLoadFilteredTags}
				/>
				<ul className="sidebar-menu tags">
					{tags}
				</ul>
			</li>
		);
	}
}
