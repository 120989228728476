import React from 'react';
import { Alert } from 'reactstrap';

export function DismissableAlert({ show, role, text, icon, onDismiss }) {
	let style = {
		background: '#FDDFE3',
		color: '#9C0016',
		fontSize: '14px',
		padding: '16px',
		borderRadius: '10px',
		border: 'none',
	}
	if (role === 'success') {
		style = {
			background: '#D5F2EA',
			color: '#007343',
			fontSize: '14px',
			padding: '16px',
			border: 'none',
			borderRadius: '10px'
		}
	}
	const leftIcon = <i className={icon} style={{fontSize: '21px', marginRight: '24px', verticalAlign: 'top'}} aria-hidden="true"></i>;
	return (
		<Alert isOpen={show} toggle={onDismiss} style={style}>
			{leftIcon}
			{text}
		</Alert>
	);
}
