import React from 'react';
import classNames from 'classnames';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import {
	centionGreen
	, centionRed
} from '../styles/_variables'
import { isSipConnected } from '../common/v5/helpers';

const StyledSipStatusWithStatus = styled.span`
	// padding-left: ${props => props.collapsed ? "0px": "5px"};
	&:after {
		display: ${props => props.collapsed ? "inline-block" : "none"};
		width: 11px;
		height: 11px;
		border-radius: 30px;
		background-color: #ccc;
		content: '';
		// left: 20px;
		position: absolute;
		top: 0px;
		right: 0px;
		transition: background-color .2s ease;
		background: ${props => props.active ? centionGreen : centionRed};
	}
`;

const StyledSipStatusText = styled.span`
	font-size: 13px;
	color: ${props => props.active ? centionGreen : centionRed};
`;

class SipStatus extends React.Component {
	constructor(props) {
		super(props);
	}
	render() {
		let isConnected = isSipConnected(this.props.sipCallStatus);
		let connectText = I("SIP disconnected");
		if(isConnected == true){
			connectText = I("SIP connected");
		}
		return (
		<StyledSipStatusWithStatus className='c3-call-icon'
			active={isConnected} 
			collapsed={this.props.collapsed}>
			<i className="icon-phone"/>
			<StyledSipStatusText hidden={this.props.collapsed}
				active={isConnected}
				> {connectText}</StyledSipStatusText>
		</StyledSipStatusWithStatus>
		)
	}
}

SipStatus.propTypes = {
};

export default SipStatus;
